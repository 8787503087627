import anfahrtszeit from './anfahrtszeit';
import rueckfahrtzeit from './rueckfahrtzeit';
import anfahrtsstrecke from './anfahrtsstrecke';
import rueckfahrtstrecke from './rueckfahrtstrecke';
import standardleistungenTour from './abrechnung-standardleistungen-tour';
import bulkyTour from './abrechnung-bulky-tour';
import identServiceTour from './abrechnung-ident-service-tour';
import nachnahmeTour from './abrechnung-nachnahme-tour';
import gepaeckAbholungTour from './abrechnung-gepaeck-abholung-tour';
import zeitfensterTour from './abrechnung-zeitfenster-tour';
import zusMitnahmeTour from './abrechnung-zus-mitnahme-tour';
import quittungslosTour from './abrechnung-quittungslos-tour';
import coincidentTour from './abrechnung-coincident-tour';
import psStoppsTour from './abrechnung-ps-stopps-tour';
import psSendungenTour from './abrechnung-ps-sendungen-tour';
import atgStoppsTour from './abrechnung-atg-stopps-tour';
import atgSendungenTour from './abrechnung-atg-sendungen-tour';
import eilSendungenTour from './abrechnung-eil-sendungen-tour';
import sendungenZugestelltUhrzeit from './sendungen-zugestellt-uhrzeit';
import sendungenDauervollmacht from './sendungen-dauervollmacht';
import sendungenEilservice from './sendungen-eilservice';
import sendungenIdentservice from './sendungen-identservice';
import sendungenNachnahme from './sendungen-nachnahme';
import sendungenWunschzeitfenster from './sendungen-wunschzeitfenster';
import sendungenQuittungslos from './sendungen-quittungslos';
import nachnahmeBetrag from './nachnahme-betrag';
import empfaenger from './empfaenger';
import abholvorgaenge from './abholvorgaenge';
import abholvorgaengeUhrzeit from './abholvorgaenge-uhrzeit';
import abholvorgaengeSendugsanzahl from './abholvorgaenge-sendungsanzahl';
import abholvorgaengeQuelle from './abholvorgaenge-quelle';
import sendungenAbgeholt from './sendungen-abgeholt';
import sendungenAbgeholtSendungsart from './sendungen-abgeholt-sendungsart';
import sendungenAbgeholtUhrzeit from './sendungen-abgeholt-uhrzeit';
import zustellvorgaenge from './zustellvorgaenge';
import zustellvorgaengeEmpfaengeranzahl from './zustellvorgaenge-empfaengeranzahl';
import zustellvorgaengeSendungsanzahl from './zustellvorgaenge-sendungsanzahl';
import geldbetrag from './geldbetrag';
import benachrichtigungen from './benachrichtigungen';
import benachrichtigungenArt from './benachrichtigungen-art';
import wertsendungenTour from './wertsendungen-tour';
import stoppsanzahlNachweisart from './stoppsanzahl-nachweisart';
import touren from './touren';
import werktage from './werktage';
import gpsGenauigkeit from './gps-genauigkeit';
import gpsVerzoegerung from './gps-verzoegerung';
import prognoseSendungseingangsmenge from './prognose-sendungseingangsmenge';
import prognoseSendungseingangsmengeDatum from './prognose-sendungseingangsmenge-datum';
import einwohner from './einwohner';
import flaeche from './flaeche';
import servicebeanstandungenMeldung from './servicebeanstandungen-meldung';
import servicebeanstandungenGrund from './servicebeanstandungen-grund';
import liegenbleiber from './liegenbleiber';
import liegenbleiberEil from './liegenbleiber-eil';
import lagernde from './lagernd';
import afnus from './afnus';
import bestand from './bestand';
import zugangsmenge from './zugangsmenge';
import abwicklungsmengeZsb from './abwicklungsmenge-zsb';
import abwicklungsmenge from './abwicklungsmenge';
import abholungenByReturnType from './abholungen-by-return-type';
import mitnahmenType from './mitnahmen-by-return-type';
import abholungen from './abholungen-ohne-mitnahmen';
import abholungenMitnahmen from './abholungen-und-mitnahmen';
import mitnahmen from './mitnahmen';
import atgAbholungen from './atg-abholungen';
import psAbgaben from './ps-abgaben';
import psMitnahmenByReturnType from './ps-mitnahmen-by-return-type';
import psMitnahmen from './ps-mitnahmen';
import ruecklaufAbholkartenGrund from './ruecklauf-abholkarten-grund';
import ruecklaufAbholkarten from './ruecklauf-abholkarten';
import ruecklauferEilGrund from './ruecklaufer-eil-grund';
import ruecklaufer from './ruecklaufer';
import ruecklauferEil from './ruecklaufer-eil';
import abholauftraegeInclNichtGestartet from './abholauftraege-incl-nicht-gestartet';
import sollLademenge from './lademenge-soll';
import sollLademengeAvg from './lademenge-soll-avg';
import istLademenge from './lademenge-ist';
import istLademengeAvg from './lademenge-ist-avg';
import abholauftraege from './abholauftraege';
import abholauftraegeSuccessful from './abholauftraege-successful';
import zustellungenArt from './zustellungen-art';
import zustellungen from './zustellungen';
import wertsendungenTourSbInclNichtGestartet from './wertsendungen-tour-sb-incl-nicht-gestartet';
import bulky from './bulky';
import bulkyZugestellt from './bulky-zugestellt';
import lademengeSendungsart from './lademenge-sendungsart';
import hinweisHtWzfZustellung from './hinweis-ht-wzf-zustellung';
import hinweisHtWzfAbholung from './hinweis-ht-wzf-abholung';
import hinweisAtgWzfAbholung from './hinweis-atg-wzf-abholung';
import hinweisWzfUnknown from './hinweis-wzf-unknown';
import hinweisTagversetzteBearbeitung from './hinweis-tagversetzte-bearbeitung';
import hinweisRuecklaufAmWunschtag from './hinweis-ruecklauf-am-wunschtag';
import hinweisAusserhalbZustellzeit from './hinweis-ausserhalb-zustellzeit';
import hinweisTeillieferungMehrcollig from './hinweis-teillieferung-mehrcollig';
import hinweisRuecklaufAmTourende from './hinweis-ruecklauf-am-tourende';
import bruttoZustellzeit from './brutto-zustellzeit';
import nettoHtZeit from './netto-ht-zeit';
import nettoPsZeit from './netto-ps-zeit';
import nettoAtgZeit from './netto-atg-zeit';
import ruecklauferGrund from './ruecklaufer-grund';
import dauervollmachtquote from './dauervollmachtquote';
import zustellungenAvg from './zustellungen-avg';
import hinweisAusserhalbWzf from './hinweis-ausserhalb-wzf';
import servicebeanstandungenQuoteZsb from './servicebeanstandungen-quote-zsb';
import servicebeanstandungenQuoteVerlustrelevant from './servicebeanstandungen-quote-verlustrelevant';
import ruecklauferAvg from './ruecklaufer-avg';
import ruecklaufQuote from './ruecklauf-quote';
import bruttoZustellzeitAvg from './brutto-zustellzeit-avg';
import nettoHtZeitAvg from './netto-ht-zeit-avg';
import nettoPsZeitAvg from './netto-ps-zeit-avg';
import nettoAtgZeitAvg from './netto-atg-zeit-avg';
import nettoZustellzeit from './netto-zustellzeit';
import nettoZustellzeitAvg from './netto-zustellzeit-avg';
import htProduktivitaet from './ht-produktivitaet';
import psProduktivitaet from './ps-produktivitaet';
import atgProduktivitaet from './atg-produktivitaet';
import abholungenAvg from './abholungen-avg';
import atgAbholungenAvg from './atg-abholungen-avg';
import schadenVerlustQuote from './schaden-verlust-quote';
import servicebeanstandungenMeldungVerlustrelevant from './servicebeanstandungen-meldung-verlustrelevant';
import psMitnahmenAvg from './ps-mitnahmen-avg';
import psAbgabenAvg from './ps-abgaben-avg';
import anfahrtszeitAvg from './anfahrtszeit-avg';
import rueckfahrtzeitAvg from './rueckfahrtzeit-avg';
import tourfahrzeit from './tourfahrzeit';
import tourfahrzeitAvg from './tourfahrzeit-avg';
import anfahrtsstreckeAvg from './anfahrtsstrecke-avg';
import rueckfahrtstreckeAvg from './rueckfahrtstrecke-avg';
import tourstrecke from './tourstrecke';
import tourstreckeAvg from './tourstrecke-avg';
import fortschritt from './tourfortschritt';
import liegenbleiberQuote from './liegenbleiber-quote';
import liegenbleiberEilQuote from './liegenbleiber-eil-quote';
import lagerndQuote from './lagernd-quote';
import ePlus0Quote from './e-plus-0-quote';
import ePlus0EilQuote from './e-plus-0-eil-quote';
import zsbPlus0Quote from './zsb-plus-0-quote';
import zsbPlus0EilQuote from './zsb-plus-0-eil-quote';
import benachrichtigungenQuote from './benachrichtigungen-quote';
import fotoUnterschriftenNutzungsquote from './foto-unterschriften-nutzungsquote';
import lademengePerKm from './lademenge-per-km';
import lademengePerEinwohner from './lademenge-per-einwohner';
import einwohnerProKilometer2 from './einwohner-pro-kilometer2';
import zustellungenPerEinwohner from './zustellungen-per-einwohner';
import zustellungenPerHour from './zustellungen-per-hour';
import zustellungenPerKm from './zustellungen-per-km';
import zustellungenNachbarQuote from './zustellungen-nachbar-quote';
import tourFreigabeZeitpunkt from './tour-freigabe-zeitpunkt-avg';
import altgeraetemitnahme from './abrechnung-altgeraete-mitnahme-tour';
import tourStoppsHt from './tour-stops-ht';
import tourStoppsPs from './tour-stops-ps';
import tourStoppsAtg from './tour-stops-atg';
import reihenfolgeEinhaltungQuote from './reihenfolgeeinhaltung-quote';
import sendungenTanTour from './sendungen-tan-tour';
import sendungenTanZugestellt from './sendungen-tan-zustellungen';
import sendungenTanRuecklauf from './sendungen-tan-ruecklaeufer';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

const valueExpressions = [
  anfahrtszeit,
  rueckfahrtzeit,
  anfahrtsstrecke,
  rueckfahrtstrecke,
  standardleistungenTour,
  bulkyTour,
  identServiceTour,
  nachnahmeTour,
  gepaeckAbholungTour,
  zeitfensterTour,
  zusMitnahmeTour,
  quittungslosTour,
  coincidentTour,
  psStoppsTour,
  psSendungenTour,
  atgStoppsTour,
  atgSendungenTour,
  eilSendungenTour,
  sendungenZugestelltUhrzeit,
  sendungenDauervollmacht,
  sendungenEilservice,
  sendungenIdentservice,
  sendungenNachnahme,
  sendungenWunschzeitfenster,
  sendungenQuittungslos,
  nachnahmeBetrag,
  empfaenger,
  abholvorgaenge,
  abholvorgaengeUhrzeit,
  abholvorgaengeSendugsanzahl,
  abholvorgaengeQuelle,
  sendungenAbgeholt,
  sendungenAbgeholtSendungsart,
  sendungenAbgeholtUhrzeit,
  zustellvorgaenge,
  zustellvorgaengeEmpfaengeranzahl,
  zustellvorgaengeSendungsanzahl,
  geldbetrag,
  benachrichtigungen,
  benachrichtigungenArt,
  wertsendungenTour,
  stoppsanzahlNachweisart,
  touren,
  werktage,
  gpsGenauigkeit,
  gpsVerzoegerung,
  prognoseSendungseingangsmenge,
  prognoseSendungseingangsmengeDatum,
  einwohner,
  flaeche,
  servicebeanstandungenMeldung,
  servicebeanstandungenGrund,
  liegenbleiber,
  liegenbleiberEil,
  lagernde,
  afnus,
  bestand,
  zugangsmenge,
  abwicklungsmengeZsb,
  abwicklungsmenge,
  abholungenByReturnType,
  mitnahmenType,
  abholungenMitnahmen,
  abholungen,
  mitnahmen,
  atgAbholungen,
  psAbgaben,
  psMitnahmenByReturnType,
  psMitnahmen,
  ruecklaufAbholkartenGrund,
  ruecklaufAbholkarten,
  ruecklauferEilGrund,
  ruecklaufer,
  ruecklauferEil,
  ruecklauferGrund,
  abholauftraegeInclNichtGestartet,
  istLademenge,
  istLademengeAvg,
  sollLademenge,
  sollLademengeAvg,
  abholauftraege,
  abholauftraegeSuccessful,
  zustellungenArt,
  zustellungen,
  zustellungenNachbarQuote,
  wertsendungenTourSbInclNichtGestartet,
  bulky,
  bulkyZugestellt,
  lademengeSendungsart,
  hinweisHtWzfZustellung,
  hinweisHtWzfAbholung,
  hinweisAtgWzfAbholung,
  hinweisWzfUnknown,
  hinweisTagversetzteBearbeitung,
  hinweisRuecklaufAmWunschtag,
  hinweisAusserhalbZustellzeit,
  hinweisTeillieferungMehrcollig,
  hinweisRuecklaufAmTourende,
  bruttoZustellzeit,
  nettoHtZeit,
  nettoPsZeit,
  nettoAtgZeit,
  dauervollmachtquote,
  zustellungenAvg,
  hinweisAusserhalbWzf,
  servicebeanstandungenQuoteZsb,
  servicebeanstandungenQuoteVerlustrelevant,
  ruecklauferAvg,
  ruecklaufQuote,
  bruttoZustellzeitAvg,
  nettoHtZeitAvg,
  nettoPsZeitAvg,
  nettoAtgZeitAvg,
  nettoZustellzeit,
  nettoZustellzeitAvg,
  htProduktivitaet,
  psProduktivitaet,
  atgProduktivitaet,
  abholungenAvg,
  atgAbholungenAvg,
  schadenVerlustQuote,
  servicebeanstandungenMeldungVerlustrelevant,
  psMitnahmenAvg,
  psAbgabenAvg,
  anfahrtszeitAvg,
  rueckfahrtzeitAvg,
  tourfahrzeit,
  tourfahrzeitAvg,
  anfahrtsstreckeAvg,
  rueckfahrtstreckeAvg,
  tourstrecke,
  tourstreckeAvg,
  fortschritt,
  liegenbleiberQuote,
  liegenbleiberEilQuote,
  lagerndQuote,
  ePlus0Quote,
  ePlus0EilQuote,
  zsbPlus0Quote,
  zsbPlus0EilQuote,
  benachrichtigungenQuote,
  fotoUnterschriftenNutzungsquote,
  lademengePerKm,
  lademengePerEinwohner,
  einwohnerProKilometer2,
  zustellungenPerEinwohner,
  zustellungenPerHour,
  zustellungenPerKm,
  tourFreigabeZeitpunkt,
  altgeraetemitnahme,
  tourStoppsHt,
  tourStoppsPs,
  tourStoppsAtg,
  reihenfolgeEinhaltungQuote,
  sendungenTanTour,
  sendungenTanZugestellt,
  sendungenTanRuecklauf,
];

const valueExpressionsWithChildren = valueExpressions.flatMap((ve) => [ve, ...ve.getChildren()]);

export default new Map<string, ValueExpression>(valueExpressionsWithChildren.map((ve) => [ve.identifier, ve]));
