import { useMemo } from 'react';

import { OrgKey } from '@data-table/data-table.types';
import { Duration } from '@legacy-modules/dashboard/models/enums/Duration';
import { extractOrgIdFromOrgKey } from '@legacy-modules/dashboard/utils/OrgKeyUtils';
import { TourUtils } from '@legacy-modules/utils/tours/TourUtils';
import { DurationUtils } from '@utils/duration-utils';
import { TourIdentifierWithOrgKeys } from './types';
import { useToursOfOrgUnitWithAuth } from '@hooks/use-tours-by-org-unit-hook';
import { ContractorUtils } from '@legacy-modules/utils/tours/ContractorUtils';
import { useToursDrivenByContractorWithAuth } from '@hooks/use-tours-driven-by-contractor-hook';
import { ZSBUtils } from '@legacy-modules/utils/tours/ZSBUtils';
import TourListEntry from '@legacy-modules/tour/models/entities/TourListEntry';

type UseTourListOutput = {
  tourList: { tours: Array<TourListEntry> };
  uniqueTourIdentifiers: TourIdentifierWithOrgKeys[];
  isLoading: boolean;
};

const useTourList = (
  orgKey: OrgKey,
  duration: Duration,
  isComparison: boolean = false,
  enabled: boolean = true
): UseTourListOutput => {
  const primaryRange = isComparison
    ? DurationUtils.getComparisonDateRange(duration)
    : DurationUtils.getDateRange(duration);

  const { from, to } = {
    from: primaryRange?.from?.format('YYYY-MM-DD'),
    to: primaryRange?.to?.format('YYYY-MM-DD'),
  };

  const dateRangeQueryKeyPart = primaryRange?.from?.isSame(primaryRange?.to, 'day') ? from : `${from}-${to}`;
  // Depending on type of passed 'orgKey', it can be either from an org unit or contractor
  const orgOrContractorId = extractOrgIdFromOrgKey(orgKey);
  // When fetching orgs on org level, those keys will always have the 'oz' prefix
  const isOrgUnitTour = ZSBUtils.isZSB(orgKey);
  // When fetching contractors, those keys will always have the 'ov' prefix
  const isContractorTour = ContractorUtils.isContractor(orgKey);
  // Only relevant when fetching contractor tours, this acts as
  // a filter of sorts (UI knows this as 'ContractorViewMode')
  const isHomeTour: boolean | undefined = ContractorUtils.isSimpleContractorTour(orgKey)
    ? undefined
    : ContractorUtils.isHomeTour(orgKey);

  const orgUnitData = useToursOfOrgUnitWithAuth(
    {
      orgId: orgOrContractorId,
      dateFilter: {
        range: {
          from,
          until: to,
        },
      },
    },
    {
      queryKey: [orgOrContractorId, dateRangeQueryKeyPart],
      enabled: isOrgUnitTour && !!orgOrContractorId && !!from && !!to && enabled,
    }
  );

  const contractorData = useToursDrivenByContractorWithAuth(
    {
      orgId: orgOrContractorId,
      dateFilter: {
        range: {
          from,
          until: to,
        },
      },
      isHomeTour,
    },
    {
      queryKey: [orgOrContractorId, dateRangeQueryKeyPart, isHomeTour],
      enabled: isContractorTour && !!orgOrContractorId && !!from && !!to && enabled,
    }
  );

  const tourList = useMemo<{ tours: Array<TourListEntry> }>(() => {
    if (isOrgUnitTour && orgUnitData?.data) {
      const result = orgUnitData?.data?.toursOfOrgUnit?.groups?.reduce((prev, curr) => {
        curr.tours.forEach((tour) => {
          prev.push({
            identifier: {
              organizationId: orgOrContractorId.toString(),
              number: tour.number.toString(),
              date: curr.group,
            },
          });
        });
        return prev;
      }, []);
      return {
        tours: result,
      };
    }

    if (isContractorTour && contractorData?.data) {
      const result = contractorData.data.toursDrivenByContractor.groups.reduce((prev, curr) => {
        curr.orgs.forEach((group) => {
          group.tours.forEach((tour) => {
            prev.push({
              identifier: {
                organizationId: group.orgId.toString(),
                number: tour.number.toString(),
                date: curr.group,
              },
              contractorKey: `ov_h:${orgOrContractorId}_${group.isHome ? 1 : 0}`,
            });
          });
        });
        return prev;
      }, []);
      return {
        tours: result,
      };
    }
  }, [orgUnitData?.data, contractorData?.data, isContractorTour, isOrgUnitTour, orgOrContractorId]);

  const uniqueTourIdentifiers = useMemo<TourIdentifierWithOrgKeys[]>(() => {
    const seenKeys = new Set();

    if (isOrgUnitTour && orgUnitData?.data) {
      return orgUnitData.data.toursOfOrgUnit.groups.reduce((prev, curr) => {
        curr.tours.forEach((tour) => {
          /**
           * This data structure currently only cares about the first occurring tour
           * within the list. The correct approach would be implement a map structure
           * that collects all tour dates for each unique contractor key which
           * should then be display e.g. as a list in the tooltip component.
           * TODO: Implement the correct approach mentioned above.
           * https://hermesgermany.atlassian.net/browse/AUP-1539
           */
          const key = `${orgOrContractorId}_${tour.number}`;
          if (seenKeys.has(key)) {
            return;
          }
          seenKeys.add(key);
          prev.push({
            organizationId: orgOrContractorId.toString(),
            number: tour.number.toString(),
            date: curr.group,
            standortOrgKey: `oz:${orgOrContractorId}`,
            tourOrgKey: TourUtils.getTourOrgKey(orgOrContractorId, tour.number.toString()),
            contractorKey: tour.drivenByContractor
              ? `ov_h:${tour.drivenByContractor.personId}_${tour.drivenByContractor.isHome ? 1 : 0}`
              : undefined,
          });
        });
        return prev;
      }, []);
    }

    if (isContractorTour && contractorData?.data) {
      return contractorData.data.toursDrivenByContractor.groups.reduce((prev, curr) => {
        curr.orgs.forEach((group) => {
          group.tours.forEach((tour) => {
            const key = `${group.orgId}_${tour.number}`;
            if (seenKeys.has(key)) {
              return;
            }
            seenKeys.add(key);
            prev.push({
              organizationId: group.orgId.toString(),
              number: tour.number.toString(),
              date: curr.group,
              standortOrgKey: `oz:${group.orgId}`,
              tourOrgKey: TourUtils.getTourOrgKey(group.orgId, tour.number.toString()),
              contractorKey: `ov_h:${orgOrContractorId}_${group.isHome ? 1 : 0}`,
            });
          });
        });
        return prev;
      }, []);
    }

    return [];
  }, [isOrgUnitTour, orgUnitData?.data, isContractorTour, contractorData?.data, orgOrContractorId]);

  return { uniqueTourIdentifiers, tourList, isLoading: orgUnitData?.isLoading || contractorData?.isLoading };
};

export default useTourList;
