import { useQuery, UseQueryResult } from 'react-query';
import { useSelector } from 'react-redux';

import { selectTokenId } from '@redux';
import OrgUnitTreeResponsePayload from '@legacy-modules/metrics2/models/websocket/org/OrgUnitTreeResponsePayload';
import { ConfigKey, getEnvVariable } from '@legacy-modules/common/services/EnvironmentConfigurationService';

type InputParameters = {
  realm: string;
  enabled?: boolean;
};

export default function useOrgTree(parameters: InputParameters): UseQueryResult<OrgUnitTreeResponsePayload> {
  const { realm, enabled = true } = parameters;
  const tokenId = useSelector(selectTokenId);
  const url = new URL(`${getEnvVariable(ConfigKey.HTTP_API_URL)}/orgtree/realm/${realm}`);

  return useQuery<OrgUnitTreeResponsePayload>({
    queryKey: ['org-tree', realm],
    queryFn: () =>
      fetch(url, {
        headers: {
          token: tokenId,
        },
      }).then((res) => res.json()),
    enabled: enabled && !!realm,
  });
}
