import { useMemo } from 'react';
import { UseQueryOptions, UseQueryResult } from 'react-query';
import { useSelector } from 'react-redux';

import {
  AggregationDateFilterInput,
  DateRangeGrouping,
  InputMaybe,
  ToursDrivenByContractorQuery,
  ToursDrivenByContractorQueryVariables,
  useToursDrivenByContractorQuery,
} from '@graphql-client/graphql';
import { selectToken } from '@redux/auth.selectors';
import { ConfigKey, getEnvVariable } from '@legacy-modules/common/services/EnvironmentConfigurationService';

let requestId = null;
export const generateRequestId = () => {
  if (requestId === null) {
    requestId = crypto.randomUUID();
  }
  return requestId;
};

/** Map our internal frontend input variables to API type */
const InterfaceMapper = (internal: InternalParameters): ToursDrivenByContractorQueryVariables => ({
  contractor: { personId: Number(internal.orgId) },
  dateFilter: internal.dateFilter,
  // Set explicitly to 'Day' here because we need day-based representation for tour links within UI
  dateRangeGrouping: DateRangeGrouping.Day,
  homeTourFilter: internal.isHomeTour,
});

type InternalParameters = {
  orgId: string;
  dateFilter: AggregationDateFilterInput;
  dateRangeGrouping?: InputMaybe<DateRangeGrouping>;
  isHomeTour?: boolean;
};

export function useToursDrivenByContractorWithAuth<TData = ToursDrivenByContractorQuery, TError = unknown>(
  variables: InternalParameters,
  options?: UseQueryOptions<ToursDrivenByContractorQuery, TError, TData>
): UseQueryResult<TData, TError> {
  const token = useSelector(selectToken);
  const parameters = InterfaceMapper(variables);
  const dataSource = useMemo<{ endpoint: string; fetchParams?: RequestInit }>(
    () => ({
      endpoint: getEnvVariable(ConfigKey.GQL_API_URL),
      fetchParams: {
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'application/json',
        },
      },
    }),
    [token]
  );
  return useToursDrivenByContractorQuery<TData, TError>(dataSource, parameters, options);
}
