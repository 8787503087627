import { useQuery, UseQueryResult } from 'react-query';
import { useSelector } from 'react-redux';

import { LimitClassTypes } from '@legacy-modules/dashboard/types/AbstractLimit';
import { LimitType } from '@legacy-modules/dashboard/websocket/DashboardLoadLimitResponsePayload';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import { defaultLimitsMap } from '@utils/limits-utils';
import { ConfigKey, getEnvVariable } from '@legacy-modules/common/services/EnvironmentConfigurationService';
import { selectTokenId } from '@redux';

type OrgConfig = { value: LimitClassTypes; orgKey: string; configKey: string };
type OrgConfigLoadResponse = {
  values: OrgConfig[];
};

type InputParameters = {
  orgKey: string;
  valueExpression: ValueExpression;
  enabled?: boolean;
};

/**
 * Custom hook that fetches the org unit's config.
 * @param parameters
 */
export default function useOrgConfig(parameters: InputParameters): UseQueryResult<OrgConfigLoadResponse> {
  const { orgKey, valueExpression, enabled = true } = parameters;
  const tokenId = useSelector(selectTokenId);
  const defaultLimitMapEntry = defaultLimitsMap.get(valueExpression);

  const url = new URL(`${getEnvVariable(ConfigKey.HTTP_API_URL)}/orgunitconfigs`);
  // There are no other configTypes so far. Once there are variants, this should be an input parameter.
  url.searchParams.append('configType', 'ValueExpressionGoal');
  url.searchParams.append('orgKeys', orgKey);
  url.searchParams.append('configKeys', valueExpression?.identifier);

  return useQuery<OrgConfigLoadResponse>({
    queryKey: ['ValueExpressionGoal', orgKey, valueExpression?.identifier],
    queryFn: () =>
      fetch(url, {
        headers: {
          token: tokenId,
        },
      }).then((res) => res.json()),
    enabled: enabled && !!orgKey && !!valueExpression,
    initialData: defaultLimitMapEntry && {
      values: [
        { value: { ...defaultLimitMapEntry, type: LimitType.LIMIT }, configKey: valueExpression.identifier, orgKey },
      ],
    },
  });
}
