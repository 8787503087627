import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';

import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

export default new ValueExpression({
  identifier: 'lma-tours.route.distance.return',
  label: 'Rückfahrtstrecke',
  valueFormat: '-#.###.##0,0 km',
  chart: ChartType.bar,
  positiveDirection: PositiveDirection.down,
  category: 'tours',
  definition: getDefinitionByName('Rückfahrtstrecke'),
  hidden: true,
  valueQuotient: 1000,
});
