import { ColumnFilterContentProps } from '@data-table/data-table.types';
import React, { ReactElement } from 'react';
import { getHinweisArts } from '@data-table/utils';
import { MultiSelectFilterContent } from '@data-table/components/data-table-column-filter/filter-content/multi-select-filter-content';

export const HinweisartFilterContent = <T, V>({
  table,
  column,
  onConfirm,
  onCancel,
}: ColumnFilterContentProps<T, V>): ReactElement => {
  const [activeValues, inactiveValues] = getHinweisArts<T, V>(column);
  const filterValue = column.getFilterValue() as string[];

  return (
    <MultiSelectFilterContent<T, V>
      filterValue={filterValue}
      allValues={activeValues}
      inactiveValues={inactiveValues}
      table={table}
      column={column}
      onConfirm={onConfirm}
      onCancel={onCancel}
    />
  );
};
