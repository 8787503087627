import { useState } from 'react';
import { SortingState } from '@tanstack/react-table';
import { DataTableComponentId } from '@data-table/data-table.types';
import bruttoZustellzeitAvg from '@contexts/value-expression-context/value-expressions/brutto-zustellzeit-avg';
import abholungenAvg from '@contexts/value-expression-context/value-expressions/abholungen-avg';
import atgAbholungenAvg from '@contexts/value-expression-context/value-expressions/atg-abholungen-avg';
import atgProduktivitaet from '@contexts/value-expression-context/value-expressions/atg-produktivitaet';
import htProduktivitaet from '@contexts/value-expression-context/value-expressions/ht-produktivitaet';
import psProduktivitaet from '@contexts/value-expression-context/value-expressions/ps-produktivitaet';
import einsaetze from '@contexts/value-expression-context/value-expressions/touren';
import zustellungenAvg from '@contexts/value-expression-context/value-expressions/zustellungen-avg';
import ruecklaufer from '@contexts/value-expression-context/value-expressions/ruecklaufer';
import ruecklauferAvg from '@contexts/value-expression-context/value-expressions/ruecklaufer-avg';
import psAbgabenAvg from '@contexts/value-expression-context/value-expressions/ps-abgaben-avg';
import nettoZustellzeitAvg from '@contexts/value-expression-context/value-expressions/netto-zustellzeit-avg';
import nettoPsZeitAvg from '@contexts/value-expression-context/value-expressions/netto-ps-zeit-avg';
import nettoAtgZeitAvg from '@contexts/value-expression-context/value-expressions/netto-atg-zeit-avg';
import nettoHtZeitAvg from '@contexts/value-expression-context/value-expressions/netto-ht-zeit-avg';
import ruecklaufQuote from '@contexts/value-expression-context/value-expressions/ruecklauf-quote';
import lademengeIstAvg from '@contexts/value-expression-context/value-expressions/lademenge-ist-avg';
import psMitnahmenAvg from '@contexts/value-expression-context/value-expressions/ps-mitnahmen-avg';

export type TabConfig = {
  componentId: DataTableComponentId;
  title: string;
  valueExpressionKeys: string[];
  sorting: SortingState;
  valueExpressionKey?: string;
  subTitle?: string;
};

type TabsConfig = {
  [uID: string]: TabConfig;
};

const tabsConfig: TabsConfig = {
  'touren.table.allgemein': {
    componentId: 'touren.table.allgemein',
    title: 'Übersicht',
    subTitle: 'Allgemein',
    sorting: [{ id: 'tourIdentifier', desc: true }],
    valueExpressionKeys: [
      einsaetze.identifier,
      lademengeIstAvg.identifier,
      htProduktivitaet.identifier,
      ruecklaufQuote.identifier,
      nettoZustellzeitAvg.identifier,
      bruttoZustellzeitAvg.identifier,
    ],
  },
  'touren.table.htproduktivitaet': {
    componentId: 'touren.table.htproduktivitaet',
    title: 'HT-Produktivität',
    sorting: [{ id: htProduktivitaet.identifier, desc: true }],
    valueExpressionKey: htProduktivitaet.identifier,
    valueExpressionKeys: [
      einsaetze.identifier,
      zustellungenAvg.identifier,
      abholungenAvg.identifier,
      atgAbholungenAvg.identifier,
      psAbgabenAvg.identifier,
      psMitnahmenAvg.identifier,
      nettoHtZeitAvg.identifier,
      htProduktivitaet.identifier,
    ],
  },
  'touren.table.psproduktivitaet': {
    componentId: 'touren.table.psproduktivitaet',
    title: 'PS-Produktivität',
    sorting: [
      {
        id: psProduktivitaet.identifier,
        desc: true,
      },
    ],
    valueExpressionKey: psProduktivitaet.identifier,
    valueExpressionKeys: [
      einsaetze.identifier,
      zustellungenAvg.identifier,
      abholungenAvg.identifier,
      atgAbholungenAvg.identifier,
      psAbgabenAvg.identifier,
      psMitnahmenAvg.identifier,
      nettoPsZeitAvg.identifier,
      psProduktivitaet.identifier,
    ],
  },
  'touren.table.atgproduktivitaet': {
    componentId: 'touren.table.atgproduktivitaet',
    title: 'ATG-Produktivität',
    sorting: [
      {
        id: atgProduktivitaet.identifier,
        desc: true,
      },
    ],
    valueExpressionKey: atgProduktivitaet.identifier,
    valueExpressionKeys: [
      einsaetze.identifier,
      zustellungenAvg.identifier,
      abholungenAvg.identifier,
      atgAbholungenAvg.identifier,
      psAbgabenAvg.identifier,
      psMitnahmenAvg.identifier,
      nettoAtgZeitAvg.identifier,
      atgProduktivitaet.identifier,
    ],
  },
  'touren.table.ruecklaufquote': {
    componentId: 'touren.table.ruecklaufquote',
    title: 'Rücklauf',
    sorting: [
      {
        id: ruecklaufQuote.identifier,
        desc: true,
      },
    ],
    valueExpressionKey: ruecklaufQuote.identifier,
    valueExpressionKeys: [
      einsaetze.identifier,
      lademengeIstAvg.identifier,
      zustellungenAvg.identifier,
      ruecklauferAvg.identifier,
      ruecklaufer.identifier,
      ruecklaufQuote.identifier,
    ],
  },
};

export type UseTourenTableTabsOutput = {
  currentTab: DataTableComponentId;
  currentConfig: TabConfig;
  tabsConfig: TabsConfig;
  setCurrentTab: (tabID: DataTableComponentId) => void;
};

const useTourenTableTabs = (): UseTourenTableTabsOutput => {
  const [currentTab, setCurrentTab] = useState<DataTableComponentId>('touren.table.allgemein');

  return {
    currentTab: currentTab,
    currentConfig: tabsConfig[currentTab],
    tabsConfig,
    setCurrentTab,
  };
};

export default useTourenTableTabs;
