import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';

const label = 'GPS Verzögerung';
const key = 'finished_deliveries.stop_journey.location.time_lag';

const getConfig = (category) => {
  return {
    identifier: `${key}.${category}`,
    label: category,
    longLabel: `${label} (${category})`,
    chart: ChartType.bar,
    positiveDirection: PositiveDirection.down,
    category: 'tours',
    description: '<p>Zeitliche Abweichung der Koordinate zum Stop in Sekunden</p>\n',
    hidden: true,
  };
};

const categories = ['0-5', '6-10', '11-15', '16-20', '21-30', '31-60', '61-120', '121-300', '>300'];
const children = categories.map((category) => new ValueExpression(getConfig(category)));

export default new ValueExpression({
  ...getConfig('sum'),
  label: label,
  longLabel: label,
  chart: ChartType.line,
  hidden: false,
  children: children,
});
