import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import ePlus0Quote from '@contexts/value-expression-context/value-expressions/e-plus-0-quote';
import ePlus0QuoteEil from '@contexts/value-expression-context/value-expressions/e-plus-0-eil-quote';

export const defaultLimitsMap = new Map<ValueExpression, any>([
  [
    ePlus0Quote,
    {
      positive: 0.975,
      negative: 0.975,
    },
  ],
  [
    ePlus0QuoteEil,
    {
      positive: 0.985,
      negative: 0.985,
    },
  ],
]);
