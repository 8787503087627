import React, { useCallback, useMemo, useState } from 'react';
import { Button, ButtonGroup, Input } from 'reactstrap';
import classNames from 'classnames';
import { ColumnFilterContentProps, ValueExpressionDataTableColumnMeta } from '@data-table/data-table.types';
import { FilterControls } from '../../filter-controls';

import sharedStyle from '../value-expression-filter-content.module.scss';

const IntegerValueExpressionFilterContent = <T, V>({ column, onCancel, onConfirm }: ColumnFilterContentProps<T, V>) => {
  const filterValue = column.getFilterValue();
  const meta = column.columnDef.meta as ValueExpressionDataTableColumnMeta;

  const [value, setValue] = useState<string>(filterValue?.toString()?.replace(/>|</g, '') ?? '');

  const [toggleState, setToggleState] = useState<'greater' | 'less'>(
    filterValue?.toString()?.startsWith('<') ? 'less' : 'greater'
  );

  const title = useMemo(() => {
    const label = meta?.valueExpression?.getLabel() ?? 'Werte';
    return `${label} filtern`;
  }, [meta?.valueExpression]);

  const onConfirmCallback = useCallback(() => {
    if (value === '') {
      onConfirm(undefined);
      return;
    }
    if (isFinite(Number(value))) {
      onConfirm(`${toggleState === 'less' ? '<' : '>'}${value}`);
    }
  }, [onConfirm, toggleState, value]);

  const onValueChange = useCallback((e) => {
    setValue(e.target.value);
  }, []);

  const onFilterToggleChange = useCallback(
    (toggleValue) => () => {
      setToggleState(toggleValue);
    },
    []
  );

  const isGreaterToggleState = toggleState === 'greater';
  const isValid = /^\d{0,10}$/.test(value);

  return (
    <div className={classNames(sharedStyle.filterContent)}>
      <FilterControls
        className={sharedStyle.valueExpressionFilterContent}
        inputValid={isValid}
        cancelCallback={onCancel}
        confirmCallback={onConfirmCallback}>
        <div data-testid='data-table-filter-title' className={sharedStyle.filterContentHeadline}>
          {title}
        </div>
        <div className={sharedStyle.valueExpressionFilterInput}>
          Nur Werte
          <ButtonGroup
            data-testid='data-table-filter-toggle'
            data-value={toggleState}
            size='sm'
            className={sharedStyle.valueExpressionFilterButtonGroup}>
            <Button
              data-testid='data-table-filter-toggle-greater'
              color={isGreaterToggleState ? 'primary' : 'secondary'}
              outline={!isGreaterToggleState}
              size='sm'
              onClick={onFilterToggleChange('greater')}>
              größer
            </Button>
            <Button
              data-testid='data-table-filter-toggle-less'
              color={!isGreaterToggleState ? 'primary' : 'secondary'}
              outline={isGreaterToggleState}
              size='sm'
              onClick={onFilterToggleChange('less')}>
              kleiner
            </Button>
          </ButtonGroup>
          <Input
            data-testid='data-table-filter-input'
            value={value}
            valid={value?.length > 0 && isValid}
            invalid={value?.length > 0 && !isValid}
            onChange={onValueChange}
          />
        </div>
      </FilterControls>
    </div>
  );
};

export default IntegerValueExpressionFilterContent;
