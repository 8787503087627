import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ValueExpression, {
  TargetValueType,
} from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

export default new ValueExpression({
  identifier: 'ruecklaeufer_avg',
  positiveDirection: PositiveDirection.down,
  label: 'Ø Rückläufer',
  chart: ChartType.bar,
  category: 'delivery-items',
  valueFormat: '-#.###.##0,0',
  targetValueType: TargetValueType.QUOTE,
  decimalPlaces: 2,
});
