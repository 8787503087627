import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';

import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

export default new ValueExpression({
  identifier: 'computed.active_days',
  label: 'Werktage',
  chart: ChartType.bar,
  positiveDirection: PositiveDirection.custom,
  category: 'none',
  description: '<p>Anzahl Werktage für gegebenen Zeitraum</p>\n',
});
