import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';

import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

export default new ValueExpression({
  identifier: 'lma-shipmentbased.RAA.all.count.total',
  hidden: true,
  positiveDirection: PositiveDirection.down,
  description: 'Anzahl der Abholkarten, die auf Tour in den Rücklauf gescannt wurden.',
  label: 'Rücklauf Abholkarten',
  definition: getDefinitionByName('Rücklauf Abholaufträge'),
});
