import moment from 'moment';
import { useQuery, UseQueryResult } from 'react-query';

import OrgUnitMultiResponsePayload from '@legacy-modules/metrics2/models/websocket/org/OrgUnitMultiResponsePayload';
import { OrgUnitResult } from '@legacy-modules/metrics2/models/websocket/org/OrgUnitResult';
import { OrgUnitPart } from '@legacy-modules/metrics2/models/queries/OrganizationQuery';
import { useSelector } from 'react-redux';
import { selectTokenId } from '@redux';
import { ConfigKey, getEnvVariable } from '@legacy-modules/common/services/EnvironmentConfigurationService';

type InputParameters = {
  orgKeys: string[];
  parts: OrgUnitPart[];
  orgType?: string;
  from?: moment.Moment;
  to?: moment.Moment;
  enabled?: boolean;
};

export default function useMultiOrgTree(parameters: InputParameters): UseQueryResult<Map<string, OrgUnitResult>> {
  const { orgKeys, parts, orgType, from, to, enabled } = parameters;
  const tokenId = useSelector(selectTokenId);

  const joinedOrgKeys = orgKeys.join(',');
  const joinedParts = parts.join(',');
  const effectiveFrom = from?.format('YYYYMMDD');
  const effectiveTo = to?.format('YYYYMMDD');

  const url = new URL(`${getEnvVariable(ConfigKey.HTTP_API_URL)}/orgtree/select`);
  url.searchParams.append('orgKeys', joinedOrgKeys);
  url.searchParams.append('parts', joinedParts);
  if (orgType) url.searchParams.append('orgType', orgType);
  if (effectiveFrom) url.searchParams.append('from', effectiveFrom);
  if (effectiveTo) url.searchParams.append('to', effectiveTo);

  return useQuery<OrgUnitMultiResponsePayload, unknown, Map<string, OrgUnitResult>>({
    queryKey: ['multi-org-tree', joinedOrgKeys, joinedParts, orgType, effectiveFrom, effectiveTo],
    enabled: enabled && joinedOrgKeys.length > 0 && joinedParts.length > 0,
    queryFn: () =>
      fetch(url, {
        headers: {
          token: tokenId,
        },
      }).then((res) => res.json()),
    select: (data) =>
      data.units?.reduce((acc, unit) => acc.set(unit.orgKey, unit), new Map<string, OrgUnitResult>()) ||
      new Map<string, OrgUnitResult>(),
    keepPreviousData: true,
  });
}
