import React, { useCallback, useContext, useEffect } from 'react';
import Styles from './KpiCustomThresholdSelection.module.scss';
import classNames from 'classnames';
import { Button } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { KpiOverviewContext, KpiThresholdBounds } from '../KpiOverview';
import ValueExpression from '../../../valueexpressions/models/valueexpressions/ValueExpression';
import { ILimitBounds } from '../../websocket/DashboardLoadLimitResponsePayload';
import {
  AbstractLimit,
  CustomLimit,
  DefaultLimit,
  LimitClassTypes,
  LimitType,
  TargetArea,
} from '../../types/AbstractLimit';
import { PositiveDirection } from '../../../metrics2/models/entities/PositiveDirection';
import { ToggleButtonGroup } from '../../../common/components/ButtonGroup';
import { ToggleButton } from '../../../common/components/ToggleButton';
import { KpiCustomThresholdSelectionFormBody } from './KpiCustomThresholdSelectionFormBody';
import { limitValidationSchema } from './LimitValidationSchema';
import KpiValue from '../KpiValue';
import { get } from 'lodash';
import { isSumValueExpression } from '../../hooks/useKpiIndicator';
import { defaultLimitsMap } from '@utils/limits-utils';

export type Props = {
  onValuesSelected: (firstVal: number, secondVal: number) => void;
  zielwert: LimitClassTypes;
  valueExpression: ValueExpression;
  workdaysInRange: number;
  veData: number;
  onClose?: () => void;
};

interface IFormInputs {
  positiveVal: number;
  negativeVal: number;
  direction: string;
  targetAreaSecond?: number;
  targetAreaFirst?: number;
  activeViewMode: ViewMode | null;
  defaultLimits?: ILimitBounds;
}

type ViewMode = 'limit' | 'corridor';

export const KpiCustomThresholdSelection: React.FC<Props> = ({
  valueExpression,
  onClose,
  workdaysInRange,
  veData,
  onValuesSelected,
  zielwert,
}) => {
  const { onKpiCustomLimitChange, onTargetAreaChange } = useContext(KpiOverviewContext);

  const activeViewMode = (() => {
    if (!zielwert) {
      return null;
    }
    if (zielwert.type === LimitType.CUSTOM_DIRECTION) {
      return 'limit';
    } else if (zielwert.type === LimitType.TARGET_AREA) {
      return 'corridor';
    }
    return null;
  })();

  const getDefaultValueByType = useCallback((zielwert: LimitClassTypes, type: 'positive' | 'negative') => {
    if (!zielwert) {
      return null;
    }
    if (zielwert instanceof CustomLimit) {
      return get(zielwert, `limit.[${type}]`, null);
    } else if (zielwert instanceof DefaultLimit) {
      return zielwert[type];
    }
    return null;
  }, []);

  const { register, handleSubmit, setValue, watch, getValues, formState, trigger, reset } = useForm<IFormInputs>({
    resolver: yupResolver(limitValidationSchema),
    defaultValues: {
      activeViewMode,
      positiveVal: getDefaultValueByType(zielwert, 'positive'),
      negativeVal: getDefaultValueByType(zielwert, 'negative'),
      direction: zielwert && zielwert instanceof CustomLimit ? zielwert.direction : null,
      targetAreaFirst: zielwert && zielwert instanceof TargetArea ? zielwert.targetAreas[0] : null,
      targetAreaSecond: zielwert && zielwert instanceof TargetArea ? zielwert.targetAreas[1] : null,
      defaultLimits: {},
    },
    mode: 'all',
    reValidateMode: 'onChange',
  });
  const { isValid } = formState;

  const activeMode = watch('activeViewMode');

  useEffect(() => {
    register('activeViewMode');
    register('targetAreaFirst');
    register('targetAreaSecond');
    register('direction');
    register('positiveVal');
    register('negativeVal');
    register('defaultLimits');
  }, [register]);

  useEffect(() => {
    const defaultLimits = defaultLimitsMap.get(valueExpression);
    setValue('defaultLimits', defaultLimits);
  }, [setValue, valueExpression.identifier]);

  const onSubmit = useCallback(
    (data) => {
      if (activeMode === 'limit') {
        onKpiCustomLimitChange(
          valueExpression,
          {
            positive: data.positiveVal,
            negative: data.negativeVal,
          },
          data.direction === 'up' ? PositiveDirection.up : PositiveDirection.down
        );
      } else if (activeMode === 'corridor') {
        onTargetAreaChange(valueExpression, [data.targetAreaFirst, data.targetAreaSecond]);
      } else {
        if (valueExpression.positiveDirection === 'custom') {
          throw new Error(
            'Form should never be submitted like that. If direction is custom ' +
              "the activeViewMode has to be either 'limit' or 'corridor'"
          );
        }
        onValuesSelected(data.positiveVal, data.negativeVal);
      }
      onClose && onClose();
    },
    [
      activeMode,
      onClose,
      onKpiCustomLimitChange,
      onTargetAreaChange,
      onValuesSelected,
      valueExpression.identifier,
      valueExpression.positiveDirection,
    ]
  );

  const handleViewModeChange = useCallback(
    (e, val) => {
      if (val === null) {
        return;
      }
      setValue('activeViewMode', val, {
        shouldValidate: true,
      });
    },
    [setValue]
  );

  const displayFormSection =
    (valueExpression.positiveDirection === 'custom' && activeMode) || valueExpression.positiveDirection !== 'custom';

  return (
    <div className={Styles.KpiCustomThresholdSelection}>
      <div className={Styles.Header}>
        <h2 className={Styles.Headline}>Ziele für {valueExpression.getLabel()}</h2>
        {valueExpression.positiveDirection === 'custom' && (
          <div
            className={classNames(Styles.ViewModeSelector, {
              [Styles.NoSelection]: activeMode == null,
            })}>
            <ToggleButtonGroup onChange={handleViewModeChange} value={activeMode} exclusive>
              <ToggleButton variant={'large'} value={'limit'}>
                Zielwert
              </ToggleButton>
              <ToggleButton variant={'large'} value={'corridor'}>
                Zielbereich
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        )}
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {displayFormSection && (
          <div
            className={classNames(Styles.ContentWrapper, {
              [Styles.NoSelection]: activeMode === null,
            })}>
            <KpiCustomThresholdSelectionFormBody
              watch={watch}
              getValues={getValues}
              setValue={setValue}
              valueExpression={valueExpression}
              errors={formState.errors}
              trigger={trigger}
              reset={reset}
            />
          </div>
        )}
        <div className={Styles.NoteSection}>
          <h2 className={Styles.Headline}>Hinweise</h2>
          {activeMode != null && isSumValueExpression(valueExpression) && (
            <>
              <p className={Styles.Paragraph}>
                Bei dieser Kennzahl müssen die Zielwerte für den Tagesdurchschnitt festgelegt werden. Dieser ist für
                diese Kennzahl im ausgewählten Zeitraum:{' '}
                <strong>
                  <KpiValue valueExpression={valueExpression} veData={veData / workdaysInRange} />
                </strong>{' '}
                {workdaysInRange > 1 && <>(errechnet aus {workdaysInRange} Werktagen)</>}
              </p>
              <br />
            </>
          )}
          {activeMode !== null && (
            <p className={Styles.Paragraph}>
              Die Anpassung der Zielwerte wird für diesen Standort und seine Nutzer übernommen.
            </p>
          )}
          {activeMode == null && (
            <p className={Styles.Paragraph}>
              Bitte wähle zuerst aus, ob es einen Zielwert oder einen Zielbereich gibt.
            </p>
          )}
        </div>
        {
          <div className={Styles.Footer}>
            <Button color='white' type={'reset'} className={Styles.CancelButton} onClick={onClose}>
              Abbrechen
            </Button>
            <Button color='primary' type={'submit'} disabled={!isValid || !displayFormSection}>
              Speichern
            </Button>
          </div>
        }
      </form>
    </div>
  );
};
