import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';

import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import ValueExpression, {
  TargetValueType,
} from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

export default new ValueExpression({
  identifier: 'benachrichtigung_email_quote',
  label: 'Digitale Benachrichtigungsquote',
  valueFormat: '-#.###.##0,#0%',
  valueQuotient: 0.01,
  positiveDirection: PositiveDirection.custom,
  definition: getDefinitionByName('Digitale Benachrichtigungsquote'),
  percent: true,
  targetValueType: TargetValueType.QUOTE,
  decimalPlaces: 2,
});
