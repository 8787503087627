import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';

import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

export default new ValueExpression({
  identifier: 'ruecklaeufer_express',
  hidden: true,
  positiveDirection: PositiveDirection.down,
  label: 'Rückläufer Eil',
  definition: getDefinitionByName('Rückläufer Eil'),
});
