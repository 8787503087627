import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';

import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

export default new ValueExpression({
  identifier: 'static.map.population',
  label: 'Einwohner',
  chart: ChartType.bar,
  positiveDirection: PositiveDirection.custom,
  category: 'static',
  description: '<p>Einwohneranzahl für den aktuell ausgewählten Bereich</p>\n',
});
