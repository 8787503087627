import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Button } from 'reactstrap';
import LinearProgress from '@material/react-linear-progress';
import { Placement, VirtualElement } from '@popperjs/core';
import classNames from 'classnames';
import { get } from 'lodash';

import '@material/react-linear-progress/dist/linear-progress.css';
import Styles from './ShipmentHistorySearch.module.scss';

import ShipmentSearchEntry from '../../shipments/models/entities/ShipmentSearchEntry';
import { ViewMode } from '../../app/models/enumerations/ViewMode';
import { TourIdentifier } from '../../tour/models/state/tourDetails/TourIdentifier';
import OrgUnit from '../../metrics2/models/entities/organization/OrgUnit';
import { PopOver } from '../../common/components/PopOver';
import AuthGuard from '../../auth/components/AuthGuard';
import { WellKnownPermission } from '../../auth/constants/WellKnownPermission';
import { ContractorUtils } from '../../utils/tours/ContractorUtils';
import { ContractorViewMode } from '../../navigation/constants/ContractorViewMode';
import { appSlice, dashboardSlice, selectTourDetailsTourIdentifier, tourDetailsSlice } from '@redux';
import { StopTypeType } from '@legacy-modules/tour/models/state/TourDetailsState';
import { cleanBarcode, findInTourDetails, getOrgKeyFromShipmentRow, isValidCode } from '../utils/ShipmentHistoryUtils';
import { useTourDetails } from '@hooks/use-tour-details-hook';
import useShipmentSearch from '@hooks/use-shipment-search-hook/use-shipment-search.hook';

const ShipmentHistoryContext = createContext<{
  onStopOpenClick: (row: ShipmentSearchEntry) => void;
}>({
  onStopOpenClick: () => {
    throw new Error('no implemented');
  },
});

const messages = {
  empty: {
    main: 'Es wurden keine Ergebnisse für Ihre Suche gefunden.',
    note: (
      <>
        Hinweis: Die Sendungshistorie enthält nur die Scandaten zu Sendungen, die in den letzten 11 Monaten beauftragt
        wurden.
      </>
    ),
  },
  error: {
    main: 'Aufgrund einer technischen Störung funktioniert die Anzeige der Sendungshistorie im Moment nicht.',
    note: (
      <>
        Bitte versuche es später noch einmal. Bei längerem Ausfall melde es bitte an{' '}
        <a href='mailto:lma@hermesworld.com'>lma@hermesworld.com</a>
      </>
    ),
  },
};

type ShipmentMessageProps = {
  message: {
    main: string;
    note: React.ReactNode;
  };
};

const ShipmentMessage: React.FC<ShipmentMessageProps> = ({ message }) => {
  return (
    <Table>
      <tbody>
        <tr>
          <td>
            <div>
              <div>{message.main}</div>
            </div>
            <div>
              <div className={Styles.Hint}>
                <i>{message.note}</i>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

const placesToShowButton = ['TOUR', 'ATG', 'PAKETSHOP'];

type ShipmentHistoryRowProps = {
  row: ShipmentSearchEntry;
};

const ShipmentHistoryRow: React.FC<ShipmentHistoryRowProps> = ({ row }) => {
  const { tourDate, visibility, place, tourNumber, orgUnit = {} as Partial<OrgUnit>, orgId } = row;
  const orgKey = get(orgUnit, 'orgKey', null);

  const isProperPlace = placesToShowButton.indexOf(place) !== -1;

  const displayTourButton =
    isFinite(tourNumber) && typeof orgKey === 'string' && !!tourDate && isProperPlace && !!visibility;

  const { onStopOpenClick } = useContext(ShipmentHistoryContext);

  const tourButton = displayTourButton ? (
    <AuthGuard requiredPermissions={[WellKnownPermission.TourSeeDetails]} notAllowedComponent={<td />}>
      <td>
        <Button onClick={() => onStopOpenClick(row)} size='sm' className={classNames(Styles.stopButton)}>
          Stopp öffnen
        </Button>
      </td>
    </AuthGuard>
  ) : (
    <td />
  );

  const orgUnitName = useMemo(() => {
    if (!orgUnit) {
      if (place && place === 'PADEA') {
        return 'Paketshop';
      }
      return 'OrgId: ' + (orgId || 'nicht vorhanden');
    }
    return orgUnit.name;
  }, [orgId, orgUnit, place]);

  return (
    <tr key={row.key()}>
      <td>{row.date()}</td>
      <td>{row.time()}</td>
      <td className={Styles.OrgUnit}>{orgUnitName}</td>
      <td title={row.description}>{row.shortDesc}</td>
      <td>{isFinite(row.tourNumber) ? row.tourNumber : ''}</td>
      {tourButton}
    </tr>
  );
};

type ShipmentHistoryProps = {
  rows: ShipmentSearchEntry[];
};

const ShipmentHistory: React.FC<ShipmentHistoryProps> = ({ rows }) => {
  return (
    <Table className={Styles.Table}>
      <tbody>
        {rows.map((row, index) => {
          return <ShipmentHistoryRow key={`${row.orgId}${index}`} row={row} />;
        })}
      </tbody>
      <tfoot />
    </Table>
  );
};

type ShipmentHistorySearchProps = {
  loading: boolean;
  error: boolean;
  empty: boolean;
  rows: ShipmentSearchEntry[];
};

const ShipmentHistorySearchMain: React.FC<ShipmentHistorySearchProps> = ({ loading, error, empty, rows }) => {
  if (loading) {
    return null;
  }
  if (error) {
    return <ShipmentMessage message={messages.error} />;
  }
  if (empty) {
    return <ShipmentMessage message={messages.empty} />;
  }
  return <ShipmentHistory rows={rows} />;
};

type Props = {
  barcode: string;
  target?: Element | VirtualElement | null;
  position?: Placement;
  visible?: boolean;
  onClose?: () => void;
};

const ShipmentHistorySearch: React.FC<Props> = ({ barcode, position, target, visible, onClose }) => {
  const dispatch = useDispatch();
  const cleanedBarcode = cleanBarcode(barcode);

  const [selectedRow, setSelectedRow] = useState<ShipmentSearchEntry | null>(null);
  const [enableFetch, setEnableFetch] = useState(false);

  const tourIdentifier = useSelector(selectTourDetailsTourIdentifier);

  const {
    data: shipmentEntries,
    isLoading: isShipmentLoading,
    isError: isShipmentError,
  } = useShipmentSearch(cleanedBarcode, visible);

  const {
    data: tourDetails,
    isLoading: isTourDetailsLoading,
    isPreviousData: isPreviousTourDetails,
  } = useTourDetails(tourIdentifier, enableFetch);

  // Use latest finished delivery to set current displayed stop
  useEffect(() => {
    if (!enableFetch || isTourDetailsLoading || isPreviousTourDetails || !tourDetails) {
      return;
    }

    // Use clicked row state to find finished delivery in loaded tour details
    const finishedDelivery = findInTourDetails(
      Object.values(tourDetails.finishedDeliveries || {}),
      cleanedBarcode,
      selectedRow.timestamp
    );

    if (finishedDelivery?.displayableStopNumber) {
      dispatch(
        tourDetailsSlice.actions.setSelectedStop({
          stopNumber: finishedDelivery.displayableStopNumber,
          type: StopTypeType.Processed,
          customerRef: finishedDelivery.customerDeliveries?.[0]?.customerRef,
        })
      );
    }

    const orgKey = getOrgKeyFromShipmentRow(selectedRow);

    dispatch(appSlice.actions.setViewMode(ViewMode.dashboard));
    dispatch(dashboardSlice.actions.setActiveDashboardView('Tour-Details'));
    dispatch(dashboardSlice.actions.setOrgKey(orgKey));

    if (ContractorUtils.isContractor(orgKey)) {
      dispatch(dashboardSlice.actions.setContractorViewMode(ContractorViewMode.All));
      dispatch(tourDetailsSlice.actions.setContractorKey(orgKey));
    }

    setSelectedRow(null);
    // Disable trigger
    setEnableFetch(false);

    onClose();
  }, [
    cleanedBarcode,
    dispatch,
    enableFetch,
    isPreviousTourDetails,
    isTourDetailsLoading,
    onClose,
    selectedRow,
    tourDetails,
  ]);

  const onStopClickCallback = useCallback(
    async (row: ShipmentSearchEntry) => {
      const tourIdentifier = new TourIdentifier({
        orgId: `${row.orgId}`,
        date: row.tourDate,
        number: `${row.tourNumber}`,
      });

      dispatch(tourDetailsSlice.actions.setTourIdentifier(JSON.parse(JSON.stringify(tourIdentifier))));

      setSelectedRow(row);
      // Enable trigger
      setEnableFetch(true);
    },
    [dispatch, setSelectedRow, setEnableFetch]
  );

  const providerValue = useMemo(() => {
    return {
      onStopOpenClick: onStopClickCallback,
    };
  }, [onStopClickCallback]);

  const isValidBarcode = isValidCode(cleanedBarcode);

  return !visible ? null : (
    <PopOver
      visible={visible && isValidBarcode && barcode.length > 0}
      anchorElement={target}
      placement={position}
      closeOnClickOutside
      closeOnEscape
      onClose={onClose}
      offset={[0, 6]}>
      <ShipmentHistoryContext.Provider value={providerValue}>
        <div className={classNames(Styles.ShipmentSearchContainerPopper)}>
          <div className={Styles.headline}>
            <div>
              <span style={{ paddingRight: '16px' }}>
                <b>Sendungshistorie</b>
              </span>{' '}
              <span>{cleanedBarcode}</span>
            </div>
          </div>
          <LinearProgress indeterminate={true} closed={!isShipmentLoading} />
          <div className={`primary-selection ${Styles.scrollbarAvailable}`}>
            <ShipmentHistorySearchMain
              rows={shipmentEntries}
              loading={isShipmentLoading}
              empty={(!shipmentEntries || shipmentEntries.length === 0) && isValidBarcode}
              error={isShipmentError}
            />
          </div>
        </div>
      </ShipmentHistoryContext.Provider>
    </PopOver>
  );
};

export default ShipmentHistorySearch;
