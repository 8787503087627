import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';

import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

export default new ValueExpression({
  identifier: 'lma-tours.tourstop.count.atg',
  label: 'Tour-ATG-Stopps',
  chart: ChartType.line,
  positiveDirection: 'down',
  category: 'tours',
  definition: getDefinitionByName('Tour-Stopps-ATG'),
});
