import React, { FC, HTMLAttributes, ReactElement, useCallback, useMemo, useState } from 'react';
import { useUserConfig } from '@hooks/use-user-config-hook';
import BruttoZustellzeit from '@contexts/value-expression-context/value-expressions/brutto-zustellzeit';
import NettoZustellzeit from '@contexts/value-expression-context/value-expressions/netto-zustellzeit';
import Anfahrtszeit from '@contexts/value-expression-context/value-expressions/anfahrtszeit';
import Rueckfahrtzeit from '@contexts/value-expression-context/value-expressions/rueckfahrtzeit';
import Lademenge from '@contexts/value-expression-context/value-expressions/lademenge-ist';
import HTProduktivitaet from '@contexts/value-expression-context/value-expressions/ht-produktivitaet';
import RuecklaufQuote from '@contexts/value-expression-context/value-expressions/ruecklauf-quote';
import Tourstrecke from '@contexts/value-expression-context/value-expressions/tourstrecke';
import Abholungen from '@contexts/value-expression-context/value-expressions/abholungen-ohne-mitnahmen';
import ATGAbholungen from '@contexts/value-expression-context/value-expressions/atg-abholungen';
import PSMitnahmen from '@contexts/value-expression-context/value-expressions/ps-mitnahmen';
import PSProduktivitaet from '@contexts/value-expression-context/value-expressions/ps-produktivitaet';
import { createFixedLengthArray } from '@utils/create-fixed-length-array';
import { getUniqueArrayValues } from '@utils/get-unique-array-values';
import { useValueExpressionContext } from '@contexts/value-expression-context';

type TourDetailsKennzahlen = Array<string | undefined>;

/**
 * null values are used as placeholders for empty entries
 */
export const newKennzahlen: TourDetailsKennzahlen = [
  BruttoZustellzeit.identifier,
  NettoZustellzeit.identifier,
  Anfahrtszeit.identifier,
  Rueckfahrtzeit.identifier,
  Lademenge.identifier,
  HTProduktivitaet.identifier,
  RuecklaufQuote.identifier,
  Tourstrecke.identifier,
  Abholungen.identifier,
  ATGAbholungen.identifier,
  PSMitnahmen.identifier,
  PSProduktivitaet.identifier,
  null,
  null,
  null,
  null,
];

export type WithKpiSliderProps = HTMLAttributes<HTMLDivElement> & {
  tourDate: string;
};
export type WithKpiSliderOutputProps = Omit<WithKpiSliderProps, 'tourDate'> & {
  kpiPageState: ReturnType<typeof useState<string>>;
  allKennzahlen: string[];
  filteredKennzahlen: string[];
  firstPageKennzahlen: string[];
  secondPageKennzahlen: string[];
  onKennzahlChange: (index: number) => (kennzahl: string) => void;
};
export function withKpiSlider(Component: FC<WithKpiSliderOutputProps>): FC<WithKpiSliderProps> {
  return function ({ tourDate, ...props }: WithKpiSliderProps): ReactElement {
    // Neue Produktivitätszahlen ab 01.08.2022
    const kpiPageState = useState('0');

    const defaultKennzahlen = newKennzahlen;

    const valueExpressionMap = useValueExpressionContext();

    const [userKennzahlen, updateUserKennzahlen, userKennzahlenLoading] =
      useUserConfig<string[]>('tourDetails.kennzahlen');

    const [allKennzahlen, firstPageKennzahlen, secondPageKennzahlen] = useMemo(() => {
      // we only want to use defaultKennzahlen when user config was loaded and no configuration was found
      // while loading we don't show any kennzahlen at all so it is set to an empty array
      const userKennzahlenOrDefaults = userKennzahlenLoading ? [] : userKennzahlen || defaultKennzahlen;
      // ensure userKennzahlen always has 16 elements, else fill with null values
      const fixedLengthUserKennzahlen = createFixedLengthArray(16, userKennzahlenOrDefaults).map((kennzahl, i) => {
        return valueExpressionMap.has(kennzahl) ? kennzahl : defaultKennzahlen[i];
      });

      return [fixedLengthUserKennzahlen, fixedLengthUserKennzahlen.slice(0, 8), fixedLengthUserKennzahlen.slice(8)];
    }, [userKennzahlen, defaultKennzahlen, userKennzahlenLoading]);

    const selectedKennzahlen = getUniqueArrayValues(userKennzahlen || [])?.filter((kennzahl) => !!kennzahl);

    const onKennzahlChange = useCallback(
      (index: number) => (kennzahl: string) => {
        allKennzahlen.splice(index, 1, kennzahl);
        updateUserKennzahlen(allKennzahlen);
      },
      [allKennzahlen, updateUserKennzahlen]
    );

    return (
      <Component
        {...props}
        allKennzahlen={allKennzahlen}
        kpiPageState={kpiPageState}
        filteredKennzahlen={selectedKennzahlen}
        firstPageKennzahlen={firstPageKennzahlen}
        secondPageKennzahlen={secondPageKennzahlen}
        onKennzahlChange={onKennzahlChange}
      />
    );
  };
}
export default withKpiSlider;
