import { ColumnFilterContentProps } from '@data-table/data-table.types';
import React, { ReactElement } from 'react';
import { MultiSelectFilterContent } from '@data-table/components/data-table-column-filter/filter-content/multi-select-filter-content';

export const GenericMultiSelectFilterContent = <T, V>({
  table,
  column,
  onConfirm,
  onCancel,
}: ColumnFilterContentProps<T, V>): ReactElement => {
  const currentFilterValue = column.getFilterValue() as string[];

  const rows = column.getFacetedRowModel().rows;

  const allValues = rows.map((row) => row.getValue<string>(column.id));

  return (
    <MultiSelectFilterContent<T, V>
      filterValue={currentFilterValue}
      allValues={allValues}
      table={table}
      column={column}
      onConfirm={onConfirm}
      onCancel={onCancel}
    />
  );
};
