import { VersionBadge } from '../../dashboard/components/VersionBadge';
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { MdDoneAll, MdTimelapse } from 'react-icons/md';
import arrowDown from '../assets/svg/zustellung_sendung_icon.svg';
import arrowUp from '../assets/svg/abholung_sendung_icon.svg';
import FinishedDelivery from '../models/entities/FinishedDelivery';
import UnfinishedCustomerPlanDetails from '../models/entities/UnfinishedCustomerPlanDetails';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import DeliveryModes from '../models/enumerations/DeliveryMode';
import { MarkerIcon } from '../models/enum/MarkerIcon';
import FinishedDeliveryBadge from './FinishedDeliveryBadge';
import CustomerTrackingData from '../models/types/CustomerTrackingData';
import { useDispatch } from 'react-redux';
import { DeliveryItemTrackingData } from '../models/types/DeliveryItemTrackingData';
import classNames from 'classnames';
import { AppInfoTrackingData } from '../models/types/AppInfoTrackingData';
import TimeRange from '../../utils/dates/TimeRange';
import { Tooltip as LegacyTooltip } from '../../common/components/Tooltip';
import Styles from './TourDetailsDeliveryTable.module.scss';
import { FinishedDeliveryInterruptionServiceInstance } from '../services/FinishedDeliveryInterruptionService';
import { AnomalyWarnIcon } from './AnomalyList';
import { IRawAnomalyResponse } from '../../dashboard/anomalies/interfaces/IRawAnomalyResponse';
import { AnomalyReason } from '../../dashboard/anomalies/utils/AnomalyUtils';
import { get, uniq } from 'lodash';
import moment, { Duration } from 'moment';
import { FilterProps, IdType, Row, useBlockLayout, useFilters, useSortBy, useTable } from 'react-table';
import { CenterAlignedCell } from '../../dashboard/components/table/CenterAlignedCell';
import {
  transformFinishedDeliveryTableData,
  transformUnfinishedDeliveryTableData,
} from './TourDetailsDeliveryTable.utils';
import { useTourDetailListContext } from '../../../refactoring/other/tour-detail-list-context';
import { StopTypeType } from '../models/state/TourDetailsState';
import { useToggleState } from '@hooks/use-toggle-state-hook';
import { useUserConfig } from '@hooks/use-user-config-hook';
import { Tooltip } from '@other-components/tooltip';
import { TourIdentifier } from '../models/state/tourDetails/TourIdentifier';
import { useValueExpressionContext } from '@contexts/value-expression-context';
import avgTourfreigabeZeitpunkt from '@contexts/value-expression-context/value-expressions/tour-freigabe-zeitpunkt-avg';
import {
  DurationKey,
  DurationModes,
  createDurationFromPlainObject,
} from '@legacy-modules/dashboard/models/enums/Duration';
import { DateRangeGrouping } from '@legacy-modules/metrics2/models/enumerations/DateRangeGrouping';
import { formatValueExpressionValue } from '@legacy-modules/dashboard/utils/FormatValueExpression';
import { useMultiOrgTree } from '@hooks/use-multi-org-tree-hook';
import { useAuthContext } from '@contexts/auth-context';
import { getOrgKeyByTourIdentifier } from '../helpers/GetOrgKeyByTourIdentifier';
import { tourDetailsSlice } from '@redux/tour-details.slice';
import { useTourDetailsContext } from '@other-components/tour-details-context';
import useMetricQuery from '@hooks/use-metric-query-hook/use-metric-query.hook';

export const DELIVERY_TABLE_ID = 'deliveryTable';

type Props = {
  customers: Map<string, CustomerTrackingData>;
  deliveryItems: DeliveryItemTrackingData[];
  unfinishedCustomers?: UnfinishedCustomerPlanDetails[];
  highlightRange?: TimeRange;
  appInfo: AppInfoTrackingData;
  anomalies: IRawAnomalyResponse[];
  allFinishedDeliveries: FinishedDelivery[];
  highlightStopInScrollbar?: boolean;
  tourIdentifier: TourIdentifier;
  contractorKey: string;
  filteredFDList: FinishedDelivery[];
};

type AnomalyCountProps = {
  names: string[];
  totalCount: number;
};

const AnomalyCount = ({ totalCount, names = [] }: AnomalyCountProps) => {
  const [ref, setRef] = useState(null);

  const uniqNames = uniq(names);

  const countAsString = totalCount === 0 ? '-' : String(totalCount);

  return (
    <span ref={setRef}>
      {countAsString}
      {countAsString !== '-' && (
        <LegacyTooltip placement={'top'} anchorElement={ref}>
          <div className={Styles.AnomalyContent}>
            {uniqNames.map((anomalyName) => (
              <React.Fragment key={anomalyName}>
                <span style={{ marginTop: -2 }}>
                  <AnomalyWarnIcon />
                </span>
                <span>{anomalyName}</span>
              </React.Fragment>
            ))}
          </div>
        </LegacyTooltip>
      )}
    </span>
  );
};

const TourDetailsDeliveryTable: React.FC<Props> = ({
  customers,
  deliveryItems = [],
  unfinishedCustomers = [],
  appInfo,
  anomalies,
  allFinishedDeliveries = [],
  highlightStopInScrollbar = false,
  tourIdentifier,
  contractorKey,
  filteredFDList,
}) => {
  const dispatch = useDispatch();
  const {
    highlightedMarkerState: [_, setHighlightedMarker],
    anomalyFilterState: [anomalyNameFilter],
  } = useTourDetailsContext();

  const rowsWithBreaks = useRef([]);
  const tourDetailsListContext = useTourDetailListContext();
  const [unterbrechungSelectOpen, toggleUnterbrechungSelect] = useToggleState(false);
  const [unterbrechung, setUnterbrechung] = useUserConfig('tourDetailsDeliveryTableInterruption', 15);
  const valueExpressionMap = useValueExpressionContext();
  const tourFreigabeValueExpression = valueExpressionMap.get(avgTourfreigabeZeitpunkt.identifier);
  const authService = useAuthContext();
  const duration = createDurationFromPlainObject({
    key: DurationKey.CUSTOM,
    mode: DurationModes.Custom,
    label: 'Benutzerdefiniert',
    isVisibleInForecast: false,
    offset: NaN,
    duration: NaN,
    from: moment(tourIdentifier.date),
    to: moment(tourIdentifier.date),
    range: 'days',
  });

  const orgKeys = useMemo(
    () => [`oz_t:${tourIdentifier.orgId}_${tourIdentifier.number}`],
    [tourIdentifier.orgId, tourIdentifier.number]
  );

  const { getByOrgKey } = useMetricQuery({
    orgKeys: orgKeys,
    valueExpression: tourFreigabeValueExpression,
    duration: duration,
    legacyDateRangeGrouping: DateRangeGrouping.none,
  });

  const rootOrgKeys = useMemo(
    () => [getOrgKeyByTourIdentifier(tourIdentifier, contractorKey)],
    [tourIdentifier, contractorKey]
  );
  const { data: orgDataMap } = useMultiOrgTree({ orgKeys: rootOrgKeys, parts: ['breadcrumb'] });
  const orgUnit = orgDataMap?.get(rootOrgKeys[0]);

  const rowClickAllowed = authService.canAccessStop(orgUnit?.breadcrumb);

  const tourFreigabeZeitpunktLabel = useMemo(() => {
    const tourFreigabeZeitpunktValue = getByOrgKey(orgKeys[0]);
    if (!tourFreigabeZeitpunktValue) {
      return;
    }
    return `Tourfreigabe ${formatValueExpressionValue(tourFreigabeValueExpression, tourFreigabeZeitpunktValue)}`;
  }, [getByOrgKey, orgKeys, tourFreigabeValueExpression]);

  const columns = useMemo(
    () => [
      {
        Header: () => (
          <div className={Styles.TableHeader}>
            <div className={Styles.VersionWrapper}>
              {get(appInfo, 'version', null) != null && <VersionBadge version={appInfo.version} />}
            </div>
            Sendungen
            <Dropdown size='sm' isOpen={unterbrechungSelectOpen} toggle={() => toggleUnterbrechungSelect()}>
              <Tooltip tooltipText='Scanunterbrechung' layerOptions={{ placement: 'top-end' }}>
                <DropdownToggle caret={true} className={Styles.UnterbrechungSelect}>
                  <MdTimelapse size={18} opacity={0.75} fill='#444444' />
                  <span>{unterbrechung} min</span>
                </DropdownToggle>
              </Tooltip>
              <DropdownMenu className={Styles.UnterbrechungSelectMenu}>
                <DropdownItem
                  active={unterbrechung === 10}
                  onClick={() => setUnterbrechung(10)}
                  className={Styles.UnterbrechungSelectItem}>
                  10 min
                </DropdownItem>
                <DropdownItem
                  active={unterbrechung === 15}
                  onClick={() => setUnterbrechung(15)}
                  className={Styles.UnterbrechungSelectItem}>
                  15 min
                </DropdownItem>
                <DropdownItem
                  active={unterbrechung === 20}
                  onClick={() => setUnterbrechung(20)}
                  className={Styles.UnterbrechungSelectItem}>
                  20 min
                </DropdownItem>
                <DropdownItem
                  active={unterbrechung === 25}
                  onClick={() => setUnterbrechung(25)}
                  className={Styles.UnterbrechungSelectItem}>
                  25 min
                </DropdownItem>
                <DropdownItem
                  active={unterbrechung === 30}
                  onClick={() => setUnterbrechung(30)}
                  className={Styles.UnterbrechungSelectItem}>
                  30 min
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        ),
        accessor: 'headline',
        plainName: 'Sendungen',
        columns: [
          {
            Header: () => null,
            Cell: ({ value }) => {
              const iconSrc = (() => {
                if (value == null) {
                  return MarkerIcon.DeliveriesOff;
                }
                return value === DeliveryModes.undeliverable ? MarkerIcon.UndeliverableOff : MarkerIcon.DeliveredOff;
              })();
              return <img className={Styles.Icon} src={iconSrc} alt='' />;
            },
            accessor: 'deliveryMode', // accessor is the "key" in the data
            filter: (rows: Array<Row>, id: IdType<any>[], active) => {
              if (active) {
                return rows;
              } else {
                return rows.filter((row) => {
                  const rowValue = row.values[id[0]];
                  return rowValue == null;
                });
              }
            },
            Filter: (props: FilterProps<{}>) => {
              const { column } = props;
              const { setFilter, filterValue = true } = column;

              const filterActive = filterValue === true;

              return (
                <CenterAlignedCell>
                  <Button
                    size='sm'
                    className={classNames(Styles.Button, {
                      [Styles.Active]: filterActive,
                    })}
                    onClick={() => setFilter(!filterValue)}>
                    <MdDoneAll />
                  </Button>
                </CenterAlignedCell>
              );
            },
            canFilter: true,
            align: 'center',
            canResize: false,
            maxWidth: 37,
          },
          {
            Header: () => <CenterAlignedCell>Stopp</CenterAlignedCell>,
            accessor: 'stopp', // accessor is the "key" in the data
            plainName: 'Stopp',
            align: 'center',
            canResize: false,
            disableFilters: true,
            maxWidth: 69,
            Cell: ({ value }) => <CenterAlignedCell>{value}</CenterAlignedCell>,
          },
          {
            Header: () => <CenterAlignedCell>Hinweise</CenterAlignedCell>,
            Cell: ({ value = [], state: { filters } }) => {
              const possibleAnomalyFilter = filters.find((f) => f.id === 'anomalies');

              const filterValue = possibleAnomalyFilter?.value || [];

              const filteredValue = filterValue.length ? value.filter((v) => filterValue.includes(v)) : value;

              return (
                <CenterAlignedCell>
                  <AnomalyCount names={filteredValue} totalCount={filteredValue.length} />
                </CenterAlignedCell>
              );
            },
            plainName: 'Hinweise',
            accessor: 'anomalies', // accessor is the "key" in the data
            filter: (rows: Array<Row>, id: IdType<any>[], value) => {
              if (!value?.length) return rows;
              return rows.filter((row) => {
                const rowValue = row.values[id[0]];
                return rowValue.filter((r) => value.includes(r)).length;
              });
            },
            align: 'center',
            canResize: false,
            disableFilters: false,
            maxWidth: 91,
          },
          {
            Header: () => <CenterAlignedCell>Art</CenterAlignedCell>,
            Cell: ({ value }) => (
              <CenterAlignedCell>
                <div style={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
                  <FinishedDeliveryBadge finishedDelivery={value} />
                </div>
              </CenterAlignedCell>
            ),
            accessor: 'type', // accessor is the "key" in the data
            align: 'center',
            canResize: false,
            disableFilters: true,
            maxWidth: 60,
          },
          {
            Header: () => (
              <CenterAlignedCell>
                <img alt='&darr;' src={arrowDown} title='Zustellungen' />
              </CenterAlignedCell>
            ),
            Cell: ({ value }) => <CenterAlignedCell>{value}</CenterAlignedCell>,
            accessor: 'up', // accessor is the "key" in the data
            align: 'center',
            canResize: false,
            disableFilters: true,
            maxWidth: 32,
          },
          {
            Header: () => (
              <CenterAlignedCell>
                <img alt='&uarr;' src={arrowUp} title='Abholungen & Mitnahmen' />
              </CenterAlignedCell>
            ),
            Cell: ({ value }) => <CenterAlignedCell>{value}</CenterAlignedCell>,
            accessor: 'down', // accessor is the "key" in the data
            align: 'center',
            canResize: false,
            disableFilters: true,
            maxWidth: 32,
          },
          {
            Header: () => <CenterAlignedCell>Uhrzeit</CenterAlignedCell>,
            Cell: ({ value }) => <CenterAlignedCell>{value}</CenterAlignedCell>,
            accessor: 'time', // accessor is the "key" in the data
            align: 'center',
            canResize: false,
            disableFilters: true,
          },
        ],
      },
    ],
    [appInfo, unterbrechung, setUnterbrechung, unterbrechungSelectOpen, toggleUnterbrechungSelect]
  );

  const anomaliesForStop = useMemo(() => {
    const map = new Map<number, string[]>();

    const setOrAdd = (number: number, name: string) => {
      if (map.has(number)) {
        const val = map.get(number);
        const newArray = [...val, name];
        map.set(number, newArray);
      } else {
        map.set(number, [name]);
      }
    };

    anomalies
      .filter((anomaly) => {
        if (anomaly.anomalyLevel === 'tour') {
          return false;
        }
        return true;
      })
      .forEach((ano) => {
        if (!ano?.tourStopRefs) return;
        ano.tourStopRefs.forEach((stopRef) => {
          const name = AnomalyReason[ano.anomalyType];
          // if there is no mapping, skip the anomaly
          if (!name) {
            // eslint-disable-next-line no-console
            console.info(`Skipped unknown anomaly with type: ${ano.anomalyType}`);
            return;
          }
          setOrAdd(stopRef.stopNumber, name);
        });
      }, []);
    return map;
  }, [anomalies]);

  const data = useMemo(() => {
    return [
      ...transformFinishedDeliveryTableData(filteredFDList, anomaliesForStop),
      ...transformUnfinishedDeliveryTableData(unfinishedCustomers, customers, deliveryItems, filteredFDList).filter(
        (v) => v != null
      ),
    ];
  }, [anomaliesForStop, customers, deliveryItems, filteredFDList, unfinishedCustomers]);

  const tableInstance = useTable(
    {
      columns,
      data,
      manualSorting: true,
      defaultDisableFilters: true,
      autoResetFilters: false,
      disableSortRemove: true,
    },
    useFilters,
    useSortBy,
    useBlockLayout
  );

  const { getTableProps, getTableBodyProps, rows, prepareRow, headerGroups, setFilter } = tableInstance;

  useEffect(() => {
    setFilter('anomalies', anomalyNameFilter);
  }, [anomalyNameFilter, setFilter]);

  const onRowClick = useCallback(
    (row: Row<any>) => {
      if (!rowClickAllowed) {
        return;
      }
      const { identifier: ref, deliveryMode, stopp } = row.original;
      // if mode is null, the row is a customer
      if (deliveryMode == null) {
        dispatch(
          tourDetailsSlice.actions.setSelectedStop({ type: StopTypeType.Open, stopNumber: stopp, customerRef: ref })
        );
      } else {
        dispatch(tourDetailsSlice.actions.setSelectedStopNumber(stopp));
      }
    },
    [dispatch, rowClickAllowed]
  );

  const onMouseOver = useCallback(
    (row: Row<any>) => {
      setHighlightedMarker(row?.original?.identifier);
    },
    [setHighlightedMarker]
  );

  const onMouseLeave = useCallback(() => setHighlightedMarker(null), [setHighlightedMarker]);

  const isNextStopVisible = (fd: FinishedDelivery) => {
    if (!fd) return false;

    const { displayableStopNumber } = fd;
    // Check if the next stop is visible in the filtered list
    return filteredFDList.some(({ displayableStopNumber: otherStopNo }) => otherStopNo === displayableStopNumber + 1);
  };

  const interruptionsAfterFinishedDelivery = useMemo(() => {
    const formatDuration = (duration: Duration, isNextDay: boolean) => {
      const days = Math.round(Math.abs(duration.asDays()));
      const minutes = Math.round(Math.abs(duration.asMinutes()));
      // round value down to nearest integer because we show unterbrechung
      // only for full minutes breakpoints 10, 15, 20, 25, 30
      // rounded minutes const will be used for further calculation purposes
      const minutesRoundedDown = Math.floor(Math.abs(duration.asMinutes()));
      if (minutes <= 60) return `Unterbrechung ${minutesRoundedDown} min`;
      if (isNextDay) return 'Folgetag';
      if (minutes > 60) {
        if (days > 1) {
          return `Unterbrechung ${Math.abs(duration.get('days'))} Tage ${Math.abs(duration.get('hours'))} h ${Math.abs(
            duration.get('minutes')
          )} min`;
        }
        return `Unterbrechung ${Math.abs(duration.get('hours'))} h ${Math.abs(duration.get('minutes'))} min`;
      }
    };

    return FinishedDeliveryInterruptionServiceInstance.findInterruptions(allFinishedDeliveries, unterbrechung).reduce(
      (collector, curr) => ({
        ...collector,
        [curr.interruption.after.uuid]: formatDuration(curr.duration, curr.isNextDay),
      }),
      {}
    );
  }, [allFinishedDeliveries, unterbrechung]);

  const hasInterruption = (fd: FinishedDelivery) => {
    if (!fd) return false;
    return interruptionsAfterFinishedDelivery[fd.uuid] !== undefined;
  };

  const wrapperRef = useRef(null);
  const innerRef = useRef(null);

  return (
    <div className={Styles.Wrapper} ref={wrapperRef}>
      <div className={Styles.DeliveryItems} ref={innerRef}>
        <div>
          <table {...getTableProps()} className={Styles.Table}>
            <thead className={Styles.Header}>
              {headerGroups.map((headerGroup) => (
                <tr
                  key={headerGroup.id}
                  {...headerGroup.getHeaderGroupProps({
                    style: {
                      display: 'table-row',
                    },
                  })}
                  className={Styles.Row}>
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column) => {
                      // Apply the header cell props
                      return (
                        <th key={headerGroup.id} {...column.getHeaderProps()}>
                          <div className={classNames(Styles.Content)}>
                            {column.render('Header')}
                            <div>{column.canFilter && column.id !== 'anomalies' ? column.render('Filter') : null}</div>
                          </div>
                        </th>
                      );
                    })
                  }
                </tr>
              ))}
            </thead>
            <tbody
              {...getTableBodyProps()}
              className={Styles.Body}
              onMouseLeave={onMouseLeave}
              ref={tourDetailsListContext.listContainerRef}>
              {tourFreigabeZeitpunktLabel && (
                <tr className={Styles.InterruptionRow}>
                  <td colSpan={7}>
                    <div className={Styles.Content}>
                      <span className={Styles.Text}>{tourFreigabeZeitpunktLabel}</span>
                    </div>
                  </td>
                </tr>
              )}
              {
                // Loop over the table rows
                rows.map((row, index, array) => {
                  // Prepare the row for display
                  prepareRow(row);
                  return (
                    // Apply the row props
                    <React.Fragment key={row.id}>
                      <tr
                        {...row.getRowProps()}
                        className={classNames(Styles.Row, {
                          // highlight the last 5 list items for "Rücklauf am Tourende" anomaly
                          // css selector :nth-of-type does not work because it count all tr elements
                          // inc. divider and other entries. Behavior can be changed if we do not
                          // use table and can use different elements for rows and dividers.
                          [Styles.highlighted]: index >= array.length - 5,
                          [Styles.disabled]: !rowClickAllowed,
                        })}
                        onMouseEnter={() => onMouseOver(row)}
                        onClick={() => onRowClick(row)}>
                        {
                          // Loop over the rows cells
                          row.cells.map((cell, i) => {
                            // Apply the cell props
                            return (
                              <td key={i} {...cell.getCellProps()}>
                                <div className={Styles.Content}>
                                  {
                                    // Render the cell contents
                                    cell.render('Cell')
                                  }
                                </div>
                              </td>
                            );
                          })
                        }
                      </tr>
                      {hasInterruption(row.original.type) && isNextStopVisible(row.original.type) && (
                        <React.Fragment key={'stop' + row.id}>
                          <tr
                            className={Styles.InterruptionRow}
                            ref={(el) => {
                              rowsWithBreaks.current[index] = el;
                            }}>
                            <td colSpan={7}>
                              <div className={Styles.Content}>
                                <span className={Styles.Text}>
                                  {interruptionsAfterFinishedDelivery[row.original.type.uuid]}
                                </span>
                              </div>
                            </td>
                          </tr>
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  );
                })
              }
              <tr ref={tourDetailsListContext.listElRef} />
            </tbody>
          </table>
        </div>
      </div>
      {highlightStopInScrollbar && (
        <div className={Styles.MarkerArea}>
          {rowsWithBreaks.current
            .filter((v) => Boolean(v))
            .map((b, index) => (
              <span
                key={index}
                className={Styles.Marker}
                style={{
                  top: Math.ceil((b.offsetTop * wrapperRef.current.offsetHeight) / innerRef.current.scrollHeight),
                }}
              />
            ))}
        </div>
      )}
    </div>
  );
};

export { TourDetailsDeliveryTable };
