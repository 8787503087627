import { useSelector } from 'react-redux';
import { useQuery, UseQueryResult } from 'react-query';

import { selectTokenId } from '@redux';
import { TourIdentifier } from '@data-table/data-table.types';
import { Duration } from '@legacy-modules/dashboard/models/enums/Duration';
import { DurationUtils } from '@utils/duration-utils';
import { ConfigKey, getEnvVariable } from '@legacy-modules/common/services/EnvironmentConfigurationService';
import { extractOrgIdFromOrgKey } from '@legacy-modules/dashboard/utils/OrgKeyUtils';
import { ZSBUtils } from '@legacy-modules/utils/tours/ZSBUtils';
import { BeanstandungData, BeanstandungenResponse } from '@custom-types/complaints';

type InputParameters = {
  orgKey: string;
  duration: Duration;
  enabled?: boolean;
};

/**
 * Hook that fetches complaints.
 * It is important to note that complaints can only be fetched on ZSB level
 * and do not exist for contractors. Thus, there is an explicit org key check in the enabled section.
 * @param parameters
 */
export default function useComplaints(parameters: InputParameters): UseQueryResult<BeanstandungData[]> {
  const { orgKey, duration, enabled = true } = parameters;
  const orgId = extractOrgIdFromOrgKey(orgKey);

  const tokenId = useSelector(selectTokenId);

  const primaryRange = DurationUtils.getDateRange(duration);
  const { from, to } = {
    from: primaryRange?.from?.format('YYYYMMDD'),
    to: primaryRange?.to?.format('YYYYMMDD'),
  };

  const url = new URL(`${getEnvVariable(ConfigKey.HTTP_API_URL)}/complaints`);
  url.searchParams.append('dateFrom', from);
  url.searchParams.append('dateUntil', to);
  url.searchParams.append('zsbOrgId', orgId);

  return useQuery<BeanstandungenResponse, unknown, BeanstandungData[]>({
    queryKey: ['complaints', orgId, from, to],
    queryFn: () =>
      fetch(url, {
        headers: {
          token: tokenId,
        },
      }).then((res) => res.json()),
    // the hook shall only run for ZSB org ids, since complaints exist only there
    enabled: enabled && ZSBUtils.isZSB(orgKey) && !!orgId && !!from && !!to,
    select: (data) =>
      data?.complaints?.map((beanstandung) => ({
        tourIdentifier: {
          tourNumber: beanstandung?.tour?.number?.toString(),
          orgKey: beanstandung.shipment.orgKey,
          orgId: null,
          tourDate: null,
          contractorKey: null,
        } as TourIdentifier,
        sendungsId: beanstandung?.shipment?.id,
        wert: beanstandung?.shipment?.valuable,
        beanstandungGrund: beanstandung?.complaint?.text,
        status: beanstandung?.status?.shortDescription,
        auftraggeber: beanstandung?.shipment?.client,
        meldezeitpunkt: beanstandung?.createdAt,
      })),
    placeholderData: { complaints: [] },
  });
}
