import { useCallback, useMemo } from 'react';

import { LoadingState, OrgKey, ValueExpressionIdentifier } from '@data-table/data-table.types';
import { Duration, defaultWeekdaysFilter } from '@legacy-modules/dashboard/models/enums/Duration';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import { DurationUtils } from '@utils/duration-utils';
import { useKpiQuery } from '@hooks/use-kpi-query-hook';
import { ColumnFiltersState } from '@tanstack/react-table';

type UseDataTableValueExpressionDataOutput = {
  valuesByOrgKey: Map<ValueExpressionIdentifier, Map<OrgKey, number>>;
  summaryValues: Map<ValueExpressionIdentifier, number>;
  isLoading: LoadingState;
};

const useDataTableValueExpressionData = (
  parentOrgKey: OrgKey,
  childOrgKeys: OrgKey[],
  valueExpressions: ValueExpression[],
  duration: Duration,
  isComparison: boolean = false,
  enabled: boolean = true,
  columnFilters: ColumnFiltersState = []
): UseDataTableValueExpressionDataOutput => {
  const [weekdayFilter] = useMemo(() => {
    const filterObject = duration.weekdayFilter || defaultWeekdaysFilter;
    return [
      filterObject,
      Object.keys(filterObject)
        .filter((key) => filterObject[key])
        .join(','),
    ];
  }, [duration.weekdayFilter]);

  const dateRange = isComparison
    ? DurationUtils.getComparisonDateRange(duration)
    : DurationUtils.getDateRange(duration);

  function findFilterValue(ve: ValueExpression) {
    return columnFilters.find((entry) => {
      return entry.id === ve.identifier;
    })?.value;
  }

  const expressionsFilter = valueExpressions.map((ve) => {
    return {
      valueExpression: ve,
      filterValue: findFilterValue(ve),
    };
  });

  const columnFiltersActive = columnFilters?.length > 0;

  // when column filters are active we don't want request the parent org
  // as the summary values returned by the backend will include both child and parent values then
  // which doubles the summary values because parent orgs values includes the values of all of its
  // child orgs already
  const orgKeys: OrgKey[] = columnFiltersActive ? [...childOrgKeys] : [parentOrgKey, ...childOrgKeys];

  const { data, isLoading } = useKpiQuery(
    {
      expressionsFilter: expressionsFilter,
      orgKeys,
      dateRange: dateRange,
      weekdaysFilter: weekdayFilter ? weekdayFilter : undefined,
    },
    {
      enabled: orgKeys?.length > 0 && expressionsFilter?.length > 0 && !!dateRange.from && !!dateRange.to && enabled,
    }
  );

  const valuesByOrgKey: Map<ValueExpressionIdentifier, Map<OrgKey, number>> = useMemo(() => {
    const dataMap = new Map<string, Map<OrgKey, number>>();
    data?.kpis?.groups?.forEach((result) => {
      result.kpiValues.forEach((kpiValue) => {
        if (dataMap.has(kpiValue.kpiId)) {
          const innerMap = dataMap.get(kpiValue.kpiId);
          innerMap.set(result.orgKey, kpiValue.value);
        } else {
          dataMap.set(kpiValue.kpiId, new Map([[result.orgKey, kpiValue.value]]));
        }
      });
    });
    return dataMap;
  }, [data]);

  const summaryValues: Map<ValueExpressionIdentifier, number> = useMemo(() => {
    if (columnFiltersActive) {
      // when column filters are active we can use the summary values coming from the backend
      return data?.kpis.summary.kpiValues.reduce((acc, obj) => {
        acc.set(obj.kpiId, obj.value);
        return acc;
      }, new Map<string, number>());
    } else {
      // when no column filtering is active we use the values from the parent org unit as summary values
      const filteredMap = new Map<string, number>();
      valuesByOrgKey.forEach((value, key) => filteredMap.set(key, value.get(parentOrgKey)));
      return filteredMap;
    }
  }, [valuesByOrgKey]);

  return { valuesByOrgKey, summaryValues, isLoading };
};

export default useDataTableValueExpressionData;
