import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';

import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

export default new ValueExpression({
  identifier: 'tour_loaded.customers.count',
  label: 'Empfänger',
  chart: ChartType.line,
  positiveDirection: PositiveDirection.custom,
  category: 'customers',
  description:
    // eslint-disable-next-line max-len
    '<p>Anzahl aller auf der geladenen Tour geplanten Empfänger gezählt nach dem ersten abgeschlossenen Zustellvorgang</p>\n',
});
