import React, { createContext, PropsWithChildren, ReactElement, useContext } from 'react';
import valueExpressionMap from './value-expressions';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import { useAuthContext } from '@contexts/auth-context';
import servicebeanstandungenMeldungVerlustrelevant from '../value-expression-context/value-expressions/servicebeanstandungen-meldung-verlustrelevant';
import servicebeanstandungenMeldungGrund from './value-expressions/servicebeanstandungen-grund';
import servicebeanstandungenMeldung from '../value-expression-context/value-expressions/servicebeanstandungen-meldung';

export type ValueExpressionContextType = Map<string, ValueExpression>;

const defaultValue: ValueExpressionContextType = new Map();
export const ValueExpressionContext = createContext(defaultValue);

export const useValueExpressionContext = (): ValueExpressionContextType => {
  return useContext<ValueExpressionContextType>(ValueExpressionContext);
};

type ValueExpressionContextProviderProps = Required<PropsWithChildren<{}>>;

export default function ValueExpressionContextProvider({
  children,
}: ValueExpressionContextProviderProps): ReactElement {
  const authService = useAuthContext();

  if (!authService || authService.cannot('complaints')) {
    valueExpressionMap.delete(servicebeanstandungenMeldungVerlustrelevant.identifier);
    valueExpressionMap.delete(servicebeanstandungenMeldungGrund.identifier);
    valueExpressionMap.delete(servicebeanstandungenMeldung.identifier);

    servicebeanstandungenMeldungGrund.getChildren().forEach((valueExpression) => {
      valueExpressionMap.delete(valueExpression.identifier);
    });
  }
  return <ValueExpressionContext.Provider value={valueExpressionMap}>{children}</ValueExpressionContext.Provider>;
}
