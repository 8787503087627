import { useMemo } from 'react';

import { OrgKey } from '@data-table/data-table.types';
import { useOrgConfig } from '@hooks/use-org-config-hook';
import { CustomLimit, DefaultLimit, TargetArea } from '@legacy-modules/dashboard/types/AbstractLimit';
import { LimitType } from '@legacy-modules/dashboard/websocket/DashboardLoadLimitResponsePayload';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

// primarily used for fetching kpi goals
export default function useTargetValue(orgKey: OrgKey, valueExpression: ValueExpression) {
  const { data: orgConfig, isLoading } = useOrgConfig({ orgKey, valueExpression });

  const abstractLimit = useMemo(() => {
    return reviveGoalClass(
      orgConfig?.values?.find((config) => config.orgKey === orgKey && config.configKey === valueExpression?.identifier)
        ?.value
    );
  }, [orgConfig, valueExpression, orgKey]);

  return { targetValue: abstractLimit?.mapLimits((value) => value / valueExpression.valueQuotient), isLoading };
}

const reviveGoalClass = (v: { type: string }) => {
  if (!v?.type) return null;

  switch (v.type) {
    case LimitType.LIMIT:
      return new DefaultLimit((v as DefaultLimit).positive, (v as DefaultLimit).negative);
    case LimitType.TARGET_AREA:
      return new TargetArea((v as TargetArea).targetAreas);
    case LimitType.CUSTOM_DIRECTION:
      return new CustomLimit((v as CustomLimit).direction, (v as CustomLimit).limit);
  }
};
