import { DataTableBody } from '@data-table/components/data-table-body';
import { DataTableColumnHeaders } from '@data-table/components/data-table-column-headers';
import { HinweiseTableHeader } from '@data-table/components/data-table-header';
import { DataTablePagination } from '@data-table/components/data-table-pagination';
import {
  DataTableLoadingPlaceholder,
  DataTableNoDataPlaceholder,
  HinweiseDataTableNoDataPlaceholder,
} from '@data-table/components/data-table-placeholders';
import { DataTableConfigProps } from '@data-table/data-table.types';
import { useBeanstandungenDataTableColumns } from '@data-table/hooks/use-beanstandungen-data-table-columns-hook';
import { useDataTableCustomInfo } from '@data-table/hooks/use-data-table-custom-info-hook';
import { useDataTableDuration } from '@data-table/hooks/use-data-table-duration-hook';
import { useDataTableFiltersState } from '@data-table/hooks/use-data-table-filter-state-hook';
import { useDataTableOrgKey } from '@data-table/hooks/use-data-table-org-key-hook';
import { Duration } from '@legacy-modules/dashboard/models/enums/Duration';
import React from 'react';
import { useComplaints } from '@hooks/use-complaints-hook';
import { BeanstandungData } from '@custom-types/complaints';

type BeanstandungenDataTableConfigProps = Omit<
  DataTableConfigProps<BeanstandungData>,
  'data' | 'columns' | 'isTour'
> & {
  defaultDuration?: Duration;
};

const withBeanstandungenDataTableConfig =
  (WrappedComponent: React.FC<DataTableConfigProps<BeanstandungData>>) =>
  ({ componentId, defaultDuration, defaultColumnFilters, ...rest }: BeanstandungenDataTableConfigProps) => {
    const [durationIsLoading, duration, onDurationChange] = useDataTableDuration(componentId, defaultDuration);

    const [orgKey] = useDataTableOrgKey();

    const { isTour, title } = useDataTableCustomInfo(orgKey, duration);

    const { data: beanstandungen, isLoading } = useComplaints({
      orgKey,
      duration,
      enabled: isTour && !durationIsLoading,
    });

    const columns = useBeanstandungenDataTableColumns();

    const [columnFilters, onColumnFiltersChange] = useDataTableFiltersState(componentId, defaultColumnFilters);

    const loading = isLoading || durationIsLoading;

    return (
      <WrappedComponent
        componentId={componentId}
        data={beanstandungen}
        columns={columns}
        state={{
          columnPinning: { left: ['tourIdentifier'] },
          columnFilters,
        }}
        onColumnFiltersChange={onColumnFiltersChange}
        header={
          <HinweiseTableHeader
            title={title}
            duration={duration}
            disabled={!beanstandungen?.length}
            showWeekdaysFilter={false}
            onDurationChange={onDurationChange}
          />
        }
        body={
          <>
            {loading && <DataTableLoadingPlaceholder />}
            {!loading && !isTour && <HinweiseDataTableNoDataPlaceholder />}
            {!loading && isTour && beanstandungen.length === 0 && <DataTableNoDataPlaceholder />}
            {!loading && isTour && beanstandungen.length > 0 && (
              <DataTableBody columnHeader={<DataTableColumnHeaders />} />
            )}
          </>
        }
        pagination={!loading && isTour && beanstandungen.length > 0 && <DataTablePagination />}
        {...rest}
      />
    );
  };

export default withBeanstandungenDataTableConfig;
