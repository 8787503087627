import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

export default new ValueExpression({
  identifier: 'tourfahrtzeit',
  label: 'Tourfahrtzeit',
  valueFormat: '-#.###.##0,0 h',
  positiveDirection: PositiveDirection.down,
  category: 'tours',
  definition: getDefinitionByName('Tourfahrtzeit'),
  valueQuotient: 3600,
});
