import { UndeliverableReason } from '../enumerations/UndeliverableReason';

export const complaintReasons = [
  'BNK - Keine',
  'Verspätung',
  'F-Adresse ist richtig',
  'unklarer Sendungsverbleib',
  'unklarer Abliefernachweis',
  'Fehlerhafte BNK',
  'Umverfügung nicht eingehalten',
  'fehlerhafte Scannung',
  'fehlerhafte A-Scannung',
  'fehlerhafte N-Scannung',
  'Fehler bei der Abholung',
  'Fehler bei der Zustellung',
  'Unerwünschte Zustellung am PaketShop (PS)',
  'Kontaktlose Zustellung nicht erfolgt',
  'Abholung nicht erfolgt',
  'keine Quittung',
  'Paketankündigung nicht eingehalten',
  'Schaden gemeldet',
  'Inhalt fehlt (Teilverlust)',
  'Haftpflichtschaden',
  'Korrekturbeleg eingetroffen',
  'Widerspruch nach OPS Klärung',
  'Widerspruch zur Haftungsentscheidung',
  'Rückzahlung von KD/ATG',
  'Zahlungsrückläufer',
  'Unfreundlicher Mitarbeiter',
  'Prävention',
  'Ungepflegtes Erscheinungsbild',
  'Sendung verschmutzt/riecht nach Rauch',
  'Fehlerhafte Datenänderung',
  'sprachliche Probleme / Verständigungsprobleme',
  'unzureichende / falsche Beratung',
  'Ermittlung durch Polizei/Staatsanwaltschaft',
  'Bearbeitungszeit überschritten',
  'Reklamation unvollständig abgeschlossen',
  'PaketShop existiert nicht mehr/ falsche Adresse',
  'Öffnungszeiten des Paketshops wurden nicht eingehalten',
  'Hermes App - allgemeine Probleme',
  'Hermes App - Verbindungsabbrüche',
  'Hermes App - Sendungsverfolgung fehlerhaft',
];

export const undeliverableReasonsShipmentBased: UndeliverableReason[] = [
  'Canceled',
  'DeliveryItemDamaged',
  'DeliveryRefused',
  'IdentAge',
  'IdentDocument',
  'IdentName',
  'IdentPicture',
  'IdentBirthday',
  'MisroutedDeliveryItem',
  'NoMoney',
  'NotMet1',
  'NotMet2',
  'NotMet3',
  'NotMet4',
  'Tan1',
  'Tan2',
  'Tan3',
  'Tan4',
  'RLoGGS',
  'TooLate',
  'WrongAddress',
];

export const shipmentTypes = [
  'ABA',
  'BRFBU',
  'BRFST',
  'FAH',
  'GEPSO',
  'GEPST',
  'GRST',
  'HERVP',
  'HPGE',
  'HPST',
  'KATST',
  'KATUN',
  'KFS',
  'KRTS',
  'KWS',
  'PKT',
  'SG',
  'SKI',
  'TUE',
  'LTUE',
  'UNB',
];

export const shipmentTypeMap = {
  ABA: 'Abholauftrag',
  BRFBU: 'Businessbrief',
  BRFST: 'Brief Standard',
  FAH: 'Fahrrad',
  GEPSO: 'Sondergepäck',
  GEPST: 'Gepäck Standard',
  GRST: 'Großstück',
  HERVP: 'Herstellerverpackung',
  HPGE: 'Hängepaket gebündelt',
  HPST: 'Hängepaket Standard',
  KATST: 'Katalog Standard',
  KATUN: 'Katalog unfoliert',
  KFS: 'Krankenfahrstuhl',
  KRTS: 'Kartusche',
  KWS: 'Kleinwarensendung',
  PKT: 'Paket',
  SG: 'Servicegebühr',
  SKI: 'Ski',
  TUE: 'Tüte',
  LTUE: 'Leichttüte',
  UNB: 'Unbekannt',
};

export const abhMitReturnTypes = ['Gepäck', 'PriPS', 'Retoure'];
