import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';

import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

const label = 'Anzahl Stopps nach Nachweisart';
const key = 'finished_deliveries.proof_of_delivery.by_type';

const getConfig = (category) => {
  return {
    identifier: `${key}.${category.key}`,
    longLabel: `${label} (${category.label})`,
    label: category.label,
    chart: ChartType.bar,
    positiveDirection: PositiveDirection.up,
    category: 'deliveries',
    description: '<p>Anzahl Stopps nach Nachweisart (Foto-Unterschrift, Unterschrift, Scanner, Ohne)</p>\n',
    hidden: true,
  };
};

const categories = {
  none: 'Ohne',
  image: 'Foto',
  signature: 'Unterschrift',
  imageSignature: 'Foto-Unterschrift',
  tan: 'Tan',
};

const children = Object.entries(categories).map((category) => {
  const [key, label] = category;
  return new ValueExpression(getConfig({ key: key, label: label }));
});

export default new ValueExpression({
  ...getConfig({ key: 'sum', label: '' }),
  label: label,
  longLabel: label,
  chart: ChartType.line,
  hidden: false,
  children: children,
});
