import React from 'react';
import { useSelector } from 'react-redux';
import { Duration } from '../../../../dashboard/models/enums/Duration';
import { Table, TableColumn, TableRow } from '../../../../common/components/table/Table';
import { selectDashboardOrgKey } from '@redux/dashboard.selectors';
import { useKpiQuery } from '@hooks/use-kpi-query-hook';
import { DurationUtils } from '@utils/duration-utils';
import Styles from './ComplaintsValueRows.module.scss';
import { DateRangeGrouping } from '@legacy-modules/metrics2/models/enumerations/DateRangeGrouping';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

type Props = {
  duration: Duration;
  valueExpression: ValueExpression;
  style?: React.CSSProperties;
  headline?: string;
  renderSingle?: boolean;
};

const ComplaintsValueRows: React.FC<Props> = ({ duration, valueExpression, style, headline, renderSingle = false }) => {
  const orgKey = useSelector(selectDashboardOrgKey);

  const dateRange = DurationUtils.getDateRange(duration);

  const valueExpressions = renderSingle ? [valueExpression] : [...valueExpression.getChildren()];

  const { data: summaryKpiValues, isLoading } = useKpiQuery(
    {
      expressionsFilter: valueExpressions.map((ve) => {
        return { valueExpression: ve };
      }),
      orgKeys: [orgKey],
      dateRange: dateRange,
      grouping: DateRangeGrouping.none,
    },
    {
      enabled: !!orgKey && !!dateRange.from && !!dateRange.to,
    },
    (data) => data.kpis.summary.kpiValues
  );

  const renderComplaintsTable = () => {
    const sortedData = summaryKpiValues.toSorted((a, b) => b.value - a.value);
    return sortedData.slice(0, 5).map(({ value, kpiId }) => {
      return (
        <TableRow key={`sb-value-row-${kpiId}`} className={Styles.Row}>
          <TableColumn variant={'small'}>
            {valueExpressions.find((ve) => ve.identifier === kpiId)?.getShortLabel()}
          </TableColumn>
          <TableColumn variant={'small'} isNumber>
            {value}
          </TableColumn>
        </TableRow>
      );
    });
  };

  if (!summaryKpiValues?.length || isLoading) {
    return null;
  }

  if (renderSingle) {
    return <span>{valueExpression.getValueFormatter()(summaryKpiValues[0].value)}</span>;
  } else {
    return (
      <>
        <div className={Styles.Headline}>{headline}</div>
        <div style={{ minHeight: '170px' }}>
          <Table style={style} className={Styles.ComplaintsValueRows} body={renderComplaintsTable()} />
        </div>
      </>
    );
  }
};

export default ComplaintsValueRows;
