/* eslint-disable @typescript-eslint/no-explicit-any */
import 'moment/locale/de';
import moment from 'moment-timezone';
import { extendMoment } from 'moment-range';
import { sortBy } from 'lodash';

import MetricsDataProvider from '../../metrics2/services/MetricsDataProvider';
import { Duration, DurationModes } from '../models/enums/Duration';
import { DateRange } from '../../utils/dates/DateRange';
import { AnnotationConfig, LineAnnotationLabel, LineAnnotationOptions } from 'chartjs-plugin-annotation';
import { CustomLimit, DefaultLimit, LimitClassTypes, TargetArea } from '../types/AbstractLimit';
import { DateRangeGrouping } from '../../metrics2/models/enumerations/DateRangeGrouping';

const { range } = extendMoment(moment as any);

type DateRangeValues = {
  resolution: WeekResolution;
  dates: Array<moment.Moment>;
  axisLabels: Array<Array<Array<string>>> | string[];
  label?: string;
  dataLabels: Array<Array<Array<string>>> | string[];
};

export enum WeekResolution {
  day = 'day',
  month = 'month',
  isoWeek = 'isoWeek',
  weeks = 'weeks',
  week1 = 'week1',
}

export type FromTo = {
  from: moment.Moment;
  to: moment.Moment;
};

moment.updateLocale('de', null);

type ChartView = {
  to: number;
  axisLabelFn: (d: moment.Moment) => string;
  dateLabelFn: (d: moment.Moment) => string;
  formatDates: (axisLabelFn: (d: moment.Moment) => string, dates: moment.Moment[]) => string[] | string[][][];
};

const weekFormatFn = (axisLabelFn: (d: moment.Moment) => string, dates: moment.Moment[]) => {
  return dates.map((date) => {
    return axisLabelFn(date);
  });
};

const dayFormatFn = (axisLabelFn: (d: moment.Moment) => string, dates: moment.Moment[]) => {
  return dates.map((date, i) => {
    if (i === 0) {
      return [[axisLabelFn(date)], [date.format('dd')]];
    }

    if (date.date() === 1) {
      return [[axisLabelFn(date)], [date.format('dd')]];
    } else {
      return [[date.format('D')], [date.format('dd')]];
    }
  });
};

// If days > this value, it will be rendered as weeks in charts
const DAYS_UNTIL_RENDERED_AS_WEEK = 26 * 7;

const CHART_VIEWS: ChartView[] = [
  {
    to: 7,
    axisLabelFn: (d) => d.format('D MMM'),
    dateLabelFn: (d) => d.format('DD.MM.YYYY'),
    formatDates: dayFormatFn,
  },
  {
    to: 5 * 7, // 5 weeks,
    axisLabelFn: (d) => d.format('D MMM'),
    dateLabelFn: (d) => d.format('DD.MM.YYYY'),
    formatDates: dayFormatFn,
  },
  {
    to: DAYS_UNTIL_RENDERED_AS_WEEK, // 26 weeks,
    axisLabelFn: (d) => 'KW ' + d.format('W'),
    dateLabelFn: (d) => 'KW ' + d.format('W YYYY'),
    formatDates: weekFormatFn,
  },
  {
    to: Number.MAX_SAFE_INTEGER,
    axisLabelFn: (d) => d.format('MMMM YYYY'),
    dateLabelFn: (d) => d.format('MMMM YYYY'),
    formatDates: weekFormatFn,
  },
];
const PROGNOSE_CHART_VIEWS: ChartView[] = [
  {
    to: 7,
    axisLabelFn: (d) => d.format('D MMM'),
    dateLabelFn: (d) => d.format('DD.MM.YYYY'),
    formatDates: dayFormatFn,
  },
  {
    to: 5 * 7, // 5 weeks,
    axisLabelFn: (d) => d.format('D MMM'),
    dateLabelFn: (d) => d.format('DD.MM.YYYY'),
    formatDates: dayFormatFn,
  },
  {
    to: 8 * 7, // 8 weeks
    axisLabelFn: (d) => 'KW ' + d.format('W'),
    dateLabelFn: (d) => d.format('DD.MM.YYYY'),
    formatDates: weekFormatFn,
  },
  {
    to: DAYS_UNTIL_RENDERED_AS_WEEK, // 26 weeks,
    axisLabelFn: (d) => 'KW ' + d.format('W'),
    dateLabelFn: (d) => 'KW ' + d.format('W YYYY'),
    formatDates: weekFormatFn,
  },
  {
    to: Number.MAX_SAFE_INTEGER,
    axisLabelFn: (d) => d.format('MMMM YYYY'),
    dateLabelFn: (d) => d.format('MMMM YYYY'),
    formatDates: weekFormatFn,
  },
];

const sortedChartViews = sortBy(CHART_VIEWS, 'to');

const getChartViewForDay = (day: number) => {
  return sortedChartViews.find((dv) => day < dv.to);
};
const getChartViewForPrognoseDay = (day: number) => {
  return PROGNOSE_CHART_VIEWS.find((dv) => day < dv.to);
};

export default class ChartUtils {
  static readonly thresholdDataBarsCount: number = 72;
  static readonly thresholdAxisBarsCount: number = 72;

  static getDayCount(from: moment.Moment, to: moment.Moment): number {
    return moment(to).diff(moment(from), 'days');
  }

  static getDateResolution(
    from: moment.Moment,
    to: moment.Moment,
    weekResolution: WeekResolution = WeekResolution.isoWeek,
    maxBars = ChartUtils.thresholdDataBarsCount
  ): WeekResolution {
    const days = ChartUtils.getDayCount(from, to);
    if (days < maxBars) {
      return WeekResolution.day;
    } else if (days < DAYS_UNTIL_RENDERED_AS_WEEK) {
      return weekResolution;
    } else {
      return WeekResolution.month;
    }
  }

  static getCorrectedFilterRange(from: moment.Moment, to: moment.Moment): DateRange {
    const days = ChartUtils.getDayCount(from, to);
    const resolution = ChartUtils.getDateResolution(
      from,
      to,
      WeekResolution.isoWeek,
      ChartUtils.thresholdDataBarsCount
    );
    return {
      from: moment(from).startOf(resolution as moment.unitOfTime.StartOf),
      to: moment(from)
        .clone()
        .add(days, 'days')
        .endOf(resolution as moment.unitOfTime.StartOf),
    };
  }

  static getDateRanges(from: moment.Moment, to: moment.Moment): DateRangeValues {
    const days = ChartUtils.getDayCount(from, to);
    const resolution = ChartUtils.getDateResolution(
      from,
      to,
      WeekResolution.isoWeek,
      ChartUtils.thresholdDataBarsCount
    );

    const dateRange: DateRange = ChartUtils.getCorrectedFilterRange(from, to);
    const dates: Array<moment.Moment> = Array.from(
      range(dateRange.from.clone().startOf(resolution as moment.unitOfTime.StartOf), dateRange.to)
        // FIXME: Use correct typing
        .by(MetricsDataProvider.timeRangeResolutionForRangeGrouping(resolution) as any)
    );

    const { axisLabelFn, dateLabelFn, formatDates } = getChartViewForDay(days);
    const formattedAxisLabels = formatDates(axisLabelFn, dates);
    const dataLabels = dates.map(dateLabelFn);
    return {
      resolution,
      dates,
      axisLabels: formattedAxisLabels,
      dataLabels,
    };
  }

  /**
   * FIXME: This is a workaround to quickly fix the issue with special requirements
   * of the mengenprognose chart.As the whole chart implementation is considered
   * to be refactored, this is a quick fix to get the other charts working correctly.
   * see https://hermesgermany.atlassian.net/browse/AUP-543
   */
  static getPrognoseDateRanges(from: moment.Moment, to: moment.Moment): DateRangeValues {
    const days = ChartUtils.getDayCount(from, to);
    const resolution = ChartUtils.getDateResolution(
      from,
      to,
      WeekResolution.isoWeek,
      ChartUtils.thresholdDataBarsCount
    );

    const dateRange: DateRange = ChartUtils.getCorrectedFilterRange(from, to);
    const dates: Array<moment.Moment> = Array.from(
      range(dateRange.from.clone().startOf(resolution as moment.unitOfTime.StartOf), dateRange.to)
        // FIXME: Use correct typing
        .by(MetricsDataProvider.timeRangeResolutionForRangeGrouping(resolution) as any)
    );
    const { axisLabelFn, dateLabelFn, formatDates } = getChartViewForPrognoseDay(days);
    const formattedAxisLabels = formatDates(axisLabelFn, dates);
    const dataLabels = dates.map(dateLabelFn);
    return {
      resolution,
      dates,
      axisLabels: formattedAxisLabels,
      dataLabels,
    };
  }

  static getDateRange(duration: Duration): FromTo {
    if (duration.mode === DurationModes.Custom) {
      // maybe from and to are strings when loading user configs...
      const from = moment(duration.from);
      const to = moment(duration.to);
      return { from: from, to: to };
    } else {
      const from = moment().add(duration.offset, duration.range).startOf(duration.range);
      const to = moment(from)
        .add(duration.duration - 1, duration.range)
        .endOf(duration.range);
      return { from, to };
    }
  }

  static getComparisonDateRange(duration: Duration): DateRange {
    const dateRange = this.getDateRange(duration);
    return ChartUtils.calculateComparisonDateRangeFromDateRange(dateRange);
  }

  static calculateComparisonDateRangeFromDateRange(dateRange: DateRange): DateRange {
    const startEndDiff = moment(dateRange.to).diff(dateRange.from, 'days') + 1;
    const numberOfWeeks = Math.ceil(startEndDiff / 7);
    const from = moment(dateRange.from).subtract(numberOfWeeks, 'weeks');
    const to = moment(dateRange.to).subtract(numberOfWeeks, 'weeks');
    return { from, to };
  }
}

export const getBoxesForKpi = () => {
  return [
    {
      drawTime: 'beforeDatasetsDraw', // overrides annotation.drawTime if set
      display: true,
      type: 'box',
      mode: 'horizontal',
      xScaleID: 'x-axis-0',
      yScaleID: 'y-axis-0',
      borderColor: 'white',
      borderWidth: 0,
      backgroundColor: '#c8fac3',
      yMin: 5000,
    },
    {
      drawTime: 'beforeDatasetsDraw', // overrides annotation.drawTime if set
      display: true,
      type: 'box',
      mode: 'horizontal',
      xScaleID: 'x-axis-0',
      yScaleID: 'y-axis-0',
      borderColor: 'white',
      borderWidth: 0,
      backgroundColor: '#fae2a7',
      yMax: 5000,
      yMin: 3000,
    },
    {
      drawTime: 'beforeDatasetsDraw', // overrides annotation.drawTime if set
      display: true,
      type: 'box',
      mode: 'horizontal',
      xScaleID: 'x-axis-0',
      yScaleID: 'y-axis-0',
      borderColor: 'white',
      borderWidth: 0,
      backgroundColor: '#faa7a7',
      yMax: 3000,
    },
  ];
};

const positiveColor = '#51e4a0';
const negativeColor = '#fa6162';

export const getLinesForGoal = (goal: LimitClassTypes, showLabel: boolean = false): Array<LineAnnotationOptions> => {
  const getLabel = (text: string, color: string): LineAnnotationLabel => ({
    // Background color of label, default below
    backgroundColor: color,

    fontFamily: 'Roboto',
    // Font family of text, inherits from global

    // Font size of text, inherits from global
    fontSize: 12,

    // Font style of text, default below
    fontStyle: 'bold',

    // Font color of text, default below
    fontColor: '#fff',

    // Padding of label to add left/right, default below
    xPadding: 6,

    // Padding of label to add top/bottom, default below
    yPadding: 6,

    // Radius of label rectangle, default below
    cornerRadius: 0,

    // Anchor position of label on line, can be one of: top, bottom, left, right, center. Default below.
    position: 'left',

    // Adjustment along x-axis (left-right) of label relative to above number (can be negative)
    // For horizontal lines positioned left or right, negative values move
    // the label toward the edge, and positive values toward the center.
    xAdjust: 0,

    // Adjustment along y-axis (top-bottom) of label relative to above number (can be negative)
    // For vertical lines positioned top or bottom, negative values move
    // the label toward the edge, and positive values toward the center.
    yAdjust: 10,

    // Whether the label is enabled and should be displayed
    enabled: true,

    // Text to display in label - default is null. Provide an array to display values on a new line
    content: text,
  });

  const getLine = (value: number, color: string, label: string): LineAnnotationOptions => ({
    drawTime: 'beforeDatasetsDraw', // overrides annotation.drawTime if set
    type: 'line',
    mode: 'horizontal',
    scaleID: 'y-axis-0',
    borderColor: color,
    borderWidth: 2,
    borderDash: [1, 4],
    value,
    label: showLabel ? getLabel(label, color) : undefined,
  });

  if (!goal) {
    return [];
  }

  if (goal instanceof DefaultLimit) {
    return [
      getLine((goal as DefaultLimit).positive, positiveColor, 'Zielwert 1'),
      getLine((goal as DefaultLimit).negative, negativeColor, 'Zielwert 2'),
    ];
  } else if (goal instanceof CustomLimit) {
    return [
      getLine(goal.limit.positive, positiveColor, 'Zielwert 1'),
      getLine(goal.limit.negative, negativeColor, 'Zielwert 2'),
    ];
  } else if (goal instanceof TargetArea) {
    return [
      getLine(goal.targetAreas[0], positiveColor, 'Zielwert 1'),
      getLine(goal.targetAreas[1], positiveColor, 'Zielwert 2'),
    ];
  }
};

export const getAnnotationsForGoal = (goal: LimitClassTypes): AnnotationConfig | undefined => {
  if (!goal) {
    return;
  }

  return {
    drawTime: 'beforeDatasetsDraw', // (default)
    dblClickSpeed: 350, // ms (default)
    annotations: [...getLinesForGoal(goal, false)],
  };
};

export const chartHoverFnFactory = (onActiveTooltipIndex, onTooltipPositionChange) => (e, activeElements) => {
  if (activeElements[0]) {
    const hoveredDatasetIndex = activeElements[0]._datasetIndex;
    onActiveTooltipIndex(hoveredDatasetIndex);
    onTooltipPositionChange({
      x: activeElements[0]._model.x,
      y: activeElements[0]._model.y,
    });
  } else {
    onActiveTooltipIndex(-1);
  }
};

export const chartCustomFnFactory = (custom, onTooltipPositionChange) => (tooltipModel: any) => {
  custom(tooltipModel);
  onTooltipPositionChange({
    x: tooltipModel.caretX,
    y: tooltipModel.caretY,
  });
};
