import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import OrgUnit from '../../metrics2/models/entities/organization/OrgUnit';
import { OrgUnitPart } from '../../metrics2/models/queries/OrganizationQuery';
import { MapLayerStyle } from '../../utils/map/MapLayerStyle';
import MapUtils, { Bounds } from '../../utils/map/MapUtils';
import OverviewMapLegend from '../components/maps/OverviewMapLegend';
import MapBreadcrumb from '../components/maps/MapBreadcrumb';
import TreeTypeSwitch from '../components/maps/TreeTypeSwitch';
import { PositiveDirections, PositiveDirection } from '../../metrics2/models/entities/PositiveDirection';
import MapboxGLMap, { Tile } from '../../common/components/maps/MapboxGLMap';
import breadcrumbFilter from './../../utils/breadcrumbFilterUtil';
import { OrgUnitUtils } from '../../utils/tours/OrgUnitUtils';
import { MapValueMode } from '../models/enumerations/MapValueMode';
import { TreeType } from '../../metrics2/models/enumerations/TreeType';
import { useAuthContext } from '@contexts/auth-context';
import { useMultiOrgTree } from '@hooks/use-multi-org-tree-hook';
import { useOverviewLegendData } from '@other-components/use-overview-legend-data-hook';
import { selectComparisonMode, selectOverviewValueExpression, selectPrimaryFilter } from '@redux/overview.selectors';
import { useOverviewContext } from '@contexts/overview-context';
import { overviewSlice } from '@redux/overview.slice';

const getOrgUnitStyle = (
  mapValueMode: MapValueMode,
  positiveDirection: PositiveDirections,
  percentage: number | null | undefined,
  hover: boolean = false
): MapLayerStyle => {
  if (percentage) {
    const computedStyle = MapUtils.calculateGradientColor(
      percentage,
      hover,
      mapValueMode,
      mapValueMode === MapValueMode.difference && positiveDirection === PositiveDirection.down
    );
    return { ...MapUtils.DEFAULT_LAYER_STYLE, ...computedStyle };
  }
  return MapUtils.DEFAULT_LAYER_STYLE;
};

/**
 * TODO: Colorize OrgUnits
 * Data needed:
 *  - OrgUnits
 *  - Min / Max Values for current value expression
 *  - MapValueMode
 *
 */
const _calculateBoundingBox = (orgUnits: Array<OrgUnit>): Bounds => {
  const orgUnitsToUse = orgUnits.filter((o) => o.bbox != null);

  const swLatitudes = orgUnitsToUse.map((ou) => ou.bbox[1]);
  const swLongitudes = orgUnitsToUse.map((ou) => ou.bbox[0]);
  const neLatitudes = orgUnitsToUse.map((ou) => ou.bbox[3]);
  const neLongitudes = orgUnitsToUse.map((ou) => ou.bbox[2]);
  return [
    [Math.min(...swLongitudes), Math.min(...swLatitudes)],
    [Math.max(...neLongitudes), Math.max(...neLatitudes)],
  ];
};

const FALLBACK_BOUNDS: Bounds = [
  [5.8670874, 47.2702482],
  [15.0416685, 55.0537783],
];

const OverviewMapContainer: React.FC = () => {
  const dispatch = useDispatch();
  const authService = useAuthContext();
  const primaryFilter = useSelector(selectPrimaryFilter);
  const primaryValueExpression = useSelector(selectOverviewValueExpression);
  const comparisonMode = useSelector(selectComparisonMode);
  const { hoverOrgKeyState, selectedTreeTypesState, mapValueModeState } = useOverviewContext();
  const [hoverOrgKey, setHoverOrgKey] = hoverOrgKeyState;
  const [selectedTreeTypes, setSelectedTreeTypes] = selectedTreeTypesState;
  const [mapValueMode, setMapValueMode] = mapValueModeState;

  const _onValueModeChanged = useCallback(
    (mode: MapValueMode) => {
      if (mode === mapValueMode) {
        return;
      }
      setMapValueMode(mode);
    },
    [mapValueMode, setMapValueMode]
  );

  const [orgKeys, properties, childrenProperties] = useMemo(
    () => [
      primaryFilter?.orgKeys,
      ['children', 'breadcrumb', 'geometry', 'treeTypes'] as OrgUnitPart[],
      ['geometry', 'properties'] as OrgUnitPart[],
    ],
    [primaryFilter?.orgKeys]
  );

  const { data: orgDataMap } = useMultiOrgTree({
    orgKeys,
    parts: properties,
    from: primaryFilter.from,
    to: primaryFilter.to,
    enabled: !!primaryFilter?.orgKey,
  });

  const rootOrgUnits = useMemo(() => {
    if (!orgDataMap || orgDataMap.size === 0) return [];
    return Array.from(orgDataMap.values()).map((result) => new OrgUnit(result));
  }, [orgDataMap]);

  const [availableTreeType, availableTreeTypes] = useMemo(() => {
    if (rootOrgUnits.length === 0) return [null, []];
    const selectedTreeType = selectedTreeTypes[rootOrgUnits[0].orgType];
    if (selectedTreeType && !rootOrgUnits[0].treeTypes?.includes(selectedTreeType)) {
      setSelectedTreeTypes((prev) => {
        const next = { ...prev };
        delete next[rootOrgUnits[0].orgType];
        return next;
      });
    }
    return [selectedTreeType || rootOrgUnits[0]?.treeTypes?.[0] || null, rootOrgUnits[0]?.treeTypes];
  }, [rootOrgUnits, selectedTreeTypes, setSelectedTreeTypes]);

  const { data: childOrgDataMap } = useMultiOrgTree({
    orgKeys: rootOrgUnits.length === 1 ? rootOrgUnits[0]?.children : [],
    parts: childrenProperties,
    orgType: availableTreeType,
    from: primaryFilter.from,
    to: primaryFilter.to,
    enabled: primaryFilter && rootOrgUnits.length === 1 && availableTreeType != null,
  });

  const [parentOrgUnit, orgUnits] = useMemo(() => {
    if (rootOrgUnits.length === 0) return [null, []];
    if (rootOrgUnits.length === 1) {
      return [
        rootOrgUnits[0],
        childOrgDataMap?.size > 0 ? Array.from(childOrgDataMap.values()).map((result) => new OrgUnit(result)) : [],
      ];
    }
    return [null, rootOrgUnits];
  }, [rootOrgUnits, childOrgDataMap]);

  const { data: breadcrumbOrgDataMap } = useMultiOrgTree({
    orgKeys: rootOrgUnits.length === 1 ? rootOrgUnits[0]?.breadcrumb : [],
    parts: childrenProperties,
    from: primaryFilter.from,
    to: primaryFilter.to,
    enabled: primaryFilter && rootOrgUnits.length === 1,
  });

  const orgBreadcrumb = useMemo(() => {
    if (rootOrgUnits.length > 1) {
      return breadcrumbFilter(rootOrgUnits, authService.rootOrgKeys.overview);
    }
    if (!breadcrumbOrgDataMap || breadcrumbOrgDataMap.size === 0) return [];
    return breadcrumbFilter(
      Array.from(breadcrumbOrgDataMap.values()).map((result) => new OrgUnit(result)),
      authService.rootOrgKeys.overview
    );
  }, [breadcrumbOrgDataMap, authService.rootOrgKeys.overview, rootOrgUnits]);

  const _onOrgUnitHoverChanged = useCallback(
    (orgKey: string) => {
      if (!authService.can('ui.overview.map.area.hover')) {
        return;
      }
      setHoverOrgKey(orgKey);
    },
    [authService, setHoverOrgKey]
  );

  const _onOrgUnitClicked = useCallback(
    (orgKey: string) => {
      if (!authService.can('ui.overview.map.area.click')) {
        return;
      }
      dispatch(overviewSlice.actions.updateOrgKey({ primaryOrgKey: orgKey, compareOrgKey: null, comparisonMode }));
    },
    [authService, dispatch, comparisonMode]
  );

  const _onBreadcrumbClick = useCallback(
    (orgUnit: OrgUnit) => {
      dispatch(
        overviewSlice.actions.updateOrgKey({ primaryOrgKey: orgUnit.orgKey, compareOrgKey: null, comparisonMode })
      );
    },
    [dispatch, comparisonMode]
  );

  const _onSwitchTreeType = useCallback(
    (orgType: string, treeType: TreeType) => {
      setSelectedTreeTypes((prev) => ({
        ...prev,
        [orgType]: treeType,
      }));
    },
    [setSelectedTreeTypes]
  );

  const visibleOrgUnits = useMemo(() => OrgUnitUtils.filterVisible<OrgUnit>(orgUnits), [orgUnits]);

  const mapboxBounds = useMemo(() => {
    if (parentOrgUnit?.bbox?.length === 4) {
      return parentOrgUnit.mapboxBoundingBox;
    }
    if (orgUnits.length > 0) {
      return _calculateBoundingBox(orgUnits);
    }
    return FALLBACK_BOUNDS;
  }, [orgUnits, parentOrgUnit]);

  const legendData = useOverviewLegendData(visibleOrgUnits);
  const tiles: Tile[] = useMemo(
    () =>
      visibleOrgUnits.map((ou) => {
        return {
          type: 'Feature',
          geometry: ou.geometry,
          properties: {
            key: ou.orgKey,
            ...ou.properties,
            tooltip: ou.name,
            style: getOrgUnitStyle(
              mapValueMode,
              primaryValueExpression.positiveDirection,
              legendData?.percentageMap?.get(ou.orgKey) || 0,
              ou.orgKey === hoverOrgKey
            ),
          },
        };
      }),
    [visibleOrgUnits, mapValueMode, primaryValueExpression.positiveDirection, legendData.percentageMap, hoverOrgKey]
  );

  return (
    <div
      style={{
        display: 'flex',
        position: 'relative',
        flexGrow: 1,
        flexDirection: 'column',
      }}>
      {orgBreadcrumb && <MapBreadcrumb items={orgBreadcrumb} onClickItem={_onBreadcrumbClick} />}
      {parentOrgUnit && orgBreadcrumb && orgBreadcrumb.length > 0 && availableTreeTypes?.length > 1 && (
        <TreeTypeSwitch
          orgType={parentOrgUnit.orgType}
          treeType={selectedTreeTypes[parentOrgUnit.orgType]}
          treeTypes={availableTreeTypes}
          onSwitchTreeType={_onSwitchTreeType}
        />
      )}
      <MapboxGLMap
        bounds={mapboxBounds}
        tiles={tiles}
        onTileClick={_onOrgUnitClicked}
        onTileMouseEnter={_onOrgUnitHoverChanged}
        onTileMouseLeave={() => _onOrgUnitHoverChanged(null)}
        interactiveTiles={orgUnits.length > 1}
        tilePopups
      />
      {primaryValueExpression && (
        <OverviewMapLegend
          {...legendData}
          orgUnits={orgUnits}
          onHoverOrgUnit={_onOrgUnitHoverChanged}
          onValueModeChanged={_onValueModeChanged}
        />
      )}
    </div>
  );
};

export default OverviewMapContainer;
