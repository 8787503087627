import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';

import { LimitClassTypes } from '@legacy-modules/dashboard/types/AbstractLimit';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import { ConfigKey, getEnvVariable } from '@legacy-modules/common/services/EnvironmentConfigurationService';
import { selectTokenId } from '@redux';

type OrgConfig = { value: LimitClassTypes; orgKey: string; valueExpression: ValueExpression };

/**
 * Custom hook that saves the org unit's config.
 */
export default function useSaveOrgConfig(): UseMutationResult<unknown, unknown, OrgConfig> {
  const queryClient = useQueryClient();
  const tokenId = useSelector(selectTokenId);

  return useMutation<unknown, unknown, OrgConfig>(
    ({ valueExpression, orgKey, value }) => {
      // normalize limits before persisting, see usage of mapLimits in use-target-value-hook
      const limit = value ? value.mapLimits((limit) => limit * valueExpression.valueQuotient) : value;
      const url = new URL(`${getEnvVariable(ConfigKey.HTTP_API_URL)}/orgunitconfigs`);
      // There are no other configTypes so far. Once there are variants, this should be an input parameter.
      url.searchParams.append('configType', 'ValueExpressionGoal');
      url.searchParams.append('orgKey', orgKey);
      url.searchParams.append('configKey', valueExpression?.identifier);

      return fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          token: tokenId,
        },
        body: JSON.stringify(limit),
      });
    },
    {
      async onSuccess(data, variables) {
        await queryClient.invalidateQueries({
          queryKey: ['ValueExpressionGoal', variables.orgKey, variables.valueExpression.identifier],
        });
      },
    }
  );
}
