import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';

import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import ValueExpression, {
  TargetValueType,
} from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

export default new ValueExpression({
  identifier: 'complaints.quote.count_lossrelevant/complaints.quote.base',
  label: 'vSB-Quote (ZSB/VP+)',
  valueFormat: '-#.###.##0,##0%',
  chart: ChartType.bar,
  positiveDirection: PositiveDirection.down,
  definition: getDefinitionByName('vSB-Quote (ZSB/VP+)'),
  category: 'complaints',
  hidden: true,
  valueQuotient: 0.01,
  percent: true,
  targetValueType: TargetValueType.QUOTE,
  decimalPlaces: 3,
  chartRenderOptions: {
    startYAxisOnZero: false,
  },
});
