import ValueExpression, {
  TargetValueType,
} from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';

export default new ValueExpression({
  identifier: 'einwohner_pro_kilometer2',
  label: 'Einwohner pro km²',
  valueFormat: '-#.###.##0,0',
  chart: ChartType.line,
  percent: true,
  targetValueType: TargetValueType.QUOTE,
  decimalPlaces: 2,
  chartRenderOptions: {
    startYAxisOnZero: false,
  },
});
