import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import Styles from './DashboardTrend.module.scss';
import { FloatingComponent } from '../../common/components/FloatingComponent';
import { ComponentHeader } from '../../common/components/ComponentHeader';
import KpiSelector from '../kpiCompare/KpiSelector';
import { useSelector } from 'react-redux';
import { Duration } from '../models/enums/Duration';
import DetailedChart from '../charts/DetailedChart';
import { ChartType } from '../../metrics2/models/enumerations/ChartType';
import DateSelect from '../../common/components/DateSelect';
import { DefaultLimit } from '../types/AbstractLimit';
import { EmptyKpiSelectionInfo } from '../kpiCompare/EmptyKpiSelectionInfo';
import { useValueExpressionContext } from '@contexts/value-expression-context';
import { defaultLimitsMap } from '@utils/limits-utils';
import { selectDashboardOrgKey } from '@redux/dashboard.selectors';
import LademengeIst from '@contexts/value-expression-context/value-expressions/lademenge-ist';
import useTargetValue from '@hooks/use-target-value-hook/use-target-value.hook';

type Props = {
  duration: Partial<Duration>;
  onKpiSelectionChange: (key: string) => void;
  selectedKpiKey: string;
  displayDefaultLimits?: boolean;
  onDurationSelected: (duration: Duration) => void;
  customKpiSelector?: ReactNode;
  fallbackValueExpression?: string;
};

export const DASHBOARD_TREND_UID = 'dashboardTrend';

const DashboardTrend: React.FC<Props> = (props: Props) => {
  const {
    duration,
    selectedKpiKey: selectedKpiKeyProps,
    onKpiSelectionChange,
    displayDefaultLimits = true,
    onDurationSelected,
    customKpiSelector,
    fallbackValueExpression = LademengeIst.identifier,
  } = props;
  const orgKey = useSelector(selectDashboardOrgKey);
  const valueExpressionMap = useValueExpressionContext();

  const [selectedKpiKey, setSelectedKpiKey] = useState<string | null>(null);

  const selectedValueExpression = selectedKpiKey != null ? valueExpressionMap.get(selectedKpiKey) : null;

  const { targetValue } = useTargetValue(orgKey, selectedValueExpression);

  const userOrDefaultGoal = useMemo(() => {
    if (!targetValue && displayDefaultLimits) {
      const defaultLimit = defaultLimitsMap.get(valueExpressionMap.get(selectedKpiKeyProps)) || null;
      if (!defaultLimit) {
        return null;
      }
      return new DefaultLimit(defaultLimit.positive, defaultLimit.negative);
    }
    return targetValue;
  }, [displayDefaultLimits, targetValue, selectedKpiKeyProps]);

  useEffect(() => {
    const safeSetExistingValue = (identifier: string) => {
      const valueExpression = valueExpressionMap.get(identifier);
      if (valueExpression == null) {
        if (fallbackValueExpression) {
          setSelectedKpiKey(fallbackValueExpression);
        }
      } else {
        setSelectedKpiKey(identifier);
      }
    };

    if (selectedKpiKeyProps) {
      return safeSetExistingValue(selectedKpiKeyProps);
    }
  }, [fallbackValueExpression, selectedKpiKeyProps, valueExpressionMap]);

  return (
    <div className={Styles.DashboardTrend}>
      <FloatingComponent>
        <ComponentHeader headline={'Trend'}>
          <div className={Styles.SelectionWrapper}>
            {customKpiSelector ?? (
              <KpiSelector
                showDot={false}
                selectedValueExpressionKeys={[selectedKpiKey]}
                onSelect={onKpiSelectionChange}
                categoriesSelectable
              />
            )}
            <DateSelect selectedDuration={duration as Duration} onDurationSelected={onDurationSelected} />
          </div>
        </ComponentHeader>
        <div>
          <React.Fragment key={`chart`}>
            {selectedKpiKey ? (
              <DetailedChart
                orgKey={orgKey}
                threshold={2}
                key={selectedKpiKey}
                valueExpressionKey={selectedKpiKey}
                defaultChartType={ChartType.line}
                showChartModeSelector={selectedValueExpression.hasChildren()}
                uID={DASHBOARD_TREND_UID}
                duration={duration as Duration}
                style={{ boxShadow: 'none', margin: 0 }}
                enableKpiAvg
                goal={userOrDefaultGoal}
                renderChildren={true}
              />
            ) : (
              <EmptyKpiSelectionInfo arrowPosition={[144, 20]} />
            )}
          </React.Fragment>
        </div>
      </FloatingComponent>
    </div>
  );
};

export default DashboardTrend;
