import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';

import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

export default new ValueExpression({
  identifier: 'zustellungen_bulky',
  label: 'Bulky zugestellt',
  positiveDirection: PositiveDirection.custom,
  definition: getDefinitionByName('Bulky zugestellt'),
});
