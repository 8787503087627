import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import ValueExpression, {
  TargetValueType,
} from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';

export default new ValueExpression({
  identifier: 'Nutzungsquote Foto-Unterschriften',
  label: 'Nutzungsquote Foto-Unterschriften',
  definition: getDefinitionByName('Nutzungsquote Foto-Unterschriften'),
  positiveDirection: PositiveDirection.up,
  percent: true,
  targetValueType: TargetValueType.QUOTE,
  decimalPlaces: 2,
});
