import React from 'react';
import { calculateYesterday, Durations } from '../models/enums/Duration';
import { useDispatch, useSelector } from 'react-redux';
import Styles from './AnomalyPage.module.scss';
import { getValueExpressionNodeByKey } from '../utils/KpiTree';
import DashboardTrend from './DashboardTrend';
import KpiSelector from '../kpiCompare/KpiSelector';
import { IAnomalyPageOptions } from '../interfaces/IAnomalyPageOptions';
import DataTable from '@data-table';
import { useValueExpressionContext } from '@contexts/value-expression-context';
import { selectLocalComponentOptions } from '@redux/dashboard.selectors';
import { dashboardSlice } from '@redux/dashboard.slice';
import HinweisAtgWzfAbholung from '@contexts/value-expression-context/value-expressions/hinweis-atg-wzf-abholung';
import HinweisAusserhalbWzf from '@contexts/value-expression-context/value-expressions/hinweis-ausserhalb-wzf';
import HinweisTagversetzteBearbeitung from '@contexts/value-expression-context/value-expressions/hinweis-tagversetzte-bearbeitung';
import HinweisRuecklaufAmWunschtag from '@contexts/value-expression-context/value-expressions/hinweis-ruecklauf-am-wunschtag';
import HinweisAusserhalbZustellzeit from '@contexts/value-expression-context/value-expressions/hinweis-ausserhalb-zustellzeit';
import HinweisTeillieferungMehrcollig from '@contexts/value-expression-context/value-expressions/hinweis-teillieferung-mehrcollig';
import HinweisRuecklaufAmTourende from '@contexts/value-expression-context/value-expressions/hinweis-ruecklauf-am-tourende';

const defaultSorting = [{ id: 'tourIdentifier', desc: true }];

type Props = {};
const uID = 'anomalyPage';

const AnomalyPage: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const localComponentOptions = useSelector(selectLocalComponentOptions(uID, 'default'));
  const selectedKpiKey = localComponentOptions?.selectedKpiKey;
  const selectedChartDuration = localComponentOptions?.selectedChartDuration || Durations.last_7;
  const valueExpressionMap = useValueExpressionContext();
  const updateOptions = (options: Partial<IAnomalyPageOptions>) => {
    dispatch(
      dashboardSlice.actions.setLocalComponentOptions({
        componentId: uID,
        optionKey: 'default',
        value: options,
      })
    );
  };

  const veKeys = (() => {
    return [
      HinweisAusserhalbWzf.identifier,
      HinweisAtgWzfAbholung.identifier,
      HinweisTagversetzteBearbeitung.identifier,
      HinweisRuecklaufAmWunschtag.identifier,
      HinweisAusserhalbZustellzeit.identifier,
      HinweisTeillieferungMehrcollig.identifier,
      HinweisRuecklaufAmTourende.identifier,
    ];
  })();

  const tree = veKeys.map((k) => getValueExpressionNodeByKey(k, valueExpressionMap));

  return (
    <>
      <DataTable.HinweiseDataTable componentId='hinweise.table' defaultSorting={defaultSorting} />
      <DashboardTrend
        onKpiSelectionChange={(key) => updateOptions({ selectedKpiKey: key })}
        onDurationSelected={(dur) => updateOptions({ selectedChartDuration: dur })}
        duration={calculateYesterday(selectedChartDuration)}
        fallbackValueExpression={null}
        selectedKpiKey={selectedKpiKey}
        customKpiSelector={
          <KpiSelector
            tree={tree}
            showDot={false}
            selectedValueExpressionKeys={[selectedKpiKey]}
            onSelect={(kpiKey) => {
              updateOptions({ selectedKpiKey: kpiKey });
            }}
            categoriesSelectable
          />
        }
      />
    </>
  );
};

export { AnomalyPage };
