import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';

import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

const label = 'Zustellvorgänge nach Sendungsanzahl';
const key = 'finished_deliveries.by_item_count';

const getConfig = (category) => {
  return {
    identifier: `${key}.${category}`,
    label: category,
    longLabel: `${label} (${category})`,
    chart: ChartType.bar,
    positiveDirection: PositiveDirection.custom,
    category: 'deliveries',
    description:
      '<p>Anzahl aller abgeschlossenen Zustellvorgänge nach Anzahl bearbeiteter Sendungen pro Zustellvorgang</p>\n',
  };
};

const categories = ['1', '2', '3', '4', '5-10', '11-20', '21-100', '>100'];
const children = categories.map((category) => new ValueExpression(getConfig(category)));

export default new ValueExpression({
  ...getConfig('sum'),
  label: label,
  longLabel: label,
  chart: ChartType.line,
  children: children,
});
