import React, { useCallback, useMemo, useState } from 'react';
import { Button, ButtonGroup, Input } from 'reactstrap';
import classNames from 'classnames';

import { ColumnFilterContentProps, ValueExpressionDataTableColumnMeta } from '@data-table/data-table.types';
import { FilterControls } from '../../filter-controls';

import sharedStyle from '../value-expression-filter-content.module.scss';

const TimeBasedValueExpressionFilterContent = <T, V>({
  column,
  onCancel,
  onConfirm,
}: ColumnFilterContentProps<T, V>) => {
  const filterValue = column.getFilterValue();

  const [value, setValue] = useState(() => {
    const _filterValue = filterValue?.toString()?.replace(/>|</g, '');
    const filterNumberValue = Number(_filterValue);
    if (isNaN(filterNumberValue)) {
      return '';
    }
    const hours = filterNumberValue - (filterNumberValue % 1);
    const minutes = Math.round((filterNumberValue % 1) * 60);
    if (minutes === 0) {
      return `${hours}`;
    }
    return `${hours}:${minutes}`;
  });

  const meta = column?.columnDef?.meta as ValueExpressionDataTableColumnMeta;

  const [toggleState, setToggleState] = useState<'greater' | 'less'>(
    filterValue?.toString()?.startsWith('<') ? 'less' : 'greater'
  );

  const onConfirmCallback = useCallback(() => {
    const splitValue = value.split(':');
    const hoursValue = splitValue[0];
    const minutesValue = splitValue[1];
    const hours = isNaN(Number(hoursValue)) ? 0 : Number(hoursValue);
    // 0:5 -> 0:50
    // 0:50 -> 0:50
    // 0:05 -> 0:05
    const minutesNumberValue = Number(minutesValue?.concat('00')?.slice(0, 2));
    // 60min - 1
    // 59min = 0.98
    const decimalMinutes = isNaN(minutesNumberValue) ? 0 : minutesNumberValue / 60;
    const newFilterValue = hours + decimalMinutes;
    if (newFilterValue > 0) {
      onConfirm(`${toggleState === 'less' ? '<' : '>'}${newFilterValue}`);
    } else {
      onConfirm(undefined);
    }
  }, [onConfirm, toggleState, value]);

  const title = useMemo(() => {
    return `${meta?.valueExpression?.getLabel() ?? 'Zeit'} filtern`;
  }, [meta?.valueExpression]);

  const onMaskedValueChange = useCallback((e) => {
    setValue(e.target.value);
  }, []);

  const onFilterToggleChange = useCallback(
    (toggleValue) => () => {
      setToggleState(toggleValue);
    },
    []
  );

  const isGreaterToggleState = toggleState === 'greater';
  const isValid = /^\d{0,7}(:[0-5]\d?)?$/.test(value);

  return (
    <div className={classNames(sharedStyle.filterContent)}>
      <FilterControls
        className={sharedStyle.valueExpressionFilterContent}
        inputValid={isValid}
        cancelCallback={onCancel}
        confirmCallback={onConfirmCallback}>
        <div className={sharedStyle.filterContentHeadline}>{title}</div>
        <div className={sharedStyle.numberFilterContentContainer}>
          <div className={sharedStyle.valueExpressionFilterInput}>
            Nur Werte
            <ButtonGroup size='sm' className={sharedStyle.valueExpressionFilterButtonGroup}>
              <Button
                color={isGreaterToggleState ? 'primary' : 'secondary'}
                outline={!isGreaterToggleState}
                size='sm'
                onClick={onFilterToggleChange('greater')}>
                größer
              </Button>
              <Button
                color={!isGreaterToggleState ? 'primary' : 'secondary'}
                outline={isGreaterToggleState}
                size='sm'
                onClick={onFilterToggleChange('less')}>
                kleiner
              </Button>
            </ButtonGroup>
            <Input value={value} onChange={onMaskedValueChange} placeholder='h:min' />
          </div>
        </div>
      </FilterControls>
    </div>
  );
};

export default TimeBasedValueExpressionFilterContent;
