import ValueExpression, {
  TargetValueType,
} from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';

export default new ValueExpression({
  identifier: 'abholungen_props_avg',
  label: 'Ø ATG Abholungen',
  valueFormat: '-#.###.##0,0',
  positiveDirection: PositiveDirection.custom,
  targetValueType: TargetValueType.QUOTE,
  decimalPlaces: 2,
});
