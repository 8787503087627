import { DateRangeGrouping } from '../enumerations/DateRangeGrouping';
import moment from 'moment-timezone';
import { CacheKeyInterface } from '../../../datamanager/models/entities/CacheKeyInterface';
import { WeekdaysFilter, defaultWeekdaysFilter } from '@legacy-modules/dashboard/models/enums/Duration';

export default class MetricsEntityKey implements CacheKeyInterface {
  orgKey: string;
  type: string;
  dateFrom: moment.Moment;
  dateUntil: moment.Moment;
  valueKey: string | null | undefined;
  grouping: DateRangeGrouping;
  weekdayFilter: WeekdaysFilter;

  constructor(
    type: string,
    orgKey: string,
    dateFrom: moment.Moment,
    dateUntil: moment.Moment,
    grouping: DateRangeGrouping = DateRangeGrouping.day,
    weekDayFilter: WeekdaysFilter = defaultWeekdaysFilter
  ) {
    this.type = type;
    this.orgKey = orgKey;
    this.dateFrom = moment(dateFrom);
    this.dateUntil = moment(dateUntil);
    this.grouping = grouping;
    this.weekdayFilter = weekDayFilter;
  }

  get identifier() {
    return `${this.type}:${this.orgKey}:${this.dateIdentifier}:${''}${Object.keys(
      this.weekdayFilter
    )
      .filter((k) => this.weekdayFilter[k])
      .join(',')}`;
  }

  get dateIdentifier() {
    return (
      this.dateFrom.format('YYYY-MM-DD') +
      '_' +
      (this.grouping === DateRangeGrouping.none ? this.dateUntil.format('YYYY-MM-DD') : this.grouping)
    );
  }
}
