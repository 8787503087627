import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';

import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

export default new ValueExpression({
  identifier: 'tour_loaded.deliveryitems.customer.authorization.count',
  label: 'Sendungen mit Dauervollmacht',
  chart: ChartType.bar,
  positiveDirection: PositiveDirection.custom,
  category: 'delivery-items',
  description:
    '<p>Anzahl aller auf der geladenen Tour geplanten Empfänger mit einer hinterlegten Dauervollmacht gezählt ' +
    'nach dem ersten abgeschlossenen Zustellvorgang</p>\n',
});
