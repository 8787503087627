import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import ValueExpression, {
  TargetValueType,
} from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';

export default new ValueExpression({
  identifier: 'e_plus_0_quote',
  label: 'E+0 Standard',
  definition: getDefinitionByName('E+0 Standard'),
  positiveDirection: PositiveDirection.up,
  valueQuotient: 0.01,
  valueFormat: '-#.###.##0,#0%',
  percent: true,
  targetValueType: TargetValueType.QUOTE,
  decimalPlaces: 2,
  chartRenderOptions: {
    startYAxisOnZero: false,
  },
});
