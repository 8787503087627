import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';

import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';

const label = 'Zustellungen nach Art';
const key = 'lma-shipmentbased.ZU.all.count.by_delivery_type';

const getConfig = (category) => {
  return {
    identifier: `${key}.${category.key}`,
    label: `${category.label}`,
    longLabel: `${label} (${category.label})`,
    positiveDirection: PositiveDirection.custom,
    chart: ChartType.bar,
    description: '<p>Anzahl der erfolgreich bearbeiteten Sendungen nach Zustellart</p>\n',
    category: 'delivery-items',
    hidden: true,
  };
};

const deliveryTypes = {
  props: 'ProPs',
  paketShop: 'PaketShop',
  person: 'unbekannt',
  'person:atDoor': 'In Wohnung',
  'person:intendedReceiver': 'Originalempfänger',
  'person:nextDoor': 'Nachbar',
  'person:reception': 'Rezeption',
  'person:staff': 'Mitarbeiter',
  'putDownOutside:Bag': 'Zustelltüte',
  'putDownOutside:bnkAuthorization': 'BNK-Vollmacht',
  'putDownOutside:Mailbox': 'Briefkasten',
  'putDownOutside:oneTimeAuthorization': 'Einmalvollmacht',
  'putDownOutside:permanentAuthorization': 'Dauervollmacht',
  'putDownOutside:unknownAuthorization': 'Vollmacht',
};

const children = Object.entries(deliveryTypes).map((type) => {
  const [key, label] = type;
  return new ValueExpression(getConfig({ key: key, label: label }));
});

const sumExpression = new ValueExpression({
  ...getConfig({ key: 'sum', label: '' }),
  label: label,
  longLabel: label,
  hidden: false,
  children: children,
});

export default sumExpression;
