import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';

import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

export default new ValueExpression({
  identifier: 'stock.remaining',
  label: 'Liegenbleiber',
  chart: ChartType.bar,
  positiveDirection: PositiveDirection.down,
  category: 'stock',
  description: '<p>Description for stock.remaining - tbd</p>\n',
  definition: getDefinitionByName('Liegenbleiber'),
});
