import { undeliverableReasonsShipmentBased } from '@legacy-modules/metrics2/models/entities/MetricType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';

import { UndeliverableReasons } from '@legacy-modules/metrics2/models/enumerations/UndeliverableReason';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

const label = 'Rückläufer nach Grund';
const key = 'lma-shipmentbased.RL.all.count.by_undeliverable_reason';

const getConfig = (category) => {
  return {
    identifier: `${key}.${category.key}`,
    label: category.label,
    shortLabel: category.shortLabel,
    category: 'delivery-items',
    positiveDirection: PositiveDirection.down,
    description:
      // eslint-disable-next-line max-len
      '<p>Anzahl der erfolgreich bearbeiteten Sendungen, denen ein Rücklaufgrund zugewiesen wurde, nach Grund des Rücklaufs</p>\n',
    color: category.color,
  };
};

const children = undeliverableReasonsShipmentBased.map((reason) => {
  const label = UndeliverableReasons[reason].label;
  const shortLabel = UndeliverableReasons[reason].shortLabel;
  const color = UndeliverableReasons[reason].color;
  return new ValueExpression(getConfig({ key: reason, label: label, shortLabel: shortLabel, color: color }));
});

export default new ValueExpression({
  ...getConfig({ key: 'sum', label: '' }),
  label: label,
  shortLabel: label,
  children: children,
});
