import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Styles from './ComplaintsChart.module.scss';
import { Duration, Durations } from '../../../dashboard/models/enums/Duration';
import DetailedChart from '../../../dashboard/charts/DetailedChart';
import { Row, Col, ButtonGroup, Button } from 'reactstrap';
import ComplaintsValueRows from './components/ComplaintsValueRows';
import FlopTourComplaints from './components/FlopTourComplaints';
import { ZSBUtils } from '../../../utils/tours/ZSBUtils';
import DateSelect, {
  DateSelectIncludingTodayConfig,
  DateSelectionDefaultConfig,
} from '../../../common/components/DateSelect';
import { FloatingComponent } from '../../../common/components/FloatingComponent';
import { ComponentHeader } from '../../../common/components/ComponentHeader';
import { useAuthContext } from '@contexts/auth-context';
import { selectDashboardOrgKey, selectLocalComponentOptions } from '@redux/dashboard.selectors';
import { dashboardSlice } from '@redux/dashboard.slice';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ServicebeanstandungenMeldungVerlustrelevantNachGrund from '@contexts/value-expression-context/value-expressions/servicebeanstandungen-verlustrelevant-grund';
import ServicebeanstandungenMeldung from '@contexts/value-expression-context/value-expressions/servicebeanstandungen-meldung';
import ServicebeanstandungenGrund from '@contexts/value-expression-context/value-expressions/servicebeanstandungen-grund';

export type ComplaintsChartOptions = {};

type Props = {
  uID: string | null | undefined;
  title: string;
  hideTodayInSelection?: boolean;
};

function ComplaintsChart({ title, uID, hideTodayInSelection }: Props) {
  const dispatch = useDispatch();
  const orgKey = useSelector(selectDashboardOrgKey);
  const options = useSelector(selectLocalComponentOptions(uID, 'default'));
  const duration = options?.duration || Durations.last_7;

  const sbVerlustrelevant = ServicebeanstandungenMeldungVerlustrelevantNachGrund;
  const sbAll = ServicebeanstandungenMeldung;
  const valueExpressionIdentifier = options?.valueExpressionKey || sbAll.identifier;

  const authService = useAuthContext();
  const componentOptionsToState = useCallback(
    (update = {}) => {
      dispatch(
        dashboardSlice.actions.setLocalComponentOptions({
          componentId: uID,
          optionKey: 'default',
          value: update,
        })
      );
    },
    [dispatch, uID]
  );

  const onDurationSelected = useCallback(
    (duration: Duration) => {
      componentOptionsToState({ duration });
    },
    [componentOptionsToState]
  );

  const showChart = authService.can('complaints');
  const complaintsHeadline = useMemo(() => {
    if (!valueExpressionIdentifier) {
      return 'Beanstandungsgründe';
    } else if (valueExpressionIdentifier === sbAll.identifier) {
      return 'Häufigste Beanstandungsgründe';
    } else if (valueExpressionIdentifier === sbVerlustrelevant.identifier) {
      return 'Verlustrelevante Beanstandungsgründe';
    } else {
      return 'Beanstandungsgründe';
    }
  }, [valueExpressionIdentifier]);

  if (!showChart) {
    return null;
  }

  const onlyValuable = valueExpressionIdentifier === sbVerlustrelevant.identifier;

  return (
    <FloatingComponent>
      <ComponentHeader
        headline={title}
        right={
          <DateSelect
            selectedDuration={duration}
            onDurationSelected={onDurationSelected}
            config={hideTodayInSelection ? DateSelectionDefaultConfig : DateSelectIncludingTodayConfig}
          />
        }
      />
      <div className={Styles.ComplaintsChart}>
        <Row>
          <Col md='8'>
            <DetailedChart
              orgKey={orgKey}
              uID={uID}
              threshold={2}
              valueExpressionKey={valueExpressionIdentifier}
              defaultChartType={ChartType.line}
              enableKpiAvg={true}
              duration={duration}
              style={{ boxShadow: 'none', margin: 0 }}
              renderChildren={false}
            />
          </Col>
          <Col md='4'>
            <div className={Styles.Toggle}>
              <ButtonGroup className={Styles.ButtonGroup}>
                <Button
                  outline={valueExpressionIdentifier !== sbAll.identifier}
                  onClick={() => componentOptionsToState({ valueExpressionKey: sbAll.identifier })}
                  color='primary'>
                  Alle
                </Button>
                <Button
                  outline={valueExpressionIdentifier !== sbVerlustrelevant.identifier}
                  onClick={() => componentOptionsToState({ valueExpressionKey: sbVerlustrelevant.identifier })}
                  color='primary'>
                  Verlustrelevant
                </Button>
              </ButtonGroup>
              <div className={Styles.TotalValue}>
                <ComplaintsValueRows
                  valueExpression={onlyValuable ? sbVerlustrelevant : ServicebeanstandungenGrund}
                  duration={duration}
                  renderSingle
                />
              </div>
            </div>
            <div className={Styles.TopReasons}>
              <ComplaintsValueRows
                headline={complaintsHeadline}
                valueExpression={onlyValuable ? sbVerlustrelevant : ServicebeanstandungenGrund}
                duration={duration}
              />
            </div>
            {ZSBUtils.isZSB(orgKey) && (
              <div className={Styles.TopReasons}>
                <FlopTourComplaints orgKey={orgKey} onlyValuable={onlyValuable} duration={duration} />
              </div>
            )}
          </Col>
        </Row>
      </div>
    </FloatingComponent>
  );
}

export default ComplaintsChart;
