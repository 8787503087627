import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';

import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

export default new ValueExpression({
  identifier: 'complaints.reported.count_total',
  label: 'SB (Meldung)',
  chart: ChartType.bar,
  positiveDirection: 'down',
  category: 'complaints',
  description: '<p>Kundenkontakte im Customer Service, bei denen es sich um Servicebeanstandungen handelt</p>\n',
  definition: getDefinitionByName('SB (Meldung)'),
});
