import ValueExpression, {
  TargetValueType,
} from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';

export default new ValueExpression({
  identifier: 'tourfahrtzeit_avg',
  label: 'Ø Tourfahrtzeit',
  positiveDirection: PositiveDirection.down,
  valueFormat: '-#.###.##0,0 h',
  valueQuotient: 3600,
  definition: getDefinitionByName('Tourfahrtzeit'),
  targetValueType: TargetValueType.QUOTE,
  decimalPlaces: 2,
});
