/* eslint-disable max-len */
import React, { ReactElement, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MapboxMap } from '../../common/components/mapbox-map';
import mapboxgl, { Anchor, Map as MapGLMap, PointLike } from 'mapbox-gl';
import Color from 'color';
import { MdPlace } from 'react-icons/md';
import moment from 'moment';

import GeoCodedAddress from '@legacy-modules/geo/models/GeoCodedAddress';
import { getOrgKeyByTourIdentifier } from '@legacy-modules/tour/helpers/GetOrgKeyByTourIdentifier';

import { useAuthContext } from '@contexts/auth-context';
import { useMultiOrgTree } from '@hooks/use-multi-org-tree-hook';
import { useTourDetailsContext } from '@other-components/tour-details-context';
import { tourDetailsSlice } from '@redux/tour-details.slice';
import { dashboardSlice } from '@redux/dashboard.slice';
import { appSlice } from '@redux/app.slice';
import {
  selectTourDetailsContractorKey,
  selectTourDetailsSelectedStop,
  selectTourDetailsTourIdentifier,
} from '@redux/tour-details.selectors';

import FinishedDelivery from '../../../modules/tour/models/entities/FinishedDelivery';
import MarkerDetails from '../../../modules/tour/models/entities/MarkerDetails';
import DeliveryModes from '../../../modules/tour/models/enumerations/DeliveryMode';
import { MarkerColor, MarkerColors, MarkerColorsType } from '../../../modules/tour/models/enumerations/MarkerColors';
import { MarkerType } from '../../../modules/tour/models/enumerations/MarkerType';
import { MapboxMarker } from '../../common/components/mapbox-map/mapbox-marker';
import { MapboxPopup } from '../../common/components/mapbox-map/mapbox-popup';
import { MapboxSource } from '../../common/components/mapbox-map/mapbox-source';
import { MapboxLayer } from '../../common/components/mapbox-map/mapbox-layer';
import { MapStyleContextProvider } from '../../common/components/mapbox-map/map-style-context';
import { MapboxButtonGroup } from '../../common/components/mapbox-map/mapbox-button-group';
import OrgUnit from '../../../modules/metrics2/models/entities/organization/OrgUnit';
import { StopTypeType } from '../../../modules/tour/models/state/TourDetailsState';
import CustomerTrackingData from '../../../modules/tour/models/types/CustomerTrackingData';
import TourPlanStop from '../../../modules/tour/models/entities/TourPlanStop';
import { LoadingIndicator } from '../loading-indicator';
import { FallbackComponent } from '../fallback-component';
import { WellKnownPermission } from '../../../modules/auth/constants/WellKnownPermission';

type CustomerWithStops = {
  customer: CustomerTrackingData;
  stop: Partial<TourPlanStop> & { stopNumber: number };
};

const defaultFitBoundsOptions = {
  padding: 100,
  linear: true,
};

type PopupOffset = Partial<{
  [anchor in Anchor]: PointLike;
}>;

const defaultPopupOffset: PopupOffset = { top: [0, 10], bottom: [0, -10] };
const activeMarkerPopupOffset: PopupOffset = { top: [0, 10], bottom: [0, -45] };
const LOCATION_ERROR_MSG = `Die Daten zu dieser Tour dürfen aufgrund fehlender Berechtigungen für diesen Standort nicht angezeigt werden.

Bitte wähle einen anderen Standort.`;
const INTERNAL_PROJECTION_ERROR = `Die Daten zu dieser Tour dürfen aufgrund des Schutzes interner Mitarbeiter nicht angezeigt werden

"Bitte wähle eine andere Tour."`;

type MarkerMode = 'all' | 'address' | 'gps';
type RouteMode = 'all' | 'real' | 'planned';

export function TourDetailsMap(): ReactElement {
  const dispatch = useDispatch();
  const authService = useAuthContext();

  const [markerMode, setMarkerMode] = useState<MarkerMode>('all');
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ lng: 0, lat: 0 });
  const [tooltipContent, setTooltipContent] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const mapRef = useRef<MapGLMap>();
  const [initialBounds, setInitialBounds] = useState<mapboxgl.LngLatBounds>();
  // force bounds update after first map render
  const [forceBoundsUpdate, setForceBoundsUpdate] = useState(false);
  const [routeMode, setRouteMode] = useState<RouteMode>('real');
  const [tooltipOffset, setTooltipOffset] = useState<PopupOffset>(defaultPopupOffset);

  const tourIdentifier = useSelector(selectTourDetailsTourIdentifier);
  const contractorKey = useSelector(selectTourDetailsContractorKey);
  const selectedStop = useSelector(selectTourDetailsSelectedStop);

  const {
    highlightedMarkerState: [highlightedMarkerRef, setMarkerRef],
    filteredFinishedDeliveries,
    tourDetailsLoading,
    tourDetails,
  } = useTourDetailsContext();

  const isAllowedToSeeTourDetails = authService.can(WellKnownPermission.TourSeeDetails);
  const isAllowedToSeeHermesTours = authService.can(WellKnownPermission.TourEmploymentTypesAll);
  const isHermesDriver = tourDetails?.driver?.employmentType === 'internal';

  const hasPlannedStops = tourDetails?.plannedStops;

  useEffect(() => {
    if (!tourDetailsLoading && !hasPlannedStops) {
      setRouteMode('real');
    }
  }, [hasPlannedStops, tourDetailsLoading]);

  const orgKeys = useMemo(() => {
    return [getOrgKeyByTourIdentifier(tourIdentifier, contractorKey)];
  }, [contractorKey, tourIdentifier]);
  const { data: orgDataMap } = useMultiOrgTree({
    orgKeys,
    parts: ['breadcrumb', 'children', 'treeTypes'],
    enabled: orgKeys?.length > 0,
  });

  const orgUnit = useMemo(() => {
    if (!orgDataMap?.has(orgKeys[0])) {
      return null;
    }
    return new OrgUnit(orgDataMap.get(orgKeys[0]));
  }, [orgDataMap, orgKeys]);

  useEffect(() => {
    // force bounds update only when tour identifier changes
    if (tourIdentifier && mapRef.current && !tourDetailsLoading) {
      setForceBoundsUpdate(true);
    }
  }, [tourDetailsLoading, tourIdentifier]);

  useEffect(() => {
    // Reset active / selected marker when route mode changes
    if (routeMode) {
      setMarkerRef(null);
    }
  }, [routeMode, setMarkerRef]);

  const {
    plannedMarkers,
    allPlannedMarkers,
  }: {
    plannedMarkers: MarkerDetails[];
    allPlannedMarkers: MarkerDetails[];
  } = useMemo(() => {
    if (!tourDetails?.plannedStops || routeMode === 'real') {
      return { allPlannedMarkers: [], plannedMarkers: [] };
    }
    // um die stop location zu finden
    // nehmen wir pro stop eine shipment id heraus
    // und finden die dazugehörigen delivery items heraus
    const deliveryItems = tourDetails.plannedStops
      // da uns an der Stelle nur eine Sendung pro Stop interessiert (gleiche Location)
      // ist es egal ob wir eine Sendung oder einen Abholauftrag nehmen
      ?.map((stop) => stop?.shipments?.[0] || stop?.collectionOrders?.[0])
      ?.map((deliveryItemId) => tourDetails.deliveryItems?.get(deliveryItemId))
      ?.filter((deliveryItem) => !!deliveryItem);

    const shipmentsMap = new Map(
      tourDetails?.plannedStops?.flatMap((stop, _stopNumber) =>
        [...(stop?.shipments || []), ...(stop?.collectionOrders || [])]?.map((shipment) => [
          shipment,
          { ...stop, stopNumber: _stopNumber + 1 },
        ])
      )
    );

    // anhand von customer Referenzen finden wir die
    // dazugehörigen customers heraus
    const customersWithAssociatedStops: CustomerWithStops[] = deliveryItems
      ?.map((deliveryItem) => {
        const associatedStop = shipmentsMap.get(deliveryItem?.id);
        const associatedCustomer = tourDetails?.customers?.get(deliveryItem?.customerRef);
        return !!associatedCustomer && !!associatedStop
          ? {
              customer: associatedCustomer,
              stop: associatedStop,
            }
          : null;
      })
      ?.filter((customer) => !!customer);

    // wir entnehmen die customer infos und location
    // um die geplanten markers zu erstellen
    const allPlannedMarkers = customersWithAssociatedStops?.map(({ customer, stop }) => {
      const TooltipContent = () => {
        const hasCustomerAddress = customer?.street && customer?.streetNr && customer?.city && customer?.zipcode;
        const street = hasCustomerAddress && joinMultipartString(customer?.street, customer?.streetNr);
        const addressSupplement = customer.addressSupplement;
        const city = hasCustomerAddress && joinMultipartString(customer?.city, customer?.zipcode);

        return (
          <div style={{ padding: '5px' }}>
            <strong>Geplanter Stopp</strong>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                paddingTop: '5px',
                textTransform: 'capitalize',
              }}>
              {hasCustomerAddress ? (
                <>
                  <div>{street}</div>
                  {addressSupplement && <div>{addressSupplement}</div>}
                  <div>{city}</div>
                </>
              ) : (
                <span>Adresse unbekannt</span>
              )}
            </div>
          </div>
        );
      };

      const location: GeoCodedAddress = new GeoCodedAddress({ lng: stop.lon, lat: stop.lat });

      return new MarkerDetails({
        markerType: MarkerType.planned,
        markerRef: customer?.customerRef,
        location,
        stopNumber: stop.stopNumber,
        time: undefined,
        content: stop.stopNumber.toString(),
        color: MarkerColors.planned.color,
        opacity: undefined,
        tooltipCallback: TooltipContent,
      });
    });
    return {
      allPlannedMarkers,
      plannedMarkers: allPlannedMarkers.filter((marker) => marker?.location?.isValid()),
    };
  }, [tourDetails, routeMode]);

  const {
    finishedMarkers,
    allFinishedMarkers,
  }: {
    finishedMarkers: MarkerDetails[];
    allFinishedMarkers: MarkerDetails[];
  } = useMemo(() => {
    if (!filteredFinishedDeliveries || routeMode === 'planned') {
      return {
        finishedMarkers: [],
        allFinishedMarkers: [],
      };
    }

    const filteredByMarkerType = filteredFinishedDeliveries.filter((finishedDelivery) => {
      switch (markerMode) {
        case 'all':
        case 'address':
          return true;
        case 'gps':
          return finishedDelivery?.location?.isGpsLocation();
      }
    });

    const getFdMarkers = (
      finishedDeliveries: FinishedDelivery[]
    ): {
      finishedMarkers: MarkerDetails[];
      allFinishedMarkers: MarkerDetails[];
    } => {
      if (!finishedDeliveries) {
        return {
          finishedMarkers: [],
          allFinishedMarkers: [],
        };
      }

      const allFinishedMarkers = finishedDeliveries.map((finishedDelivery: FinishedDelivery) => {
        const customers = Object.keys(finishedDelivery.customerDeliveries ?? {})
          .map((customerId) => tourDetails?.customers.get(customerId) ?? undefined)
          .filter((customer) => !!customer);
        const location =
          markerMode === 'address'
            ? customers[0]?.location?.isValid()
              ? customers[0].location
              : null
            : finishedDelivery?.location && finishedDelivery.location.isValid()
            ? finishedDelivery.location
            : null;

        const kindOfMarker =
          markerMode === 'address' ? 'hadv' : finishedDelivery?.location?.provider?.toLowerCase() ?? '';
        const colorConfig: MarkerColorsType = MarkerColor.byKindAndModeWithDefault(
          kindOfMarker,
          finishedDelivery.deliveryMode
        );

        const tooltipCallback: React.FC = () => {
          const hasCustomerAddress =
            customers[0]?.street && customers[0]?.streetNr && customers[0]?.city && customers[0]?.zipcode;
          const street = hasCustomerAddress && joinMultipartString(customers[0]?.street, customers[0]?.streetNr);
          const addressSupplement = customers[0].addressSupplement;
          const city = hasCustomerAddress && joinMultipartString(customers[0]?.city, customers[0]?.zipcode);
          return (
            <div style={{ padding: '5px' }}>
              <strong>Abgeschlossener Stopp</strong>
              <div style={{ fontStyle: 'italic' }}>
                {finishedDelivery.deliveryMode === DeliveryModes.undeliverable ? 'Rücklauf um ' : 'Zugestellt um '}
                {finishedDelivery.finishedAt?.format('HH:mm')} Uhr
              </div>
              <div style={{ paddingTop: '5px', textTransform: 'capitalize' }}>
                {hasCustomerAddress ? (
                  <>
                    <div>{street}</div>
                    {addressSupplement && <div>{addressSupplement.toLowerCase()}</div>}
                    <div>{city}</div>
                  </>
                ) : (
                  <span>Adresse unbekannt</span>
                )}
              </div>
            </div>
          );
        };
        return new MarkerDetails({
          markerType: MarkerType.finishedDelivery,
          markerRef: finishedDelivery.uuid,
          location: location,
          time: moment(finishedDelivery.finishedAt),
          content: finishedDelivery.displayableStopNumber.toString(),
          stopNumber: finishedDelivery.displayableStopNumber,
          color: new Color(colorConfig?.color),
          opacity: 1,
          tooltipCallback,
        });
      });
      return {
        finishedMarkers: allFinishedMarkers.filter((marker) => marker?.location?.isValid()),
        allFinishedMarkers,
      };
    };

    return getFdMarkers(filteredByMarkerType);
  }, [filteredFinishedDeliveries, routeMode, markerMode, tourDetails?.customers]);

  const {
    unfinishedMarkers,
    allUnfinishedMarkers,
  }: {
    unfinishedMarkers: MarkerDetails[];
    allUnfinishedMarkers: MarkerDetails[];
  } = useMemo(() => {
    if (!tourDetails || routeMode === 'planned') {
      return {
        unfinishedMarkers: [],
        allUnfinishedMarkers: [],
      };
    }
    let lastFinishedStopNr = 0;
    const finishedCustomerIds = new Set(
      Object.values(tourDetails?.finishedDeliveries ?? {})
        .map((finishedDelivery) => {
          if (finishedDelivery?.displayableStopNumber > lastFinishedStopNr) {
            lastFinishedStopNr = finishedDelivery.displayableStopNumber;
          }
          return Object.keys(finishedDelivery.customerDeliveries ?? {});
        })
        .flat()
    );
    const unfinishedCustomers = Array.from(tourDetails?.customers?.values() || [])?.filter((customer) => {
      return !finishedCustomerIds.has(customer.customerRef);
    });

    const allUnfinishedMarkers = unfinishedCustomers?.map((customer, stopIndex) => {
      const TooltipContent = () => {
        const hasCustomerAddress = customer?.street && customer?.streetNr && customer?.city && customer?.zipcode;
        const street = hasCustomerAddress && joinMultipartString(customer?.street, customer?.streetNr);
        const city = hasCustomerAddress && joinMultipartString(customer?.city, customer?.zipcode);
        return (
          <div style={{ padding: '5px' }}>
            <strong>Offener Stopp</strong>
            <div
              style={{
                textTransform: 'capitalize',
              }}>
              <div>{street}</div>
              <div>{city}</div>
            </div>
          </div>
        );
      };
      return new MarkerDetails({
        markerType: MarkerType.customer,
        markerRef: customer.customerRef,
        location: customer.location,
        time: undefined,
        // stopNumber is used only to uniquely identify the marker
        // if same customer is at multiple stops (Paketshop)
        stopNumber: lastFinishedStopNr + 1 + stopIndex,
        content: <MdPlace color='white' size={18} />,
        color: MarkerColors.planned.color,
        opacity: undefined,
        tooltipCallback: TooltipContent,
      });
    });
    return {
      unfinishedMarkers: allUnfinishedMarkers.filter((marker) => marker?.location?.isValid()),
      allUnfinishedMarkers,
    };
  }, [tourDetails, routeMode]);

  const allMarkers = useMemo(() => {
    if (routeMode === 'planned') {
      return plannedMarkers;
    } else if (routeMode === 'real') {
      return [...unfinishedMarkers, ...finishedMarkers];
    } else {
      // HINWEIS: Reihenfolge im Array ist wichtig,
      // die am Anfang liegenden Markers werden unterhalb gezeichnet
      // die unfinished werden von geplanten Markern überdeckt
      // welche von abgeschlossenen Markers überdeckt werden
      return [...unfinishedMarkers, ...plannedMarkers, ...finishedMarkers];
    }
  }, [finishedMarkers, unfinishedMarkers, plannedMarkers, routeMode]);

  const calculatedBounds = useMemo(() => {
    const newBounds = new mapboxgl.LngLatBounds();
    if (!tourDetails) {
      return newBounds;
    }
    const locations = allMarkers
      ?.map((marker) => marker?.location)
      ?.filter((location) => !!location && location.isValid());
    locations?.forEach((location) => {
      newBounds.extend([location.lng, location.lat]);
    });
    return newBounds;
  }, [allMarkers, tourDetails]);

  useEffect(() => {
    // initial bounds are not reactive, so set it only once
    if (!initialBounds) {
      if (calculatedBounds && !calculatedBounds.isEmpty()) {
        setInitialBounds(calculatedBounds);
      }
    }
  }, [calculatedBounds, initialBounds]);

  useEffect(() => {
    if (!mapRef.current) {
      return;
    }
    if (calculatedBounds && !calculatedBounds.isEmpty()) {
      mapRef.current.fitBounds(calculatedBounds, {
        ...defaultFitBoundsOptions,
        essential: true,
      });
    }
  }, [calculatedBounds]);

  const pathCoordinates: number[][] = useMemo(() => {
    if (!tourDetails || routeMode === 'planned') {
      return [];
    }
    return Object.values(tourDetails.finishedDeliveries)
      .filter((finishedDelivery) => !!finishedDelivery?.location && finishedDelivery?.location?.isValid())
      .map((finishedDelivery) => {
        if (markerMode === 'address') {
          const customers = Object.keys(finishedDelivery.customerDeliveries ?? {})
            .map((customerId) => tourDetails.customers.get(customerId) ?? undefined)
            .filter((customer) => !!customer);
          if (!customers?.[0]?.location) {
            return null;
          }
          return customers[0].location.toReversedArray();
        } else {
          if (markerMode === 'gps' && !finishedDelivery.location.isGpsLocation()) {
            return null;
          }
          return finishedDelivery.location.toReversedArray();
        }
      })
      .filter((value) => !!value);
  }, [tourDetails, markerMode, routeMode]);

  const plannedPathCoordinates: number[][] = useMemo(() => {
    if (!plannedMarkers || routeMode === 'real') {
      return [];
    }
    return plannedMarkers?.map((marker) => marker?.location?.toReversedArray());
  }, [plannedMarkers, routeMode]);

  const updatePopup = (
    show: boolean,
    longitude?: number,
    latitude?: number,
    content?: () => ReactNode,
    isActiveMarker?: boolean
  ) => {
    if (show) {
      setShowTooltip(true);
      setTooltipOffset(isActiveMarker ? activeMarkerPopupOffset : defaultPopupOffset);
      setTooltipPosition({ lng: longitude || 0, lat: latitude || 0 });
      setTooltipContent(content);
    } else {
      setShowTooltip(false);
      setTooltipContent(null);
    }
  };

  const onMarkerClick = (markerDetails: MarkerDetails) => {
    if (!authService.canAccessStop(orgUnit?.breadcrumb)) {
      return;
    }

    setMarkerRef(null);
    if (!markerDetails) {
      return;
    }
    dispatch(
      tourDetailsSlice.actions.setSelectedStop({
        type:
          markerDetails.markerType === MarkerType.finishedDelivery
            ? StopTypeType.Processed
            : markerDetails.markerType === MarkerType.customer
            ? StopTypeType.Open
            : StopTypeType.Planned,
        stopNumber: markerDetails.stopNumber,
        customerRef: markerDetails.markerRef,
      })
    );
  };

  const selectedMarkerType = useMemo(() => {
    return selectedStop?.type === StopTypeType.Processed
      ? MarkerType.finishedDelivery
      : selectedStop?.type === StopTypeType.Open
      ? MarkerType.customer
      : MarkerType.planned;
  }, [selectedStop]);

  const [prevStopMarker, nextStopMarker] = useMemo(() => {
    if (!selectedStop) {
      return [];
    }
    const markerArray =
      selectedMarkerType === MarkerType.finishedDelivery || selectedMarkerType === MarkerType.customer
        ? [...allFinishedMarkers, ...allUnfinishedMarkers]
        : selectedMarkerType === MarkerType.planned
        ? allPlannedMarkers
        : [];
    const markerIndex = markerArray?.findIndex(
      (markerDetails) => markerDetails.stopNumber === selectedStop?.stopNumber
    );
    if (markerIndex === -1) {
      return [];
    }
    return [markerArray?.[markerIndex - 1], markerArray?.[markerIndex + 1]];
  }, [allFinishedMarkers, selectedStop, selectedMarkerType, allUnfinishedMarkers, allPlannedMarkers]);

  const isMarkerActive = useCallback(
    (markerDetails: MarkerDetails) => {
      if (highlightedMarkerRef && markerDetails.markerRef === highlightedMarkerRef) {
        return true;
      } else if (!selectedStop || selectedMarkerType !== markerDetails?.markerType) {
        return false;
      }
      return selectedStop?.stopNumber === markerDetails?.stopNumber;
    },
    [selectedStop, selectedMarkerType, highlightedMarkerRef]
  );

  const onLoad = useCallback((event) => {
    mapRef.current = event.target;
  }, []);

  const onIdle = useCallback(() => {
    // force map bounds update only when new tour is loaded and new bounds are valid
    // we prevent map bounds from being updated when markerMode changes
    if (!mapRef.current || !forceBoundsUpdate) {
      return;
    }
    if (calculatedBounds && !calculatedBounds.isEmpty()) {
      mapRef.current.fitBounds(calculatedBounds, {
        ...defaultFitBoundsOptions,
        essential: true,
      });
      setForceBoundsUpdate(false);
    }
  }, [calculatedBounds, forceBoundsUpdate]);

  const onReturnToListClick = useCallback(() => {
    dispatch(dashboardSlice.actions.setActiveDashboardView('Touren'));
  }, [dispatch]);

  const onReturnToDashboardClick = useCallback(() => {
    dispatch(dashboardSlice.actions.setActiveDashboardView(null));
    dispatch(appSlice.actions.setViewMode('dashboard'));
  }, [dispatch]);

  if (tourDetailsLoading) {
    return <LoadingIndicator />;
  } else if (!tourDetails) {
    return (
      <FallbackComponent
        title='Die Daten zu der Tour sind nicht verfügbar, bitte wähle ein anderes Datum.'
        body={`Deine Auswahl: Tour Nummer ${tourIdentifier?.number}${orgUnit?.name ? ' in ' + orgUnit?.name : ''}`}
        returnActionTitle='Zurück zur Liste'
        onReturnClick={onReturnToListClick}
      />
    );
  } else if (!allMarkers?.length) {
    return (
      <FallbackComponent
        title='Zu dieser Tour existieren keine Geo-Daten, bitte wähle ein anderes Datum.'
        body={`Deine Auswahl: Tour Nummer ${tourIdentifier?.number}${orgUnit?.name ? ' in ' + orgUnit?.name : ''}`}
        returnActionTitle='Zurück zur Liste'
        onReturnClick={onReturnToListClick}
      />
    );
  } else if (!isAllowedToSeeTourDetails || (isHermesDriver && !isAllowedToSeeHermesTours)) {
    return (
      <FallbackComponent
        title={!isAllowedToSeeTourDetails ? LOCATION_ERROR_MSG : INTERNAL_PROJECTION_ERROR}
        body={`Deine Auswahl: Tour Nummer ${tourIdentifier?.number}${orgUnit?.name ? ' in ' + orgUnit?.name : ''}`}
        returnActionTitle='Zurück zum Dashboard'
        onReturnClick={onReturnToDashboardClick}
      />
    );
  }
  return (
    <MapStyleContextProvider primaryStyle='default' secondaryStyle='satellite'>
      {initialBounds && !initialBounds?.isEmpty() && (
        <MapboxMap
          initialViewState={{
            bounds: initialBounds,
          }}
          minZoom={6}
          maxZoom={20}
          maxBounds={[
            [5.85, 47.25],
            [15.1, 55.1],
          ]}
          onLoad={onLoad}
          onIdle={onIdle}
          showNavigationControl={true}
          navigationControlProps={{ showCompass: false }}
          showScaleControl={true}
          showStyleControl={true}
          styleControlProps={{ position: 'top-right', offset: [45, 10] }}
          showBreadcrumbControl={false}
          showLegendControl={true}
          legendControlProps={{ position: 'bottom-right', offset: [10, 25] }}
          showStopSelectControl={!!selectedStop}
          stopSelectControlProps={{
            position: 'top-left',
            offset: [10, 10],
            label: selectedMarkerType === MarkerType.customer ? 'Offener Stopp' : `Stopp ${selectedStop?.stopNumber}`,
            onNextClick: nextStopMarker ? () => onMarkerClick(nextStopMarker) : null,
            onPrevClick: prevStopMarker ? () => onMarkerClick(prevStopMarker) : null,
          }}
          onClick={(clickEvent) => {
            if (
              // className prop exists on click target
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (clickEvent.originalEvent?.target as any)?.className === 'mapboxgl-canvas'
            ) {
              setMarkerRef(null);
            }
          }}>
          {routeMode !== 'planned' && (
            <MapboxSource
              id='route'
              type='geojson'
              data={{
                type: 'Feature',
                properties: {},
                geometry: {
                  type: 'LineString',
                  coordinates: pathCoordinates,
                },
              }}>
              <MapboxLayer
                id='route-layer'
                type='line'
                layout={{
                  'line-cap': 'round',
                }}
                paint={{
                  'line-color': '#0091CD',
                  'line-width': 3,
                  'line-opacity': routeMode === 'all' ? 0.5 : 1,
                }}
              />
              <MapboxLayer
                id='route-arrows-layer'
                type='symbol'
                layout={{
                  'symbol-placement': 'line',
                  'symbol-spacing': ['interpolate', ['linear'], ['zoom'], 0, 250, 22, 75],
                  'icon-allow-overlap': true,
                  'icon-image': 'triangle-primary',
                  visibility: 'visible',
                  'icon-rotate': 90,
                  'icon-rotation-alignment': 'map',
                }}
              />
            </MapboxSource>
          )}
          {routeMode !== 'real' && (
            <MapboxSource
              id='planned-route'
              type='geojson'
              data={{
                type: 'Feature',
                properties: {},
                geometry: {
                  type: 'LineString',
                  coordinates: plannedPathCoordinates,
                },
              }}>
              <MapboxLayer
                id='planned-route-layer'
                beforeId={routeMode === 'all' ? 'route-layer' : undefined}
                type='line'
                layout={{
                  'line-join': 'round',
                  'line-cap': 'round',
                }}
                paint={{
                  'line-color': '#969696',
                  'line-width': 3,
                  'line-opacity': 1,
                  'line-dasharray': [3, 2],
                }}
              />
              <MapboxLayer
                id='planned-route-arrows-layer'
                beforeId={routeMode === 'all' ? 'route-arrows-layer' : undefined}
                type='symbol'
                layout={{
                  'symbol-placement': 'line',
                  'symbol-spacing': ['interpolate', ['linear'], ['zoom'], 0, 250, 22, 75],
                  'icon-allow-overlap': true,
                  'icon-image': 'triangle-disabled',
                  visibility: 'visible',
                  'icon-rotate': 90,
                  'icon-rotation-alignment': 'map',
                }}
              />
            </MapboxSource>
          )}
          {allMarkers.map((markerDetails: MarkerDetails) => {
            const isActiveMarker = isMarkerActive(markerDetails);
            return (
              <MapboxMarker
                {...markerDetails}
                key={markerDetails.markerRef + markerDetails.markerType + markerDetails?.stopNumber}
                scaleSizeByZoom={true}
                onMouseEnter={() => {
                  updatePopup(
                    true,
                    markerDetails.location?.lng,
                    markerDetails.location?.lat,
                    markerDetails.tooltipCallback,
                    isActiveMarker
                  );
                  setSelectedMarker(markerDetails);
                }}
                onMouseLeave={() => {
                  updatePopup(false);
                  setSelectedMarker(null);
                }}
                zIndex={
                  // 0-planed stop 1-non finished stop 2-finished stop 3-active marker 4-hover marker 5-mapbox control 6-mapbox popup 10-tooltips
                  isActiveMarker
                    ? 3
                    : selectedMarker === markerDetails
                    ? 4
                    : markerDetails.markerType === MarkerType.finishedDelivery
                    ? 2
                    : markerDetails.markerType === MarkerType.customer
                    ? 1
                    : 0
                }
                onClick={() => onMarkerClick(markerDetails)}
                active={isActiveMarker}
              />
            );
          })}
          <MapboxPopup
            shown={showTooltip}
            longitude={tooltipPosition?.lng}
            latitude={tooltipPosition?.lat}
            offset={tooltipOffset}
            closeButton={false}
            closeOnClick={true}
            closeOnMove={true}
            focusAfterOpen={false}>
            {tooltipContent}
          </MapboxPopup>
          <MapboxButtonGroup
            disabled={routeMode === 'planned'}
            values={[
              {
                label: 'Alle',
                value: 'all',
                tooltipProps: {
                  tooltipText:
                    'Zeigt Stopps an, für die die Position von Fahrer oder Adresse bekannt sind. ' +
                    'Wenn beide vorhanden sind, wird die GPS-Position des Fahrers angezeigt.',
                },
              },
              {
                label: 'Adr.',
                value: 'address',
                tooltipProps: {
                  tooltipText:
                    ' Zeigt bei allen Stopps die Position der Adresse an (Stops ohne Adressposition sind ausgeblendet).',
                },
              },
              {
                label: 'GPS',
                value: 'gps',
                tooltipProps: {
                  tooltipText:
                    'Zeigt bei allen Stopps die GPS-Position des Fahrers an (Stops ohne GPS sind ausgeblendet).',
                },
              },
            ]}
            size='sm'
            offset={[110, 12]}
            onValueChange={(value: MarkerMode) => setMarkerMode(value)}
          />
          <MapboxButtonGroup
            values={[
              {
                label: 'Gefahren',
                value: 'real',
                tooltipProps: {
                  tooltipText: 'Zeigt die tatsächlich gefahrene Stopp-Reihenfolge an.',
                },
              },
              {
                label: 'Geplant',
                value: 'planned',
                tooltipProps: {
                  tooltipText: hasPlannedStops
                    ? 'Zeigt die ursprünglich geplante Stopp-Reihenfolge an.'
                    : 'Für diese Tour wurde keine Planung mit RouteMaster erstellt.',
                },
                disabled: !hasPlannedStops,
              },
              {
                label: 'Beides',
                value: 'all',
                tooltipProps: {
                  tooltipText: hasPlannedStops
                    ? 'Zeigt sowohl die geplante, als auch die gefahrene Stopp-Reihenfolge an.'
                    : 'Für diese Tour wurde keine Planung mit RouteMaster erstellt.',
                },
                disabled: !hasPlannedStops,
              },
            ]}
            size='sm'
            offset={[270, 12]}
            onValueChange={(value: RouteMode) => setRouteMode(value)}
          />
        </MapboxMap>
      )}
    </MapStyleContextProvider>
  );
}
export default TourDetailsMap;
const joinMultipartString = (...parts: string[]) => {
  return parts
    .filter((part) => part != null)
    .join(' ')
    .trim()
    .toLowerCase();
};
