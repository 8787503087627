import { useValueExpressionContext } from '@contexts/value-expression-context';
import {
  DataTableComponentId,
  DataTableUserConfig,
  LoadingState,
  ValueExpressionIdentifier,
} from '@data-table/data-table.types';
import { cleanupValueExpressionKeys } from '@data-table/utils';
import { useUserConfig } from '@hooks/use-user-config-hook';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import { useCallback, useMemo } from 'react';
import Tourfortschritt from '@contexts/value-expression-context/value-expressions/tourfortschritt';

const defaultValueExpressionKeys = [];

type UseDataTableValueExpressionsOutput = [
  ValueExpression[],
  ValueExpressionIdentifier[],
  (columnId: ValueExpressionIdentifier) => void,
  (columnId: ValueExpressionIdentifier) => void,
  LoadingState
];

const useDataTableValueExpressionsState = (
  componentId: DataTableComponentId,
  showFortschritt: boolean,
  defaultState: string[] = defaultValueExpressionKeys
): UseDataTableValueExpressionsOutput => {
  const valueExpressionMap = useValueExpressionContext();

  const defaultValue = useMemo(
    () => ({
      valueExpressionKeys: defaultState,
    }),
    [defaultState]
  );

  const [dataTableUserConfig, updateDataTableUserConfig, valueExpressionKeysLoading] =
    useUserConfig<DataTableUserConfig>(componentId, defaultValue);

  const valueExpressionKeys = dataTableUserConfig?.valueExpressionKeys || defaultState;
  const cleanValueExpressionKeys = cleanupValueExpressionKeys(valueExpressionKeys);

  const updateValueExpressionKeys = useCallback(
    (valueExpressionKeys: ValueExpressionIdentifier[]) => {
      updateDataTableUserConfig({
        ...dataTableUserConfig,
        valueExpressionKeys,
      });
    },
    [dataTableUserConfig, updateDataTableUserConfig]
  );

  const uniqueValueExpressionKeys = useMemo(() => {
    return showFortschritt
      ? Array.from(new Set([...cleanValueExpressionKeys, Tourfortschritt.identifier]))
      : cleanValueExpressionKeys;
  }, [cleanValueExpressionKeys, showFortschritt]);

  const valueExpressions = useMemo(() => {
    return uniqueValueExpressionKeys
      .map((key) => valueExpressionMap.get(key))
      .filter((valueExpression) => !!valueExpression?.identifier);
  }, [uniqueValueExpressionKeys, valueExpressionMap]);

  const onColumnAdd = useCallback(
    (columnId: ValueExpressionIdentifier) => {
      updateValueExpressionKeys([...cleanValueExpressionKeys, columnId]);
    },
    [cleanValueExpressionKeys, updateValueExpressionKeys]
  );

  const onColumnRemove = useCallback(
    (columnId: ValueExpressionIdentifier) => {
      const columnIndex = cleanValueExpressionKeys.indexOf(columnId);
      if (columnIndex !== -1) {
        const newValueExpressionKeys = [...cleanValueExpressionKeys];
        newValueExpressionKeys.splice(columnIndex, 1);
        updateValueExpressionKeys(newValueExpressionKeys);
      }
    },
    [cleanValueExpressionKeys, updateValueExpressionKeys]
  );

  return [valueExpressions, uniqueValueExpressionKeys, onColumnAdd, onColumnRemove, valueExpressionKeysLoading];
};

export default useDataTableValueExpressionsState;
