import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';

import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

export default new ValueExpression({
  identifier: 'static.map.area',
  label: 'Fläche',
  chart: ChartType.line,
  positiveDirection: PositiveDirection.custom,
  valueFormat: '#.###.##0,0km²',
  category: 'static',
  description: '<p>Fläche in Quadratkilometer für den aktuell ausgewählten Bereich</p>\n',
});
