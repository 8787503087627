import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';

import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

export default new ValueExpression({
  identifier: 'anfahrtsstrecke_avg',
  label: 'Ø Anfahrtsstrecke',
  chart: ChartType.bar,
  positiveDirection: PositiveDirection.custom,
  category: 'tours',
  valueFormat: '-#.###.##0,0 km',
  valueQuotient: 1000,
  definition: getDefinitionByName('Anfahrtsstrecke'),
});
