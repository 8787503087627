import React, { HTMLAttributes } from 'react';
import { MdClose, MdSearch } from 'react-icons/md';
import { Button, FormFeedback, Input, InputProps } from 'reactstrap';
import classNames from 'classnames';

import style from './input-wrapper.module.scss';

type InputWrapperProps = {
  inputProps: InputProps;
  className?: HTMLAttributes<HTMLDivElement>['className'];
  showAddon?: boolean;
  onInputClear?: () => void;
};

const InputWrapper = ({ inputProps, onInputClear, className, showAddon = true }: InputWrapperProps) => {
  const { value, onChange } = inputProps;

  return (
    <div className={classNames(style.inputWrapperContainer, className)}>
      {showAddon && (
        <div className={classNames(style.inputWrapperAddon, style.inputWrapperSearch)}>
          <MdSearch />
        </div>
      )}
      <Input
        {...inputProps}
        autoFocus={true}
        type='text'
        onChange={onChange}
        className={classNames(style.inputWrapper, {
          [style.addonLeft]: showAddon,
        })}
      />
      <FormFeedback tooltip={true}>Überprüfen Sie Ihre Eingabe!</FormFeedback>
      {onInputClear && value.toString().length > 0 && (
        <Button
          className={classNames(style.inputWrapperAddon, style.inputWrapperButtonAddon)}
          type='button'
          onClick={onInputClear}>
          <MdClose />
        </Button>
      )}
    </div>
  );
};

export default InputWrapper;
