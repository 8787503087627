import { useQuery, UseQueryResult } from 'react-query';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { ConfigKey, getEnvVariable } from '@legacy-modules/common/services/EnvironmentConfigurationService';
import { TourIdentifier } from '@legacy-modules/tour/models/state/tourDetails/TourIdentifier';
import { selectTokenId } from '@redux/auth.selectors';
import TourDetailsGenerator from '@legacy-modules/tour/converters/TourDetailsGenerator';
import { LMAnalyticsInputTourModel } from '@legacy-modules/tour/models/types/TourInputModels';
import TourDetails from '@legacy-modules/tour/models/entities/TourDetails';

const baseHttpUrl = getEnvVariable(ConfigKey.HTTP_API_URL);

export default function useTourDetails(
  tourIdentifier: TourIdentifier,
  enabled: boolean = true
): UseQueryResult<TourDetails> {
  const { orgId, date: tourDate, number: tourNumber } = tourIdentifier ?? {};
  const effectiveTourDate = moment(tourDate).format('YYYYMMDD');
  const url = new URL(`${baseHttpUrl}/tour-details`);
  url.searchParams.append('tourNumber', tourNumber);
  url.searchParams.append('orgId', orgId);
  url.searchParams.append('tourDate', effectiveTourDate);
  const tokenId = useSelector(selectTokenId);

  return useQuery<LMAnalyticsInputTourModel, unknown, TourDetails>({
    queryKey: [orgId, effectiveTourDate, tourNumber],
    queryFn: () =>
      fetch(url, {
        headers: {
          token: tokenId,
        },
      }).then((res) => res.json()),
    enabled: enabled && !!orgId && !!tourDate && !!tourNumber,
    keepPreviousData: true,
    select: (data) => {
      if (!data) return null;
      const tourDetailsGenerator = new TourDetailsGenerator();
      return tourDetailsGenerator.convert(data);
    },
  });
}
