import ValueExpression, {
  TargetValueType,
} from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';

export default new ValueExpression({
  identifier: 'abholungen_avg',
  valueFormat: '-#.###.##0,0',
  label: 'Ø Abholungen',
  positiveDirection: PositiveDirection.custom,
  definition: getDefinitionByName('Abholungen'),
  targetValueType: TargetValueType.QUOTE,
  decimalPlaces: 2,
});
