import React, { useCallback, useRef, useState } from 'react';
import { Loader } from '../../common/components/Loader';
import FinishedDelivery from '../models/entities/FinishedDelivery';
import { useApiImageFetch } from '../hook/useApiImageFetch';
import { ImageError } from './ImageError';
import { LazyImage } from '../../common/components/LazyImage';
import { getImageErrorMessageByError } from '../helpers/GetImageErrorMessageByError';
import { ProofOfDelivery } from '../models/enumerations/ProofOfDelivery';
import { NO_OP } from '../../dashboard/components/FilterBubble';

export type SignatureRotation = 0 | 90 | 180 | 270;

type Props = {
  url: string;
  rotation?: SignatureRotation;
  lazyLoadRoot?: Element;
  finishedDelivery?: FinishedDelivery;
  width?: number;
  height?: number;
  handleMouseEnter: (e: React.MouseEvent<HTMLDivElement>) => void;
  handleMouseLeave: (e?: React.MouseEvent<HTMLDivElement>) => void;
  onImageLoaded?: (src: string) => void;
};

const ScannerSignature: React.FC<Props> = ({
  url,
  handleMouseEnter,
  handleMouseLeave,
  width = 200,
  height = 150,
  lazyLoadRoot = undefined,
  onImageLoaded = NO_OP,
}: Props) => {
  const [loaded, setLoaded] = useState(false);
  const { error, loadFn, resetError } = useApiImageFetch(url);

  const onLoadCompletedCallback = useCallback(
    (src) => {
      setLoaded(true);
      onImageLoaded(src);
    },
    [onImageLoaded]
  );

  const imageStyle = {
    width,
    height: 'auto',
  };

  const imageRef = useRef<HTMLImageElement>(null);

  const loaderOverlay = (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
      }}>
      <Loader />
    </div>
  );

  if (error) {
    const { retryable, message } = getImageErrorMessageByError(error, ProofOfDelivery.signature);

    return (
      <div
        style={{
          width,
          height,
        }}>
        <ImageError error={message} onRetry={resetError} retryable={retryable} />
      </div>
    );
  }

  return (
    <div
      style={{
        width,
        height,
        position: 'relative',
      }}
      onMouseOver={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
      <figure>
        <LazyImage
          style={imageStyle}
          lazyLoadRoot={lazyLoadRoot}
          onLoadCompleted={onLoadCompletedCallback}
          loadFn={loadFn}
          alt=''
          ref={imageRef}
        />
      </figure>
      {!loaded && loaderOverlay}
    </div>
  );
};

export default ScannerSignature;
