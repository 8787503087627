import moment from 'moment';

import { useKpiQuery } from '@hooks/use-kpi-query-hook';
import { TransformedKpis } from '@hooks/use-kpi-query-hook/use-kpi-query.hook';
import { Duration } from '@legacy-modules/dashboard/models/enums/Duration';
import ChartUtils, { WeekResolution } from '@legacy-modules/dashboard/utils/ChartUtils';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import { DurationUtils } from '@utils/duration-utils';
import { DateRange } from '@legacy-modules/utils/dates/DateRange';

export type UseChartKpiQueryProps = {
  orgKey: string;
  duration: Duration;
  valueExpression: ValueExpression;
  isComparisonDate?: boolean;
  fetchChildren?: boolean;
};

type UseChartKpiQueryOutput = {
  data: { rangeData: { [n: string]: number }; summaryData: Map<string, number> };
  loading: boolean;
};

export const transformData = (dateRange: DateRange) => (data: TransformedKpis) => {
  const groupStringSuffix = ChartUtils.getDateResolution(
    dateRange.from,
    dateRange.to,
    WeekResolution.week1,
    ChartUtils.thresholdDataBarsCount
  );
  const mappedData = data.kpis.groups.reduce((obj, currVal) => {
    const mappedGroup = moment(currVal.group).format('YYYY-MM-DD');
    if (mappedGroup && currVal.kpiValues.length > 0) {
      currVal.kpiValues.forEach((kpiValue) => {
        obj[`${mappedGroup}_${groupStringSuffix}_${kpiValue.kpiId}`] = kpiValue?.value;
      });
    }
    return obj;
  }, {});
  const summaryData = new Map(data.kpis.summary.kpiValues.map((x) => [x.kpiId, x.value]));
  return { rangeData: mappedData, summaryData: summaryData };
};

export default function useChartKpiQuery({
  orgKey,
  duration,
  valueExpression,
  isComparisonDate = false,
  fetchChildren = true,
}: UseChartKpiQueryProps): UseChartKpiQueryOutput {
  const dateRange = isComparisonDate
    ? DurationUtils.getComparisonDateRange(duration)
    : DurationUtils.getDateRange(duration);

  const dateRangeResolution = ChartUtils.getDateResolution(
    dateRange.from,
    dateRange.to,
    WeekResolution.isoWeek,
    ChartUtils.thresholdDataBarsCount
  );

  let valueExpressions: ValueExpression[] = [];

  if (valueExpression) {
    valueExpressions = fetchChildren ? [valueExpression, ...valueExpression.getChildren()] : [valueExpression];
  }

  const { data, isLoading } = useKpiQuery<UseChartKpiQueryOutput['data']>(
    {
      expressionsFilter: valueExpressions.map((ve) => {
        return { valueExpression: ve };
      }),
      orgKeys: [orgKey],
      dateRange: dateRange,
      grouping: dateRangeResolution,
    },
    {
      enabled: valueExpressions.length > 0 && !!orgKey && !!dateRange.from && !!dateRange.to,
    },
    transformData(dateRange)
  );

  return { data, loading: isLoading };
}
