import { v4 as uuidV4 } from 'uuid';
import { sortBy } from 'lodash';
import AuthService from '../../common/services/AuthService';
import { NodeData, TreeMenuNode } from '../../navigation/TreeMenu';
import { ContractorUtils } from '@legacy-modules/utils/tours/ContractorUtils';
import { ValueExpressionMap } from '../hooks/useChartDataTransformation';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import AbholungenByReturnType from '@contexts/value-expression-context/value-expressions/abholungen-by-return-type';
import MitnahmenByReturnType from '@contexts/value-expression-context/value-expressions/mitnahmen-by-return-type';
import RuecklaufAbholkartenGrund from '@contexts/value-expression-context/value-expressions/ruecklauf-abholkarten-grund';
import RuecklaufAbholkarten from '@contexts/value-expression-context/value-expressions/ruecklauf-abholkarten';
import RuecklauferEilGrund from '@contexts/value-expression-context/value-expressions/ruecklaufer-eil-grund';
import LademengeSendungsart from '@contexts/value-expression-context/value-expressions/lademenge-sendungsart';
import RuecklaeuferGrund from '@contexts/value-expression-context/value-expressions/ruecklaufer-grund';
import ZustellungenArt from '@contexts/value-expression-context/value-expressions/zustellungen-art';
import BruttoZustellzeit from '@contexts/value-expression-context/value-expressions/brutto-zustellzeit';
import BruttoZustellzeitAvg from '@contexts/value-expression-context/value-expressions/brutto-zustellzeit-avg';
import Abholungen from '@contexts/value-expression-context/value-expressions/abholungen-ohne-mitnahmen';
import AbholungenMitnahmen from '@contexts/value-expression-context/value-expressions/abholungen-und-mitnahmen';
import AbholungenAvg from '@contexts/value-expression-context/value-expressions/abholungen-avg';
import Mitnahmen from '@contexts/value-expression-context/value-expressions/mitnahmen';
import AtgAbholungenAvg from '@contexts/value-expression-context/value-expressions/atg-abholungen-avg';
import AtgProduktivitaet from '@contexts/value-expression-context/value-expressions/atg-produktivitaet';
import Anfahrtszeit from '@contexts/value-expression-context/value-expressions/anfahrtszeit';
import AnfahrtszeitAvg from '@contexts/value-expression-context/value-expressions/anfahrtszeit-avg';
import Anfahrtsstrecke from '@contexts/value-expression-context/value-expressions/anfahrtsstrecke';
import BenachrichtigungenQuote from '@contexts/value-expression-context/value-expressions/benachrichtigungen-quote';
import TourStrecke from '@contexts/value-expression-context/value-expressions/tourstrecke';
import UhrzeitTourfreigabe from '@contexts/value-expression-context/value-expressions/tour-freigabe-zeitpunkt-avg';
import TourfahrtzeitAvg from '@contexts/value-expression-context/value-expressions/tourfahrzeit-avg';
import Rueckfahrtstrecke from '@contexts/value-expression-context/value-expressions/rueckfahrtstrecke';
import NettoHtZeit from '@contexts/value-expression-context/value-expressions/netto-ht-zeit';
import NettoHtZeitAvg from '@contexts/value-expression-context/value-expressions/netto-ht-zeit-avg';
import NettoPsZeit from '@contexts/value-expression-context/value-expressions/netto-ps-zeit';
import NettoPsZeitAvg from '@contexts/value-expression-context/value-expressions/netto-ps-zeit-avg';
import NettoAtgZeit from '@contexts/value-expression-context/value-expressions/netto-atg-zeit';
import NettoAtgZeitAvg from '@contexts/value-expression-context/value-expressions/netto-atg-zeit-avg';
import NettoZustellzeit from '@contexts/value-expression-context/value-expressions/netto-zustellzeit';
import NettoZustellzeitAvg from '@contexts/value-expression-context/value-expressions/netto-zustellzeit-avg';
import RueckfahrtzeitAvg from '@contexts/value-expression-context/value-expressions/rueckfahrtzeit-avg';
import Rueckfahrtzeit from '@contexts/value-expression-context/value-expressions/rueckfahrtzeit';
import Tourfahrzeit from '@contexts/value-expression-context/value-expressions/tourfahrzeit';
import TourstreckeAvg from '@contexts/value-expression-context/value-expressions/tourstrecke-avg';
import RueckfahrtstreckeAvg from '@contexts/value-expression-context/value-expressions/rueckfahrtstrecke-avg';
import TourStopsHt from '@contexts/value-expression-context/value-expressions/tour-stops-ht';
import TourStopsPs from '@contexts/value-expression-context/value-expressions/tour-stops-ps';
import TourStopsAtg from '@contexts/value-expression-context/value-expressions/tour-stops-atg';
import AnfahrtsstreckeAvg from '@contexts/value-expression-context/value-expressions/anfahrtsstrecke-avg';
import ReihenfolgeeinhaltungQuote from '@contexts/value-expression-context/value-expressions/reihenfolgeeinhaltung-quote';
import Benachrichtigungen from '@contexts/value-expression-context/value-expressions/benachrichtigungen';
import ZustellungenAvg from '@contexts/value-expression-context/value-expressions/zustellungen-avg';
import Zustellungen from '@contexts/value-expression-context/value-expressions/zustellungen';
import ZustellungenNachbarQuote from '@contexts/value-expression-context/value-expressions/zustellungen-nachbar-quote';
import FotoUnterschriftenNutzungsquote from '@contexts/value-expression-context/value-expressions/foto-unterschriften-nutzungsquote';
import Dauervollmachtquote from '@contexts/value-expression-context/value-expressions/dauervollmachtquote';
import BenachrichtigungenArt from '@contexts/value-expression-context/value-expressions/benachrichtigungen-art';
import Abholauftraege from '@contexts/value-expression-context/value-expressions/abholauftraege';
import AbholauftraegeSuccessful from '@contexts/value-expression-context/value-expressions/abholauftraege-successful';
import AtgAbholungen from '@contexts/value-expression-context/value-expressions/atg-abholungen';
import HinweisHtWzfZustellung from '@contexts/value-expression-context/value-expressions/hinweis-ht-wzf-zustellung';
import HinweisAtgWzfAbholung from '@contexts/value-expression-context/value-expressions/hinweis-atg-wzf-abholung';
import HinweisTagversetzteBearbeitung from '@contexts/value-expression-context/value-expressions/hinweis-tagversetzte-bearbeitung';
import HinweisRuecklaufAmWunschtag from '@contexts/value-expression-context/value-expressions/hinweis-ruecklauf-am-wunschtag';
import HinweisAusserhalbZustellzeit from '@contexts/value-expression-context/value-expressions/hinweis-ausserhalb-zustellzeit';
import HinweisTeillieferungMehrcollig from '@contexts/value-expression-context/value-expressions/hinweis-teillieferung-mehrcollig';
import HinweisRuecklaufAmTourende from '@contexts/value-expression-context/value-expressions/hinweis-ruecklauf-am-tourende';
import HinweisHtWzfAbholung from '@contexts/value-expression-context/value-expressions/hinweis-ht-wzf-abholung';
import HinweisWzfUnknown from '@contexts/value-expression-context/value-expressions/hinweis-wzf-unknown';
import AbholauftraegeInclNichtGestartet from '@contexts/value-expression-context/value-expressions/abholauftraege-incl-nicht-gestartet';
import RuecklauferEil from '@contexts/value-expression-context/value-expressions/ruecklaufer-eil';
import WertsendungenTourSbInclNichtGestartet from '@contexts/value-expression-context/value-expressions/wertsendungen-tour-sb-incl-nicht-gestartet';
import Touren from '@contexts/value-expression-context/value-expressions/touren';
import LademengeIst from '@contexts/value-expression-context/value-expressions/lademenge-ist';
import LademengeSoll from '@contexts/value-expression-context/value-expressions/lademenge-soll';
import PsAbgabenAvg from '@contexts/value-expression-context/value-expressions/ps-abgaben-avg';
import PsMitnahmenAvg from '@contexts/value-expression-context/value-expressions/ps-mitnahmen-avg';
import PsMitnahmen from '@contexts/value-expression-context/value-expressions/ps-mitnahmen';
import PsAbgaben from '@contexts/value-expression-context/value-expressions/ps-abgaben';
import PsMitnahmenNachGrund from '@contexts/value-expression-context/value-expressions/ps-mitnahmen-by-return-type';
import Liegenbleiber from '@contexts/value-expression-context/value-expressions/liegenbleiber';
import LiegenbleiberEil from '@contexts/value-expression-context/value-expressions/liegenbleiber-eil';
import Lagernd from '@contexts/value-expression-context/value-expressions/lagernd';
import Afnus from '@contexts/value-expression-context/value-expressions/afnus';
import Zugangsmenge from '@contexts/value-expression-context/value-expressions/zugangsmenge';
import Bestand from '@contexts/value-expression-context/value-expressions/bestand';
import LiegenbleiberQuote from '@contexts/value-expression-context/value-expressions/liegenbleiber-quote';
import LiegenbleiberEilQuote from '@contexts/value-expression-context/value-expressions/liegenbleiber-eil-quote';
import LagerndQuote from '@contexts/value-expression-context/value-expressions/lagernd-quote';
import EPlus0Quote from '@contexts/value-expression-context/value-expressions/e-plus-0-quote';
import EPlus0EilQuote from '@contexts/value-expression-context/value-expressions/e-plus-0-eil-quote';
import ZsbPlus0Quote from '@contexts/value-expression-context/value-expressions/zsb-plus-0-quote';
import ZsbPlus0EilQuote from '@contexts/value-expression-context/value-expressions/zsb-plus-0-eil-quote';
import WertsendungenTour from '@contexts/value-expression-context/value-expressions/wertsendungen-tour';
import BulkyTourSb from '@contexts/value-expression-context/value-expressions/bulky';
import BulkyTourSbZugestellt from '@contexts/value-expression-context/value-expressions/bulky-zugestellt';
import SendungenTanTour from '@contexts/value-expression-context/value-expressions/sendungen-tan-tour';
import SendungenTanZustellungen from '@contexts/value-expression-context/value-expressions/sendungen-tan-zustellungen';
import SendungenTanRuecklaeufer from '@contexts/value-expression-context/value-expressions/sendungen-tan-ruecklaeufer';
import HtProduktivitaet from '@contexts/value-expression-context/value-expressions/ht-produktivitaet';
import PsProduktivitaet from '@contexts/value-expression-context/value-expressions/ps-produktivitaet';
import LademengeIstAvg from '@contexts/value-expression-context/value-expressions/lademenge-ist-avg';
import LademengeSollAvg from '@contexts/value-expression-context/value-expressions/lademenge-soll-avg';
import AbwicklungsmengeZsb from '@contexts/value-expression-context/value-expressions/abwicklungsmenge-zsb';
import Abwicklungsmenge from '@contexts/value-expression-context/value-expressions/abwicklungsmenge';
import ServicebeanstandungenQuoteZsb from '@contexts/value-expression-context/value-expressions/servicebeanstandungen-quote-zsb';
import ServicebeanstandungenQuoteVerlustrelevant from '@contexts/value-expression-context/value-expressions/servicebeanstandungen-quote-verlustrelevant';
import ServicebeanstandungenMeldung from '@contexts/value-expression-context/value-expressions/servicebeanstandungen-meldung';
import ServicebeanstandungenMeldungVerlustrelevant from '@contexts/value-expression-context/value-expressions/servicebeanstandungen-meldung-verlustrelevant';
import RuecklaufQuote from '@contexts/value-expression-context/value-expressions/ruecklauf-quote';
import RuecklauferAvg from '@contexts/value-expression-context/value-expressions/ruecklaufer-avg';
import Ruecklaufer from '@contexts/value-expression-context/value-expressions/ruecklaufer';
import StandardleistungenTour from '@contexts/value-expression-context/value-expressions/abrechnung-standardleistungen-tour';
import BulkyTour from '@contexts/value-expression-context/value-expressions/abrechnung-bulky-tour';
import IdentServiceTour from '@contexts/value-expression-context/value-expressions/abrechnung-ident-service-tour';
import NachnahmeTour from '@contexts/value-expression-context/value-expressions/abrechnung-nachnahme-tour';
import GepaeckAbholungTour from '@contexts/value-expression-context/value-expressions/abrechnung-gepaeck-abholung-tour';
import ZeitfensterTour from '@contexts/value-expression-context/value-expressions/abrechnung-zeitfenster-tour';
import ZusMitnahmeTour from '@contexts/value-expression-context/value-expressions/abrechnung-zus-mitnahme-tour';
import QuittungslosTour from '@contexts/value-expression-context/value-expressions/abrechnung-quittungslos-tour';
import CoincidentTour from '@contexts/value-expression-context/value-expressions/abrechnung-coincident-tour';
import PsStoppsTour from '@contexts/value-expression-context/value-expressions/abrechnung-ps-stopps-tour';
import PsSendungenTour from '@contexts/value-expression-context/value-expressions/abrechnung-ps-sendungen-tour';
import AtgStoppsTour from '@contexts/value-expression-context/value-expressions/abrechnung-atg-stopps-tour';
import AtgSendungenTour from '@contexts/value-expression-context/value-expressions/abrechnung-atg-sendungen-tour';
import EilSendungenTour from '@contexts/value-expression-context/value-expressions/abrechnung-eil-sendungen-tour';
import AltgeraeteMitnahme from '@contexts/value-expression-context/value-expressions/abrechnung-altgeraete-mitnahme-tour';
import SchadenVerlustQuote from '@contexts/value-expression-context/value-expressions/schaden-verlust-quote';
import ServicebeanstandungenGrund from '@contexts/value-expression-context/value-expressions/servicebeanstandungen-grund';
import HinweisAusserhalbWzf from '@contexts/value-expression-context/value-expressions/hinweis-ausserhalb-wzf';

export const getValueExpressionNodeByKey = (key: string, ves: ValueExpressionMap): ValueExpressionNode | null => {
  if (!ves) return null;
  return ves.has(key) ? new ValueExpressionNode(ves.get(key)) : null;
};

export const getValueExpressionNode = (ve: ValueExpression, ves: ValueExpressionMap): ValueExpressionNode | null => {
  if (!ves) return null;
  const key = ve.identifier;
  return ves.has(key) ? new ValueExpressionNode(ves.get(key)) : null;
};

const getValueExpressionChildNodes = (ve: ValueExpression, ves: ValueExpressionMap): ValueExpressionNode[] =>
  ve.getChildren().map((c) => getValueExpressionNode(c, ves));

const sendungsbasiert = (builder: KpiMenuBuilder) =>
  builder.create(
    'Sendungsbasiert',
    builder.createNodes([
      Mitnahmen,
      AbholauftraegeInclNichtGestartet,
      AbholauftraegeSuccessful,
      RuecklauferEil,
      WertsendungenTourSbInclNichtGestartet,
      AbholungenByReturnType,
      MitnahmenByReturnType,
      LademengeSendungsart,
    ])
  );

const development = (builder: KpiMenuBuilder) =>
  builder.create('## Development ##', builder.createNodes([HinweisWzfUnknown]));

const anomaliesWithPrototypes = (builder: KpiMenuBuilder) =>
  builder.create(
    'Hinweise',
    builder.createNodes([
      HinweisHtWzfZustellung,
      HinweisHtWzfAbholung,
      HinweisAtgWzfAbholung,
      HinweisTagversetzteBearbeitung,
      HinweisRuecklaufAmWunschtag,
      HinweisAusserhalbZustellzeit,
      HinweisAusserhalbWzf,
      HinweisTeillieferungMehrcollig,
      HinweisRuecklaufAmTourende,
    ])
  );

export class ValueExpressionNode implements TreeMenuNode<NodeData> {
  constructor(public readonly valueExpression: ValueExpression) {}

  public parent: KpiNode;

  get data() {
    return {
      getLabel: () => this.valueExpression.getLabel(),
      getShortLabel: () => this.valueExpression.getShortLabel(),
      getLongLabel: () => this.valueExpression.getLongLabel(),
      getIdentifier: () => this.valueExpression.identifier,
    };
  }

  matchesSearchInput(inputValue: string): boolean {
    // Billing kpis contain digits that staff searches for
    const isNumberInput = /^\d+$/.test(inputValue);
    if (isNumberInput) {
      return this.data.getIdentifier().toLocaleLowerCase().includes(inputValue);
    }

    return (
      this.data.getLabel().toLocaleLowerCase().includes(inputValue) ||
      this.data.getShortLabel()?.toLocaleLowerCase()?.includes(inputValue) ||
      this.data.getLongLabel()?.toLocaleLowerCase()?.includes(inputValue) ||
      (this.parent !== undefined ? this.parent.data.toString().toLocaleLowerCase().includes(inputValue) : false)
    );
  }
}

export class KpiMenuBuilder {
  constructor(private readonly ves: Map<string, ValueExpression>) {}

  private createNode(valueExpression: ValueExpression): KpiNode {
    return this.create(
      valueExpression.getLabel(),
      getValueExpressionChildNodes(valueExpression, this.ves),
      valueExpression.identifier
    );
  }

  createNodes(children: Array<ValueExpression>): Array<ValueExpressionNode | KpiNode> {
    return children.map((ve) => {
      return ve.hasChildren() ? this.createNode(ve) : getValueExpressionNode(ve, this.ves);
    });
  }

  create(label: string, children: Array<KpiNode | ValueExpressionNode>, valueExpressionKey?: string): KpiNode {
    const node = new KpiNode(label, children);
    node.valueExpressionKey = valueExpressionKey;
    children.forEach((c) => {
      if (!c) return;
      c.parent = node;
    });
    return node;
  }
}

export class KpiNode implements TreeMenuNode<NodeData> {
  public parent: KpiNode | null;
  public valueExpressionKey: string;

  get data(): NodeData {
    return {
      toString: () => this.label,
      getIdentifier: () => this.uid,
    };
  }

  matchesSearchInput(inputValue: string): boolean {
    return this.data.toString().toLocaleLowerCase().includes(inputValue);
  }

  constructor(
    public readonly label: string,
    public children: Array<KpiNode | ValueExpressionNode>,
    private readonly uid: string = uuidV4()
  ) {}
}

const buildKpiTree = (builder: KpiMenuBuilder): KpiNode[] => {
  const tree = [
    builder.create('Schäden & Verluste', builder.createNodes([SchadenVerlustQuote])),
    builder.create('Touren', builder.createNodes([Touren, LademengeIst, LademengeSoll, UhrzeitTourfreigabe])),
    builder.create(
      'Tourlänge & -Dauer',
      builder.createNodes([
        BruttoZustellzeitAvg,
        BruttoZustellzeit,
        NettoHtZeit,
        NettoHtZeitAvg,
        NettoPsZeit,
        NettoPsZeitAvg,
        NettoAtgZeit,
        NettoAtgZeitAvg,
        NettoZustellzeit,
        NettoZustellzeitAvg,
        AnfahrtszeitAvg,
        Anfahrtszeit,
        RueckfahrtzeitAvg,
        Rueckfahrtzeit,
        Tourfahrzeit,
        TourfahrtzeitAvg,
        TourstreckeAvg,
        TourStrecke,
        Rueckfahrtstrecke,
        RueckfahrtstreckeAvg,
        Anfahrtsstrecke,
        TourStopsHt,
        TourStopsPs,
        TourStopsAtg,
        AnfahrtsstreckeAvg,
        ReihenfolgeeinhaltungQuote,
      ])
    ),
    builder.create(
      'Zustellvorgänge',
      builder.createNodes([
        Benachrichtigungen,
        ZustellungenAvg,
        Zustellungen,
        ZustellungenNachbarQuote,
        FotoUnterschriftenNutzungsquote,
        BenachrichtigungenQuote,
        Dauervollmachtquote,
        BenachrichtigungenArt,
        ZustellungenArt,
      ])
    ),
    builder.create(
      'Abholvorgänge',
      builder.createNodes([
        AtgAbholungenAvg,
        AbholungenAvg,
        Abholungen,
        AbholungenMitnahmen,
        RuecklaufAbholkarten,
        RuecklaufAbholkartenGrund,
        Abholauftraege,
        AbholauftraegeSuccessful,
        AtgAbholungen,
      ])
    ),
    builder.create(
      'Paketshop',
      builder.createNodes([PsAbgabenAvg, PsMitnahmenAvg, PsMitnahmen, PsAbgaben, PsMitnahmenNachGrund])
    ),
    builder.create(
      'Laufzeiten und Bestände',
      builder.createNodes([
        Liegenbleiber,
        LiegenbleiberEil,
        Lagernd,
        Afnus,
        Bestand,
        Zugangsmenge,
        LiegenbleiberQuote,
        LiegenbleiberEilQuote,
        LagerndQuote,
        EPlus0Quote,
        EPlus0EilQuote,
        ZsbPlus0Quote,
        ZsbPlus0EilQuote,
      ])
    ),
    builder.create(
      'Produkte & Services',
      builder.createNodes([
        WertsendungenTour,
        BulkyTourSb,
        BulkyTourSbZugestellt,
        SendungenTanTour,
        SendungenTanZustellungen,
        SendungenTanRuecklaeufer,
      ])
    ),
    builder.create(
      'Leistung',
      builder.createNodes([
        HtProduktivitaet,
        PsProduktivitaet,
        AtgProduktivitaet,
        LademengeIstAvg,
        LademengeSollAvg,
        AbwicklungsmengeZsb,
        Abwicklungsmenge,
      ])
    ),
    builder.create(
      'Servicebeanstandungen',
      builder.createNodes([
        ServicebeanstandungenQuoteZsb,
        ServicebeanstandungenQuoteVerlustrelevant,
        ServicebeanstandungenMeldung,
        ServicebeanstandungenMeldungVerlustrelevant,
        ServicebeanstandungenGrund,
      ])
    ),
    builder.create(
      'Rückläufer',
      builder.createNodes([
        RuecklaufQuote,
        RuecklauferAvg,
        Ruecklaufer,
        RuecklauferEil,
        RuecklaeuferGrund,
        RuecklauferEilGrund,
      ])
    ),
  ];

  tree.push(
    builder.create(
      'Abrechnung',
      builder.createNodes([
        StandardleistungenTour,
        BulkyTour,
        IdentServiceTour,
        NachnahmeTour,
        GepaeckAbholungTour,
        ZeitfensterTour,
        ZusMitnahmeTour,
        QuittungslosTour,
        CoincidentTour,
        PsStoppsTour,
        PsSendungenTour,
        AtgStoppsTour,
        AtgSendungenTour,
        EilSendungenTour,
        AltgeraeteMitnahme,
      ])
    )
  );

  return tree;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const sortTree = <T extends { data: { toString: () => string }; children: any[] }>(tree: T[]): T[] => {
  const sorted = sortBy(tree, (t) => {
    if (!t) return null;
    return t.data.toString();
  });
  sorted.forEach((t) => {
    if (!t?.children) return t;
    t.children = sortTree(t.children);
  });
  return sorted;
};

export const getKpiTree = (
  authService: AuthService,
  ves: Map<string, ValueExpression>,
  orgKey: string
): TreeMenuNode<NodeData>[] => {
  const builder = new KpiMenuBuilder(ves);

  let tree = buildKpiTree(builder);

  if (ContractorUtils.isContractor(orgKey)) {
    const laufzeitenUndBestaendeNode = tree.find((t) => t.label === 'Laufzeiten und Bestände');
    laufzeitenUndBestaendeNode.children = laufzeitenUndBestaendeNode.children.filter((child) =>
      [EPlus0Quote.identifier, EPlus0EilQuote.identifier].includes(child.data.getIdentifier())
    );
    tree = tree.filter((t) => t.label !== 'Servicebeanstandungen');
  }

  if (authService.can('shipment-based-kpis')) {
    tree.push(sendungsbasiert(builder));
  }
  if (authService.can('kpi.show-development')) {
    tree.push(development(builder));
  }
  tree.push(anomaliesWithPrototypes(builder));

  return sortTree(tree);
};
