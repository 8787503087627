import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';

import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

export default new ValueExpression({
  identifier: 'tour_loaded.deliveryitems.services.nachnahme.count',
  label: 'Sendungen mit Nachnahme',
  chart: ChartType.bar,
  positiveDirection: PositiveDirection.custom,
  category: 'delivery-items',
  description:
    // eslint-disable-next-line max-len
    '<p>Anzahl aller auf Tour geladenen Sendungen mit Nachnahmezustellung gezählt nach dem ersten abgeschlossenen Zustellvorgang</p>\n',
});
