import { AbstractLimit } from '../../../modules/dashboard/types/AbstractLimit';
import { getDirectionFromGoal } from '../../../modules/dashboard/utils/DirectionUtils';
import { PositiveDirection } from '../../../modules/metrics2/models/entities/PositiveDirection';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

export const compareKpiValue = (
  valueExpression: ValueExpression,
  diffValue: number,
  zielwert?: AbstractLimit
): 'positive' | 'negative' => {
  const direction = getDirectionFromGoal(valueExpression, zielwert);
  if (!direction) {
    return;
  }
  if (diffValue > 0) {
    return direction === PositiveDirection.up ? 'positive' : 'negative';
  } else if (diffValue < 0) {
    return direction === PositiveDirection.down ? 'positive' : 'negative';
  }
};
