import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';

import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

export default new ValueExpression({
  identifier: 'stock.incoming',
  label: 'Zugangsmenge',
  positiveDirection: PositiveDirection.custom,
  chart: ChartType.bar,
  category: 'stock',
  description: '<p>Description for stock.incoming - tbd</p>\n',
  definition: getDefinitionByName('Zugangsmenge'),
});
