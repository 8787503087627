import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

export default new ValueExpression({
  identifier: 'zustellzeit_ht_netto_avg',
  label: 'Ø Netto-HT-Zeit',
  definition: getDefinitionByName('Netto-HT-Zeit'),
  positiveDirection: PositiveDirection.custom,
  valueFormat: '-#.###.##0,0 h',
  valueQuotient: 3600,
});
