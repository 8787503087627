import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { MdFilterList } from 'react-icons/md';
import { DataTableColumnFilterComponentProps, withDataTableColumnFilter } from './with-data-table-column-filter.hoc';

import style from './data-table-column-filter.module.scss';

export const DataTableColumnFilter = ({
  showFilter,
  filterProps,
  filtered,
  toggleFilter,
  children,
}: DataTableColumnFilterComponentProps): ReactElement => {
  return (
    <>
      {filterProps.renderLayer(
        showFilter && (
          <motion.section
            {...filterProps.layerProps}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{ ...filterProps.layerProps.style, zIndex: 10 }}>
            {children}
          </motion.section>
        )
      )}
      <div
        data-testid='data-table-column-filter-toggle'
        data-filtered={filtered || showFilter}
        {...filterProps.triggerProps}
        className={classNames(style.filterButton, {
          [style.filtered]: filtered || showFilter,
        })}
        onClick={toggleFilter}>
        <MdFilterList />
      </div>
    </>
  );
};
export default withDataTableColumnFilter(DataTableColumnFilter);
