import React, { useEffect, useRef, useState } from 'react';
import WebsocketApiService from '../../common/services/WebsocketApiService';
import AuthService from '../../common/services/AuthService';
import DeepLinkService from '../../routing/services/DeepLinkService';
import DataConnectionsService from '../../dataconnection/services/DataConnectionsService';
import RequestScheduler from '../../datamanager/services/RequestScheduler';
import MetricsDataProvider from '../../metrics2/services/MetricsDataProvider';
// import ValueExpressionDataProvider from '../../valueexpressions/services/ValueExpressionDataProvider';
import TrackingService from '../../tracking/services/TrackingService';
import AlertsService from '../services/AlertsService';
import RequireAuthentication from '../components/RequireAuthentication';
import { ErrorBoundary } from 'react-error-boundary';
import { AppErrorPage } from '../components/AppErrorPage';
import { UrlRestorer } from './UrlRestorer';
import { AppMainPage } from './AppMainPage';
import { ApiContextProvider } from '../../../refactoring/common/contexts/api-context';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { AuthContextProvider } from '@contexts/auth-context';
import { ValueExpressionContextProvider } from '@contexts/value-expression-context';
import AlertsContextProvider from '@contexts/alerts-context/alerts.context';
import { DeepLinkContextProvider } from '@contexts/deep-link-context';
import { OrgTreeContextProvider } from '@contexts/org-tree-context';
import { useComponentWillMount } from '@hooks/use-component-will-mount-hook';
import { MetricsDataContextProvider } from '@contexts/metrics-data-context';
import { DataConnectionContextProvider } from '@contexts/data-connection-context';

const ONE_MINUTE = 1000 * 60;
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 10 * ONE_MINUTE,
      cacheTime: 10 * ONE_MINUTE,
      refetchOnWindowFocus: false,
    },
  },
});

const AppRoot: React.FC = () => {
  const websocketApiService = useRef<WebsocketApiService>(null);
  const trackingService = useRef<TrackingService>(null);
  const authService = useRef<AuthService>(null);
  const deepLinkService = useRef<DeepLinkService>(null);
  const metricsDataProvider = useRef<MetricsDataProvider>(null);
  const sparseMetricsDataProvider = useRef<MetricsDataProvider>(null);
  const requestScheduler = useRef<RequestScheduler>(null);
  const dataConnectionsService = useRef<DataConnectionsService>(null);
  const alertsService = useRef<AlertsService>(null);

  const [initializing, setInitializing] = useState(true);

  useEffect(() => {
    return () => {
      requestScheduler.current.destruct();
      deepLinkService.current.destruct();
      metricsDataProvider.current.destruct();
      alertsService.current.destruct();
    };
  }, []);

  const setup = async () => {
    websocketApiService.current = new WebsocketApiService();
    trackingService.current = new TrackingService(websocketApiService.current);
    authService.current = new AuthService(websocketApiService.current, trackingService.current);
    requestScheduler.current = new RequestScheduler(websocketApiService.current, authService.current);
    metricsDataProvider.current = new MetricsDataProvider(requestScheduler.current);
    dataConnectionsService.current = new DataConnectionsService();
    sparseMetricsDataProvider.current = new MetricsDataProvider(requestScheduler.current);
    sparseMetricsDataProvider.current._returnEmptyValues = false;
    deepLinkService.current = new DeepLinkService(
      authService.current,
      websocketApiService.current,
      trackingService.current
    );
    alertsService.current = new AlertsService(requestScheduler.current, authService.current);
    requestScheduler.current.init();
    const authServicePromise = authService.current.init();
    metricsDataProvider.current.init();
    const deepLinkPromise = deepLinkService.current.init();

    await Promise.all([deepLinkPromise, authServicePromise]);
  };

  useComponentWillMount(async () => {
    try {
      await setup();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error on initializing', e);
    } finally {
      setInitializing(false);
    }
  });

  if (initializing) {
    return <span>Init</span>;
  }

  return (
    <ErrorBoundary
      FallbackComponent={AppErrorPage}
      onReset={() => {
        window.location.href = '/';
      }}>
      <QueryClientProvider client={queryClient}>
        <AuthContextProvider authService={authService.current}>
          <ApiContextProvider websocketApiService={websocketApiService.current}>
            <AlertsContextProvider alertsService={alertsService.current}>
              <RequireAuthentication>
                <MetricsDataContextProvider metricsDataProvider={metricsDataProvider.current}>
                  <DataConnectionContextProvider dataConnectionsService={dataConnectionsService.current}>
                    <DeepLinkContextProvider deepLinkService={deepLinkService.current}>
                      <UrlRestorer>
                        <OrgTreeContextProvider>
                          <ValueExpressionContextProvider>
                            <ReactQueryDevtools />
                            <AppMainPage />
                          </ValueExpressionContextProvider>
                        </OrgTreeContextProvider>
                      </UrlRestorer>
                    </DeepLinkContextProvider>
                  </DataConnectionContextProvider>
                </MetricsDataContextProvider>
              </RequireAuthentication>
            </AlertsContextProvider>
          </ApiContextProvider>
        </AuthContextProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
};

export default AppRoot;
