import { undeliverableReasonsShipmentBased } from '@legacy-modules/metrics2/models/entities/MetricType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';

import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import { UndeliverableReasons } from '@legacy-modules/metrics2/models/enumerations/UndeliverableReason';

const label = 'Rückläufer Eil nach Grund';
const key = 'lma-shipmentbased.RL.express.count.by_undeliverable_reason';

const getConfig = (category) => {
  return {
    identifier: `${key}.${category.key}`,
    label: category.label,
    shortLabel: `${category.shortLabel} Eil`,
    category: 'delivery-items',
    positiveDirection: PositiveDirection.down,
    color: category.color,
  };
};

const children = undeliverableReasonsShipmentBased.map((reason) => {
  const label = UndeliverableReasons[reason].label;
  const shortLabel = UndeliverableReasons[reason].shortLabel;
  const color = UndeliverableReasons[reason].color;
  return new ValueExpression(getConfig({ key: reason, label: label, shortLabel: shortLabel ?? label, color: color }));
});

export default new ValueExpression({
  ...getConfig({ key: 'sum', label: '' }),
  label: label,
  shortLabel: label,
  children: children,
});
