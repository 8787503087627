import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';

import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';

export default new ValueExpression({
  identifier: 'lma-shipmentbased.TA.relevant_tan.count.total',
  label: 'TAN Sendungen auf Tour',
  chart: ChartType.bar,
  positiveDirection: PositiveDirection.custom,
  definition: getDefinitionByName('TAN Sendungen auf Tour'),
});
