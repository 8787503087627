import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';

import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

const label = 'Zustellvorgänge nach Empfängeranzahl';
const key = 'finished_deliveries.by_customer_count';

const getConfig = (category) => {
  return {
    identifier: `${key}.${category}`,
    longLabel: `${label} (${category})`,
    label: category,
    chart: ChartType.bar,
    positiveDirection: PositiveDirection.custom,
    category: 'deliveries',
    description: '<p>Anzahl aller abgeschlossenen Zustellvorgänge nach Anzahl Empfänger pro Zustellvorgang</p>\n',
    hidden: true,
  };
};

const categories = ['1', '2', '3', '4', '5-10', '>10'];
const children = categories.map((category) => new ValueExpression(getConfig(category)));

export default new ValueExpression({
  ...getConfig('sum'),
  label: label,
  longLabel: label,
  chart: ChartType.line,
  hidden: false,
  children: children,
});
