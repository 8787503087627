import classNames from 'classnames';
import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { FilterControls } from '../filter-controls';
import { ColumnFilterContentProps } from '@data-table/data-table.types';
import { getDataTableColumnFilterTitle, getSortedMultiselectValues } from '@data-table/utils';
import { InputCheckbox } from '@legacy-modules/dashboard/components/InputCheckbox';
import InputWrapper from '@components/input-wrapper/input-wrapper';

import style from './multi-select-filter-content.module.scss';

type MultiSelectFilterContentProps<T, V> = ColumnFilterContentProps<T, V> & {
  filterValue: string[];
  allValues: string[];
  inactiveValues?: string[];
  headerControls?: React.ReactNode;
};

const MultiSelectFilterContent = <T, V>({
  filterValue,
  allValues,
  inactiveValues = [],
  headerControls,
  column,
  onCancel,
  onConfirm,
}: MultiSelectFilterContentProps<T, V>) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedValues, setSelectedValues] = useState<Set<string>>(new Set<string>(filterValue ?? []));

  const valuesMap = allValues.reduce((acc, value) => {
    if (acc.has(value)) {
      acc.set(value, acc.get(value) + 1);
    } else {
      acc.set(value, 1);
    }
    return acc;
  }, new Map<string, number>());

  const activeValues = useMemo(() => [...valuesMap.keys()], [valuesMap]);
  const values = getSortedMultiselectValues(activeValues, inactiveValues);

  const toggleAll = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSelectedValues(new Set(event.target.checked ? activeValues : []));
    },
    [activeValues]
  );

  const toggleOne = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    event.persist();
    setSelectedValues((currentValues) => {
      const nextValues = new Set(currentValues);
      if (event.target.checked) {
        nextValues.add(event.target.value);
      } else {
        nextValues.delete(event.target.value);
      }
      return new Set(nextValues);
    });
  }, []);

  const onFilterChange = useCallback((e) => {
    const currentSearchValue = e.target.value;
    setSearchValue(currentSearchValue.toLowerCase());
  }, []);

  const onConfirmCallback = useCallback(() => {
    const listOfSelectedValuesSet = Array.from(selectedValues);
    onConfirm(listOfSelectedValuesSet.length ? listOfSelectedValuesSet : undefined);
  }, [onConfirm, selectedValues]);

  const filteredValues = searchValue ? values.filter((value) => value.toLowerCase().includes(searchValue)) : values;
  const allSelected = selectedValues.size === activeValues.length && activeValues.every((v) => selectedValues.has(v));
  const indeterminate = values.some((v) => selectedValues.has(v));
  const title = getDataTableColumnFilterTitle(column.id);
  const showCount = column.id !== 'orgName';
  const isValid = filteredValues?.length > 0;

  return (
    <section className={classNames(style.filterContent)}>
      <FilterControls inputValid={isValid} cancelCallback={onCancel} confirmCallback={onConfirmCallback}>
        <div
          data-testid='data-table-filter-title'
          className={classNames(style.filterContentHeadline, style.multiselectFilterContentHeadline)}>
          {title}
        </div>
        {headerControls}
        <InputWrapper
          className={style.multiselectFilterContentSearch}
          inputProps={{
            placeholder: 'Auswahl durchsuchen...',
            valid: isValid,
            onChange: onFilterChange,
          }}
        />
        <div className={style.multiSelectFilterContentContainer}>
          <InputCheckbox
            data-testid='data-table-filter-checkbox'
            containerClassName={style.checkbox}
            checked={allSelected}
            indeterminate={indeterminate}
            onChange={toggleAll}>
            (Alles auswählen)
          </InputCheckbox>
          {filteredValues.map((value) => (
            <InputCheckbox
              data-testid='data-table-filter-checkbox'
              data-value={value}
              containerClassName={style.checkbox}
              style={{
                color: valuesMap.get(value) > 0 ? 'var(--black1)' : 'var(--gray23)',
              }}
              key={value}
              value={value}
              disabled={inactiveValues?.includes(value)}
              checked={selectedValues.has(value)}
              onChange={toggleOne}>
              {showCount ? `${value} (${valuesMap.get(value) || 0})` : value}
            </InputCheckbox>
          ))}
        </div>
      </FilterControls>
    </section>
  );
};

export default MultiSelectFilterContent;
