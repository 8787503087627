import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';

import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

export default new ValueExpression({
  identifier: 'tour_loaded.deliveryitems.services.nachnahme.sum',
  label: 'Nachnahmebetrag',
  chart: ChartType.line,
  positiveDirection: PositiveDirection.custom,
  valueFormat: '#.###.##0, Cent',
  category: 'delivery-items',
  description:
    '<p>Summe der Nachnahmebeträge aller auf Tour geladenen Sendungen mit Nachnahmezustellung gezählt nach ' +
    'dem ersten abgeschlossenen Zustellvorgang</p>\n',
});
