import ValueExpression, {
  TargetValueType,
} from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';

export default new ValueExpression({
  identifier: 'abholungen_mitnahmen',
  positiveDirection: PositiveDirection.custom,
  label: 'Abholungen',
  valueFormat: '-#.###.##0',
  description: 'Abholungen und Mitnahmen',
  definition: getDefinitionByName('Abholungen'),
  targetValueType: TargetValueType.SUM,
});
