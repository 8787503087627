import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import ValueExpression, {
  TargetValueType,
} from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';

export default new ValueExpression({
  identifier: 'ps_mitnahmen_avg',
  label: 'Ø PS Mitnahmen',
  valueFormat: '-#.###.##0,0',
  positiveDirection: PositiveDirection.custom,
  definition: getDefinitionByName('PS Mitnahmen'),
  targetValueType: TargetValueType.QUOTE,
  decimalPlaces: 2,
});
