import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';

import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

export default new ValueExpression({
  identifier: 'ps_abgaben',
  positiveDirection: PositiveDirection.custom,
  label: 'PS Abgaben',
  definition: getDefinitionByName('PS Abgaben'),
});
