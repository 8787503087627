import React, { useMemo } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import Loading from '../../assets/loading_chart.svg';
import ValueExpression from '../../../valueexpressions/models/valueexpressions/ValueExpression';
import { getBarChartData } from './PrimaryTimelineChart';
import { Duration, DurationModes } from '@legacy-modules/dashboard/models/enums/Duration';
import { DateRangeGrouping } from '@legacy-modules/metrics2/models/enumerations/DateRangeGrouping';
import { useSelector } from 'react-redux';
import { useMultiOrgTree } from '@hooks/use-multi-org-tree-hook';
import { Chart } from '@components/chart';
import {
  selectCompareFilter,
  selectCompareText,
  selectPrimaryFilter,
  selectPrimaryText,
  selectOverviewWeekdayFilter,
} from '@redux/overview.selectors';
import { useOverviewContext } from '@contexts/overview-context';
import useMetricQuery from '@hooks/use-metric-query-hook/use-metric-query.hook';
import { useKpiQuery } from '@hooks/use-kpi-query-hook';
import { DurationUtils } from '@utils/duration-utils';

const chartOptions: Bar['props']['options'] | Line['props']['options'] = {
  animation: {
    duration: 0,
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          display: false,
        },
        ticks: {
          beginAtZero: true,
          display: false,
        },
      },
    ],
  },
  legend: {
    display: false,
  },
};

type Props = {
  valueExpression?: ValueExpression;
  onClick: (arg0: ValueExpression) => void;
  className: string | null | undefined;
  tooltips: boolean;
};

const thresholdDataBarsCount = 30;
const thresholdAxisBarsCount = 50;

const emptyFn = () => {
  /* ignore */
};

const TinyTimelineChart: React.FC<Props> = ({
  valueExpression,
  className = '',
  onClick = emptyFn,
  tooltips,
}: Props) => {
  const primaryFilter = useSelector(selectPrimaryFilter);
  const compareFilter = useSelector(selectCompareFilter);
  const weekdayFilter = useSelector(selectOverviewWeekdayFilter);
  const primaryText = useSelector(selectPrimaryText);
  const compareText = useSelector(selectCompareText);
  const { hoverOrgKeyState } = useOverviewContext();
  const [hoverOrgKey] = hoverOrgKeyState;
  const orgKeys = useMemo(() => (hoverOrgKey ? [hoverOrgKey] : primaryFilter.orgKeys), [hoverOrgKey, primaryFilter]);
  const { data: orgData } = useMultiOrgTree({
    orgKeys,
    parts: ['properties'],
    from: primaryFilter.from,
    to: primaryFilter.to,
  });
  const primaryDuration = useMemo(
    () =>
      ({
        from: primaryFilter.from,
        to: primaryFilter.to,
        weekdayFilter,
        mode: DurationModes.Custom,
      } as Duration),
    [primaryFilter, weekdayFilter]
  );

  const primaryDateRange = DurationUtils.getDateRange(primaryDuration);
  const compareDateRange = DurationUtils.getComparisonDateRange(primaryDuration);

  const format = valueExpression?.getValueFormatter();

  const { data: primarySum } = useKpiQuery(
    {
      orgKeys: orgKeys,
      expressionsFilter: [{ valueExpression: valueExpression }],
      dateRange: DurationUtils.getDateRange(primaryDuration),
    },
    {
      enabled: orgKeys.length > 0 && !!valueExpression && !!primaryDateRange.from && !!primaryDateRange.to,
    },
    (data) => format?.(data.kpis.summary.kpiValues[0]?.value)
  );

  const { data: compareSum } = useKpiQuery(
    {
      orgKeys: orgKeys,
      expressionsFilter: [{ valueExpression: valueExpression }],
      dateRange: DurationUtils.getComparisonDateRange(primaryDuration),
    },
    {
      enabled:
        orgKeys.length > 0 && !!valueExpression && !!compareDateRange.from && !!compareDateRange.to && !!compareFilter,
    },
    (data) => format?.(data.kpis.summary.kpiValues[0]?.value)
  );

  const { isLoading: primaryDataLoading, getByOrgKey: getPrimaryDataByGroupingKey } = useMetricQuery({
    orgKeys: orgKeys,
    valueExpression: valueExpression,
    duration: primaryDuration,
    legacyDateRangeGrouping: DateRangeGrouping.day,
  });
  const { isLoading: compareDataLoading, getByOrgKey: getCompareDataByGroupingKey } = useMetricQuery({
    orgKeys: orgKeys,
    valueExpression: valueExpression,
    duration: primaryDuration,
    legacyDateRangeGrouping: DateRangeGrouping.day,
    isComparison: true,
    enabled: !!compareFilter,
  });

  const chartData = useMemo(() => {
    const orgUnits = hoverOrgKey ? [orgData?.get(hoverOrgKey)] : primaryFilter.orgKeys.map((key) => orgData?.get(key));
    return getBarChartData(
      primaryFilter,
      compareFilter,
      thresholdDataBarsCount,
      thresholdAxisBarsCount,
      orgUnits,
      primaryText,
      compareText,
      getPrimaryDataByGroupingKey,
      getCompareDataByGroupingKey
    );
  }, [
    hoverOrgKey,
    compareFilter,
    primaryFilter,
    compareText,
    primaryText,
    orgData,
    getPrimaryDataByGroupingKey,
    getCompareDataByGroupingKey,
  ]);

  const options = useMemo(() => {
    return { ...chartOptions, tooltips: { enabled: tooltips } };
  }, [tooltips]);

  if (!valueExpression || !chartData || primaryDataLoading || compareDataLoading) {
    return <ChartLoader className={className} />;
  }
  return (
    <div className={`tinyTimelineChart ${className}`} onClick={() => onClick(valueExpression)}>
      <Chart data={chartData} chartType={valueExpression?.chartType} options={options} />
      <div className='tiny-value-label'>{valueExpression?.getLongLabel()}</div>
      <div className='tiny-value-value'>
        {primarySum}
        {!!compareSum && ' (' + compareSum + ')'}
      </div>
    </div>
  );
};

export default TinyTimelineChart;

const ChartLoader: React.FC<Pick<Props, 'className'>> = ({ className }) => (
  <div className={`tinyTimelineChart loading ${className}`}>
    <div className='loading-image'>
      <img src={Loading} className='loading-chart' alt='loading' />
    </div>
    <div className='textLoading'>Daten werden geladen...</div>
  </div>
);
