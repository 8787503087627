import { useOverviewContext } from '@contexts/overview-context';
import MetricsEntityKey from '@legacy-modules/metrics2/models/entities/MetricsEntityKey';
import { DateRangeGrouping } from '@legacy-modules/metrics2/models/enumerations/DateRangeGrouping';
import OrgUnitMultiResponsePayload from '@legacy-modules/metrics2/models/websocket/org/OrgUnitMultiResponsePayload';
import { OrgUnitResult } from '@legacy-modules/metrics2/models/websocket/org/OrgUnitResult';
import {
  selectCompareFilter,
  selectCompareText,
  selectOverviewValueExpression,
  selectOverviewWeekdayFilter,
  selectPrimaryFilter,
  selectPrimaryText,
  selectTokenId,
} from '@redux';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as XLSX from 'xlsx';
import * as fs from 'fs';
import { useKpiQuery } from '@hooks/use-kpi-query-hook';
import { ConfigKey, getEnvVariable } from '@legacy-modules/common/services/EnvironmentConfigurationService';

XLSX.set_fs(fs);

export type ExportType = 'time' | 'organization';
export type ExportFormat = 'xlsx' | 'csv';
type UseOverviewExportOutput = (type: ExportType, format: ExportFormat) => Promise<void>;

export default function useOverviewExport(rootOrgUnits: OrgUnitResult[]): UseOverviewExportOutput {
  const primaryFilter = useSelector(selectPrimaryFilter);
  const compareFilter = useSelector(selectCompareFilter);
  const { selectedTreeTypesState } = useOverviewContext();
  const valueExpression = useSelector(selectOverviewValueExpression);
  const weekdayFilter = useSelector(selectOverviewWeekdayFilter);
  const primaryText = useSelector(selectPrimaryText);
  const compareText = useSelector(selectCompareText);
  const [orgUnits, setOrgUnits] = useState<OrgUnitResult[]>([]);
  const [exportFormat, setExportFormat] = useState<ExportFormat | undefined>(undefined);
  const [grouping, setGrouping] = useState<DateRangeGrouping | undefined>(undefined);
  const tokenId = useSelector(selectTokenId);

  const { data: primaryResponse, isSuccess: isPrimarySuccess } = useKpiQuery(
    {
      expressionsFilter: [valueExpression].map((ve) => {
        return { valueExpression: ve };
      }),
      orgKeys: orgUnits.map((unit) => unit.orgKey),
      dateRange: {
        from: primaryFilter.from,
        to: primaryFilter.to,
      },
      grouping: grouping,
      weekdaysFilter: weekdayFilter,
    },
    {
      enabled: !!grouping && orgUnits.length > 0 && !!valueExpression && !!primaryFilter.from && !!primaryFilter.to,
    },
    (data) => {
      const values: [MetricsEntityKey, number][][] = data.kpis.groups.map((group) => {
        return group.kpiValues.map((kpiValue) => [
          new MetricsEntityKey(
            kpiValue.kpiId,
            group.orgKey,
            group.dateRange.from,
            group.dateRange.until,
            grouping,
            weekdayFilter
          ),
          kpiValue.value,
        ]);
      });
      return new Map(values.flat());
    }
  );

  const { data: compareResponse, isLoading: isCompareLoading } = useKpiQuery(
    {
      expressionsFilter: [valueExpression].map((ve) => {
        return { valueExpression: ve };
      }),
      orgKeys: orgUnits.map((unit) => unit.orgKey),
      dateRange: {
        from: compareFilter?.from,
        to: compareFilter?.to,
      },
      grouping: grouping,
      weekdaysFilter: weekdayFilter,
    },
    {
      enabled: !!grouping && orgUnits.length > 0 && !!valueExpression && !!compareFilter?.from && !!compareFilter?.to,
    },
    (data) => {
      const values: [MetricsEntityKey, number][][] = data.kpis.groups.map((group) => {
        return group.kpiValues.map((kpiValue) => [
          new MetricsEntityKey(
            kpiValue.kpiId,
            group.group,
            group.dateRange.from,
            group.dateRange.until,
            grouping,
            weekdayFilter
          ),
          kpiValue.value,
        ]);
      });
      return new Map(values.flat());
    }
  );

  const makeDokument = useCallback(
    (
      primaryData: Map<MetricsEntityKey, number>,
      compareData: Map<MetricsEntityKey, number>,
      orgData: OrgUnitResult[],
      format: ExportFormat
    ) => {
      const primarySheetData = Array.from(primaryData.entries())
        .sort(([keyA], [keyB]) => {
          if (keyA.dateFrom.isSame(keyB.dateFrom)) {
            return 0;
          }
          return keyA.dateFrom.isBefore(keyB.dateFrom) ? -1 : 1;
        })
        .map(([key, value]) => {
          if (compareData?.size > 0) {
            return {
              label: valueExpression.getLabel(),
              group:
                format === 'xlsx'
                  ? `Primär (${primaryText})`
                  : `Primär (${key.dateFrom.format('DD.MMM')} - ${key.dateUntil.format('DD.MMM')})`,
              organization: orgData.find((org) => org.orgKey === key.orgKey)?.name,
              date:
                format === 'xlsx'
                  ? key.dateFrom.format('DD.MM.YYYY')
                  : `${key.dateFrom.format('DD.MM.YYYY')} - ${key.dateUntil.format('DD.MM.YYYY')}`,
              value: valueExpression.getValueFormatter()(value),
            };
          } else {
            return {
              label: valueExpression.getLabel(),
              organization: orgData.find((org) => org.orgKey === key.orgKey)?.name,
              date:
                format === 'xlsx'
                  ? key.dateFrom.format('DD.MM.YYYY')
                  : `${key.dateFrom.format('DD.MM.YYYY')} - ${key.dateUntil.format('DD.MM.YYYY')}`,
              value: valueExpression.getValueFormatter()(value),
            };
          }
        });
      const compareSheetData =
        compareData?.size > 0
          ? Array.from(compareData.entries())
              .sort(([keyA], [keyB]) => {
                if (keyA.dateFrom.isSame(keyB.dateFrom)) {
                  return 0;
                }
                return keyA.dateFrom.isBefore(keyB.dateFrom) ? -1 : 1;
              })
              .map(([key, value]) => ({
                label: valueExpression.getLabel(),
                group:
                  format === 'xlsx'
                    ? `Vergleich (${compareText})`
                    : `Vergleich (${key.dateFrom.format('DD.MMM')} - ${key.dateUntil.format('DD.MMM')})`,
                organization: orgData.find((org) => org.orgKey === key.orgKey)?.name,
                date:
                  format === 'xlsx'
                    ? key.dateFrom.format('DD.MM.YYYY')
                    : `${key.dateFrom.format('DD.MM.YYYY')} - ${key.dateUntil.format('DD.MM.YYYY')}`,
                value: valueExpression.getValueFormatter()(value),
              }))
          : [];
      const worksheet =
        format === 'xlsx'
          ? XLSX.utils.aoa_to_sheet([
              ['Last Mile Analytics Export'],
              ['Zeitraum:', primaryText],
              ['Zeitraum:', compareText],
              ['Organisation:', rootOrgUnits?.map((orgUnit) => orgUnit?.name).join(', ') ?? ''],
              [],
              compareSheetData?.length > 0
                ? ['Anzeigewert', 'Primär / Vergleich', 'Organisation', 'Datum', 'Wert']
                : ['Anzeigewert', 'Organisation', 'Datum', 'Wert'],
              ...[...primarySheetData, ...compareSheetData].map((data) =>
                Object.values(data).map((value) => value ?? '-')
              ),
            ])
          : XLSX.utils.aoa_to_sheet([
              compareSheetData?.length > 0
                ? ['Anzeigewert', 'Primär / Vergleich', 'Organisation', 'Datum', 'Wert']
                : ['Anzeigewert', 'Organisation', 'Datum', 'Wert'],
              ...[...primarySheetData, ...compareSheetData].map((data) =>
                Object.values(data).map((value) => value ?? '-')
              ),
            ]);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Last Mile Analytics');
      XLSX.writeFile(workbook, `export.` + format);

      setOrgUnits([]);
    },
    [valueExpression, primaryText, compareText, rootOrgUnits]
  );

  useEffect(() => {
    if (orgUnits?.length > 0 && isPrimarySuccess && primaryResponse.size > 0 && !isCompareLoading) {
      makeDokument(primaryResponse, compareResponse, orgUnits, exportFormat);
    }
  }, [isPrimarySuccess, primaryResponse, compareResponse, isCompareLoading, orgUnits, exportFormat, makeDokument]);

  const fetchOrgUnits = useCallback(
    async (orgKeys: string[]): Promise<OrgUnitMultiResponsePayload> => {
      try {
        const url = new URL(`${getEnvVariable(ConfigKey.HTTP_API_URL)}/orgtree/select`);
        url.searchParams.append('orgKeys', orgKeys.join(','));
        url.searchParams.append('parts', 'properties');
        url.searchParams.append('orgType', selectedTreeTypesState?.[rootOrgUnits[0].orgType]);
        url.searchParams.append('from', primaryFilter.from.format('YYYYMMDD'));
        url.searchParams.append('to', primaryFilter.to.format('YYYYMMDD'));

        const response = await fetch(url, {
          headers: {
            token: tokenId,
          },
        });
        return response.json();
      } catch (e) {
        throw new Error('Couldnt load org units');
      }
    },
    [rootOrgUnits, selectedTreeTypesState, primaryFilter.from, primaryFilter.to, tokenId]
  );

  const fetchOrgUnitsForOrganizationExport = useCallback(
    async (rootOrgKeys: string[]): Promise<OrgUnitMultiResponsePayload> => {
      const orgKeys = rootOrgUnits
        ?.flatMap((org) => org.children)
        ?.concat(rootOrgKeys)
        .filter((key) => !key.startsWith('oh'));
      return fetchOrgUnits(orgKeys);
    },
    [fetchOrgUnits, rootOrgUnits]
  );

  const fetchOrgUnitsForTimeExport = useCallback(
    async (rootOrgKeys: string[]): Promise<OrgUnitMultiResponsePayload> => {
      return fetchOrgUnits(rootOrgKeys);
    },
    [fetchOrgUnits]
  );

  const exportData = useCallback(
    async (type: ExportType, format: ExportFormat) => {
      if (!rootOrgUnits || rootOrgUnits?.length === 0) {
        return;
      }
      setExportFormat(format);
      const rootOrgKeys = rootOrgUnits.map((org) => org.orgKey) ?? [];
      if (rootOrgUnits?.length === 1 && type === 'organization') {
        setGrouping(DateRangeGrouping.none);
        const orgUnits = await fetchOrgUnitsForOrganizationExport(rootOrgKeys);
        setOrgUnits(orgUnits.units);
      } else {
        setGrouping(DateRangeGrouping.day);
        const orgUnits = await fetchOrgUnitsForTimeExport(rootOrgKeys);
        setOrgUnits(orgUnits.units);
      }
    },
    [rootOrgUnits, fetchOrgUnitsForOrganizationExport, fetchOrgUnitsForTimeExport]
  );

  return exportData;
}
