import React, { ReactElement, useCallback } from 'react';

import { ColumnFilterContentProps } from '@data-table/data-table.types';
import { getHinweisArts } from '@data-table/utils';
import { MultiSelectFilterContent } from '../multi-select-filter-content';

export const OrgNameFilterContent = <T, V>({
  table,
  column,
  onConfirm,
  onCancel,
}: ColumnFilterContentProps<T, V>): ReactElement => {
  const rows = column.getFacetedRowModel().rows;
  const allValues = rows.map((row) => row.getValue<string>(column.id));

  // For orgKey filtering, we need actual org keys as filter values.
  // The child component expects the column id, so transform the filter before passing it down.
  const currentFilterValue = column.getFilterValue() as string[];
  const filterValue = currentFilterValue?.map((filter: string) =>
    rows.find((row) => row.getValue<string>('orgKey') === filter).getValue<string>(column.id)
  );

  // For orgKey filtering, we need actual org keys as filter values.
  // Map the column ids to correspondent org keys before setting the filter state in the callback.
  const onConfirmCallback = useCallback(
    (values: string[] | undefined) => {
      const mappedValues = values?.map((value) =>
        rows.find((row) => row.getValue<string>(column.id) === value).getValue<string>('orgKey')
      );
      onConfirm(mappedValues);
    },
    [column.id, onConfirm, rows]
  );

  return (
    <MultiSelectFilterContent<T, V>
      filterValue={filterValue}
      allValues={allValues}
      table={table}
      column={column}
      onConfirm={onConfirmCallback}
      onCancel={onCancel}
    />
  );
};
