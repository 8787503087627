import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

export default new ValueExpression({
  identifier: 'returns.finished_deliveries.count',
  label: 'Abholvorgänge',
  chart: ChartType.line,
  positiveDirection: PositiveDirection.custom,
  category: 'returns',
  description:
    '<p>Anzahl aller abgeschlossenen Abholvorgänge, bei der ein Abholvorgang die Bearbeitung eines oder ' +
    'mehrerer Pakete für ein oder mehrere Kunden ist</p>\n',
});
