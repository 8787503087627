import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';

import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

export default new ValueExpression({
  identifier: 'prognose_sendungsmenge_datum',
  label: 'Prognostizierte Sendungseingangsmenge Datum',
  chart: ChartType.bar,
  positiveDirection: PositiveDirection.custom,
  category: 'sat',
  description: '<p>Erstellungsdatum der Prognose für die Sendungseingangsmenge</p>\n',
  hidden: true,
});
