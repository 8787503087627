import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import ValueExpression, {
  TargetValueType,
} from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';

export default new ValueExpression({
  identifier: 'netto-atg-zeit/touren',
  label: 'Ø Netto-ATG-Zeit',
  definition: getDefinitionByName('Netto-ATG-Zeit'),
  positiveDirection: PositiveDirection.custom,
  valueFormat: '-#.###.##0,0 h',
  category: 'tours',
  chart: ChartType.line,
  valueQuotient: 3600,
  percent: false,
  targetValueType: TargetValueType.QUOTE,
  decimalPlaces: 2,
});
