import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';

import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

export default new ValueExpression({
  identifier: 'stock.inventory',
  label: 'Bestand',
  chart: ChartType.bar,
  positiveDirection: 'down',
  category: 'stock',
  description: '<p>Description for stock.inventory - tbd</p>\n',
  definition: getDefinitionByName('Bestand'),
});
