import ValueExpression, {
  TargetValueType,
} from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

export default new ValueExpression({
  identifier: 'fortschritt',
  label: 'Fortschritt',
  valueQuotient: 1 / 100,
  valueFormat: '-#.###.##0,#0%',
  hidden: true,
  percent: true,
  targetValueType: TargetValueType.QUOTE,
  decimalPlaces: 2,
});
