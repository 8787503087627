import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';

import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';

export default new ValueExpression({
  identifier: 'tan_zustellungen',
  label: 'TAN Sendungen zugestellt',
  chart: ChartType.bar,
  positiveDirection: PositiveDirection.custom,
  definition: getDefinitionByName('TAN Sendungen zugestellt'),
});
