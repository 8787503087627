import { shipmentTypeMap, shipmentTypes } from '@legacy-modules/metrics2/models/entities/MetricType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';

import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

const label = 'Lademenge nach Sendungsart';
const key = 'lma-shipmentbased.TA.relevant.count.by_shipment_type';

const getConfig = (category) => {
  return {
    identifier: `${key}.${category.key}`,
    longLabel: `${label} (${category.label})`,
    label: category.label,
    hidden: true,
    positiveDirection: PositiveDirection.custom,
  };
};

const children = shipmentTypes.map((type) => {
  return new ValueExpression(getConfig({ key: type, label: shipmentTypeMap[type] }));
});

export default new ValueExpression({
  ...getConfig({ key: 'sum', label: '' }),
  label: label,
  longLabel: label,
  children: children,
});
