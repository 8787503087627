import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import ValueExpression, {
  TargetValueType,
} from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';

export default new ValueExpression({
  identifier: 'ruecklauf_quote',
  label: 'RL-Quote',
  definition: getDefinitionByName('Rücklaufquote'),
  valueQuotient: 0.01,
  valueFormat: '-#.###.##0,#0%',
  targetValueType: TargetValueType.QUOTE,
  decimalPlaces: 2,
  percent: true,
  positiveDirection: PositiveDirection.down,
});
