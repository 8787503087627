import ValueExpression, {
  TargetValueType,
} from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';

export default new ValueExpression({
  identifier: 'zustellzeit_brutto',
  label: 'Brutto-Zustellzeit',
  valueFormat: '#.###.##0,0 h',
  valueQuotient: 3600,
  positiveDirection: PositiveDirection.custom,
  definition: getDefinitionByName('Brutto-Zustellzeit'),
  targetValueType: TargetValueType.SUM,
});
