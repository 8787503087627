import React, { useEffect } from 'react';
import { DataTableBody } from '@data-table/components/data-table-body';
import { DataTableColumnHeaders } from '@data-table/components/data-table-column-headers';
import { DataTableFooter } from '@data-table/components/data-table-footer';
import { ValueExpressionTableHeader } from '@data-table/components/data-table-header';
import {
  DataTableLoadingPlaceholder,
  DataTableNoDataPlaceholder,
} from '@data-table/components/data-table-placeholders';
import { DashboardDataTableDataType, DataTableConfigProps } from '@data-table/data-table.types';
import { useDashboardDataTableColumns } from '@data-table/hooks/use-dashboard-data-table-columns-hook';
import { useDataTableCustomInfo } from '@data-table/hooks/use-data-table-custom-info-hook';
import { useDataTableDuration } from '@data-table/hooks/use-data-table-duration-hook';
import { useDataTableOrgKey } from '@data-table/hooks/use-data-table-org-key-hook';
import { useDataTableValueExpressionsState } from '@data-table/hooks/use-data-table-value-expressions-state-hook';
import { useValueExpressionDataTableData } from '@data-table/hooks/use-value-expression-data-table-data-hook';
import { Duration } from '@legacy-modules/dashboard/models/enums/Duration';
import { useDataTableCellColoring } from '@data-table/hooks/use-data-table-cell-coloring-hook';
import { useDataTableFiltersState } from '@data-table/hooks/use-data-table-filter-state-hook';

export type DashboardDataTableConfigProps = Omit<
  DataTableConfigProps<DashboardDataTableDataType>,
  'data' | 'columns' | 'isTour'
> & {
  defaultDuration?: Duration;
  defaultValueExpressionKeys?: string[];
  availableValueExpressionKeys?: string[];
};

const withDashboardDataTableConfig =
  (WrappedComponent: React.FC<DataTableConfigProps<DashboardDataTableDataType>>) =>
  ({
    componentId,
    defaultDuration,
    defaultValueExpressionKeys,
    availableValueExpressionKeys,
    header,
    defaultSorting,
    defaultColumnFilters,
    ...rest
  }: DashboardDataTableConfigProps) => {
    const [durationIsLoading, duration, onDurationChange] = useDataTableDuration(componentId, defaultDuration);

    const [hasCellColoring] = useDataTableCellColoring(componentId);

    const [orgKey, orgTree] = useDataTableOrgKey();

    const { isTour, isToday, showFortschritt, showStandort, title } = useDataTableCustomInfo(orgKey, duration);

    const [valueExpressions, valueExpressionKeys, onColumnAdd, onColumnRemove, valueExpressionsLoading] =
      useDataTableValueExpressionsState(componentId, isToday && isTour, defaultValueExpressionKeys);

    const [columnFilters, saveColumnFilters, setColumnFilterState] = useDataTableFiltersState(
      componentId,
      defaultColumnFilters
    );

    // Remove filter state for org columns on selected org change
    useEffect(() => {
      const filteredFilterState = columnFilters.filter((entry) => entry.id !== 'orgName');
      setColumnFilterState(filteredFilterState);
    }, [orgKey]);

    const {
      data: primaryData,
      summaryValues: primarySummaryValues,
      isLoading: isPrimaryLoading,
    } = useValueExpressionDataTableData(
      orgKey,
      duration,
      orgTree,
      valueExpressions,
      false,
      !durationIsLoading && !valueExpressionsLoading,
      columnFilters
    );

    const {
      data: compareData,
      summaryValues: compareSummaryValues,
      isLoading: isCompareLoading,
    } = useValueExpressionDataTableData(
      orgKey,
      duration,
      orgTree,
      valueExpressions,
      true,
      !durationIsLoading && !valueExpressionsLoading,
      columnFilters
    );

    const columns = useDashboardDataTableColumns(
      orgKey,
      duration,
      valueExpressions,
      hasCellColoring,
      primarySummaryValues
    );

    const comparisonColumns = useDashboardDataTableColumns(
      orgKey,
      duration,
      valueExpressions,
      false,
      compareSummaryValues
    );

    const loading = durationIsLoading || valueExpressionsLoading || isPrimaryLoading || isCompareLoading;
    const dataAvailable = !!primaryData.length;

    return (
      <WrappedComponent
        componentId={componentId}
        data={primaryData}
        columns={columns}
        state={{
          columnVisibility: {
            orgKey: false,
            orgName: !isTour,
            tourIdentifier: isTour,
            standort: showStandort,
            fortschritt: showFortschritt,
          },
          columnPinning: {
            left: ['tourIdentifier', 'orgName', 'standort', 'fortschritt'],
          },
          columnFilters,
        }}
        onColumnFiltersChange={saveColumnFilters}
        header={
          header || (
            <ValueExpressionTableHeader
              title={title}
              duration={duration}
              valueExpressionKeys={valueExpressionKeys}
              availableKpiSelectorKeys={availableValueExpressionKeys}
              disabled={!dataAvailable}
              loading={loading}
              onDurationChange={onDurationChange}
              onColumnRemove={onColumnRemove}
              onColumnAdd={onColumnAdd}
            />
          )
        }
        body={
          <>
            {loading && <DataTableLoadingPlaceholder />}
            {!loading && !dataAvailable && <DataTableNoDataPlaceholder />}
            {!loading && dataAvailable && (
              <DataTableBody columnHeader={<DataTableColumnHeaders />} footer={<DataTableFooter />} />
            )}
          </>
        }
        comparisonData={compareData}
        comparisonColumns={comparisonColumns}
        defaultSorting={
          defaultSorting ?? isTour ? [{ id: 'tourIdentifier', desc: true }] : [{ id: 'orgName', desc: true }]
        }
        {...rest}
      />
    );
  };

export default withDashboardDataTableConfig;
