import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';

import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import ValueExpression, {
  TargetValueType,
} from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

export default new ValueExpression({
  identifier: 'zustellungen_avg',
  positiveDirection: PositiveDirection.custom,
  label: 'Ø Zustellungen',
  definition: getDefinitionByName('Zustellungen'),
  valueFormat: '-#.###.##0,0',
  targetValueType: TargetValueType.QUOTE,
  decimalPlaces: 2,
});
