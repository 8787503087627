import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';

import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import {
  UndeliverableReason,
  UndeliverableReasons,
} from '@legacy-modules/metrics2/models/enumerations/UndeliverableReason';

const label = 'Rücklauf Abholkarten nach Grund';
const key = 'lma-shipmentbased.RAA.all.count.by_undeliverable_reason';

const getConfig = (category) => {
  return {
    identifier: `${key}.${category.key}`,
    label: category.label,
    longLabel: `${label} (${category.label})`,
    hidden: true,
    positiveDirection: PositiveDirection.down,
  };
};

const raaReasons: UndeliverableReason[] = [
  'Canceled',
  'DeliveryRefused',
  'NoMoney',
  'NotMet1',
  'NotMet2',
  'NotMet3',
  'NotMet4',
  'WrongAddress',
];

const children = raaReasons.map((reason) => {
  const label = UndeliverableReasons[reason].label;
  return new ValueExpression(getConfig({ key: reason, label: label }));
});

export default new ValueExpression({
  ...getConfig({ key: 'sum', label: '' }),
  label: label,
  longLabel: label,
  children: children,
});
