import { DataTableColumnCell } from '@data-table/components/data-table-column-cell';
import { DataTableColumnFooter } from '@data-table/components/data-table-column-footer';
import { DataTableColumnHeader } from '@data-table/components/data-table-column-header';
import {
  DashboardDataTableDataType,
  DataTableColumnMeta,
  OrgKey,
  ValueExpressionDataTableColumnMeta,
  ValueExpressionIdentifier,
} from '@data-table/data-table.types';
import {
  escapeIdentifier,
  filterTourIdentifierRows,
  filterValueExpressionRows,
  sortTourIdentifierRows,
  valueExpressionSortingFn,
} from '@data-table/utils';
import { Duration } from '@legacy-modules/dashboard/models/enums/Duration';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

const columnHelper = createColumnHelper<DashboardDataTableDataType>();

const useDashboardDataTableColumns = (
  orgKey: OrgKey,
  duration: Duration,
  valueExpressions: ValueExpression[],
  hasCellColoring: boolean,
  summaryValues: Map<ValueExpressionIdentifier, number>
): ColumnDef<DashboardDataTableDataType>[] => {
  const columns = [
    columnHelper.accessor('tourIdentifier', {
      id: 'tourIdentifier',
      header: DataTableColumnHeader.CenterAlignedColumnHeader,
      cell: DataTableColumnCell.TourNumberColumnCell,
      footer: DataTableColumnFooter.ColumnFooter,
      meta: {
        hideFooter: true,
        exportValue: 'Tour',
        columnStyle: {
          gridColumn: '100px', // values from each individual column are combined into gridTemplateColumns on parent row
        },
        orgKey,
      } as DataTableColumnMeta,
      sortingFn: sortTourIdentifierRows,
      filterFn: filterTourIdentifierRows,
    }),
    columnHelper.accessor('standort', {
      id: 'standort',
      header: DataTableColumnHeader.CenterAlignedColumnHeader,
      cell: DataTableColumnCell.TruncatedColumnCell,
      footer: DataTableColumnFooter.ColumnFooter,
      meta: {
        exportValue: 'Standort',
        columnCellClassName: 'leftAlignedCell',
        bodyCellClassName: 'leftAlignedCell',
        footerBorderRight: '1px solid #e0e0e0',
        columnStyle: {
          left: '100px', // left offset of the 2th pinned column
          gridColumn: '200px',
        },
      } as DataTableColumnMeta,
      sortingFn: 'text',
      invertSorting: true,
      filterFn: 'arrIncludesSome',
    }),
    columnHelper.accessor('orgKey', { id: 'orgKey' }),
    columnHelper.accessor('orgName', {
      id: 'orgName',
      header: DataTableColumnHeader.CenterAlignedColumnHeader,
      cell: DataTableColumnCell.OrgNameColumnCell,
      footer: DataTableColumnFooter.ColumnFooter,
      meta: {
        hideFooter: true,
        exportValue: 'Name',
        columnStyle: {
          gridColumn: '200px', // values from each individual column are combined into gridTemplateColumns on parent row
        },
        columnCellClassName: 'leftAlignedCell',
        bodyCellClassName: 'leftAlignedCell',
      } as DataTableColumnMeta,
      sortingFn: 'text',
      invertSorting: true,
      filterFn: (row, columnId, filterValue) => filterValue.includes(row.getValue('orgKey')),
    }),
    ...valueExpressions.map((valueExpression) => {
      const label = valueExpression?.getShortLabel();
      return columnHelper.accessor(escapeIdentifier(valueExpression.identifier), {
        id: valueExpression.identifier,
        header: DataTableColumnHeader.TruncatedColumnHeader,
        cell: DataTableColumnCell.ValueExpressionColumnCell,
        footer: DataTableColumnFooter.ValueExpressionColumnFooter,
        meta: {
          exportValue: label,
          columnStyle: {
            // values from each individual column are combined into gridTemplateColumns on parent row
            gridColumn: 'minmax(150px, 1fr)',
          },
          orgKey,
          valueExpression,
          duration,
          hasCellColoring,
        } as ValueExpressionDataTableColumnMeta,
        filterFn: filterValueExpressionRows<DashboardDataTableDataType>(valueExpression),
        aggregationFn: (columnId, filteredRows, unfilteredRows) => {
          return summaryValues?.get(valueExpression.identifier);
        },
        sortingFn: valueExpressionSortingFn,
      });
    }),
  ];
  return columns;
};

export default useDashboardDataTableColumns;
