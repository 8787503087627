import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';

import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

export default new ValueExpression({
  identifier: 'abholaufträge_soll',
  hidden: true,
  positiveDirection: PositiveDirection.custom,
  label: 'Abholaufträge inkl. nicht gestartet',
  definition: getDefinitionByName('Abholaufträge'),
});
