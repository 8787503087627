import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';

import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';

const label = 'PS Mitnahmen nach Rückgabegrund';
const key = 'lma-shipmentbased.PSE.all.count.by_return_type';

const getConfig = (category) => {
  return {
    identifier: `${key}.${category}`,
    label: category,
    longLabel: `${label} (${category})`,
    hidden: true,
    positiveDirection: PositiveDirection.custom,
  };
};

const categories = ['Gepäck', 'PriPS', 'Retoure'];
const children = categories.map((category) => new ValueExpression(getConfig(category)));

export default new ValueExpression({
  ...getConfig('sum'),
  label: label,
  longLabel: label,
  chart: ChartType.line,
  children: children,
});
