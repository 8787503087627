import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';

import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ServicebeanstandungenGrund from '@contexts/value-expression-context/value-expressions/servicebeanstandungen-grund';
import {
  ValuableComplaintReasonsMap,
  ValuableComplaintsReasons,
} from '@legacy-modules/complaints/models/enums/ValuableComplaintsReason';

const children = ServicebeanstandungenGrund.getChildren().filter((child) => {
  return Object.values(ValuableComplaintReasonsMap).includes(child.getShortLabel() as ValuableComplaintsReasons);
});

export default new ValueExpression({
  identifier: 'complaints.reported.valuable.count',
  label: 'vSB nach Grund (Meldung)',
  chart: ChartType.bar,
  positiveDirection: 'down',
  category: 'complaints',
  description: '',
  definition: getDefinitionByName('vSB (Meldung)'),
  children: children,
});
