import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';

import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

export default new ValueExpression({
  identifier: 'lma-anomalies.s.colli-teillieferung.count',
  label: 'Teillieferung (mehrcollig)',
  positiveDirection: PositiveDirection.custom,
  definition: getDefinitionByName('Teillieferung (mehrcollig)'),
});
