const MAX_TOUR_NUMBER = 1000;

export class TourFilterStringParser {
  // Regex for grouping, e.g. 2-10
  groupRegex = /((^|,)\d+-\d+)/g;
  // Regex for negative groupings, e.g. !2-10
  negativeGroupRegex = /(!\d+-\d+)/g;
  // Regex for a single value, e.g. 12
  singleValueRegex = /(^(\d+)|,(\d+))/g;
  // Regex for a negative single value: !12
  negativeSingleValue = /(^(!\d+)|,(!\d+))/g;

  isValidFilterString(filterString: string): boolean {
    if (!filterString?.length) {
      return true;
    }
    // Bail early things like double comma and end with comma and exclamation mark
    // as well as number sequences which are just separated by whitespace
    if (/(,,|^,|!!|-!|,$|!$|((\d+)(\s+)(\d+)))/g.test(filterString)) {
      return false;
    }
    const cleanedFilterString = this.removeWhitespaceFromString(filterString);
    const negativeGroups = this.getNegativeNumberGroups(cleanedFilterString);
    for (const g of negativeGroups) {
      if (!this.validateGroup(g)) {
        return false;
      }
    }
    const groups = this.getNumberGroups(cleanedFilterString);
    for (const g of groups) {
      if (!this.validateGroup(g)) {
        return false;
      }
    }
    const negativeSingleValues = this.getNegativeSingleValues(cleanedFilterString);

    const singleValues = this.getSingleValues(cleanedFilterString)
      .sort((a, b) => a - b)
      .reverse();

    // It's important to sort here to match larger groups first
    const combinedSingleValues = [...singleValues, ...negativeSingleValues].sort((a, b) => a - b).reverse();

    let stringWithoutValues = cleanedFilterString;
    // We replace each match and check if there are characters remaining, if there are,
    // these are invalid. Because we also remove any comma and exclamation mark here,
    // we need to check these don't occur beforehand.
    [...groups, ...negativeGroups, ...combinedSingleValues, ',', '!'].forEach((v) => {
      stringWithoutValues = stringWithoutValues.replace(new RegExp(`${v}`, 'g'), '');
    });
    return stringWithoutValues.length === 0;
  }

  removeWhitespaceFromString(filterString: string): string {
    return filterString.replace(/\s/g, '');
  }

  extractBoundsFromGroup(group: string): number[] {
    const regex = /(\d+)-(\d+)/g;
    const match = regex.exec(group);
    if (!match) {
      throw new Error('Invalid group: ' + group);
    }
    return [Number.parseInt(match[1]), Number.parseInt(match[2])];
  }

  validateGroup(group: string): boolean {
    const bounds = this.extractBoundsFromGroup(group);
    if (bounds.length !== 2) {
      return false;
    }
    const [lower, upper] = bounds;
    const safeUpper = Math.min(upper, MAX_TOUR_NUMBER);

    return lower <= safeUpper;
  }

  getNumberGroups(filterString: string): string[] {
    return (filterString.match(this.groupRegex) || []).map((v) => {
      return v.replace(/,/g, '');
    });
  }

  getNegativeNumberGroups(filterstring: string): string[] {
    return (filterstring.match(this.negativeGroupRegex) || []).map((v) => v.replace(/!/g, '').replace(/,/g, ''));
  }

  getSingleValues(filterString: string): number[] {
    return (filterString.match(this.singleValueRegex) || [])
      .map((v) => v.replace(/,/g, ''))
      .map((v) => Number.parseInt(v, 10));
  }

  getNegativeSingleValues(filterString: string): number[] {
    return (filterString.match(this.negativeSingleValue) || [])
      .map((v) => v.replace(/!/g, '').replace(/,/g, ''))
      .map((v) => Number.parseInt(v, 10));
  }

  /**
   * Parses a given filter string and returns all
   * tour numbers that should be displayed to the user.
   *
   * It consists of:
   * - *exclusion rules*, which have a leading exclamation mark
   * before their number or number group, e.g. !14 or !10-15
   * - *inclusion rules*, which indicate which tour or tours
   * the user wants to see, e.g. 14 or 10-15
   *
   * Example of filter strings:
   * - 10,15,8-12
   * - !15
   * - 10-15,!14
   *
   * The uses the following rules when determining
   * the tours for the user:
   * - If there are only exclusion rules in the string,
   * it matches all rules except those specified
   * - If there are only inclusion rules, the one
   * specified will be matched (if they exist)
   * - If both combined, the exclusion rule only matches
   * tours which are marked as included
   *
   * @param filterString
   * @param knownTourNumbers
   */
  parseFilterString(filterString: string, knownTourNumbers: number[]): number[] {
    if (!filterString?.length) {
      return [];
    }

    if (!this.isValidFilterString(filterString)) {
      throw new Error('Invalid filter string' + filterString);
    }
    const cleaned = this.removeWhitespaceFromString(filterString);

    // Build set with all negative values. Negative values are these,
    // that have a ! before it's value. These have to be substracted
    // from the including values
    const negativeValues = new Set<number>();
    const negativeGroups = this.getNegativeNumberGroups(cleaned);
    negativeGroups.forEach((g) => {
      const [lower, upper] = this.extractBoundsFromGroup(g);
      const safeUpper = Math.min(upper, MAX_TOUR_NUMBER);
      if (Number.isNaN(lower) || Number.isNaN(safeUpper)) {
        console.error(`Invalid bounds (lower: ${lower}, upper: ${safeUpper})`);
        return;
      }
      for (let i = lower; i <= safeUpper; i++) {
        negativeValues.add(i);
      }
    });
    this.getNegativeSingleValues(cleaned).forEach((v) => negativeValues.add(v));

    // Build set with all values that will match
    const values = new Set<number>();
    const groups = this.getNumberGroups(cleaned);
    groups.forEach((g) => {
      const [lower, upper] = this.extractBoundsFromGroup(g);
      const safeUpper = Math.min(upper, MAX_TOUR_NUMBER);
      if (Number.isNaN(lower) || Number.isNaN(safeUpper)) {
        console.error(`Invalid bounds (lower: ${lower}, upper: ${safeUpper})`);
        return;
      }
      for (let i = lower; i <= safeUpper; i++) {
        values.add(i);
      }
    });
    this.getSingleValues(cleaned).forEach((v) => values.add(v));

    // Special case, if we don't have inclusions but have exclusions, we
    // subtract the exclusion from all tour number we have.
    if (!values.size && negativeValues.size) {
      return knownTourNumbers.filter((knownNumber) => !negativeValues.has(knownNumber));
    }

    // Remove all negative matches from result set
    negativeValues.forEach((nv) => {
      values.delete(nv);
    });

    const allUserMatchedNumbers = [...values].sort((a, b) => a - b);

    return knownTourNumbers.filter((v) => allUserMatchedNumbers.includes(v));
  }
}
