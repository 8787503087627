import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import { complaintReasons } from '@legacy-modules/metrics2/models/entities/MetricType';

import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { ValuableComplaintReasonsColors } from '@legacy-modules/complaints/models/enums/ValuableComplaintsReason';

const label = 'SB nach Grund (Meldung)';
const key = 'complaints.reported.count_by_type';

const getConfig = (category) => {
  return {
    identifier: `${key}.${category.key}`,
    label: category.label,
    longLabel: `${label} (${category.label})`,
    chart: ChartType.bar,
    positiveDirection: PositiveDirection.down,
    category: 'complaints',
    description:
      '<p>Kundenkontakte im Customer Service, bei denen es sich um Servicebeanstandungen handelt nach Grund</p>\n',
    definition: getDefinitionByName('SB (Meldung)'),
    hidden: true,
    color: category.color,
  };
};

const children = complaintReasons.map((reason) => {
  const color = ValuableComplaintReasonsColors[reason];
  return new ValueExpression(getConfig({ key: reason, label: reason, color: color }));
});

export default new ValueExpression({
  ...getConfig({ key: 'sum', label: '' }),
  label: label,
  longLabel: label,
  chart: ChartType.line,
  hidden: false,
  children: children,
});
