/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { useQuery, UseQueryOptions } from 'react-query';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };

function fetcher<TData, TVariables>(endpoint: string, requestInit: RequestInit, query: string, variables?: TVariables) {
  return async (): Promise<TData> => {
    const res = await fetch(endpoint, {
      method: 'POST',
      ...requestInit,
      body: JSON.stringify({ query, variables }),
    });

    const json = await res.json();

    if (json.errors) {
      const { message } = json.errors[0];

      throw new Error(message);
    }

    return json.data;
  };
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Date: { input: any; output: any };
  /** The `Long` scalar type represents non-fractional signed whole numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: { input: any; output: any };
  OrgKey: { input: any; output: any };
};

export type AggregationDateFilterInput = {
  range: DateRangeInput;
  weekdays?: InputMaybe<Array<WeekdayFilterInput>>;
};

export type AggregationGroupResult = {
  __typename?: 'AggregationGroupResult';
  dateRange: DateRangeObjectType;
  group: Scalars['String']['output'];
  kpiValues: Array<AggregationValue>;
  orgKey: Scalars['OrgKey']['output'];
};

export type AggregationResponse = {
  __typename?: 'AggregationResponse';
  groups: Array<AggregationGroupResult>;
  summary: AggregationSummary;
};

export type AggregationSummary = {
  __typename?: 'AggregationSummary';
  kpiValues: Array<AggregationValue>;
};

export type AggregationValue = {
  __typename?: 'AggregationValue';
  kpiId: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type ContractorInput = {
  personId: Scalars['Long']['input'];
};

/** computing aggregations like sum, min and max */
export enum DateRangeAggregationInput {
  /** aggregate result by COUNT */
  Count = 'COUNT',
  /** aggregate result by MAX */
  Max = 'MAX',
  /** aggregate result by MIN */
  Min = 'MIN',
  /** aggregate result by SUM */
  Sum = 'SUM',
}

/** Automatically group according to the selected grouping method */
export enum DateRangeGrouping {
  /** group result by day */
  Day = 'DAY',
  /** group result by month */
  Month = 'MONTH',
  /** group all results into single group */
  Single = 'SINGLE',
  /** group result by week */
  WeekMonday = 'WEEK_MONDAY',
  /** group result by week */
  WeekSunday = 'WEEK_SUNDAY',
  /** group result by year */
  Year = 'YEAR',
}

export type DateRangeInput = {
  from: Scalars['Date']['input'];
  until: Scalars['Date']['input'];
};

export type DateRangeObjectType = {
  __typename?: 'DateRangeObjectType';
  from: Scalars['Date']['output'];
  until: Scalars['Date']['output'];
};

export type KpiDef = {
  __typename?: 'KpiDef';
  description?: Maybe<KpiDescription>;
  dimension?: Maybe<KpiDimension>;
  kpiId: KpiId;
};

export type KpiDefsQueryResponse = {
  __typename?: 'KpiDefsQueryResponse';
  defs: Array<KpiDef>;
};

export type KpiDescription = {
  __typename?: 'KpiDescription';
  definition: Scalars['String']['output'];
  explanation: Scalars['String']['output'];
};

export type KpiDimension = {
  __typename?: 'KpiDimension';
  category: KpiDimensionCategory;
  symbol?: Maybe<Scalars['String']['output']>;
  type: KpiDimensionType;
};

/** categories of a dimension of a kpi */
export enum KpiDimensionCategory {
  /** dimension category AREA */
  Area = 'AREA',
  /** dimension category CURRENCY */
  Currency = 'CURRENCY',
  /** dimension category DISTANCE */
  Distance = 'DISTANCE',
  /** dimension category TIME */
  Time = 'TIME',
  /** dimension category TIME_PERIOD */
  TimePeriod = 'TIME_PERIOD',
}

/** types of a dimension of a kpi */
export enum KpiDimensionType {
  /** dimension type DATE */
  Date = 'DATE',
  /** dimension type EURO */
  Euro = 'EURO',
  /** dimension type EURO_CENT */
  EuroCent = 'EURO_CENT',
  /** dimension category HOUR */
  Hour = 'HOUR',
  /** dimension category METER */
  Meter = 'METER',
  /** dimension category MINUTE */
  Minute = 'MINUTE',
  /** dimension category SECOND */
  Second = 'SECOND',
  /** dimension category SQUARE_KILOMETER */
  SquareKilometer = 'SQUARE_KILOMETER',
}

export type KpiId = {
  __typename?: 'KpiId';
  id: Scalars['String']['output'];
};

export type KpiIdInput = {
  id: Scalars['String']['input'];
};

/** filter for kpi values */
export type KpiValueFilterInput = {
  operator: ValueFilterOperator;
  value: Scalars['Float']['input'];
};

export type KpiValueSelectorInput = {
  /**
   * filter for grouped and aggregated kpi values.
   * if a single kpi value of an aggregated group doesn't match the given condition,
   * the whole group including all other kpi values will be filtered from the result!
   *
   */
  aggregatedValueFilter?: InputMaybe<KpiValueFilterInput>;
  kpiId: Scalars['String']['input'];
};

export type OrgUnitInput = {
  orgId: Scalars['Int']['input'];
};

export type Query = {
  __typename?: 'Query';
  kpiDefs: KpiDefsQueryResponse;
  kpis: AggregationResponse;
  /**
   * Returns tours driven by a contractor on behalf of org units (like Depots, ZSBs) within a given date range.
   * The result is grouped by the specified date range grouping then. The tours returned per group
   * may not driven by the contractor at each single day within this groups date range but at least at one day within that range!
   */
  toursDrivenByContractor: ToursDrivenByContractor;
  /**
   * Returns tours that are driven within an org units responsibility within a given date range.
   * The result is grouped by the specified date range grouping then. The tours maybe driven by the org unit itself or by a contractor.
   * A tour withing an selected date range maybe driven by the org unit itself or by different contractors (for example in the context of so-called support tours).
   * In this case the specific tour number will be returned multiple times including the different driver information!
   */
  toursOfOrgUnit: ToursOfOrgUnit;
};

export type QueryKpiDefsArgs = {
  fulltextFilter?: InputMaybe<Scalars['String']['input']>;
  kpiIdsFilter: Array<KpiIdInput>;
};

export type QueryKpisArgs = {
  dateFilter: AggregationDateFilterInput;
  dateRangeAggregation?: InputMaybe<DateRangeAggregationInput>;
  dateRangeGrouping?: InputMaybe<DateRangeGrouping>;
  kpis?: InputMaybe<Array<KpiValueSelectorInput>>;
  orgKeys: Array<Scalars['OrgKey']['input']>;
  requestId: Scalars['ID']['input'];
};

export type QueryToursDrivenByContractorArgs = {
  contractor: ContractorInput;
  dateFilter: AggregationDateFilterInput;
  dateRangeGrouping?: InputMaybe<DateRangeGrouping>;
  homeTourFilter?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryToursOfOrgUnitArgs = {
  dateFilter: AggregationDateFilterInput;
  dateRangeGrouping?: InputMaybe<DateRangeGrouping>;
  orgUnit: OrgUnitInput;
};

export type ToursDrivenByContractor = {
  __typename?: 'ToursDrivenByContractor';
  groups: Array<ToursDrivenByContractorGroup>;
};

export type ToursDrivenByContractorGroup = {
  __typename?: 'ToursDrivenByContractorGroup';
  dateRange: DateRangeObjectType;
  group: Scalars['String']['output'];
  orgs: Array<ToursDrivenByContractorOrg>;
};

export type ToursDrivenByContractorOrg = {
  __typename?: 'ToursDrivenByContractorOrg';
  isHome: Scalars['Boolean']['output'];
  orgId: Scalars['Long']['output'];
  tours: Array<ToursDrivenByContractorTour>;
};

export type ToursDrivenByContractorTour = {
  __typename?: 'ToursDrivenByContractorTour';
  number: Scalars['Int']['output'];
};

export type ToursOfOrgUnit = {
  __typename?: 'ToursOfOrgUnit';
  groups: Array<ToursOfOrgUnitGroup>;
};

export type ToursOfOrgUnitGroup = {
  __typename?: 'ToursOfOrgUnitGroup';
  dateRange: DateRangeObjectType;
  group: Scalars['String']['output'];
  tours: Array<ToursOfOrgUnitTour>;
};

export type ToursOfOrgUnitTour = {
  __typename?: 'ToursOfOrgUnitTour';
  drivenByContractor?: Maybe<ToursOfOrgUnitTourDrivenByContractor>;
  number: Scalars['Int']['output'];
};

export type ToursOfOrgUnitTourDrivenByContractor = {
  __typename?: 'ToursOfOrgUnitTourDrivenByContractor';
  isHome: Scalars['Boolean']['output'];
  personId: Scalars['Long']['output'];
};

/** operator for filtering kpi values */
export enum ValueFilterOperator {
  /** greater than operator */
  Gt = 'GT',
  /** less than operator */
  Lt = 'LT',
}

export type WeekdayFilterInput = {
  active: Scalars['Boolean']['input'];
  weekday: WeekdayInput;
};

export enum WeekdayInput {
  Fri = 'FRI',
  Mon = 'MON',
  Sat = 'SAT',
  Sun = 'SUN',
  Thu = 'THU',
  Tue = 'TUE',
  Wed = 'WED',
}

export type KpisQueryVariables = Exact<{
  requestId: Scalars['ID']['input'];
  kpis: Array<KpiValueSelectorInput> | KpiValueSelectorInput;
  orgKeys: Array<Scalars['OrgKey']['input']> | Scalars['OrgKey']['input'];
  dateFilter: AggregationDateFilterInput;
  dateRangeGrouping?: InputMaybe<DateRangeGrouping>;
  dateRangeAggregation?: InputMaybe<DateRangeAggregationInput>;
}>;

export type KpisQuery = {
  __typename?: 'Query';
  kpis: {
    __typename?: 'AggregationResponse';
    groups: Array<{
      __typename?: 'AggregationGroupResult';
      group: string;
      orgKey: any;
      dateRange: { __typename?: 'DateRangeObjectType'; from: any; until: any };
      kpiValues: Array<{ __typename?: 'AggregationValue'; kpiId: string; value: string }>;
    }>;
    summary: {
      __typename?: 'AggregationSummary';
      kpiValues: Array<{ __typename?: 'AggregationValue'; kpiId: string; value: string }>;
    };
  };
};

export type ToursDrivenByContractorQueryVariables = Exact<{
  contractor: ContractorInput;
  dateFilter: AggregationDateFilterInput;
  dateRangeGrouping?: InputMaybe<DateRangeGrouping>;
  homeTourFilter?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type ToursDrivenByContractorQuery = {
  __typename?: 'Query';
  toursDrivenByContractor: {
    __typename?: 'ToursDrivenByContractor';
    groups: Array<{
      __typename?: 'ToursDrivenByContractorGroup';
      group: string;
      dateRange: { __typename?: 'DateRangeObjectType'; from: any; until: any };
      orgs: Array<{
        __typename?: 'ToursDrivenByContractorOrg';
        orgId: any;
        isHome: boolean;
        tours: Array<{ __typename?: 'ToursDrivenByContractorTour'; number: number }>;
      }>;
    }>;
  };
};

export type ToursOfOrgUnitQueryVariables = Exact<{
  orgUnit: OrgUnitInput;
  dateFilter: AggregationDateFilterInput;
  dateRangeGrouping?: InputMaybe<DateRangeGrouping>;
}>;

export type ToursOfOrgUnitQuery = {
  __typename?: 'Query';
  toursOfOrgUnit: {
    __typename?: 'ToursOfOrgUnit';
    groups: Array<{
      __typename?: 'ToursOfOrgUnitGroup';
      group: string;
      dateRange: { __typename?: 'DateRangeObjectType'; from: any; until: any };
      tours: Array<{
        __typename?: 'ToursOfOrgUnitTour';
        number: number;
        drivenByContractor?: {
          __typename?: 'ToursOfOrgUnitTourDrivenByContractor';
          personId: any;
          isHome: boolean;
        } | null;
      }>;
    }>;
  };
};

export const KpisDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Kpis' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'requestId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'kpis' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'KpiValueSelectorInput' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orgKeys' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'OrgKey' } } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dateFilter' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AggregationDateFilterInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dateRangeGrouping' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateRangeGrouping' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dateRangeAggregation' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateRangeAggregationInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'kpis' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'requestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'requestId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'kpis' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'kpis' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orgKeys' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orgKeys' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dateFilter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dateFilter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dateRangeGrouping' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dateRangeGrouping' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dateRangeAggregation' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dateRangeAggregation' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dateRange' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'from' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'until' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'group' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'kpiValues' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'kpiId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'orgKey' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'summary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'kpiValues' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'kpiId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<KpisQuery, KpisQueryVariables>;
export const ToursDrivenByContractorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ToursDrivenByContractor' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'contractor' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ContractorInput' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dateFilter' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AggregationDateFilterInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dateRangeGrouping' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateRangeGrouping' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'homeTourFilter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'toursDrivenByContractor' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'contractor' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'contractor' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dateFilter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dateFilter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dateRangeGrouping' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dateRangeGrouping' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'homeTourFilter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'homeTourFilter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'group' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dateRange' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'from' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'until' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'orgs' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'orgId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isHome' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tours' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'number' } }],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ToursDrivenByContractorQuery, ToursDrivenByContractorQueryVariables>;
export const ToursOfOrgUnitDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ToursOfOrgUnit' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orgUnit' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'OrgUnitInput' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dateFilter' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AggregationDateFilterInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dateRangeGrouping' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateRangeGrouping' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'toursOfOrgUnit' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orgUnit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orgUnit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dateFilter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dateFilter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dateRangeGrouping' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dateRangeGrouping' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'group' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dateRange' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'from' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'until' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tours' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'drivenByContractor' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'personId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'isHome' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ToursOfOrgUnitQuery, ToursOfOrgUnitQueryVariables>;

export const QueryKpisDocument = `
    query Kpis($requestId: ID!, $kpis: [KpiValueSelectorInput!]!, $orgKeys: [OrgKey!]!, $dateFilter: AggregationDateFilterInput!, $dateRangeGrouping: DateRangeGrouping, $dateRangeAggregation: DateRangeAggregationInput) {
  kpis(
    requestId: $requestId
    kpis: $kpis
    orgKeys: $orgKeys
    dateFilter: $dateFilter
    dateRangeGrouping: $dateRangeGrouping
    dateRangeAggregation: $dateRangeAggregation
  ) {
    groups {
      dateRange {
        from
        until
      }
      group
      kpiValues {
        kpiId
        value
      }
      orgKey
    }
    summary {
      kpiValues {
        kpiId
        value
      }
    }
  }
}
    `;
export const useKpisQuery = <TData = KpisQuery, TError = unknown>(
  dataSource: { endpoint: string; fetchParams?: RequestInit },
  variables: KpisQueryVariables,
  options?: UseQueryOptions<KpisQuery, TError, TData>
) =>
  useQuery<KpisQuery, TError, TData>(
    ['Kpis', variables],
    fetcher<KpisQuery, KpisQueryVariables>(
      dataSource.endpoint,
      dataSource.fetchParams || {},
      QueryKpisDocument,
      variables
    ),
    options
  );
export const QueryToursDrivenByContractorDocument = `
    query ToursDrivenByContractor($contractor: ContractorInput!, $dateFilter: AggregationDateFilterInput!, $dateRangeGrouping: DateRangeGrouping, $homeTourFilter: Boolean) {
  toursDrivenByContractor(
    contractor: $contractor
    dateFilter: $dateFilter
    dateRangeGrouping: $dateRangeGrouping
    homeTourFilter: $homeTourFilter
  ) {
    groups {
      group
      dateRange {
        from
        until
      }
      orgs {
        orgId
        isHome
        tours {
          number
        }
      }
    }
  }
}
    `;
export const useToursDrivenByContractorQuery = <TData = ToursDrivenByContractorQuery, TError = unknown>(
  dataSource: { endpoint: string; fetchParams?: RequestInit },
  variables: ToursDrivenByContractorQueryVariables,
  options?: UseQueryOptions<ToursDrivenByContractorQuery, TError, TData>
) =>
  useQuery<ToursDrivenByContractorQuery, TError, TData>(
    ['ToursDrivenByContractor', variables],
    fetcher<ToursDrivenByContractorQuery, ToursDrivenByContractorQueryVariables>(
      dataSource.endpoint,
      dataSource.fetchParams || {},
      QueryToursDrivenByContractorDocument,
      variables
    ),
    options
  );
export const QueryToursOfOrgUnitDocument = `
    query ToursOfOrgUnit($orgUnit: OrgUnitInput!, $dateFilter: AggregationDateFilterInput!, $dateRangeGrouping: DateRangeGrouping) {
  toursOfOrgUnit(
    orgUnit: $orgUnit
    dateFilter: $dateFilter
    dateRangeGrouping: $dateRangeGrouping
  ) {
    groups {
      group
      dateRange {
        from
        until
      }
      tours {
        number
        drivenByContractor {
          personId
          isHome
        }
      }
    }
  }
}
    `;
export const useToursOfOrgUnitQuery = <TData = ToursOfOrgUnitQuery, TError = unknown>(
  dataSource: { endpoint: string; fetchParams?: RequestInit },
  variables: ToursOfOrgUnitQueryVariables,
  options?: UseQueryOptions<ToursOfOrgUnitQuery, TError, TData>
) =>
  useQuery<ToursOfOrgUnitQuery, TError, TData>(
    ['ToursOfOrgUnit', variables],
    fetcher<ToursOfOrgUnitQuery, ToursOfOrgUnitQueryVariables>(
      dataSource.endpoint,
      dataSource.fetchParams || {},
      QueryToursOfOrgUnitDocument,
      variables
    ),
    options
  );
