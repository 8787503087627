import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';

import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';

export default new ValueExpression({
  identifier: 'zustellzeit_atg_netto',
  label: 'Netto-ATG-Zeit',
  chart: ChartType.line,
  positiveDirection: PositiveDirection.custom,
  valueFormat: '#.###.##0,0 h',
  category: 'tours',
  definition: getDefinitionByName('Netto-ATG-Zeit'),
  hidden: true,
  valueQuotient: 3600,
});
