import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';

import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

const label = 'Zugestellte Sendungen nach Uhrzeit';
const key = 'finished_deliveries.items.delivered.by_hour';

const getConfig = (category) => {
  return {
    identifier: `${key}.${category}`,
    label: category,
    longLabel: `${label} (${category})`,
    chart: ChartType.bar,
    positiveDirection: PositiveDirection.custom,
    category: 'delivery-items',
    description: '<p>Anzahl erfolgreich zugestellter Sendungen (kein Rücklauf) nach Uhrzeit</p>\n',
  };
};

const categories = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
];

const children = categories.map((category) => new ValueExpression(getConfig(category)));

export default new ValueExpression({
  ...getConfig('sum'),
  label: label,
  longLabel: label,
  chart: ChartType.line,
  children: children,
});
