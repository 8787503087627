/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import '../../dashboard/cssModules/kpiDetailsContainerStyles.scss';
import { useDispatch, useSelector } from 'react-redux';
import ChartUtils, { chartCustomFnFactory, chartHoverFnFactory, getAnnotationsForGoal } from '../utils/ChartUtils';
import moment from 'moment-timezone';
import { Bar, Line } from 'react-chartjs-2';
import { Button, ButtonGroup } from 'reactstrap';
import DetailedChartLegend, { LegendItem } from './DetailedChartLegend';
import { Duration, DurationKey } from '../models/enums/Duration';
import DetailedChartKpiValue from './DetailedChartKpiValue';
import Styles from './DetailedChartKpiValueStyles.module.scss';
import * as ModuleStyles from './DetailedChart.module.scss';
import { defaultDuration } from '../models/enums/ComponentOptions';
import ValueExpression from '../../valueexpressions/models/valueexpressions/ValueExpression';
import { ChartType } from '../../metrics2/models/enumerations/ChartType';
import { ChartLoader } from '../../common/components/Loader';
import DetailedChartTooltip from './DetailedChartTooltip';
import * as ChartJsPluginAnnotation from 'chartjs-plugin-annotation';
import { ChartOptions, ChartTooltipLabelColor } from 'chart.js';
import { useChartDataTransformation } from '../hooks/useChartDataTransformation';
import DateSelect, { DateSelectionDefaultConfig, ForecastConfig } from '../../common/components/DateSelect';
import { ComponentHeader } from '../../common/components/ComponentHeader';
import { FloatingComponent } from '../../common/components/FloatingComponent';
import KpiValue from '../components/KpiValue';
import { formatValueExpressionValue } from '../utils/FormatValueExpression';
import { Position } from '../types/Position';
import { LimitClassTypes } from '../types/AbstractLimit';
import { ToggleButtonGroup } from '../../common/components/ButtonGroup';
import { ToggleButton } from '../../common/components/ToggleButton';
import { useIsMounted } from '../hooks/useIsMounted';
import * as Loader from '../cssModules/loaderStyles.module.scss';
import empty from '../img/cloud_off.svg';
import classNames from 'classnames';
import { useValueExpressionContext } from '@contexts/value-expression-context';
import {
  selectActiveDashboardView,
  selectDashboardOrgKey,
  selectLocalComponentOptions,
} from '@redux/dashboard.selectors';
import { ActiveDashboardView, dashboardSlice } from '@redux/dashboard.slice';
import { useChartKpiQuery } from '@hooks/use-chart-kpi-query-hook';
import { useKpiQuery } from '@hooks/use-kpi-query-hook';

import PrognoseSendungseingangsmengeDatum from '@contexts/value-expression-context/value-expressions/prognose-sendungseingangsmenge-datum';
import RuecklauferGrund from '@contexts/value-expression-context/value-expressions/ruecklaufer-grund';
import RuecklauferEilGrund from '@contexts/value-expression-context/value-expressions/ruecklaufer-eil-grund';
import { DateRangeGrouping } from '@legacy-modules/metrics2/models/enumerations/DateRangeGrouping';
import PrognoseSendungseingangsmenge from '@contexts/value-expression-context/value-expressions/prognose-sendungseingangsmenge';

export const _isStacked = (chartType: ChartType): boolean => {
  return chartType === ChartType.lineStacked || chartType === ChartType.bar;
};

const _getChartOptions = (
  valueExpression: ValueExpression,
  chartType: ChartType,
  custom,
  goal,
  duration,
  onActiveTooltipIndex,
  onTooltipPositionChange: (position: Position) => void
): ChartOptions => {
  const stacked = _isStacked(chartType);
  const ve = valueExpression;
  if (typeof ve === 'undefined') {
    return;
  }

  return {
    responsive: true,
    responsiveAnimationDuration: 0,
    maintainAspectRatio: false,
    resizeDelay: 0,
    legend: {
      display: false,
    },
    annotation: getAnnotationsForGoal(goal),
    layout: {
      padding: {
        left: 20,
        bottom: 20,
      },
    },
    hover: {
      mode: 'nearest',
    },
    animation: { duration: 0 },
    scales: {
      yAxes: [
        {
          position: 'right',
          stacked,
          gridLines: {
            color: 'rgba(0,0,0,0.04)',
          },
          ticks: {
            beginAtZero: ve.chartRenderOptions?.startYAxisOnZero !== false,
            callback: (value) => ve.getValueFormatter()(value),
            // precision of the values of the y-axis, default is 0 to prevent the y-axis to have multiple same values, e.g. 1;1;1;1.
            // If we display percentages however, and display them in the decimal format, we increase have to increase
            // the precision to at least to two so, 0,2;0,4;0,6;0,8;1.0 becomes 20%, 40%, 60%, 80% and 100% instead of 0% and 100%
            // Precision does exist. @see https://www.chartjs.org/docs/latest/axes/cartesian/linear.html
            precision: ve.percent ? undefined : 0,
          },
        },
      ],
      xAxes: [
        {
          stacked,
          gridLines: {
            color: 'rgba(0,0,0,0.04)',
          },
          ticks: {
            autoSkip: true,
            autoSkipPadding: 10,
            maxRotation: 0,
            fontColor: 'hsl(203, 100%, 7%)',
          },
        },
      ],
    },
    tooltips: {
      mode: 'index',
      intersect: false,
      position: 'nearest',
      enabled: false,
      callbacks: {
        beforeBody: (tooltipItem, data: any) => {
          if (data?.datasets?.filter((ds) => ds.compare)?.length > 0) {
            return;
          }

          const index = tooltipItem[0].index;
          const total = data.datasets
            .filter((ds) => ds._meta[Object.keys(ds._meta)[0]].hidden === null)
            .map((ds) => (ds.data[index] ? ds.data[index] : 0))
            .reduce((a, b) => a + b, 0);
          const totalFormatted = ve.getValueFormatter()(total);
          return data?.datasets?.length > 1 ? totalFormatted : null;
        },
        title: (tooltipItem, data: any) => {
          // show value-expression label if on compare mode
          if (data?.datasets?.filter((ds) => ds.compare)?.length > 0) {
            const dataset: DatasetType = data?.datasets?.find((ds) => ds.primary);
            return dataset.label;
          } else {
            const index = tooltipItem[0].index;
            return data.dataLabels[index];
          }
        },
        beforeLabel: (tooltipItem: any) => {
          return tooltipItem.datasetIndex || '0';
        },
        afterLabel: (tooltipItem) => {
          return `${formatValueExpressionValue(ve, Number(tooltipItem.yLabel))}`;
        },
        labelColor: (tooltipItem, chart) => {
          return chart.config.data.datasets[tooltipItem.datasetIndex] as ChartTooltipLabelColor;
        },
        label: (tooltipItem, data: any) => {
          const dataset = data?.datasets?.[tooltipItem.datasetIndex];
          // show date label if on compare mode
          if (data?.datasets?.filter((ds) => ds.compare)?.length > 0) {
            return dataset.primary ? data.dataLabels[tooltipItem.index] : data.compareDataLabels[tooltipItem.index];
          } else {
            return dataset.label;
          }
        },
      },
      custom: chartCustomFnFactory(custom, onTooltipPositionChange),
    },
    onHover: chartHoverFnFactory(onActiveTooltipIndex, onTooltipPositionChange),
  };
};

/**
 * FIXME: This is a workaround to quickly fix the issue with special requirements
 * of the mengenprognose chart.As the whole chart implementation is considered
 * to be refactored, this is a quick fix to get the other charts working correctly.
 * see https://hermesgermany.atlassian.net/browse/AUP-543
 */
const _getPrognoseChartOptions = (
  valueExpression: ValueExpression,
  chartType: ChartType,
  custom,
  goal,
  duration,
  onActiveTooltipIndex,
  onTooltipPositionChange: (position: Position) => void
): ChartOptions => {
  const stacked = _isStacked(chartType);

  const ve = valueExpression;

  if (typeof ve === 'undefined') {
    return;
  }

  return {
    responsive: true,
    responsiveAnimationDuration: 0,
    maintainAspectRatio: false,
    resizeDelay: 0,
    legend: {
      display: false,
    },
    annotation: getAnnotationsForGoal(goal),
    layout: {
      padding: {
        left: 20,
        bottom: 20,
      },
    },
    hover: {
      mode: 'nearest',
    },
    animation: { duration: 0 },
    scales: {
      yAxes: [
        {
          position: 'right',
          stacked,
          gridLines: {
            color: 'rgba(0,0,0,0.04)',
          },
          ticks: {
            beginAtZero: ve.chartRenderOptions?.startYAxisOnZero !== false,
            callback: (value) => ve.getValueFormatter()(value),
            // precision of the values of the y-axis, default is 0 to prevent the y-axis to have multiple same values, e.g. 1;1;1;1.
            // If we display percentages however, and display them in the decimal format, we increase have to increase
            // the precision to at least to two so, 0,2;0,4;0,6;0,8;1.0 becomes 20%, 40%, 60%, 80% and 100% instead of 0% and 100%
            // Precision does exist. @see https://www.chartjs.org/docs/latest/axes/cartesian/linear.html
            // @ts-ignore
            precision: ve.percent ? undefined : 0.1,
          },
        },
      ],
      xAxes: [
        {
          stacked,
          gridLines: {
            color: 'rgba(0,0,0,0.04)',
          },
          ticks: {
            autoSkip: true,
            autoSkipPadding: 10,
            maxRotation: 0,
            fontColor: 'hsl(203, 100%, 7%)',
            maxTicksLimit: 56, // default is 11
            callback: function (value, index, ticks) {
              if (duration.key !== DurationKey.NEXT_56) {
                return value;
              }
              // skip duplicated weeks names on x-axis (AUP-108)
              return ticks.indexOf(value) === index ? value : '';
            },
            labelOffset: duration.key === DurationKey.NEXT_56 ? 10 : 0,
          },
        },
      ],
    },
    tooltips: {
      mode: 'index',
      intersect: false,
      position: 'nearest',
      enabled: false,
      callbacks: {
        beforeBody: (tooltipItem, data: any) => {
          if (data?.datasets?.filter((ds) => ds.compare)?.length > 0) {
            return;
          }

          const index = tooltipItem[0].index;
          const total = data.datasets
            .filter((ds) => ds._meta[Object.keys(ds._meta)[0]].hidden === null)
            .map((ds) => (ds.data[index] ? ds.data[index] : 0))
            .reduce((a, b) => a + b, 0);
          const totalFormatted = ve.getValueFormatter()(total);
          return data?.datasets?.length > 1 ? totalFormatted : null;
        },
        title: (tooltipItem, data: any) => {
          // show value-expression label if on compare mode
          if (data?.datasets?.filter((ds) => ds.compare)?.length > 0) {
            const dataset: DatasetType = data?.datasets?.find((ds) => ds.primary);
            return dataset?.label;
          } else {
            const index = tooltipItem?.[0]?.index;
            return data?.dataLabels?.[index];
          }
        },
        beforeLabel: (tooltipItem: any) => {
          return tooltipItem.datasetIndex || '0';
        },
        afterLabel: (tooltipItem) => {
          return `${formatValueExpressionValue(ve, Number(tooltipItem.yLabel))}`;
        },
        labelColor: (tooltipItem, chart) => {
          return chart.config.data.datasets[tooltipItem.datasetIndex] as ChartTooltipLabelColor;
        },
        label: (tooltipItem, data: any) => {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          // show date label if on compare mode
          if (data?.datasets?.filter((ds) => ds.compare)?.length > 0) {
            return dataset.primary ? data.dataLabels[tooltipItem.index] : data.compareDataLabels[tooltipItem.index];
          } else {
            return dataset.label;
          }
        },
      },
      custom: chartCustomFnFactory(custom, onTooltipPositionChange),
    },
    onHover: chartHoverFnFactory(onActiveTooltipIndex, onTooltipPositionChange),
  };
};

type ChartDataProps = {
  chartData: ChartData;
  valueExpression: ValueExpression;
  _onChartTypeClick: (chartType: ChartType) => void;
  showChartModeSelector: boolean;
  selectedChartType: ChartType;
  custom: (model: any) => void;
  goal: LimitClassTypes;
  tooltipModel: any;
  duration: Duration;
  isPrognose?: boolean;
  enableLegend?: boolean;
};

const Chart: React.FC<ChartDataProps> = ({
  chartData,
  valueExpression,
  _onChartTypeClick,
  showChartModeSelector,
  selectedChartType,
  custom,
  goal,
  tooltipModel,
  duration,
  isPrognose = false,
  enableLegend = false,
}: ChartDataProps) => {
  const showLegend = enableLegend && valueExpression?.hasChildren();
  const [activeTooltipIdx, setActiveTooltipIdx] = useState(-1);
  const [tooltipPosition, setTooltipPosition] = useState<Position>({
    x: 0,
    y: 0,
  });
  const [chartRef, setChartRef] = useState<Line | Bar>();

  const mounted = useIsMounted();

  const _onLegendItemClick = useCallback(
    (legendItem: LegendItem) => {
      const ci = chartRef.chartInstance;
      const meta = ci.getDatasetMeta(legendItem.legendIndex);
      meta.hidden = !meta.hidden;
      ci.update();
    },
    [chartRef]
  );

  const _onLegendItemToggleAll = useCallback(
    (datasets: DatasetType[], hide: boolean) => {
      const ci = chartRef.chartInstance;
      datasets.forEach((ds, legendIndex) => {
        const meta = ci.getDatasetMeta(legendIndex);
        meta.hidden = hide;
      });
      ci.update();
    },
    [chartRef]
  );

  const updateActiveTooltipIdx = useCallback(
    (index: number) => {
      if (!mounted.current) return;
      setActiveTooltipIdx(index);
    },
    [mounted]
  );

  const updateTooltipPosition = useCallback(
    (position: Position) => {
      if (!mounted.current) return;
      setTooltipPosition(position);
    },
    [mounted]
  );

  const chartOptions = useMemo(() => {
    return isPrognose
      ? _getPrognoseChartOptions(
          valueExpression,
          selectedChartType,
          custom,
          goal,
          duration,
          updateActiveTooltipIdx,
          updateTooltipPosition
        )
      : _getChartOptions(
          valueExpression,
          selectedChartType,
          custom,
          goal,
          duration,
          updateActiveTooltipIdx,
          updateTooltipPosition
        );
  }, [
    valueExpression,
    selectedChartType,
    custom,
    goal,
    duration,
    isPrognose,
    updateActiveTooltipIdx,
    updateTooltipPosition,
  ]);

  const modeSelector = (
    <div className={ModuleStyles.ChartModeSelector}>
      <ButtonGroup size='sm'>
        <Button
          outline={selectedChartType !== ChartType.lineStacked}
          onClick={() => _onChartTypeClick(ChartType.lineStacked)}
          color='primary'
          className={ModuleStyles.Btn}>
          Gestapelt
        </Button>
        <Button
          outline={selectedChartType !== ChartType.line}
          onClick={() => _onChartTypeClick(ChartType.line)}
          color='primary'>
          Linien
        </Button>
        <Button
          outline={selectedChartType !== ChartType.bar}
          onClick={() => _onChartTypeClick(ChartType.bar)}
          color='primary'>
          Balken
        </Button>
      </ButtonGroup>
    </div>
  );

  const legend = (
    <div className='col-md-4'>
      <div className='legend--container'>
        <DetailedChartLegend
          datasets={chartData.datasets}
          onLegendItemToggleAll={(a, b) => _onLegendItemToggleAll(a, b)}
          onLegendItemClick={_onLegendItemClick}
        />
      </div>
    </div>
  );

  const chart = (
    <div className={ModuleStyles.ChartContainer}>
      <DetailedChartTooltip
        target={chartRef}
        model={tooltipModel}
        activeTooltipIndex={activeTooltipIdx}
        position={tooltipPosition}
      />
      {selectedChartType === ChartType.line && (
        <Line ref={setChartRef} data={chartData} options={chartOptions} plugins={[ChartJsPluginAnnotation]} />
      )}
      {selectedChartType === ChartType.lineStacked && (
        <Line
          ref={setChartRef}
          data={chartData}
          options={chartOptions as unknown as ChartOptions}
          plugins={[ChartJsPluginAnnotation]}
        />
      )}
      {selectedChartType === ChartType.bar && (
        <Bar
          ref={setChartRef}
          data={chartData}
          options={chartOptions as unknown as ChartOptions}
          plugins={[ChartJsPluginAnnotation]}
        />
      )}
    </div>
  );

  return (
    <div className={'row'}>
      <div className={`${!showLegend ? 'col-md-12' : 'col-md-8'}`}>
        {showChartModeSelector && modeSelector}
        {chart}
      </div>
      {showLegend && legend}
    </div>
  );
};

export type DatasetType = {
  primary?: boolean;
  label?: string;
  lineTension?: number;
  spanGaps?: boolean;
  pointRadius?: number;
  pointBorderWidth?: number;
  pointBackgroundColor?: string;
  fill?: boolean;
  backgroundColor?: string;
  borderColor?: string;
  data: number[];
  details?: any;
  sum?: number;
  percentage?: number;
  compare?: boolean;
  pointHitRadius?: number;
  borderWidth?: number;
  tooltop?: boolean;
};

export type ChartData = {
  labels: any;
  dataLabels: any;
  compareDataLabels: any;
  labelValues: any;
  datasets: DatasetType[];
  target: any;
  loaded: boolean;
  labelsCompare?: boolean;
};

export type Line1 = {
  value: number;
  color: string;
};

type Props = {
  orgKey: string;
  uID: string | null | undefined;
  valueExpressionKey: string | null | undefined;
  duration: Duration;
  defaultChartType: ChartType;
  style?: React.CSSProperties;
  showChartModeSelector?: boolean;
  showBenchmark?: boolean;
  enableKpiAvg?: boolean;
  showDateSelector?: boolean;
  showMetricValues?: boolean;
  title?: string;
  threshold?: number;
  goal?: LimitClassTypes;
  showToggle?: boolean;
  enableLegend?: boolean;
  renderChildren?: boolean;
};

const DetailedChart: React.FC<Props> = (props: Props) => {
  const {
    orgKey,
    threshold = 1,
    valueExpressionKey,
    duration: propsDuration,
    style = {},
    showDateSelector = false,
    enableKpiAvg = false,
    title,
    showMetricValues = false,
    showBenchmark = false,
    showChartModeSelector = false,
    uID,
    goal,
    showToggle = false,
    defaultChartType,
    enableLegend,
    renderChildren = true,
  } = props;

  const dispatch = useDispatch();

  const reduxOrgKey = useSelector(selectDashboardOrgKey);
  const usedOrgKey = orgKey ?? reduxOrgKey;

  const localComponentOptions = useSelector(selectLocalComponentOptions(uID, 'default'));
  const optionsDuration = localComponentOptions?.duration;
  const optionsChartType = localComponentOptions?.selectedChartType;
  const activeDashboard = useSelector(selectActiveDashboardView);

  const duration = propsDuration || optionsDuration || defaultDuration(uID);
  const selectedChartType = optionsChartType || defaultChartType || ChartType.bar;

  const valueExpressionMap = useValueExpressionContext();

  const [activeToggleValue, setActiveToggleValue] = useState<string>(RuecklauferGrund.identifier);

  const effectiveValueExpression = useMemo(() => {
    const valueExpression = valueExpressionMap.get(valueExpressionKey);
    if (showToggle === true) {
      return valueExpressionMap.get(activeToggleValue);
    }
    return valueExpression;
  }, [activeToggleValue, showToggle, valueExpressionKey]);

  const showLegend = enableLegend || (renderChildren && effectiveValueExpression.hasChildren());
  const showKpiAvg = enableKpiAvg && !showLegend;

  const { data: primaryData, loading: primaryLoading } = useChartKpiQuery({
    orgKey: usedOrgKey,
    duration,
    valueExpression: effectiveValueExpression,
    fetchChildren: renderChildren,
  });

  const { data: compareData, loading: compareLoading } = useChartKpiQuery({
    orgKey: usedOrgKey,
    duration,
    valueExpression: effectiveValueExpression,
    isComparisonDate: true,
    fetchChildren: renderChildren,
  });

  const typeKey = effectiveValueExpression.identifier;

  const data = { primary: primaryData?.rangeData, compare: compareData?.rangeData };
  const summaryData = {
    primary: primaryData?.summaryData.get(typeKey),
    compare: compareData?.summaryData.get(typeKey),
  };

  const isLoading = primaryLoading || compareLoading;

  const valueExpressions =
    renderChildren && effectiveValueExpression.hasChildren()
      ? [...effectiveValueExpression.getChildren()]
      : [effectiveValueExpression];

  const chartData = useChartDataTransformation(
    showBenchmark,
    showMetricValues,
    activeDashboard,
    selectedChartType,
    duration,
    data,
    isLoading,
    valueExpressions,
    threshold
  );

  const componentOptionsToState = useCallback(
    (update) => {
      const propOptions = {
        selectedChartType,
        duration,
      };
      const options = { ...propOptions, ...update };

      if (uID) {
        dispatch(
          dashboardSlice.actions.setLocalComponentOptions({
            componentId: uID,
            optionKey: 'default',
            value: options,
          })
        );
      }
    },
    [selectedChartType, duration, uID, dispatch]
  );

  const _onChartTypeClick = useCallback(
    (selectedChartType: string) => {
      componentOptionsToState({ selectedChartType });
    },
    [componentOptionsToState]
  );

  const _onDurationSelected = useCallback(
    (duration: Duration): void => {
      componentOptionsToState({ duration });
    },
    [componentOptionsToState]
  );

  const chartLoading = <ChartLoader />;

  const dateRange = ChartUtils.getDateRange(duration);

  const mengenprognose: ActiveDashboardView = 'Prognose';

  const { data: prognoseDate, isLoading: prognoseDateIsLoading } = useKpiQuery(
    {
      expressionsFilter: [PrognoseSendungseingangsmengeDatum].map((ve) => {
        return { valueExpression: ve };
      }),
      orgKeys: [usedOrgKey],
      dateRange: dateRange,
      grouping: DateRangeGrouping.day,
    },
    {
      enabled: activeDashboard === mengenprognose && !!usedOrgKey && !!dateRange.from && !!dateRange.to,
    },
    (data) => {
      const filteredGroups = data.kpis.groups.filter((group) => group.kpiValues.length > 0);
      if (filteredGroups.length === 0) {
        return '';
      } else {
        const queryValue = Number(filteredGroups[0].kpiValues[0].value).toString();
        const year = queryValue.substring(0, 4);
        const month = queryValue.length !== 0 ? moment(queryValue.substring(4, 6), 'MM', true).format('MMMM') : 0;
        const day = queryValue.substring(6, 8);
        return `(Aktualisiert am ${day}. ${month} ${year}*)`;
      }
    }
  );

  const [tooltipModel, setTooltipModel] = useState(null);

  const dateSelector = (
    <ComponentHeader headline={title || effectiveValueExpression?.getLabel()}>
      {activeDashboard === mengenprognose && (
        <span
          style={{
            opacity: '0.8',
            fontSize: '16px',
            textTransform: 'none',
            marginLeft: '5px',
          }}>
          {isLoading || prognoseDateIsLoading ? 'Aktualisiert...' : prognoseDate}
        </span>
      )}
      <div
        style={{
          flex: '1',
          display: 'flex',
          justifyContent: 'flex-end',
        }}>
        {showToggle && (
          <div
            style={{
              height: 26,
              display: 'flex',
              margin: 'auto 30px',
            }}>
            <ToggleButtonGroup
              onChange={(e, val) => {
                if (!val) return;
                setActiveToggleValue(val);
              }}
              value={activeToggleValue}
              exclusive>
              <ToggleButton value={RuecklauferGrund.identifier}>Alle</ToggleButton>
              <ToggleButton value={RuecklauferEilGrund.identifier}>Eil</ToggleButton>
            </ToggleButtonGroup>
          </div>
        )}
        <DateSelect
          onDurationSelected={(duration: Duration) => _onDurationSelected(duration)}
          selectedDuration={duration}
          config={activeDashboard === mengenprognose ? ForecastConfig : DateSelectionDefaultConfig}
        />
      </div>
    </ComponentHeader>
  );

  if (activeDashboard === mengenprognose && !prognoseDate) {
    return (
      <FloatingComponent style={style}>
        <div className={classNames(ModuleStyles.DetailedChart)}>
          {showDateSelector && dateSelector}
          <div className={ModuleStyles.ErrorMsg}>
            <div>
              <img src={empty} className={Loader.emptyicon} alt='' />
            </div>
            <p>Dieser Standort wird bei der Mengenprognose noch nicht berücksichtigt.</p>
            <p>
              Wende dich bitte an{' '}
              <a href={'mailto:MP-Coreteam@hermesworld.com'} className={ModuleStyles.Link}>
                MP-Coreteam@hermesworld.com
              </a>{' '}
              und melde für welches Gebiet dieser Standort verantwortlich ist. Gib dabei auch die Organisations-ID{' '}
              <strong>{usedOrgKey?.replace(/^.*?:/, '')}</strong> mit an.
            </p>
          </div>
        </div>
      </FloatingComponent>
    );
  }

  return (
    <FloatingComponent style={style}>
      <div className={ModuleStyles.DetailedChart}>
        {showDateSelector && dateSelector}
        {showKpiAvg && effectiveValueExpression && (
          <div className={ModuleStyles.Kpis}>
            <div>
              <DetailedChartKpiValue
                orgKey={usedOrgKey}
                duration={duration}
                valueExpressionKey={effectiveValueExpression.identifier}
                selected
                rawData={summaryData}
              />
            </div>
            <div className={Styles.DetailedChartKpiValue} style={{ padding: 0 }}>
              {showBenchmark && (
                <div className={Styles.item} style={{ margin: 0 }}>
                  <div className={Styles.title}>Zielwert</div>
                  <div className={Styles.value}>
                    <span style={{ backgroundColor: '#f31a95' }} />
                    <KpiValue valueExpression={effectiveValueExpression} veData={chartData.target} />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {chartData ? (
          <Chart
            chartData={chartData}
            valueExpression={effectiveValueExpression}
            _onChartTypeClick={_onChartTypeClick}
            showChartModeSelector={showChartModeSelector}
            selectedChartType={selectedChartType}
            custom={setTooltipModel}
            goal={goal}
            tooltipModel={tooltipModel}
            duration={duration}
            isPrognose={activeDashboard === mengenprognose}
            enableLegend={showLegend}
          />
        ) : (
          chartLoading
        )}
      </div>
    </FloatingComponent>
  );
};

export default DetailedChart;
