import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';

import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

export default new ValueExpression({
  identifier: 'alen.lde.billing_tourdate.3001.no-pauschal',
  label: 'ATG Sendungen',
  chart: ChartType.bar,
  positiveDirection: PositiveDirection.custom,
  category: 'abrechnungen',
  description: '',
});
