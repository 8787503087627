import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import { MdSearch, MdInfoOutline, MdLayers, MdArrowDropDown } from 'react-icons/md';
import ValueExpression from '../models/valueexpressions/ValueExpression';
import MetricCategory from '../../metrics2/models/entities/MetricCategory';
import { sortBy } from 'lodash';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { Tooltip } from '../../common/components/Tooltip';
import { PopOver } from '../../common/components/PopOver';
import classNames from 'classnames';
import { useOnClickOutside } from '../../common/hooks/useOnClickOutside';
import { useKeyboardShortcut } from '../../navigation/hooks/useKeyboardShortcut';
import Styles from './ValueExpressionSelector.module.scss';
import { AvailableMetricCategories } from '@legacy-modules/metrics2/constants/metricCategories';
import { useValueExpressionContext } from '@contexts/value-expression-context';
import { useSelector } from 'react-redux';
import { selectOverviewValueExpression } from '@redux/overview.selectors';
import anfahrtszeit from '@contexts/value-expression-context/value-expressions/anfahrtszeit';
import anfahrtszeitAvg from '@contexts/value-expression-context/value-expressions/anfahrtszeit-avg';
import anfahrtsstrecke from '@contexts/value-expression-context/value-expressions/anfahrtsstrecke';
import atgProduktivitaet from '@contexts/value-expression-context/value-expressions/atg-produktivitaet';
import benachrichtigungenQuote from '@contexts/value-expression-context/value-expressions/benachrichtigungen-quote';
import tourStrecke from '@contexts/value-expression-context/value-expressions/tourstrecke';
import uhrzeitTourfreigabe from '@contexts/value-expression-context/value-expressions/tour-freigabe-zeitpunkt-avg';
import tourfahrtzeitAvg from '@contexts/value-expression-context/value-expressions/tourfahrzeit-avg';
import rueckfahrtstrecke from '@contexts/value-expression-context/value-expressions/rueckfahrtstrecke';
import htProduktivitaet from '@contexts/value-expression-context/value-expressions/ht-produktivitaet';
import LademengeIst from '@contexts/value-expression-context/value-expressions/lademenge-ist';
import LademengeSoll from '@contexts/value-expression-context/value-expressions/lademenge-soll';
import LademengeSollAvg from '@contexts/value-expression-context/value-expressions/lademenge-soll-avg';
import LademengeIstAvg from '@contexts/value-expression-context/value-expressions/lademenge-ist-avg';
import Touren from '@contexts/value-expression-context/value-expressions/touren';
import Zustellungen from '@contexts/value-expression-context/value-expressions/zustellungen';
import Ruecklaufer from '@contexts/value-expression-context/value-expressions/ruecklaufer';
import RuecklaufQuote from '@contexts/value-expression-context/value-expressions/ruecklauf-quote';
import Dauervollmachtquote from '@contexts/value-expression-context/value-expressions/dauervollmachtquote';
import FotoUnterschriftenNutzungsquote from '@contexts/value-expression-context/value-expressions/foto-unterschriften-nutzungsquote';
import Tourfahrzeit from '@contexts/value-expression-context/value-expressions/tourfahrzeit';
import TourstreckeAvg from '@contexts/value-expression-context/value-expressions/tourstrecke-avg';
import AnfahrtsstreckeAvg from '@contexts/value-expression-context/value-expressions/anfahrtsstrecke-avg';
import Rueckfahrtzeit from '@contexts/value-expression-context/value-expressions/rueckfahrtzeit';
import RueckfahrtzeitAvg from '@contexts/value-expression-context/value-expressions/rueckfahrtzeit-avg';
import RueckfahrtstreckeAvg from '@contexts/value-expression-context/value-expressions/rueckfahrtstrecke-avg';
import NettoHtZeit from '@contexts/value-expression-context/value-expressions/netto-ht-zeit';
import NettoHtZeitAvg from '@contexts/value-expression-context/value-expressions/netto-ht-zeit-avg';
import NettoPsZeit from '@contexts/value-expression-context/value-expressions/netto-ps-zeit';
import NettoPsZeitAvg from '@contexts/value-expression-context/value-expressions/netto-ps-zeit-avg';
import NettoAtgZeit from '@contexts/value-expression-context/value-expressions/netto-atg-zeit';
import NettoAtgZeitAvg from '@contexts/value-expression-context/value-expressions/netto-atg-zeit-avg';
import NettoZustellzeit from '@contexts/value-expression-context/value-expressions/netto-zustellzeit';
import NettoZustellzeitAvg from '@contexts/value-expression-context/value-expressions/netto-zustellzeit-avg';
import PsProduktivitaet from '@contexts/value-expression-context/value-expressions/ps-produktivitaet';
import ZustellungenPerEinwohner from '@contexts/value-expression-context/value-expressions/zustellungen-per-einwohner';
import ZustellungenPerHour from '@contexts/value-expression-context/value-expressions/zustellungen-per-hour';
import ZustellungenPerKm from '@contexts/value-expression-context/value-expressions/zustellungen-per-km';
import EinwohnerProKilometer2 from '@contexts/value-expression-context/value-expressions/einwohner-pro-kilometer2';
import LademengePerKm from '@contexts/value-expression-context/value-expressions/lademenge-per-km';
import LademengePerEinwohner from '@contexts/value-expression-context/value-expressions/lademenge-per-einwohner';
import ServicebeanstandungenMeldungVerlustrelevant from '@contexts/value-expression-context/value-expressions/servicebeanstandungen-meldung-verlustrelevant';

type Props = {
  onValueSelect: (arg0: ValueExpression) => void;
};

const labels = {
  CATEGORY_OTHER_KEY: 'other',
  CATEGORY_OTHER_LABEL: 'Andere',
  CATEGORY_FAVORITES_KEY: 'favorites',
  CATEGORY_FAVORITES_LABEL: 'Favoriten',
  CATEGORY_FAVORITES_DESCRIPTION: 'Hier werden oft verwendete oder vorgeschlagene Werte angezeigt.',
};

type ValueExpressionListItemProps = {
  category: MetricCategory;
  valueExpression: ValueExpression;
  onOptionClick: (ve: ValueExpression) => void;
  onOptionHover: (ve: ValueExpression) => void;
  selected: boolean;
  level?: number;
  onOptionExpand: (ve: ValueExpression, event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  expanded: boolean;
};

const ValueExpressionListItem = (props: ValueExpressionListItemProps) => {
  const {
    category,
    valueExpression,
    onOptionClick,
    selected,
    onOptionHover,
    level = 0,
    onOptionExpand,
    expanded,
  } = props;

  const description = valueExpression.getDescription();
  const children = valueExpression.getChildren() || [];

  const expandRef = useRef();
  const helpRef = useRef();

  return (
    <React.Fragment>
      <li
        key={`${category.key}:${valueExpression.key}`}
        onClick={() => onOptionClick(valueExpression)}
        className={classNames(Styles.ValueItem, Styles[`Level${level}`], {
          [Styles.Selected]: selected,
        })}
        onMouseEnter={() => onOptionHover(valueExpression)}>
        <div>{valueExpression.getLongLabel()}</div>
        <span className={Styles.ExpressionInfo}>
          {children.length > 0 && (
            <React.Fragment>
              <a href='#' onClick={(e) => onOptionExpand(valueExpression, e)} ref={expandRef}>
                <MdLayers />
              </a>
              <Tooltip placement='bottom' anchorElement={expandRef?.current} enterDelay={500}>
                Detailauswahl
              </Tooltip>
            </React.Fragment>
          )}
          {description && (
            <a href='#' ref={helpRef} onClick={(e) => e.preventDefault()}>
              <MdInfoOutline />
            </a>
          )}
        </span>
        {description && (
          <Tooltip placement='bottom' anchorElement={helpRef?.current}>
            <span dangerouslySetInnerHTML={{ __html: description }} />
          </Tooltip>
        )}
      </li>
      {expanded &&
        children.map((childValueExpression, i) => (
          <ValueExpressionListItem key={i} {...props} valueExpression={childValueExpression} level={level + 1} />
        ))}
    </React.Fragment>
  );
};

export type ExtendedModeType = 'quotient' | 'subtype' | 'simple';

const ValueExpressionSelector = (props: Props) => {
  const { onValueSelect } = props;
  const availableTypes = useValueExpressionContext();
  const currentValueExpression = useSelector(selectOverviewValueExpression);

  const [searchFilterText, setSearchFilterText] = useState('');
  const [open, setOpen] = useState(false);
  const [extendedMode, setExtendedMode] = useState<ExtendedModeType | null>(null);
  const searchFilter = useRef<HTMLInputElement | null>(null);
  const [selectedValueExpression, setSelectedValueExpression] = useState(null);
  const [expandedValueExpression, setExpandedValueExpression] = useState(null);
  const [selectedFirstValueExpression, setSelectedFirstValueExpression] = useState(null);

  const _onOptionExpand = useCallback(
    (value: ValueExpression, event) => {
      event.stopPropagation();
      event.preventDefault();
      const newValue = expandedValueExpression === value.key ? null : value.key;
      setExpandedValueExpression(newValue);
    },
    [expandedValueExpression]
  );

  const onSubmit = useCallback(() => {
    if (selectedFirstValueExpression) {
      onValueSelect(selectedFirstValueExpression);
      setOpen(false);
    } else {
      // @Todo: no value selected
    }
  }, [onValueSelect, selectedFirstValueExpression]);

  const onCancel = useCallback(() => {
    setOpen(false);
  }, []);

  const categories = useMemo(() => {
    const categories: Array<MetricCategory> = AvailableMetricCategories;
    return [
      new MetricCategory({
        key: labels.CATEGORY_FAVORITES_KEY,
        label: labels.CATEGORY_FAVORITES_LABEL,
        description: labels.CATEGORY_FAVORITES_DESCRIPTION,
      }),
      ...categories,
      new MetricCategory({
        key: labels.CATEGORY_OTHER_KEY,
        label: labels.CATEGORY_OTHER_LABEL,
      }),
    ];
  }, []);

  const categorizedValueExpressions = useMemo(() => {
    const filterValueExpressions = (input: Array<ValueExpression>): Array<ValueExpression> => {
      const text = searchFilterText.toLowerCase();
      return input
        ?.filter((valueExpression) => valueExpression instanceof ValueExpression && !valueExpression?.hidden)
        ?.filter((e) => {
          return e?.getLabel()?.toLowerCase()?.includes(text);
        });
    };

    const getFilteredFavoriteValueExpressions = (): Array<ValueExpression> => {
      const text = searchFilterText.toLowerCase();
      return [
        availableTypes.get(LademengeIst.identifier),
        availableTypes.get(LademengeSoll.identifier),
        availableTypes.get(LademengeSollAvg.identifier),
        availableTypes.get(LademengeIstAvg.identifier),
        availableTypes.get(Touren.identifier),
        availableTypes.get(Zustellungen.identifier),
        availableTypes.get(Ruecklaufer.identifier),
        availableTypes.get(RuecklaufQuote.identifier),
        availableTypes.get(Dauervollmachtquote.identifier),
        availableTypes.get(benachrichtigungenQuote.identifier),
        availableTypes.get(FotoUnterschriftenNutzungsquote.identifier),
        availableTypes.get(Tourfahrzeit.identifier),
        availableTypes.get(tourfahrtzeitAvg.identifier),
        availableTypes.get(tourStrecke.identifier),
        availableTypes.get(TourstreckeAvg.identifier),
        availableTypes.get(anfahrtszeit.identifier),
        availableTypes.get(anfahrtszeitAvg.identifier),
        availableTypes.get(anfahrtsstrecke.identifier),
        availableTypes.get(AnfahrtsstreckeAvg.identifier),
        availableTypes.get(Rueckfahrtzeit.identifier),
        availableTypes.get(RueckfahrtzeitAvg.identifier),
        availableTypes.get(rueckfahrtstrecke.identifier),
        availableTypes.get(RueckfahrtstreckeAvg.identifier),
        availableTypes.get(NettoHtZeit.identifier),
        availableTypes.get(NettoHtZeitAvg.identifier),
        availableTypes.get(NettoPsZeit.identifier),
        availableTypes.get(NettoPsZeitAvg.identifier),
        availableTypes.get(NettoAtgZeit.identifier),
        availableTypes.get(NettoAtgZeitAvg.identifier),
        availableTypes.get(NettoZustellzeit.identifier),
        availableTypes.get(NettoZustellzeitAvg.identifier),
        availableTypes.get(htProduktivitaet.identifier),
        availableTypes.get(PsProduktivitaet.identifier),
        availableTypes.get(atgProduktivitaet.identifier),
        availableTypes.get(uhrzeitTourfreigabe.identifier),
        availableTypes.get(ZustellungenPerEinwohner.identifier),
        availableTypes.get(ZustellungenPerHour.identifier),
        availableTypes.get(ZustellungenPerKm.identifier),
        availableTypes.get(EinwohnerProKilometer2.identifier),
        availableTypes.get(LademengePerKm.identifier),
        availableTypes.get(LademengePerEinwohner.identifier),
        availableTypes.get(ServicebeanstandungenMeldungVerlustrelevant.identifier),
      ].filter((e) => {
        return e?.getLabel()?.toLowerCase()?.includes(text);
      });
    };

    const getTypesAsValueExpressions = (): Array<ValueExpression> => {
      return Array.from(availableTypes.values());
    };

    const getFilteredTypeValueExpression = (): Array<ValueExpression> => {
      return filterValueExpressions(getTypesAsValueExpressions());
    };

    const categorizedMap: Map<string, Array<ValueExpression>> = new Map();
    categorizedMap.set(labels.CATEGORY_FAVORITES_KEY, getFilteredFavoriteValueExpressions());
    getFilteredTypeValueExpression().forEach((v) => {
      const category = v.category || labels.CATEGORY_OTHER_KEY;
      const categoryValues = categorizedMap.has(category) ? categorizedMap.get(category) : [];
      categoryValues.push(v);
      categorizedMap.set(category, categoryValues);
    });
    return categorizedMap;
  }, [availableTypes, searchFilterText]);

  const _onOptionHover = useCallback(
    (value: ValueExpression) => {
      if (!extendedMode) {
        setSelectedValueExpression(value.key);
      }
    },
    [extendedMode]
  );

  const _onExtendedModeClick = (mode: ExtendedModeType) => {
    setExtendedMode(mode);
  };

  const _onOptionClick = useCallback(
    (value: ValueExpression) => {
      if (extendedMode) {
        setSelectedFirstValueExpression(value);
      } else {
        setOpen(false);
        onValueSelect(value);
      }
    },
    [extendedMode, onValueSelect]
  );

  const renderOptions = useCallback(() => {
    return (
      <ul>
        {categories
          .filter((c) => categorizedValueExpressions.has(c.key) && categorizedValueExpressions.get(c.key).length > 0)
          .map((c, i) => {
            let valueExpressions = categorizedValueExpressions.get(c.key);
            // sort value-expressions except favorites
            if (c.key !== 'favorites') {
              valueExpressions = sortBy(valueExpressions, ['metricType.label']);
            }

            const categoryDescription = c.description;
            const categoryTooltipKey = 'category-selector-tooltip__' + c.key;
            return (
              <>
                <li className={classNames(Styles.Headline, Styles.Favorite)} key={'category-headline-' + c.key + i}>
                  {c.label}
                  {categoryDescription && (
                    <React.Fragment>
                      <span className={Styles.ExpressionInfo}>
                        <a href='#' id={categoryTooltipKey} onClick={(e) => e.preventDefault()}>
                          <MdInfoOutline />
                        </a>
                      </span>
                      <Tooltip placement='bottom' anchorElement={document.querySelector(`#${categoryTooltipKey}`)}>
                        <span
                          style={{ fontWeight: 'normal' }}
                          dangerouslySetInnerHTML={{
                            __html: categoryDescription,
                          }}
                        />
                      </Tooltip>
                    </React.Fragment>
                  )}
                </li>
                {valueExpressions.map((ve, i) => (
                  <ValueExpressionListItem
                    key={'first-level' + i}
                    onOptionExpand={_onOptionExpand}
                    expanded={ve.key === expandedValueExpression}
                    category={c}
                    valueExpression={ve}
                    onOptionClick={_onOptionClick}
                    onOptionHover={_onOptionHover}
                    selected={ve.key === selectedValueExpression}
                  />
                ))}
              </>
            );
          })}
      </ul>
    );
  }, [
    _onOptionClick,
    _onOptionExpand,
    _onOptionHover,
    categories,
    categorizedValueExpressions,
    expandedValueExpression,
    selectedValueExpression,
  ]);

  const emptySearchFilter = useCallback(() => {
    setSearchFilterText('');
  }, []);

  const toggleSelect = useCallback(() => {
    setOpen((v) => !v);
    setSearchFilterText('');
    setSelectedValueExpression(null);
    setExpandedValueExpression(null);
  }, []);

  const searchFilterChanged = useCallback((event) => {
    setSearchFilterText(event.target.value);
  }, []);

  useEffect(() => {
    if (open) {
      searchFilter.current?.focus();
    }
  }, [open]);

  const [ref, setRef] = useState(null);

  const closeWindowIfOpen = useCallback(() => setOpen(false), []);
  useOnClickOutside(ref, closeWindowIfOpen);
  useKeyboardShortcut({ code: 'Escape' }, closeWindowIfOpen);

  const getButtonForType = (type: ExtendedModeType, label: string) => (
    <Button
      size='sm'
      className={classNames(Styles.BtnMode, {
        [Styles.Selected]: extendedMode === type,
      })}
      onClick={() => _onExtendedModeClick(type)}>
      {label}
    </Button>
  );

  return (
    <div className={Styles.ValueExpressionSelector}>
      <div ref={setRef}>
        <div
          className={classNames('btn', Styles.BtnPrimary, 'btn-block', {
            [Styles.Empty]: !currentValueExpression,
          })}
          onClick={toggleSelect}>
          <div className={Styles.ValueExpressionLabel}>
            {currentValueExpression && (
              <span id='value-expression-infotag'>
                <MdInfoOutline size='18' />
                <Tooltip placement='bottom' anchorElement={document.querySelector('.value-expression-infotag')}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: currentValueExpression.getDescription(),
                    }}
                  />
                </Tooltip>
              </span>
            )}
            <span className={Styles.Label}>{currentValueExpression?.getLongLabel() || 'Bitte Wert auswählen...'}</span>
          </div>
          <MdArrowDropDown size={18} />
        </div>
      </div>
      {open && (
        <PopOver anchorElement={ref} visible={open} placement={'bottom-start'} onClose={closeWindowIfOpen}>
          <div
            className={classNames(Styles.SelectPopper, {
              'extended-mode': extendedMode,
            })}
            style={{
              display: 'flex',
              zIndex: 5,
            }}>
            <div className={Styles.SearchInput}>
              <div className={Styles.Icon}>
                <MdSearch />
              </div>
              <input
                ref={searchFilter}
                placeholder='Durchsuchen'
                value={searchFilterText}
                onChange={searchFilterChanged}
              />
              <div className={Styles.RemoveButton} onClick={emptySearchFilter}>
                <IoIosCloseCircleOutline />
              </div>
            </div>
            <div className={Styles.List}>{renderOptions()}</div>
            {extendedMode && (
              <div className={Styles.ExtendedTabs}>
                <ButtonGroup className={Styles.BtnGroup}>
                  {getButtonForType('simple', 'Einfach')}
                  {getButtonForType('quotient', 'Geteilt durch')}
                  {getButtonForType('subtype', 'Unterwert')}
                </ButtonGroup>
                {extendedMode === 'quotient' && renderOptions()}
                {extendedMode === 'subtype' && <div>Bitte Anzeigeschlüssel wählen</div>}
                <div>
                  <Button onClick={onSubmit}>OK</Button>
                  <Button onClick={onCancel}>ABBRECHEN</Button>
                </div>
              </div>
            )}
          </div>
        </PopOver>
      )}
    </div>
  );
};
export default ValueExpressionSelector;
