import { useTourDetails } from '@hooks/use-tour-details-hook';
import { IRawAnomalyResponse } from '@legacy-modules/dashboard/anomalies/interfaces/IRawAnomalyResponse';
import { ServiceBadge } from '@legacy-modules/tour/components/ServicesFilterPanel';
import TourDetailsInTimeGenerator from '@legacy-modules/tour/converters/TourDetailsInTimeGenerator';
import { useListOfServicesForStop } from '@legacy-modules/tour/hook/useListOfServicesForStop';
import FinishedDelivery from '@legacy-modules/tour/models/entities/FinishedDelivery';
import TourDetails from '@legacy-modules/tour/models/entities/TourDetails';
import TourDetailsInTime from '@legacy-modules/tour/models/entities/TourDetailsInTime';
import { TourFilterServiceInstance } from '@legacy-modules/tour/services/TourFilterService';
import { knownAnomalyFilter } from '@legacy-modules/tour/utils/KnownAnomalyFilter';
import { filterStopsByBadge } from '@legacy-modules/tour/utils/getDeliveryBadge/GetDeliveryBadge';
import { selectTourDetailsTourIdentifier } from '@redux/tour-details.selectors';
import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';

export type TourDetailsContextType = {
  signaturesState: [boolean, Dispatch<React.SetStateAction<boolean>>];
  tourInTimeState: [boolean, Dispatch<React.SetStateAction<boolean>>];
  highlightedMarkerState: [string, Dispatch<React.SetStateAction<string>>];
  anomalyFilterState: [string[], Dispatch<React.SetStateAction<string[]>>];
  zustellartFilterState: [string[], Dispatch<React.SetStateAction<string[]>>];
  serviceFilterState: [string[], Dispatch<React.SetStateAction<string[]>>];
  tourDetails: TourDetails;
  tourDetailsInTime: TourDetailsInTime;
  finishedDeliveries: FinishedDelivery[];
  filteredFinishedDeliveries: FinishedDelivery[];
  tourDetailsLoading: boolean;
  allAnomalies: IRawAnomalyResponse[];
  showOnlyWithAnomalies: boolean;
  toggleShowOnlyWithAnomalies: () => void;
};

const defaultValue: TourDetailsContextType = {
  signaturesState: [false, () => null],
  tourInTimeState: [false, () => null],
  highlightedMarkerState: [null, () => null],
  anomalyFilterState: [null, () => null],
  zustellartFilterState: [null, () => null],
  serviceFilterState: [null, () => null],
  tourDetails: null,
  tourDetailsInTime: null,
  finishedDeliveries: [],
  filteredFinishedDeliveries: [],
  tourDetailsLoading: false,
  allAnomalies: [],
  showOnlyWithAnomalies: false,
  toggleShowOnlyWithAnomalies: () => null,
};
export const TourDetailsContext = createContext(defaultValue);

export const useTourDetailsContext = (): TourDetailsContextType => {
  return useContext<TourDetailsContextType>(TourDetailsContext);
};

type TourDetailsContextProviderProps = Required<PropsWithChildren<{}>>;

export default function TourDetailsContextProvider({ children }: TourDetailsContextProviderProps): ReactElement {
  const signaturesState = useState<boolean>(false);
  const tourInTimeState = useState<boolean>(false);
  const highlightedMarkerState = useState<string>(null);
  const anomalyFilterState = useState<string[]>([]);
  const zustellartFilterState = useState<string[]>([]);
  const serviceFilterState = useState<string[]>([]);
  const [showOnlyWithAnomalies, setShowOnlyWithAnomalies] = useState(false);

  const tourIdentifier = useSelector(selectTourDetailsTourIdentifier);

  const { data: tourDetails, isLoading: tourDetailsLoading } = useTourDetails(tourIdentifier);

  const tourDetailsInTime = useMemo(() => {
    const tourDetailsInTimeGenerator = new TourDetailsInTimeGenerator();
    return tourDetailsInTimeGenerator.convert(tourDetails);
  }, [tourDetails]);

  const finishedDeliveries = useMemo(
    () => Object.values(tourDetails?.finishedDeliveries || {}),
    [tourDetails?.finishedDeliveries]
  );

  const { buildListOfServicesForStop } = useListOfServicesForStop({ deliveryList: finishedDeliveries });

  const allAnomalies = useMemo(() => {
    return tourDetails?.anomalies?.filter((a) => a.anomalyLevel !== 'tour')?.filter((a) => knownAnomalyFilter(a));
  }, [tourDetails]);

  const filteredFinishedDeliveries = useMemo(() => {
    if (showOnlyWithAnomalies) {
      return TourFilterServiceInstance.filterByAnomalies(true, allAnomalies, finishedDeliveries);
    }
    const services = buildListOfServicesForStop() as unknown as ServiceBadge[];
    const activeServices = serviceFilterState[0].map((filter) => services[filter]);
    const filteredByServices = TourFilterServiceInstance.filterByServices(activeServices, finishedDeliveries);
    return filterStopsByBadge(zustellartFilterState[0], filteredByServices);
  }, [
    finishedDeliveries,
    zustellartFilterState,
    serviceFilterState,
    allAnomalies,
    showOnlyWithAnomalies,
    buildListOfServicesForStop,
  ]);

  const toggleShowOnlyWithAnomalies = useCallback(() => {
    setShowOnlyWithAnomalies((state) => !state);
  }, [setShowOnlyWithAnomalies]);

  const context: TourDetailsContextType = useMemo(
    () => ({
      signaturesState,
      tourInTimeState,
      highlightedMarkerState,
      anomalyFilterState,
      zustellartFilterState,
      serviceFilterState,
      tourDetails,
      tourDetailsInTime,
      tourDetailsLoading,
      finishedDeliveries,
      filteredFinishedDeliveries,
      allAnomalies,
      showOnlyWithAnomalies,
      toggleShowOnlyWithAnomalies,
    }),
    [
      signaturesState,
      tourInTimeState,
      highlightedMarkerState,
      anomalyFilterState,
      zustellartFilterState,
      serviceFilterState,
      tourDetails,
      tourDetailsInTime,
      tourDetailsLoading,
      finishedDeliveries,
      filteredFinishedDeliveries,
      allAnomalies,
      showOnlyWithAnomalies,
      toggleShowOnlyWithAnomalies,
    ]
  );

  return <TourDetailsContext.Provider value={context}>{children}</TourDetailsContext.Provider>;
}
