import { QueryInterface } from '../../../datamanager/models/queries/QueryInterface';
import moment from 'moment-timezone';
import BasePayload from '../../../common/models/websocket/payloads/BasePayload';
import { WeekdaysFilter, defaultWeekdaysFilter } from '@legacy-modules/dashboard/models/enums/Duration';

export default class MetricsBaseQuery implements QueryInterface {
  constructor(
    public readonly type: string,
    public readonly dateFrom: moment.Moment,
    public readonly dateUntil: moment.Moment,
    public readonly weekdayFilter: WeekdaysFilter = defaultWeekdaysFilter,
    public readonly valueKey?: string
  ) {}

  // FIXME: Lookup after run
  // @ts-ignore
  toRequestType() {
    throw new Error('invalid call');
  }

  toRequestPayload(): BasePayload<any> {
    throw new Error('invalid call');
  }
}
