import { ChartType } from '../../../metrics2/models/enumerations/ChartType';
import { PositiveDirections } from '../../../metrics2/models/entities/PositiveDirection';
import { CloneInterface } from '@legacy-modules/utils/CloneInterface';
import { ValueFormatter } from '@legacy-modules/valueexpressions/models/types/ValueFormatter';
import formatNumber from 'format-number-with-string';
import { KpiDefinitionWithoutName } from '@custom-types/kpi';
import Model, { DefaultType, MemberType } from '@legacy-modules/utils/Model';

const stundenToMillisekundenMultiplier = 60 * 60 * 1000;

export enum TargetValueType {
  QUOTE,
  SUM,
}

export type ChartRenderOptions = {
  // If true, the chart will always start on 0 on the y-axis. This can result in a lot of white spaces if all
  // values are close to each other and far away from zero, e.g. high percentage numbers (98% - 100%).
  startYAxisOnZero?: boolean;
};

export default class ValueExpression extends Model<ValueExpression> implements CloneInterface {
  identifier: string;
  chartRenderOptions: ChartRenderOptions = {};
  nullValue: string = '-';
  definition?: KpiDefinitionWithoutName;
  percent: boolean = false;
  decimalPlaces: number = 0;
  targetValueType?: TargetValueType;
  label: string;
  longLabel?: string;
  shortLabel?: string;
  positiveDirection: PositiveDirections;
  category: string | null | undefined;
  description: string | null | undefined;
  valueFormat: string;
  hidden: boolean | null | undefined;
  chart: ChartType;
  valueQuotient: number = 1;
  color?: string;
  children: ValueExpression[] = [];

  constructor(config: Partial<ValueExpression>) {
    super();
    this.hydrateFrom(config, false, this.getDefaults(), this.getMembers());
  }

  clone(): ValueExpression {
    return new ValueExpression(this);
  }

  get key(): string {
    return this.identifier;
  }

  get chartType(): ChartType {
    return this.chart ?? ChartType.line;
  }

  getLabel(): string {
    return this.label;
  }

  getShortLabel(): string {
    if (this.shortLabel) {
      return this.shortLabel;
    } else return this.getLabel();
  }

  getLongLabel(): string {
    if (this.longLabel) {
      return this.longLabel;
    } else return this.getLabel();
  }

  getDescription(): string | null | undefined {
    return this.description;
  }

  getValueFormat(): string {
    return this.valueFormat;
  }

  hasChildren(): boolean {
    return this.getChildren().length > 0;
  }

  getChildren(): Array<ValueExpression> {
    return this.children;
  }

  getValueFormatter(_language?: string): ValueFormatter {
    return (value: number) => {
      const safeValue = value || 0;
      let valueFormat = this.getValueFormat();
      if (valueFormat?.endsWith('hh:mm')) {
        const time = Intl.DateTimeFormat('de-DE', {
          timeStyle: 'short',
          // use UTC here because we doesn't want to deal with timezone offsets
          // if you set German timezone, you will get 23:00 instead of 22:00 after formatting
          timeZone: 'UTC',
        }).format(Math.abs(safeValue * stundenToMillisekundenMultiplier));
        return safeValue < 0 ? '-' + time : time;
      }
      if (!valueFormat.startsWith('-')) {
        valueFormat = `-${valueFormat}`;
      }
      if (valueFormat.endsWith('h')) {
        if (Math.abs(safeValue) < 1) {
          const minutes = Math.round(safeValue * 60);
          if (Math.abs(minutes) === 60) {
            return `${minutes / 60}:00 h`;
          }
          return `${minutes} min`; // 0,5 -> "30 min" 0,49 -> "29 min" 0.51 -> "31 min"
        }
        const rest = Math.abs(safeValue) % 1;
        const hours = Math.abs(safeValue) - rest;
        const roundedMinutes = Math.round(rest * 60);
        // Im Grenzfall wollen wir bei z.B. 59 min und 31 sec auf 1 Stunde runden anstatt auf 60 Minuten
        if (roundedMinutes === 60) {
          return `${hours + 1}:00 h`;
        }
        // 7,5 -> "7:30 h" 7,49 -> "7:29 h" 7.51 -> "7:31 h"
        return `${safeValue >= 0 ? hours : -hours}:${roundedMinutes > 9 ? roundedMinutes : '0' + roundedMinutes} h`;
      }
      return formatNumber(safeValue, valueFormat);
    };
  }

  getTargetValueType(): TargetValueType {
    return this.targetValueType !== undefined ? this.targetValueType : TargetValueType.SUM;
  }

  getDefaults(): DefaultType<ValueExpression> {
    return {
      valueFormat: () => '-#.###.##0',
      hidden: () => false,
    };
  }

  getMembers(): MemberType<ValueExpression> {
    return undefined;
  }
}
