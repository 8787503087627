import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import ValueExpression, {
  TargetValueType,
} from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';

export default new ValueExpression({
  identifier: 'tourstrecke_avg',
  label: 'Ø Tourstrecke',
  positiveDirection: PositiveDirection.down,
  valueFormat: '-#.###.##0,0 km',
  valueQuotient: 1000,
  definition: getDefinitionByName('Tourstrecke'),
  targetValueType: TargetValueType.QUOTE,
  decimalPlaces: 2,
});
