import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

export default new ValueExpression({
  identifier: 'stock.remaining_express',
  label: 'Liegenbleiber Eil',
  chart: ChartType.bar,
  positiveDirection: 'down',
  category: 'stock',
  description: '<p>Description for stock.remaining_express - tbd</p>\n',
});
