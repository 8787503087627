import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';

import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

export default new ValueExpression({
  identifier: 'finished_deliveries.count',
  label: 'Zustellvorgänge',
  chart: ChartType.bar,
  positiveDirection: PositiveDirection.custom,
  category: 'deliveries',
  description:
    '<p>Anzahl aller abgeschlossenen Zustellvorgänge, bei der ein Zustellvorgang die Bearbeitung eines oder mehrerer ' +
    'Pakete für ein oder mehrere Empfänger ist</p>\n',
});
