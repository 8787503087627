import React, { useCallback, useMemo, useState } from 'react';
import { Modal, ModalBody, ModalHeader, Input } from 'reactstrap';
import '../assets/kpiDefinitionModal.scss';
import { filter, sortBy } from 'lodash';
import { definitions } from '../util/kpiDefinitions';
import { KpiDefinition } from '@custom-types/kpi';

const sortDefinitions = (definitions: Array<KpiDefinition>) => {
  return sortBy(definitions, (o) => o.name?.toLowerCase());
};

type Props = {
  visible: boolean;
  onClose: () => void;
};

const KpiDefinitionsModal: React.FC<Props> = ({ visible, onClose }) => {
  const [searchValue, setSearchValue] = useState('');

  const filteredKpiList = useMemo(() => {
    const search = searchValue.toLowerCase();
    const filteredKpiList = filter(definitions, (kpi) => kpi.name.toLowerCase().includes(search));
    return sortDefinitions(filteredKpiList);
  }, [searchValue, definitions]);

  const _filterKpiDefinitions = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  }, []);

  return (
    <div>
      <Modal
        centered={true}
        isOpen={visible}
        autoFocus={true}
        style={{ zIndex: 9999 }}
        toggle={onClose}
        className='kpi-modal'>
        <ModalHeader className='modal-header-kpidef' toggle={onClose}>
          <span>Kennzahl-Definitionen</span>
        </ModalHeader>
        <Input
          onChange={_filterKpiDefinitions}
          value={searchValue}
          type='text'
          className='search-input'
          placeholder='Kennzahlen'
        />
        <ModalBody>
          <table>
            <thead>
              <tr>
                <th
                  style={{
                    width: '20%',
                    borderBottom: 0,
                    fontWeight: 400,
                    backgroundColor: 'var(--gray20)',
                  }}>
                  Kennzahl
                </th>
                <th
                  style={{
                    width: '30%',
                    borderBottom: 0,
                    fontWeight: 400,
                    backgroundColor: 'var(--gray20)',
                  }}>
                  Definition
                </th>
                <th
                  style={{
                    width: '50%',
                    borderBottom: 0,
                    fontWeight: 400,
                    backgroundColor: 'var(--gray20)',
                  }}>
                  Erklärung
                </th>
              </tr>
              <tr>
                <th style={{ height: '15px' }} />
              </tr>
            </thead>
            <tbody>
              {filteredKpiList?.length ? (
                filteredKpiList.map((d) => (
                  <tr key={`key-${d.name}`} className='kpi-tr'>
                    <td
                      style={{
                        width: '20%',
                        borderBottom: '1px solid rgba(0,0,0,0.2)',
                        padding: '5px 15px',
                      }}>
                      <strong>{d.name}</strong>
                    </td>
                    <td
                      style={{
                        width: '30%',
                        borderBottom: '1px solid rgba(0,0,0,0.2)',
                        padding: '5px 15px',
                      }}>
                      {d.definition}
                    </td>
                    <td
                      style={{
                        width: '50%',
                        borderBottom: '1px solid rgba(0,0,0,0.2)',
                        padding: '5px 15px',
                      }}>
                      {d.explanation.split('\n').map((item, key) => {
                        return <p key={key} dangerouslySetInnerHTML={{ __html: item }} />;
                      })}
                    </td>
                  </tr>
                ))
              ) : (
                <span
                  style={{
                    color: 'rgba(0, 0, 0, 0.4)',
                    width: '100%',
                    padding: '5px 15px',
                    position: 'absolute',
                    top: '150px',
                    textAlign: 'center',
                  }}>
                  Keine Ergebnisse gefunden
                </span>
              )}
            </tbody>
          </table>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default KpiDefinitionsModal;
