import { QueryInterface } from '../../../datamanager/models/queries/QueryInterface';
import moment from 'moment-timezone';
import { DateRangeGrouping } from '../enumerations/DateRangeGrouping';
import MetricsQueryRequestPayload from '../websocket/metrics/MetricsQueryRequestPayload';
import MetricsBaseQuery from './MetricsBaseQuery';
import { AggregationType } from '../enumerations/AggregationType';
import { WeekdaysFilter } from '@legacy-modules/dashboard/models/enums/Duration';

export default class MetricsQuery extends MetricsBaseQuery implements QueryInterface<MetricsQueryRequestPayload> {
  constructor(
    type: string,
    public readonly orgKey: string,
    dateFrom: moment.Moment,
    dateUntil: moment.Moment,
    public readonly grouping: DateRangeGrouping = DateRangeGrouping.day,
    valueKey?: string,
    protected readonly contractorKey: string | null = null,
    weekdayFilter?: WeekdaysFilter,
    private aggregation?: AggregationType
  ) {
    super(type, dateFrom, dateUntil, weekdayFilter, valueKey);
  }

  toRequestType() {
    return 'metrics.query';
  }

  toRequestPayload(): MetricsQueryRequestPayload {
    return new MetricsQueryRequestPayload({
      type: this.type,
      orgKey: this.orgKey,
      dateFilter: {
        range: {
          from: moment(this.dateFrom).format('YYYY-MM-DD'),
          until: moment(this.dateUntil).format('YYYY-MM-DD'),
        },
        weekdays: this.weekdayFilter,
      },
      grouping: this.grouping,
      valueKey: this.valueKey,
      contractorKey: this.contractorKey && this.orgKey.startsWith('oz_t:') ? this.contractorKey : null,
    });
  }
}
