import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';

import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

export default new ValueExpression({
  identifier: 'finished_deliveries.notifications.count',
  label: 'Benachrichtigungen',
  chart: ChartType.bar,
  positiveDirection: PositiveDirection.custom,
  category: 'deliveries',
  description: '<p>Anzahl aller auf Tour angefallenen Benachrichtigungen (BNK, Email)</p>\n',
  definition: getDefinitionByName('Benachrichtigungen'),
});
