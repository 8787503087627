import React, { useCallback, useMemo, useState } from 'react';
import { Button, ButtonGroup, Input } from 'reactstrap';
import classNames from 'classnames';

import { ColumnFilterContentProps, ValueExpressionDataTableColumnMeta } from '@data-table/data-table.types';
import { FilterControls } from '../../filter-controls';

import sharedStyle from '../value-expression-filter-content.module.scss';

const DecimalValueExpressionFilterContent = <T, V>({
  column,
  decimalPlaces = 2,
  onCancel,
  onConfirm,
}: ColumnFilterContentProps<T, V> & { decimalPlaces?: number }) => {
  const filterValue = column.getFilterValue();

  const [value, setValue] = useState(() => {
    const _filterValue = filterValue?.toString()?.replace(/>|</g, '');
    if (_filterValue) {
      return Number(_filterValue)?.toLocaleString('de-DE');
    }
    return '';
  });

  const meta = column?.columnDef?.meta as ValueExpressionDataTableColumnMeta;

  const [toggleState, setToggleState] = useState<'greater' | 'less'>(
    filterValue?.toString()?.startsWith('<') ? 'less' : 'greater'
  );

  const title = useMemo(() => {
    return `${meta?.valueExpression?.getLabel() ?? 'Wert'} filtern`;
  }, [meta?.valueExpression]);

  const onConfirmCallback = useCallback(() => {
    if (value === '') {
      onConfirm(undefined);
      return;
    }
    const numberValue = Number(value.replace(/,/g, '.'));
    if (isFinite(numberValue)) {
      onConfirm(`${toggleState === 'less' ? '<' : '>'}${numberValue}`);
    }
  }, [onConfirm, toggleState, value]);

  const regexp = useMemo(() => {
    // This regular expression matches a number that can have up to 10 digits, optionally followed by a comma
    // and another up to 3 digits. The entire number is optional, meaning it can also match an empty string or undefined
    return decimalPlaces === 3 ? /^((100)|(\d{1,10})(,)?(\d{1,3})?)?$/ : /^((100)|(\d{1,10})(,)?(\d{1,2})?)?$/;
  }, [decimalPlaces]);

  const onValueChange = useCallback((e) => {
    setValue(e.target.value);
  }, []);

  const onFilterToggleChange = useCallback(
    (value) => () => {
      setToggleState(value);
    },
    []
  );

  const isGreaterToggleState = toggleState === 'greater';
  const isValid = regexp.test(value);

  return (
    <div className={classNames(sharedStyle.filterContent)}>
      <FilterControls
        className={sharedStyle.valueExpressionFilterContent}
        inputValid={isValid}
        cancelCallback={onCancel}
        confirmCallback={onConfirmCallback}>
        <div className={sharedStyle.filterContentHeadline}>{title}</div>
        <div className={sharedStyle.valueExpressionFilterInput}>
          Nur Werte
          <ButtonGroup size='sm' className={sharedStyle.valueExpressionFilterButtonGroup}>
            <Button
              color={isGreaterToggleState ? 'primary' : 'secondary'}
              outline={!isGreaterToggleState}
              size='sm'
              onClick={onFilterToggleChange('greater')}>
              größer
            </Button>
            <Button
              color={!isGreaterToggleState ? 'primary' : 'secondary'}
              outline={isGreaterToggleState}
              size='sm'
              onClick={onFilterToggleChange('less')}>
              kleiner
            </Button>
          </ButtonGroup>
          <Input
            value={value}
            onChange={onValueChange}
            valid={value?.length > 0 && isValid}
            invalid={value?.length > 0 && !isValid}
          />
        </div>
      </FilterControls>
    </div>
  );
};

export default DecimalValueExpressionFilterContent;
