import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import ValueExpression, {
  TargetValueType,
} from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

export default new ValueExpression({
  identifier: 'zustellzeit_netto_avg',
  label: 'Ø Netto-Zustellzeit',
  definition: getDefinitionByName('Netto-Zustellzeit'),
  positiveDirection: PositiveDirection.custom,
  valueFormat: '-#.###.##0,0 h',
  valueQuotient: 3600,
  description: 'Zeit vom ersten bis zum letzten Stopp bereinigt um Pausenzeiten.',
  targetValueType: TargetValueType.QUOTE,
  decimalPlaces: 2,
});
