import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { OnChangeFn, Updater, ColumnFiltersState } from '@tanstack/react-table';
import { DataTableComponentId, DataTableUserConfig } from '@data-table/data-table.types';
import { useUserConfig } from '@hooks/use-user-config-hook';
import { TourFilterStringParser } from '@legacy-modules/dashboard/services/TourFilterStringParser';

type UseDataTableFiltersStateOutput = [
  ColumnFiltersState,
  OnChangeFn<ColumnFiltersState>,
  Dispatch<SetStateAction<ColumnFiltersState>>
];

const useDataTableFiltersState = (
  componentId: DataTableComponentId,
  defaultState: ColumnFiltersState = []
): UseDataTableFiltersStateOutput => {
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(defaultState);

  const defaultValue = useMemo(
    () => ({
      columnFilters: defaultState,
    }),
    [defaultState]
  );

  const [dataTableUserConfig, updateDataTableUserConfig, isLoading] = useUserConfig<DataTableUserConfig>(
    componentId,
    defaultValue
  );

  useEffect(() => {
    const loadedColumnFilterState = dataTableUserConfig?.columnFilters;
    if (isLoading || !loadedColumnFilterState?.length) {
      return;
    }
    const filters = filterLoadedColumnFilters(loadedColumnFilterState);
    setColumnFilters(filters);
  }, [isLoading, dataTableUserConfig?.columnFilters]);

  const saveFilters: OnChangeFn<ColumnFiltersState> = useCallback(
    (state: ColumnFiltersState | Updater<ColumnFiltersState>) => {
      const newFilter = typeof state === 'function' ? state(columnFilters) : state;
      setColumnFilters(newFilter);
      updateDataTableUserConfig({
        ...dataTableUserConfig,
        columnFilters: newFilter,
      });
    },
    [dataTableUserConfig, columnFilters, updateDataTableUserConfig]
  );

  return [columnFilters, saveFilters, setColumnFilters];
};

// Loaded column filters may still include broken tour number filters
// that need to be filtered out
const tourFilterStringParser = new TourFilterStringParser();
const filterLoadedColumnFilters = (loadedColumnFilters: ColumnFiltersState) =>
  loadedColumnFilters.filter((filter) =>
    filter.id === 'tourIdentifier' ? tourFilterStringParser.isValidFilterString(filter.value as string) : true
  );

export default useDataTableFiltersState;
