import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';

import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

const label = 'GPS Genauigkeit';
const key = 'finished_deliveries.stop_journey.location.accuracy';

const getConfig = (category) => {
  return {
    identifier: `${key}.${category}`,
    label: category,
    longLabel: `${label} (${category})`,
    chart: ChartType.bar,
    positiveDirection: PositiveDirection.custom,
    category: 'tours',
    description: '<p>GPS Genauigkeit in Metern, gruppiert</p>\n',
    hidden: true,
  };
};

const categories = ['0-5', '6-10', '11-20', '21-50', '51-100', '101-200', '>200'];
const children = categories.map((category) => new ValueExpression(getConfig(category)));

export default new ValueExpression({
  ...getConfig('sum'),
  label: label,
  longLabel: label,
  chart: ChartType.line,
  hidden: false,
  children: children,
});
