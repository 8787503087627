/* eslint-disable max-len */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ViewModeCategory } from '../../app/models/enumerations/ViewMode';
import { MdChevronLeft, MdChevronRight, MdMenu, MdOpenInNew } from 'react-icons/md';
import HermesWing from '../assets/hermes-white-wing.svg';
import AppMenuIcon from './icons/AppMenuIcon';
import { gt } from 'semver';
import ChangelogModal from '../../common/components/ChangelogModal';
import PerformanceIncreaseIcon from './icons/PerformanceIncreaseIcon';
import DashboardIcon from './icons/DashboardIcon';
import { localStorageService } from '../../common/services/LocalStorageService';
import detailsMapping from '../../dashboard/utils/DashboardDetailsMapping';
import KpiDefinitionsModal from '../../common/components/KpiDefinitionsModal';
import FaqModal from '../../common/components/FaqModal';
import SchlungsPDF from '/Schulungs-PDF.pdf';
import classNames from 'classnames';
import AuthGuard from '../../auth/components/AuthGuard';
import { Tooltip } from '../../common/components/Tooltip';
import { ContextMenuList } from '../../common/components/ContextMenuList';
import { ContextMenuItem } from '../../common/components/ContextMenuItem';
import { PopOver } from '../../common/components/PopOver';
import { ContextGroup } from '../../common/components/ContextGroup';
import { Profile } from '../../common/components/Profile';
import { LogoutModal } from '../../common/components/LogoutModal';
import Styles from './SideNavigation.module.scss';
import { WellKnownPermission } from '../../auth/constants/WellKnownPermission';
import { useIsMounted } from '../../dashboard/hooks/useIsMounted';
import {
  ConfigKey,
  getEnvVariable,
  getKeycloakConfiguration,
} from '../../common/services/EnvironmentConfigurationService';
import { useAuthContext } from '@contexts/auth-context';
import { useDeepLinkContext } from '@contexts/deep-link-context';
import { useViewMode } from '@hooks/use-view-mode-hook';
import { useDispatch, useSelector } from 'react-redux';
import { ViewMode } from '@redux/app.slice';
import {
  selectAvailableViewModes,
  selectActiveDashboardView,
  selectOverviewOrgKey,
  dashboardSlice,
  overviewSlice,
  selectDashboardOrgKey,
} from '@redux';
import { ActiveDashboardView } from '@redux/dashboard.slice';
import AppMenu from './AppMenu';

type Props = {};

export const getIconForViewMode = (viewModeKey: ViewMode) => {
  switch (viewModeKey) {
    case 'overview': {
      return (props) => <PerformanceIncreaseIcon {...props} />;
    }
    case 'dashboard': {
      return (props) => <DashboardIcon {...props} />;
    }
    default:
      return () => null;
  }
};

const keycloakConfig = getKeycloakConfiguration();

const SideNavigation: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const viewModes = useSelector(selectAvailableViewModes);
  const [selectedViewMode, setViewMode] = useViewMode();
  const activeDashboard = useSelector(selectActiveDashboardView);
  const orgKey = useSelector(selectedViewMode === 'dashboard' ? selectDashboardOrgKey : selectOverviewOrgKey);
  const deepLinkingService = useDeepLinkContext();

  const authService = useAuthContext();

  const [sideNavigationOpen, setSideNavigationOpen] = useState<boolean>(
    () => localStorageService.getRaw('sideNavigationOpen') !== '1'
  );
  const [lastChangelogVersion, setLastChangelogVersion] = useState<string>(() =>
    localStorageService.getRaw('lastChangelogVersion', '0.0.0')
  );

  const [helpDropdownOpen, setHelpDropdownOpen] = useState<boolean>(false);
  const [appPopoverVisible, setAppPopoverVisible] = useState<boolean>(false);
  const [faqVisible, setFaqVisible] = useState<boolean>(false);
  const [kpiDefinitionModalVisible, setKpiDefinitionModalVisible] = useState<boolean>(false);
  const [changelogVisible, setChangelogVisible] = useState<boolean>(false);
  const [logoutModalVisible, setLogoutModalVisible] = useState<boolean>(false);

  const showChangelogModal = useCallback(() => {
    setChangelogVisible(true);
    setHelpDropdownOpen(false);
  }, []);

  const onModalClose = useCallback(() => {
    setChangelogVisible(false);
    setLastChangelogVersion(getEnvVariable(ConfigKey.APP_VERSION));
    localStorageService.setRaw('lastChangelogVersion', getEnvVariable(ConfigKey.APP_VERSION));
  }, []);

  const mounted = useIsMounted();

  const onItemClick = useCallback(
    (viewMode: ViewMode, event) => {
      event.preventDefault();
      setViewMode(viewMode);
      dispatch(dashboardSlice.actions.setActiveDashboardView(null));
      if (selectedViewMode !== viewMode) {
        // On view change fall back to highest orgItem in Tree
        if (viewMode === 'overview') {
          dispatch(overviewSlice.actions.updateOrgKeys(authService.rootOrgKeys?.['overview']));
        } else if (viewMode === 'dashboard') {
          dispatch(dashboardSlice.actions.setOrgKey(authService.rootOrgKeys?.['dashboard']?.[0]));
        }
      }
    },
    [setViewMode, authService.rootOrgKeys, dispatch, selectedViewMode]
  );

  const showFAQModal = useCallback(() => {
    setFaqVisible(true);
    setHelpDropdownOpen(false);
  }, []);

  const showKpiDefinitionModal = useCallback(() => {
    setKpiDefinitionModalVisible(true);
    setHelpDropdownOpen(false);
  }, []);

  const hideKpiDefinitionModal = useCallback(() => setKpiDefinitionModalVisible(false), [setKpiDefinitionModalVisible]);

  const onExpandClick = useCallback(
    (event) => {
      event.preventDefault();
      setSideNavigationOpen((v) => !v);
      localStorageService.setRaw('sideNavigationOpen', sideNavigationOpen ? '1' : '0');
      window.dispatchEvent(new Event('storage'));
      window.dispatchEvent(new Event('resize'));
    },
    [sideNavigationOpen]
  );

  const onHomeClick = useCallback(() => deepLinkingService.applyDefaults(), [deepLinkingService]);

  const setActiveDashboard = useCallback(
    (activeDashboardView: ActiveDashboardView) => {
      setViewMode('dashboard');
      dispatch(dashboardSlice.actions.setActiveDashboardView(activeDashboardView));
    },
    [setViewMode, dispatch]
  );

  const toggleAppMenu = useCallback(() => {
    setAppPopoverVisible((visible) => !visible);
  }, []);

  useEffect(() => {
    if (getEnvVariable(ConfigKey.APP_VERSION) == null || lastChangelogVersion == null) {
      return;
    }
    setChangelogVisible(gt(getEnvVariable(ConfigKey.APP_VERSION), lastChangelogVersion));
  }, [lastChangelogVersion, showChangelogModal]);

  const renderDashboardSubmenu = () => {
    const sideBarExpressions = detailsMapping.filter((dm) => dm.showInSubmenu);
    return (
      <ul className='dashboard-list-menu'>
        {sideBarExpressions.map((sbe, i) => {
          const show = sbe.permission == null || authService.can(sbe.permission);

          if (
            Array.isArray(sbe.hideForOrgKeyPattern) &&
            sbe.hideForOrgKeyPattern.some((regexp) => regexp.test(orgKey))
          ) {
            return null;
          }
          return (
            show && (
              <li
                key={`sbe-${i}`}
                onClick={() => setActiveDashboard(sbe.label)}
                className={classNames('dashboard-list-menu-item', {
                  activeDashboardView: activeDashboard === sbe.label,
                })}>
                <span>{sbe.label}</span>
              </li>
            )
          );
        })}
      </ul>
    );
  };

  const sideNavItemCustom = { width: sideNavigationOpen ? '180px' : '50px' };

  const appRef = useRef(null);

  return (
    <React.Fragment>
      {faqVisible && <FaqModal onClose={() => setFaqVisible(false)} />}
      <KpiDefinitionsModal visible={kpiDefinitionModalVisible} onClose={hideKpiDefinitionModal} />
      <ChangelogModal visible={changelogVisible} onClose={onModalClose} />
      <div
        style={sideNavItemCustom}
        className={classNames(`side-navigation-bar flex-column`, {
          open: sideNavigationOpen,
        })}>
        <div className='side-navigation-item logo' onClick={onHomeClick}>
          <div className='item-icon'>
            <img src={HermesWing} alt='' />
          </div>
          <div
            style={{ display: sideNavigationOpen ? 'block' : 'none' }}
            className='item-text'
            aria-label='LM Analytics'>
            LM Analytics
          </div>
        </div>
        {viewModes.map((vm) => {
          const { details, label } = ViewModeCategory[vm];

          return (
            <a
              key={`nav-item-${vm}`}
              style={{ width: sideNavigationOpen ? '180px' : '53px' }}
              className={classNames('side-navigation-item', {
                active: vm === selectedViewMode,
                subActive: sideNavigationOpen && vm === 'dashboard' && !!activeDashboard,
              })}
              href=''
              id={`nav-item-${vm}-detail`}
              onClick={(e) => onItemClick(vm as ViewMode, e)}>
              <Tooltip
                placement='right'
                anchorElement={document.querySelector(`#nav-item-${vm}-detail`)}
                enterDelay={500}
                size={'small'}>
                <div style={{ lineHeight: 1.5 }}>{sideNavigationOpen ? details : label}</div>
              </Tooltip>
              <div className='item-icon' id={`nav-item-${vm}`}>
                {getIconForViewMode(vm)({ size: 24, color: 'red' })}
              </div>
              <div style={{ display: sideNavigationOpen ? 'block' : 'none' }} className='item-text'>
                {label}
              </div>
            </a>
          );
        })}
        {selectedViewMode === 'dashboard' && sideNavigationOpen && viewModes.includes('dashboard') && (
          <React.Fragment>{renderDashboardSubmenu()}</React.Fragment>
        )}

        <div className='side-navigation-spacer' />

        <PopOver
          anchorElement={document.querySelector('.appMenu')}
          placement='top'
          offset={[60, 20]}
          visible={appPopoverVisible}
          onClose={toggleAppMenu}>
          <AppMenu />
        </PopOver>

        <PopOver
          anchorElement={document.querySelector('.btn-information')}
          placement={'top'}
          offset={[60, 20]}
          visible={helpDropdownOpen}
          onClose={() => setHelpDropdownOpen(false)}>
          <div className={Styles.MenuWrapper}>
            <ContextMenuList>
              <ContextGroup name='Infos und Hilfe'>
                <ContextMenuItem onClick={showFAQModal}>FAQ</ContextMenuItem>
                <ContextMenuItem onClick={showChangelogModal}>Update Informationen</ContextMenuItem>
                <AuthGuard requiredPermissions={[WellKnownPermission.UiGeneralViewModesDashboard]}>
                  <ContextMenuItem
                    onClick={() => {
                      setHelpDropdownOpen(false);
                      window.open(SchlungsPDF);
                    }}>
                    Schulungsunterlagen
                    <MdOpenInNew />
                  </ContextMenuItem>
                </AuthGuard>
                <ContextMenuItem onClick={showKpiDefinitionModal}>Definitionen</ContextMenuItem>
              </ContextGroup>
              <ContextGroup name='Support'>
                {
                  // Link to Smart IT for hermes employees, otherwise show support email
                  authService.isHermesInternalUser ? (
                    <ContextMenuItem
                      onClick={() =>
                        window.open(
                          'https://hermes-dwp.onbmc.com/dwp/rest/share/OJSXG33VOJRWKVDZOBST2U2CL5IVKRKTKREU6TSOIFEVERJGORSW4YLOOREWIPKBI5DUEOBUJJBUOQRVJ5FUCURTKNEDETCSGNJUQMSMLBEEMNBGOJSXG33VOJRWKSLEHUYTSNJQGITGG33OORSXQ5CUPFYGKPKDIFKECTCPI5PUQT2NIU======'
                        )
                      }>
                      Ticket eröffnen
                      <MdOpenInNew />
                    </ContextMenuItem>
                  ) : (
                    <ContextMenuItem
                      onClick={() => {
                        window.open('mailto:lma@hermesworld.com');
                        setHelpDropdownOpen(false);
                      }}>
                      lma@hermesworld.com
                    </ContextMenuItem>
                  )
                }
              </ContextGroup>
              <ContextGroup name='Benutzer'>
                <ContextMenuItem
                  onClick={() => {
                    const baseUrl = keycloakConfig.url.replace('/auth', '');
                    const url = encodeURIComponent(`${location.protocol}//${location.host}`);
                    const realm = encodeURIComponent(keycloakConfig.realm);
                    const referrer = encodeURIComponent(keycloakConfig.resource);
                    window.location.href = `${baseUrl}/auth/realms/${realm}/account/totp?referrer_uri=${url}&referrer=${referrer}`;
                  }}>
                  Mein Profil
                  <Profile />
                </ContextMenuItem>
                <ContextMenuItem onClick={() => setLogoutModalVisible(true)}>Abmelden</ContextMenuItem>
              </ContextGroup>
            </ContextMenuList>
          </div>
        </PopOver>

        {logoutModalVisible && (
          <LogoutModal
            onDismiss={() => setLogoutModalVisible(false)}
            onLogout={() => {
              authService.logout().then(() => {
                if (!mounted.current) return;
                setLogoutModalVisible(false);
              });
            }}
          />
        )}

        <div className='appMenu side-navigation-item' ref={appRef} onClick={() => toggleAppMenu()}>
          <div className='item-icon'>
            <span style={{ fontSize: 20 }}>
              <AppMenuIcon />
            </span>
          </div>
          <div style={{ display: sideNavigationOpen ? 'block' : 'none' }} className='item-text'>
            Apps
          </div>
        </div>
        <div className='btn-information side-navigation-item' onClick={() => setHelpDropdownOpen((v) => !v)}>
          <div className='item-icon'>
            <span style={{ fontSize: 20 }}>
              <MdMenu />
            </span>
          </div>
          <div style={{ display: sideNavigationOpen ? 'block' : 'none' }} className='item-text'>
            Informationen
          </div>
        </div>
        <a className='side-navigation-item bordered' style={sideNavItemCustom} href='' onClick={onExpandClick}>
          <div className='item-icon item-icon-small' id='nav-item-expand'>
            {sideNavigationOpen ? <MdChevronLeft size={24} /> : <MdChevronRight size={24} />}
          </div>
          <div style={{ display: sideNavigationOpen ? 'block' : 'none' }} className='item-text'>
            Einklappen
          </div>
        </a>
      </div>
    </React.Fragment>
  );
};

export default SideNavigation;
