import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import ValueExpression, {
  TargetValueType,
} from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';

export default new ValueExpression({
  identifier: 'produktivitaet-atg',
  label: 'ATG-Produktivität',
  positiveDirection: PositiveDirection.custom,
  valueFormat: '-#.###.##0,0',
  valueQuotient: 1 / 3600,
  definition: getDefinitionByName('ATG-Produktivität'),
  targetValueType: TargetValueType.QUOTE,
  decimalPlaces: 2,
});
