import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import ValueExpression, {
  TargetValueType,
} from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';

export default new ValueExpression({
  identifier: 'zsb_plus_0_quote',
  label: 'ZSB+0 Standard',
  definition: getDefinitionByName('ZSB+0 Standard'),
  positiveDirection: PositiveDirection.up,
  valueQuotient: 0.01,
  valueFormat: '-#.###.##0,#0%',
  percent: true,
  chartRenderOptions: {
    startYAxisOnZero: false,
  },
  decimalPlaces: 2,
  targetValueType: TargetValueType.QUOTE,
});
