import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';

export default new ValueExpression({
  identifier: 'abholungen',
  label: 'Abholungen ohne Mitnahmen',
  positiveDirection: PositiveDirection.custom,
  definition: getDefinitionByName('Abholungen ohne Mitnahmen'),
  hidden: true,
});
