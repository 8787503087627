import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Nav, Navbar, NavItem } from 'reactstrap';
import { MdWhatshot } from 'react-icons/md';
import NavigationBarOverviewContent from '../../overview/containers/NavigationBarOverviewContent';
import AlertDisplay from './AlertDisplay';
import NavigationBarDashboardContent from '../../dashboard/containers/NavigationBarDashboardContent';
import NavigationBarDashboardShipmentsSearch from '../../dashboard/containers/NavigationBarDashboardShipmentSearch';
import { ToggleButtonGroup } from '../../common/components/ButtonGroup';
import { ToggleButton } from '../../common/components/ToggleButton';
import { ContractorViewMode } from '../constants/ContractorViewMode';
import { BsFillHouseDoorFill } from 'react-icons/bs';
import { ContractorUtils } from '../../utils/tours/ContractorUtils';
import { selectActiveView, selectLoggedIn, selectDashboard, dashboardSlice } from '@redux';

function NavigationBar() {
  const dispatch = useDispatch();
  const loggedIn = useSelector(selectLoggedIn);
  const appViewMode = useSelector(selectActiveView);
  const { contractorViewMode, orgKey, activeDashboardView } = useSelector(selectDashboard);
  const changeContractorViewMode = useCallback(
    (e: Event, mode: ContractorViewMode) => {
      if (!mode) return;
      dispatch(dashboardSlice.actions.setContractorViewMode(mode));
    },
    [dispatch]
  );

  return (
    <div style={{ maxWidth: '100%', overflow: 'hidden' }}>
      <Navbar>
        <Nav>
          {loggedIn && [
            appViewMode === 'overview' && <NavigationBarOverviewContent key='navbar-overview-content' />,
            appViewMode === 'dashboard' && <NavigationBarDashboardContent key='navbar-dashboard-content' />,
          ]}
        </Nav>
        {ContractorUtils.isContractor(orgKey) && appViewMode === 'dashboard' && (
          <ToggleButtonGroup
            onChange={changeContractorViewMode}
            value={contractorViewMode}
            disabled={activeDashboardView === 'Tour-Details'}>
            <ToggleButton variant={'large'} value={ContractorViewMode.All}>
              Alle
            </ToggleButton>
            <ToggleButton variant={'large'} value={ContractorViewMode.Home}>
              <BsFillHouseDoorFill />
            </ToggleButton>
            <ToggleButton variant={'large'} value={ContractorViewMode.TaskForce}>
              <MdWhatshot />
            </ToggleButton>
          </ToggleButtonGroup>
        )}
        <Nav>
          <NavigationBarDashboardShipmentsSearch key={'navigationBarDashboardShipmentsSearch'} />
          <NavItem>
            <AlertDisplay />
          </NavItem>
        </Nav>
      </Navbar>
    </div>
  );
}
export default NavigationBar;
