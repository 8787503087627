import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';

import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

export default new ValueExpression({
  identifier: 'lma-anomalies.usk.ruecklauf-am-tourende.count',
  label: 'Rücklauf am Tourende',
  positiveDirection: PositiveDirection.custom,
  definition: getDefinitionByName('RL am Tourende'),
  hidden: true,
});
