import { DateRange } from '../../utils/dates/DateRange';
import { WeekdaysFilter, defaultWeekdaysFilter } from '../models/enums/Duration';
import { useKpiQuery } from '@hooks/use-kpi-query-hook';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

export const useValueExpression = (
  ve: ValueExpression,
  orgKey: string,
  dateRange: DateRange,
  weekdaysFilter: WeekdaysFilter = defaultWeekdaysFilter
): { loading: boolean; data: number } => {
  const { data, isLoading } = useKpiQuery(
    {
      expressionsFilter: [ve].map((ve) => {
        return { valueExpression: ve };
      }),
      orgKeys: [orgKey],
      dateRange: dateRange,
      weekdaysFilter: weekdaysFilter,
    },
    {
      enabled: !!orgKey && !!dateRange.from && !!dateRange.to,
    },
    (data) => data.kpis.summary.kpiValues?.[0]?.value
  );

  return { loading: isLoading, data };
};
