import { ILimitBounds } from '../websocket/DashboardLoadLimitResponsePayload';
import { PositiveDirection } from '../../metrics2/models/entities/PositiveDirection';

export enum LimitType {
  LIMIT = 'limit',
  CUSTOM_DIRECTION = 'custom_drection',
  TARGET_AREA = 'target_area',
}

export abstract class AbstractLimit {
  protected constructor(public readonly type: LimitType) {}
  abstract mapLimits(mapFn: (number: number) => number): AbstractLimit;
}

export type LimitClassTypes = DefaultLimit | TargetArea | CustomLimit;

export class DefaultLimit extends AbstractLimit implements ILimitBounds {

  constructor(public readonly positive: number, public readonly negative: number) {
    super(LimitType.LIMIT);
  }

  mapLimits(mapFn: (number: number) => number): DefaultLimit {
     return new DefaultLimit(mapFn(this.positive), mapFn(this.negative))
  }
}
export class TargetArea extends AbstractLimit {
  constructor(public readonly targetAreas: Area) {
    super(LimitType.TARGET_AREA);
  }

  mapLimits(mapFn: (number: number) => number): TargetArea {
    return new TargetArea([mapFn(this.targetAreas[0]), mapFn(this.targetAreas[1])])
  }
}

export type Area = [number, number];

export class CustomLimit extends AbstractLimit {
  constructor(
    public readonly direction: PositiveDirection.up | PositiveDirection.down,
    public readonly limit: ILimitBounds
  ) {
    super(LimitType.CUSTOM_DIRECTION);
  }

    mapLimits(mapFn: (number: number) => number): CustomLimit {
        return new CustomLimit(this.direction, {
            positive: mapFn(this.limit.positive),
            negative: mapFn(this.limit.negative)
        })
    }
}
