import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import ValueExpression, {
  TargetValueType,
} from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';

export default new ValueExpression({
  identifier: 'planned_stops.order_compliance.quote',
  positiveDirection: PositiveDirection.up,
  label: 'Reihenfolgeeinhaltung',
  shortLabel: 'RF-Einhaltung',
  definition: getDefinitionByName('RF-Einhaltung'),
  chart: ChartType.bar,
  valueFormat: '-#.###.##0,#0%',
  valueQuotient: 0.01,
  percent: true,
  targetValueType: TargetValueType.QUOTE,
  decimalPlaces: 2,
});
