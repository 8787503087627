import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import ValueExpression, {
  TargetValueType,
} from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

export default new ValueExpression({
  identifier: 'Nachbarschaftsabgabe Quote',
  label: 'Nachbarschaftsabgabe Quote',
  definition: getDefinitionByName('Nachbarschaftsabgabe Quote'),
  positiveDirection: PositiveDirection.up,
  valueQuotient: 1 / 100,
  valueFormat: '-#.###.##0,#0%',
  percent: true,
  targetValueType: TargetValueType.QUOTE,
  decimalPlaces: 2,
});
