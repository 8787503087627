import { useOverviewContext } from '@contexts/overview-context';
import { useDataTableValueExpressionData } from '@data-table/hooks/use-data-table-value-expression-data-hook';
import { Duration, DurationModes } from '@legacy-modules/dashboard/models/enums/Duration';
import OrgUnit from '@legacy-modules/metrics2/models/entities/organization/OrgUnit';
import { MapValueMode } from '@legacy-modules/overview/models/enumerations/MapValueMode';
import Rounding from '@legacy-modules/utils/math/Rounding';
import { OHUtils } from '@legacy-modules/utils/tours/OHUtils';
import {
  selectCompareFilter,
  selectCompareText,
  selectOverviewValueExpression,
  selectPrimaryFilter,
  selectPrimaryText,
  selectOverviewWeekdayFilter,
} from '@redux/overview.selectors';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export type OrgUnitData = {
  label: string;
  minValue: number;
  maxValue: number;
  orgUnitData: {
    [key: string]: number;
  };
};

/**
 * Transform data for chart
 * @param map map that has orgKey as key and kpi value as value
 * @param label
 * @param parentOrgKey key of the orgUnit that needs to be filtered out
 * @returns
 */
const transformData = (map: Map<string, number>, label: string, parentOrgKey: string): OrgUnitData => {
  const result = {
    label,
    minValue: 0,
    maxValue: 0,
    orgUnitData: {},
  };
  if (map && map.size > 0) {
    map.forEach((value, key) => {
      if (key === parentOrgKey) {
        return;
      }
      result.orgUnitData[key] = value;
    });

    result.minValue = Rounding.roundedMin(Object.values(result.orgUnitData));
    result.maxValue = Rounding.roundedMax(Object.values(result.orgUnitData));
  }
  return result;
};

const calculateDiffData = (primary: OrgUnitData, compare?: OrgUnitData): OrgUnitData => {
  const result = {
    label: 'Differenz',
    minValue: 0,
    maxValue: 0,
    orgUnitData: {},
  };
  if (primary && compare) {
    Object.entries(primary.orgUnitData).forEach(([key, value]) => {
      result.orgUnitData[key] = value - (compare.orgUnitData[key] || 0);
    });
    result.minValue = Rounding.roundedMin(Object.values(result.orgUnitData));
    result.maxValue = Rounding.roundedMax(Object.values(result.orgUnitData));
  }
  return result;
};

export type UseOverviewLegendDataOutput = {
  primaryChartData: OrgUnitData;
  compareChartData: OrgUnitData | null;
  diffChartData: OrgUnitData | null;
  percentageMap: Map<string, number>;
};

export default function useOverviewLegendData(orgUnits: Array<OrgUnit>): UseOverviewLegendDataOutput {
  const primaryFilter = useSelector(selectPrimaryFilter);
  const primaryText = useSelector(selectPrimaryText);
  const compareFilter = useSelector(selectCompareFilter);
  const compareText = useSelector(selectCompareText);
  const primaryValueExpression = useSelector(selectOverviewValueExpression);
  const weekdayFilter = useSelector(selectOverviewWeekdayFilter);
  const {
    mapValueModeState: [valueMode],
  } = useOverviewContext();

  const [orgKeys, duration, valueExpressions] = useMemo(() => {
    return [
      orgUnits?.map((ou) => ou.orgKey)?.filter((orgKey) => !OHUtils.isOH(orgKey)) || [],
      {
        from: primaryFilter?.from,
        to: primaryFilter?.to,
        weekdayFilter: weekdayFilter,
        mode: DurationModes.Custom,
      } as Duration,
      [primaryValueExpression],
    ];
  }, [orgUnits, primaryFilter, weekdayFilter, primaryValueExpression]);

  const { valuesByOrgKey: primaryData } = useDataTableValueExpressionData(
    primaryFilter?.orgKey,
    orgKeys,
    valueExpressions,
    duration,
    false,
    !!primaryValueExpression && !!primaryFilter && orgKeys?.length > 0
  );

  const { valuesByOrgKey: compareData } = useDataTableValueExpressionData(
    primaryFilter?.orgKey,
    orgKeys,
    valueExpressions,
    duration,
    true,
    !!primaryValueExpression && !!primaryFilter && !!compareFilter && orgKeys?.length > 0
  );

  const primaryChartData = useMemo(() => {
    return transformData(primaryData.get(primaryValueExpression?.identifier), primaryText, primaryFilter?.orgKey);
  }, [primaryData, primaryValueExpression, primaryText]);

  const compareChartData = useMemo(() => {
    return compareData?.size > 0
      ? transformData(compareData.get(primaryValueExpression?.identifier), compareText, primaryFilter?.orgKey)
      : null;
  }, [compareData, primaryValueExpression, compareText]);

  const diffChartData = useMemo(() => {
    return compareChartData ? calculateDiffData(primaryChartData, compareChartData) : null;
  }, [primaryChartData, compareChartData]);

  const percentageMap = useMemo(() => {
    const chartData =
      valueMode === MapValueMode.difference
        ? diffChartData
        : valueMode === MapValueMode.comparison
        ? compareChartData
        : primaryChartData;

    if (!chartData) {
      return new Map<string, number>();
    }
    const values = Object.values(chartData.orgUnitData);
    const minValues = Math.min(...values);
    const maxValues = Math.max(...values);

    return Object.entries(chartData.orgUnitData).reduce((prev, [key, value]) => {
      prev.set(key, Math.max(Math.min(value && (value - minValues) / (maxValues - minValues), 0.95), 0.05));
      return prev;
    }, new Map<string, number>());
  }, [valueMode, primaryChartData, compareChartData, diffChartData]);

  return {
    primaryChartData,
    compareChartData,
    diffChartData,
    percentageMap,
  };
}
