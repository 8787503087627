import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';

import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ValueExpression, {
  TargetValueType,
} from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

export default new ValueExpression({
  identifier: 'rueckfahrtzeit/touren',
  positiveDirection: PositiveDirection.down,
  label: 'Ø Rückfahrtzeit',
  definition: getDefinitionByName('Rückfahrtzeit'),
  chart: ChartType.bar,
  valueFormat: '-#.###.##0,0 h',
  valueQuotient: 3600,
  category: 'tours',
  targetValueType: TargetValueType.QUOTE,
  decimalPlaces: 2,
});
