import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';

export default new ValueExpression({
  identifier: 'ruecklaeufer',
  positiveDirection: PositiveDirection.down,
  label: 'Rückläufer',
  definition: getDefinitionByName('Rückläufer'),
  chart: ChartType.bar,
  category: 'delivery-items',
});
