import React, { useCallback, useState } from 'react';
import classNames from 'classnames';

import { ColumnFilterContentProps } from '@data-table/data-table.types';
import { getDataTableColumnFilterTitle } from '@data-table/utils';
import { FilterControls } from '../filter-controls';
import InputWrapper from '@components/input-wrapper/input-wrapper';

import style from './text-filter-content.module.scss';

const TextFilterContent = <T, V>({ column, onCancel, onConfirm }: ColumnFilterContentProps<T, V>) => {
  const filterValue = column.getFilterValue();

  const [value, setValue] = useState<string>(filterValue?.toString() ?? '');

  const label = getDataTableColumnFilterTitle(column.id);

  const pattern = getFilterPatternByColumnId(column.id);

  const onValueChange = useCallback((e) => {
    setValue(e.target.value);
  }, []);

  const isValid = pattern.test(value);

  return (
    <div className={classNames(style.filterContent)}>
      <FilterControls
        className={style.textFilterContent}
        inputValid={isValid}
        cancelCallback={onCancel}
        confirmCallback={() => onConfirm(value)}>
        <div className={style.filterContentHeadline}>{label}</div>
        <InputWrapper inputProps={{ valid: isValid, value, onChange: onValueChange }} />
      </FilterControls>
    </div>
  );
};

const getFilterPatternByColumnId = (columnId: string): RegExp => {
  if (columnId === 'sendungsId') {
    return /^([hH])?(\d+)?$/;
  }
  return /^[a-zA-Z0-9äöüÄÖÜß]+$/;
};

export default TextFilterContent;
