import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import ValueExpression, {
  TargetValueType,
} from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

export default new ValueExpression({
  identifier: 'zustellungen_pro_stunde',
  label: 'Zustellungen pro Stunde',
  positiveDirection: PositiveDirection.custom,
  valueFormat: '-#.###.##0,0',
  valueQuotient: 1 / 3600,
  targetValueType: TargetValueType.QUOTE,
  decimalPlaces: 2,
});
