import React, { ReactElement, useCallback, useState } from 'react';
import classNames from 'classnames';

import { TourFilterStringParser } from '@legacy-modules/dashboard/services/TourFilterStringParser';
import { ColumnFilterContentProps } from '@data-table/data-table.types';
import { FilterControls } from '../filter-controls';
import { CodeListing } from '@legacy-modules/common/components/Code';
import InputWrapper from '@components/input-wrapper/input-wrapper';

import style from './tour-number-filter-content.module.scss';

const tourFilterStringParser = new TourFilterStringParser();

const TourNumberFilterContent = <T, V = string>({
  column,
  onCancel,
  onConfirm,
}: ColumnFilterContentProps<T, V>): ReactElement => {
  const filterValue = column.getFilterValue();

  const [value, setValue] = useState<string>(filterValue?.toString() ?? '');

  const onValueChange = useCallback((e) => {
    setValue(e.target.value);
  }, []);

  const onConfirmCallback = useCallback(() => {
    onConfirm(value);
  }, [onConfirm, value]);

  const isValid = tourFilterStringParser.isValidFilterString(value);

  return (
    <div className={classNames(style.filterContent)}>
      <FilterControls
        className={style.numberFilterContent}
        inputValid={isValid}
        confirmCallback={onConfirmCallback}
        cancelCallback={onCancel}>
        <div className={style.filterContentHeadline}>Tourenbereich eingrenzen</div>
        <div className={style.numberFilterContentContainer}>
          <InputWrapper
            inputProps={{ placeholder: 'z.B. 1-5, 8, 11-15, !13', value, valid: isValid, onChange: onValueChange }}
            onInputClear={() => setValue('')}
          />
          <div className={style.filterContentInset}>
            <div className={style.filterContentInfoText}>
              Zur Filterung kannst Du hier einzelne Touren per Komma getrennt, oder bestimmte Bereiche durch Bindestrich
              verbunden, in das Textfeld schreiben. Mit einem Ausrufezeichen können einzelne Touren sowie Gruppen
              ausgeschlossen werden
            </div>
            <div className={style.filterContentExample}>
              <div className={style.filterContentExampleHeadline}>Beispiele</div>
              <div className={style.filterContentExampleSection}>
                <CodeListing className={style.filterContentExampleCodeListing}>2, 4, 7-10</CodeListing>
                <span>Touren 2, 4 und 7 bis 10</span>
              </div>
            </div>
            <div className={style.filterContentExample}>
              <div className={style.filterContentExampleSection}>
                <CodeListing className={style.filterContentExampleCodeListing}>11-20, !15</CodeListing>
                <span>Touren 11 bis 20 ohne Tour 15</span>
              </div>
            </div>
          </div>
        </div>
      </FilterControls>
    </div>
  );
};

export default TourNumberFilterContent;
