import { useQuery, UseQueryResult } from 'react-query';
import { useSelector } from 'react-redux';

import { ConfigKey, getEnvVariable } from '@legacy-modules/common/services/EnvironmentConfigurationService';
import { selectTokenId } from '@redux/auth.selectors';
import ShipmentSearchEntry, {
  ShipmentSearchEntryApiModel,
} from '@legacy-modules/shipments/models/entities/ShipmentSearchEntry';
import { isValidCode } from '@legacy-modules/dashboard/utils/ShipmentHistoryUtils';

const baseHttpUrl = getEnvVariable(ConfigKey.HTTP_API_URL);

export default function useShipmentSearch(
  barcode: string,
  enabled: boolean = true
): UseQueryResult<ShipmentSearchEntry[]> {
  const tokenId = useSelector(selectTokenId);
  const url = new URL(`${baseHttpUrl}/shipments/${barcode}`);

  return useQuery<ShipmentSearchEntryApiModel[], unknown, ShipmentSearchEntry[]>({
    queryKey: ['shipments', barcode],
    queryFn: () =>
      fetch(url, {
        headers: {
          token: tokenId,
        },
      }).then((res) => res.json()),
    enabled: enabled && isValidCode(barcode),
    select: (data) =>
      data
        .map((r: ShipmentSearchEntryApiModel) => ShipmentSearchEntry.fromJson(r))
        .sort((a, b) => {
          return b.timestamp.unix() - a.timestamp.unix();
        }),
  });
}
