import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';

import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

export default new ValueExpression({
  identifier: 'returns.finished_deliveries.items.count',
  label: 'Abgeholte Sendungen',
  chart: ChartType.bar,
  positiveDirection: PositiveDirection.custom,
  category: 'return-items',
  description: '<p>Anzahl aller abgeholten Sendungen</p>\n',
});
