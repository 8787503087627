import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';

import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

export default new ValueExpression({
  identifier: 'lademenge_soll',
  positiveDirection: PositiveDirection.custom,
  label: 'SOLL-Lademenge',
  definition: getDefinitionByName('SOLL-Lademenge'),
  chart: ChartType.bar,
});
