import BasePayload from '../../common/models/websocket/payloads/BasePayload';
import { DefaultType, MemberType } from '../../utils/Model';
import { AbstractLimit } from '../types/AbstractLimit';
import { PlainObjectOf } from '../../metrics2/models/websocket/org/OrgUnitTreeRequestPayload';

export interface ILimitBounds {
  positive: number;
  negative: number;
}

export type LimitPayloadType = ILimitPayload | ILimitDirectionPayload | ITargetAreaPayload;

export enum LimitType {
  LIMIT = 'limit',
  CUSTOM_DIRECTION = 'custom_drection',
  TARGET_AREA = 'target_area',
}

export interface ILimitPayload {
  valueExpressionKey: string;
  limit: ILimitBounds;
  type: LimitType.LIMIT;
}

export interface ILimitDirectionPayload {
  valueExpressionKey: string;
  limit: ILimitBounds;
  type: LimitType.CUSTOM_DIRECTION;
  positiveGreater: boolean;
}

export interface ITargetAreaPayload {
  valueExpressionKey: string;
  targetAreas: [number, number];
  type: LimitType.TARGET_AREA;
}

export class DashboardLoadLimitResponsePayload extends BasePayload<DashboardLoadLimitResponsePayload> {
  payloads: Array<AbstractLimit>;

  constructor(source: PlainObjectOf<DashboardLoadLimitResponsePayload>, shallow: boolean = false) {
    super();
    this.hydrateFrom(source, shallow, this.getDefaults(), this.getMembers());
  }

  getDefaults(): DefaultType<DashboardLoadLimitResponsePayload> {
    return undefined;
  }

  getMembers(): MemberType<DashboardLoadLimitResponsePayload> {
    return undefined;
  }
}
