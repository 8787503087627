import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';

import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

export default new ValueExpression({
  identifier: 'prognose_sendungsmenge_eingang',
  label: 'Prognostizierte Sendungseingangsmenge',
  chart: ChartType.bar,
  positiveDirection: PositiveDirection.custom,
  category: 'sat',
  description: '<p>Prognostizierte Sendungseingangsmenge</p>\n',
  hidden: true,
  definition: getDefinitionByName('Prognostizierte Sendungseingangsmenge'),
});
