import React from 'react';
import Styles from './KpiIndicatorHover.module.scss';
import ValueExpression from '../../valueexpressions/models/valueexpressions/ValueExpression';
import KpiValue from './KpiValue';
import { Dot, DotColor } from '../../common/components/Dot';
import { getDirectionFromGoal } from '../utils/DirectionUtils';
import { CustomLimit, DefaultLimit, LimitClassTypes, TargetArea } from '../types/AbstractLimit';
import { isSumValueExpression } from '../hooks/useKpiIndicator';
import { defaultLimitsMap } from '@utils/limits-utils';

type Props = {
  primaryData: number;
  workdaysInRange: number;
  valueExpression: ValueExpression;
  zielwert: LimitClassTypes;
};

const KpiIndicatorHover = (props: Props) => {
  const { primaryData, workdaysInRange, valueExpression, zielwert } = props;
  const bounds = (() => {
    if (!zielwert) {
      return null;
    }
    if (zielwert instanceof CustomLimit) {
      return { positive: zielwert.limit.positive, negative: zielwert.limit.negative };
    }
    if (zielwert instanceof DefaultLimit) {
      return { positive: zielwert.positive, negative: zielwert.negative };
    }
    return null;
  })();
  const defaultLimits = defaultLimitsMap.get(valueExpression);

  const direction = getDirectionFromGoal(valueExpression, zielwert);

  const isTargetAreaGoal = zielwert instanceof TargetArea;
  const isDefaultOrCustomLimit = zielwert instanceof CustomLimit || zielwert instanceof DefaultLimit;
  const hasBounds = bounds && bounds.positive != null && bounds.negative != null;

  const boundsToUse = hasBounds ? bounds : defaultLimits;
  const hasDefaultLimits = defaultLimits?.negative != null && defaultLimits?.positive != null;

  const hoverBounds = (
    <>
      {(isDefaultOrCustomLimit || hasDefaultLimits) && (
        <>
          {!hasBounds && <p className={Styles.OrgConfigured}>Werte von Hermes gepflegt.</p>}
          <Dot style={{ marginRight: 8 }} color={DotColor.positive} />
          <span>grün</span> wenn {direction === 'up' ? '≥' : '≤'}{' '}
          <strong>
            <KpiValue valueExpression={valueExpression} veData={boundsToUse.positive} />
          </strong>
          <br />
          <Dot style={{ marginRight: 8 }} color={DotColor.negative} />
          <span>rot</span> wenn {direction === 'up' ? '<' : '>'}{' '}
          <strong>
            <KpiValue valueExpression={valueExpression} veData={boundsToUse.negative} />
          </strong>
        </>
      )}
      {isTargetAreaGoal && (
        <>
          <Dot style={{ marginRight: 8 }} color={DotColor.positive} />
          <span>grün</span> wenn zwischen{' '}
          <strong>
            <KpiValue valueExpression={valueExpression} veData={(zielwert as TargetArea).targetAreas[0]} />
          </strong>{' '}
          und{' '}
          <strong>
            <KpiValue valueExpression={valueExpression} veData={(zielwert as TargetArea).targetAreas[1]} />
          </strong>
          <br />
          <Dot style={{ marginRight: 8 }} color={DotColor.negative} />
          <span>rot</span> andernfalls
        </>
      )}
      {!bounds && !defaultLimits && !isTargetAreaGoal && <>Keine Zielwerte konfiguriert.</>}
    </>
  );

  return (
    <div className={Styles.IndicatorOverlay}>
      <h1 className={Styles.IndicatorOverlayHeadline}>Zielwerte</h1>

      <div className={Styles.IndicatorOverlayContent}>
        {isSumValueExpression(valueExpression) && (
          <div>
            Tagesdurchschnitt:{' '}
            <strong>
              <KpiValue valueExpression={valueExpression} veData={primaryData / workdaysInRange} />
            </strong>{' '}
            {workdaysInRange > 1 && (
              <>
                <br />
                (errechnet aus {workdaysInRange} Werktagen)
              </>
            )}
            <br />
            <br />
          </div>
        )}
        {hoverBounds}
      </div>
    </div>
  );
};

export default KpiIndicatorHover;
