import ValueExpression, {
  TargetValueType,
} from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';

export default new ValueExpression({
  identifier: 'Uhrzeit Tourfreigabe',
  label: 'Uhrzeit Tourfreigabe',
  positiveDirection: PositiveDirection.custom,
  valueFormat: 'hh:mm',
  valueQuotient: 3600,
  definition: getDefinitionByName('Uhrzeit Tourfreigabe'),
  targetValueType: TargetValueType.QUOTE,
  decimalPlaces: 2,
});
