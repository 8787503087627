import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';

export default new ValueExpression({
  identifier: 'lademenge_wert_ist',
  positiveDirection: PositiveDirection.custom,
  label: 'Wertsendungen auf Tour',
  chart: ChartType.bar,
  definition: getDefinitionByName('Wertsendungen auf Tour'),
});
