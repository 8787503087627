import React, { ReactElement, useMemo } from 'react';
import style from './value-expression-table-settings.module.scss';
import classNames from 'classnames';
import {
  withValueExpressionTableSettings,
  ValueExpressionTableSettingsComponentProps,
} from './with-value-expression-table-settings.hoc';
import { MdClose, MdSearch } from 'react-icons/md';
import {
  NodeData,
  ScrollBehaviour,
  SelectionBehaviour,
  TreeMenu,
  TreeMenuNode,
} from '@legacy-modules/navigation/TreeMenu';
import { KpiNode, ValueExpressionNode } from '@legacy-modules/dashboard/utils/KpiTree';
import { ValueExpressionHelpMode, ValueExpressionName } from '@legacy-modules/common/components/ValueExpressionName';

const ValueExpressionTableSettings = ({
  tree,
  searchValue,
  selectedTreeNodes,
  onSearchChange,
  onSearchClear,
  onNodeSelection,
}: ValueExpressionTableSettingsComponentProps): ReactElement => {
  return (
    <div className={style.kpiSelector}>
      <div className={style.kpiSearchInputContainer}>
        <div className={classNames(style.kpiSearchInputAddon, style.kpiSearchInputAddonLeft)}>
          <MdSearch />
        </div>
        <input
          data-testid='kpi-search-input'
          className={style.kpiSearchInput}
          tabIndex={1}
          autoFocus
          type='text'
          placeholder='Durchsuchen'
          value={searchValue}
          onChange={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onSearchChange(e.target.value);
          }}
        />
        {searchValue?.length > 0 && (
          <div
            data-testid='kpi-search-clear'
            className={classNames(style.clickable, style.kpiSearchInputAddon, style.kpiSearchInputAddonRight)}
            onClick={onSearchClear}>
            <MdClose
              className={classNames(style.kpiClearButton, {
                [style.hidden]: searchValue.length === 0,
              })}
            />
          </div>
        )}
      </div>
      <div className={style.kpiSearchResult}>
        <TreeMenu
          tree={tree}
          scrollBehaviour={ScrollBehaviour.DO_NOT_SCROLL}
          selectionBehaviour={SelectionBehaviour.ALLOW_RESELECT_SAME_VALUE}
          customLeaf={searchValue?.length > 0 ? CustomSearchResultNode : CustomTreeNode}
          multiSelection={true}
          onNodeSelection={onNodeSelection}
          selectedNodes={selectedTreeNodes}
          isCheckable={(node) => {
            // FIXME: temporary disable group selection. It causes infinite rendering loop
            return node instanceof ValueExpressionNode;
          }}
          isSelectableCallback={() => true}
          leafClassName={searchValue?.length > 0 ? style.searchResultLeaf : style.customLeaf}
        />
      </div>
    </div>
  );
};

const CustomTreeNode = (node: TreeMenuNode<NodeData>) => {
  const label = useMemo(() => {
    if (node instanceof KpiNode) {
      return <>{node.label}</>;
    } else if (node instanceof ValueExpressionNode) {
      return (
        <ValueExpressionName
          showHelpTooltip
          valueExpression={node.valueExpression}
          mode={ValueExpressionHelpMode.ShowHelpOnIconHover}
          helpDetails={'short'}
        />
      );
    } else {
      return null;
    }
  }, [node]);
  return <>{label}</>;
};

const CustomSearchResultNode = (node: ValueExpressionNode) => {
  return (
    <div>
      <em data-testid='tree-item-search-category' style={{ opacity: 0.7 }}>
        {node.parent?.label} &gt;
      </em>
      <ValueExpressionName
        showHelpTooltip
        valueExpression={node.valueExpression}
        mode={ValueExpressionHelpMode.ShowHelpOnIconHover}
        helpDetails={'short'}
      />
    </div>
  );
};

export default withValueExpressionTableSettings(ValueExpressionTableSettings);
