import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';

export default new ValueExpression({
  identifier: 'tourstrecke',
  label: 'Tourstrecke',
  positiveDirection: PositiveDirection.down,
  valueFormat: '-#.###.##0,0 km',
  valueQuotient: 1000,
  definition: getDefinitionByName('Tourstrecke'),
});
