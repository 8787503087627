import {
  DashboardDataTableDataType,
  LoadingState,
  OrgKey,
  TourIdentifier,
  ValueExpressionIdentifier,
} from '@data-table/data-table.types';
import { useDataTableCustomInfo } from '@data-table/hooks/use-data-table-custom-info-hook';
import { useDataTableValueExpressionData } from '@data-table/hooks/use-data-table-value-expression-data-hook';
import { useOrgNames } from '@data-table/hooks/use-org-names-hook';
import { useStandort } from '@data-table/hooks/use-standort-hook';
import { useTourList } from '@data-table/hooks/use-tour-list-hook';
import { escapeIdentifier } from '@data-table/utils';
import { Duration } from '@legacy-modules/dashboard/models/enums/Duration';
import { TourFilterStringParser } from '@legacy-modules/dashboard/services/TourFilterStringParser';
import OrgUnitNode from '@legacy-modules/metrics2/models/websocket/org/OrgUnitNode';
import { TourUtils } from '@legacy-modules/utils/tours/TourUtils';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import { ColumnFiltersState } from '@tanstack/react-table';

type ValueExpressionDataTableData = {
  data: DashboardDataTableDataType[];
  summaryValues: Map<ValueExpressionIdentifier, number>;
  isLoading: LoadingState;
};

const tourFilterStringParser = new TourFilterStringParser();

const useValueExpressionDataTableData = (
  orgKey: OrgKey,
  duration: Duration,
  orgTree: OrgUnitNode,
  valueExpressions: ValueExpression[] = [],
  isComparisonData: boolean = false,
  enabled: boolean = true,
  columnFilters: ColumnFiltersState = []
): ValueExpressionDataTableData => {
  const { isTour, showStandort } = useDataTableCustomInfo(orgKey, duration);

  const { uniqueTourIdentifiers, isLoading: isTourListLoading } = useTourList(
    orgKey,
    duration,
    isComparisonData,
    isTour
  );

  const orgNamesMap = useOrgNames(orgKey, orgTree);

  const standorte = useStandort(
    isTour
      ? Array.from(new Set(uniqueTourIdentifiers.map((tourIdentifier) => tourIdentifier.standortOrgKey)).keys())
      : [],
    showStandort
  );

  const knownTourNumbers = uniqueTourIdentifiers.map((identifier) =>
    parseInt(TourUtils.getTourNumberByOrgKey(identifier.tourOrgKey))
  );

  const tourNumberFilters = tourFilterStringParser.parseFilterString(
    columnFilters.find((filter) => filter.id === 'tourIdentifier')?.value as string,
    knownTourNumbers
  );

  const filteredTourOrgKeys = uniqueTourIdentifiers
    .filter((identifier) =>
      tourNumberFilters.length > 0
        ? tourNumberFilters.includes(parseInt(TourUtils.getTourNumberByOrgKey(identifier.tourOrgKey)))
        : true
    )
    .map((identifier) => identifier.tourOrgKey);

  const activeOrgKeyFilterValues = columnFilters.find((filter) => filter.id === 'orgName')?.value;

  const orgNamesMapKeys = Array.from(orgNamesMap.keys());

  const filteredOrgKeys = activeOrgKeyFilterValues
    ? orgNamesMapKeys.filter(
        (orgKey) => Array.isArray(activeOrgKeyFilterValues) && activeOrgKeyFilterValues.includes(orgKey)
      )
    : orgNamesMapKeys;

  const orgKeys = isTour ? filteredTourOrgKeys : filteredOrgKeys;

  const {
    valuesByOrgKey: valueExpressionData,
    summaryValues,
    isLoading: valueExpressionDataLoading,
  } = useDataTableValueExpressionData(
    orgKey,
    orgKeys,
    valueExpressions,
    duration,
    isComparisonData,
    enabled,
    columnFilters
  );

  const data = (
    isTour
      ? uniqueTourIdentifiers.map((tourIdentifier) => ({
          tourIdentifier: {
            tourNumber: tourIdentifier.number,
            orgKey: tourIdentifier.tourOrgKey,
            orgId: tourIdentifier.organizationId,
            tourDate: tourIdentifier.date,
            contractorKey: tourIdentifier.contractorKey,
          } as TourIdentifier,
          standort: standorte.get(tourIdentifier.standortOrgKey),
          orgKey: tourIdentifier.tourOrgKey,
          orgName: '',
        }))
      : Array.from(orgNamesMap?.entries() || []).map(([subOrgKey, orgName]) => ({
          tourIdentifier: null,
          standort: null,
          orgKey: subOrgKey,
          orgName: orgName,
        }))
  ).map((row) => {
    valueExpressions.forEach((ve) => {
      row[escapeIdentifier(ve.identifier)] = valueExpressionData?.get(ve.identifier)?.get(row.orgKey);
    });
    return row;
  });

  return { data, summaryValues, isLoading: valueExpressionDataLoading || isTourListLoading };
};

export default useValueExpressionDataTableData;
