import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';

import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

const label = 'Abgeholte Sendungen nach Art';
const key = 'returns.finished_deliveries.items.by_type';

const getConfig = (category) => {
  return {
    identifier: `${key}.${category}`,
    label: category,
    longLabel: `${label} (${category})`,
    chart: ChartType.bar,
    positiveDirection: PositiveDirection.custom,
    category: 'return-items',
    description: '<p>Anzahl aller abgeholten  Sendungen nach Art (Retoure, PriPS)</p>\n',
  };
};

const categories = ['ReturnToSender', 'NewParcel', 'PriPS', 'ProPS', 'DBCourier', 'Undeliverable'];
const children = categories.map((category) => new ValueExpression(getConfig(category)));

export default new ValueExpression({
  ...getConfig('sum'),
  label: label,
  longLabel: label,
  chart: ChartType.line,
  children: children,
});
