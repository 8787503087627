import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';

import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ValueExpression, {
  TargetValueType,
} from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

export default new ValueExpression({
  identifier: 'rueckfahrtstrecke/touren',
  positiveDirection: PositiveDirection.down,
  label: 'Ø Rückfahrtstrecke',
  definition: getDefinitionByName('Rückfahrtstrecke'),
  chart: ChartType.bar,
  valueFormat: '-#.###.##0,0 km',
  valueQuotient: 1000,
  category: 'tours',
  targetValueType: TargetValueType.QUOTE,
  decimalPlaces: 2,
});
