import { abhMitReturnTypes } from '@legacy-modules/metrics2/models/entities/MetricType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';

import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

const label = 'Mitnahmen nach Typ';
const key = 'lma-shipmentbased.MIT.all.count.by_return_type';

const getConfig = (category) => {
  return {
    identifier: `${key}.${category.key}`,
    label: category.label,
    longLabel: `${label} (${category.label})`,
    hidden: true,
    positiveDirection: PositiveDirection.custom,
  };
};

const children = abhMitReturnTypes.map((reason) => {
  return new ValueExpression(getConfig({ key: reason, label: reason }));
});

export default new ValueExpression({
  ...getConfig({ key: 'sum', label: '' }),
  label: label,
  longLabel: label,
  children: children,
});
