import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import ValueExpression, {
  TargetValueType,
} from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

export default new ValueExpression({
  identifier: 'ist_lademenge/touren',
  label: 'ø IST-Lademenge',
  definition: getDefinitionByName('ø IST-Lademenge'),
  positiveDirection: PositiveDirection.custom,
  valueFormat: '-#.###.##0,0',
  targetValueType: TargetValueType.QUOTE,
  decimalPlaces: 2,
  percent: false,
});
