import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'reactstrap';
import moment from 'moment-timezone';
import { Durations } from '../models/enums/Duration';
import { ValueFormatter } from '../../valueexpressions/models/types/ValueFormatter';
import Styles from './CalendarTableStyles.module.scss';
import { extendMoment } from 'moment-range';
import { FloatingComponent } from '../../common/components/FloatingComponent';
import { useValueExpressionContext } from '@contexts/value-expression-context';
import { selectDashboardOrgKey, selectLocalComponentOptions } from '@redux/dashboard.selectors';
import { useKpiQuery } from '@hooks/use-kpi-query-hook';
import { DateRangeGrouping } from '@legacy-modules/metrics2/models/enumerations/DateRangeGrouping';
import { DurationUtils } from '@utils/duration-utils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const { range } = extendMoment(moment as any);

type Week = { label: string; week: number; year: number };

type Calendar = {
  weeks: Array<Week>;
  from: moment.Moment;
  to: moment.Moment;
};

type Props = {
  valueExpressionKey: string;
  showTotals: boolean;
};

const DAYS_OF_WEEK: Array<number> = [1, 2, 3, 4, 5, 6];

const toDay = (week: Week, dayNum: number): moment.Moment =>
  moment().weekYear(week.year).isoWeek(week.week).isoWeekday(dayNum);

const metricByDay = (valuesByDay: Map<string, number> | null | undefined, day: moment.Moment): number => {
  const key = day.format('YYYY-MM-DD');
  return valuesByDay ? valuesByDay.get(key) : NaN;
};

const CalendarTable = (props: Props) => {
  const { showTotals, valueExpressionKey } = props;

  const reduxComponentDuration = useSelector(selectLocalComponentOptions('mengenprognoseChartPrognose', 'duration'));
  const orgKey = useSelector(selectDashboardOrgKey);
  const duration = reduxComponentDuration ?? Durations.next_28;
  const dateRange = DurationUtils.getDateRange(duration);
  dateRange.from = dateRange.from.clone().startOf('week');
  dateRange.to = dateRange.to.clone().endOf('week');

  const valueExpressionMap = useValueExpressionContext();

  const valueExpression = valueExpressionMap.get(valueExpressionKey);

  const calendar = useRef<Calendar>({
    weeks: [],
    from: moment(),
    to: moment(),
  });

  useEffect(() => {
    const { from, to } = dateRange;
    const r = range(from, to).by('week');
    const weeks = [];
    for (const week of r) {
      weeks.push({
        week: week.isoWeek(),
        year: week.weekYear(),
        label: week.format('YYYYww'),
      });
    }
    calendar.current = { weeks, from, to };
  }, [duration, orgKey, valueExpressionKey, valueExpressionMap]);

  const { data: valuesByDay } = useKpiQuery(
    {
      expressionsFilter: [valueExpression].map((ve) => {
        return { valueExpression: ve };
      }),
      orgKeys: [orgKey],
      dateRange: dateRange,
      grouping: DateRangeGrouping.day,
    },
    {
      enabled: !!orgKey && !!dateRange.from && !!dateRange.to,
    },
    (data) => {
      const valuesByDay = new Map<string, number>();
      data.kpis.groups.forEach((group) => {
        group.kpiValues.forEach((kpiValue) => {
          valuesByDay.set(group.group, kpiValue.value);
        });
      });
      return valuesByDay;
    }
  );

  const valueFormatter = (): ValueFormatter => {
    const ve = valueExpressionMap.get(valueExpressionKey);
    return ve ? ve.getValueFormatter() : (n: number) => '' + n;
  };

  const weekTotal = (absentIsInvalid: boolean) => {
    return function (week: Week): string {
      const data = valuesByDay;

      const areAreMetricsValid = (dayNum: number) => {
        const day = toDay(week, dayNum);
        const metric = metricByDay(data, day);
        return !isNaN(metric);
      };

      const allValid = DAYS_OF_WEEK.every(areAreMetricsValid);
      if (!allValid && absentIsInvalid) return '';

      return DAYS_OF_WEEK.filter(areAreMetricsValid)
        .reduce((sum, dayNum) => {
          const day = toDay(week, dayNum);
          const metric = metricByDay(data, day);
          return sum + metric;
        }, 0)
        .toString();
    };
  };

  const weekTotalStr = (week: Week): [string, string] => weekSummaryStr(week, weekTotal(false));

  const weekSummaryStr = (week: Week, totalFn: (week: Week) => string): [string, string] => {
    const fmt = valueFormatter();
    const total = totalFn(week);
    const formatted = total === '' ? '' : fmt(total);
    return [formatted, total];
  };

  const data = valuesByDay;
  if (data === undefined) return null;
  const fmt = valueFormatter();

  const cellBuilder = (week: Week) => (dayNum: number) => {
    const day = toDay(week, dayNum);
    const metric = metricByDay(data, day);
    const isToday = day.isSame(moment(), 'day');
    const todayClass = isToday ? Styles.today : '';
    const countClass = todayClass === '' ? Styles.count : todayClass;
    const originalRange = DurationUtils.getDateRange(duration);
    const showDay = day.isBetween(originalRange.from, originalRange.to, 'second', '[]');
    const pastClass = showDay || isToday ? '' : Styles.past;

    return (
      <React.Fragment key={`${week.label}_${dayNum}`}>
        <td className={`${todayClass} ${pastClass}`}>{day.format('DD.MM')}</td>
        <td className={`${countClass} ${pastClass}`}>{fmt(metric)}</td>
      </React.Fragment>
    );
  };

  const rows = calendar.current.weeks.map((week) => {
    const [totalStr, total] = weekTotalStr(week);
    return (
      Number(total) > 0 && (
        <tr key={`${week.label}_row`}>
          <td>{week.week}</td>
          {DAYS_OF_WEEK.map(cellBuilder(week))}
          {showTotals && <td className={Styles.total}>{totalStr}</td>}
        </tr>
      )
    );
  });

  const headCells = DAYS_OF_WEEK.map((day) => {
    const name = moment().day(day).format('dddd');
    return (
      <React.Fragment key={day}>
        <th colSpan={2}>{name}</th>
      </React.Fragment>
    );
  });

  const totals = showTotals && <th className={Styles.total}>∑</th>;
  const header = (
    <tr>
      <th>KW</th>
      {headCells}
      {totals}
    </tr>
  );

  if (!data) return null;

  return (
    <FloatingComponent className={Styles.CalendarTable}>
      <div className={Styles.shadow} />
      <div>
        * Eine Aktualisierung der Prognose findet im Vier-Wochen-Zyklus statt. Bitte achtet auf das angegebene Datum der
        Aktualisierung. Die tatsächlich angelieferten Sendungsmengen können von den prognostizierten Mengen abweichen.
        Bei Fragen zur Prognose wenden Sie sich an&nbsp;
        <a href='mailto:MP-Coreteam@hermesworld.com'>MP-Coreteam@hermesworld.com</a>.
      </div>
      <div className={Styles.shadow} />
      <div className={Styles.scroll}>
        <Table>
          <thead>{header}</thead>
          <tbody>{rows}</tbody>
          <tfoot />
        </Table>
      </div>
    </FloatingComponent>
  );
};

export default CalendarTable;
