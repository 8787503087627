import WebSockHop from 'websockhop';
import EventMessageFormatter from '../services/websocket/EventMessageFormatter';
import WebsocketEvent from '../models/websocket/WebsocketEvent';
import { v1 as uuidV1 } from 'uuid';
import ExtendedEventEmitter from '../../events/ExtendedEventEmitter';
import AuthTokenPayload from '../models/websocket/payloads/auth/AuthTokenPayload';
import { ConfigKey, getEnvVariable } from './EnvironmentConfigurationService';
import { AuthEvent } from './AuthService';

const apiUrl = getEnvVariable(ConfigKey.WS_API_URL);

interface requestEnvelope {
  name: string;
  payload: {
    [key: string]: unknown;
    error: number;
    message: unknown;
  };
}

export default class WebsocketApiService {
  static DEFAULT_TIMEOUT: number = 30000;
  static EVENT_SOCKET_MESSAGE_RECEIVED = 'socket.message.received';
  static EVENT_SOCKET_OPENED = 'socket.opened';
  static EVENT_SOCKET_ERROR = 'socket.error';
  static EVENT_SOCKET_CLOSED = 'socket.closed';
  static EVENT_SOCKET_AUTH_EXPIRED = 'socket.auth.expired';

  socket: WebSockHop;
  _eventEmitter: ExtendedEventEmitter;
  _requestMap: Map<string, { callback: Function; handle: number }>;
  _nextId: number;
  _socketOpen: boolean;
  _authenticatedUntil: number;

  constructor() {
    this._eventEmitter = new ExtendedEventEmitter();
    this._eventEmitter.setMaxListeners(30);
    WebSockHop.logger = () => {
      /**/
    };
    this.socket = new WebSockHop(apiUrl);
    this.socket.pingIntervalMsecs = 5000;
    this.socket._resetPingTimer();
    this.socket.on('opened', this.onOpened.bind(this));
    this.socket.on('error', this.onError.bind(this));
    this.socket.on('message', this.onMessageReceived.bind(this));
    this.socket.formatter = new EventMessageFormatter();
    this._requestMap = new Map();
    this._nextId = 0;
    this._authenticatedUntil = 0;
    WebSockHop.defaultDisconnectOnRequestTimeout = true;
    WebSockHop.disable.mobile = false;
  }

  async request<T = requestEnvelope>(event: WebsocketEvent): Promise<T> {
    const allowed = this.isAuthToken(event) || this.hasValidAuth();
    if (!allowed) {
      this._eventEmitter.emit(WebsocketApiService.EVENT_SOCKET_AUTH_EXPIRED);
    }
    return new Promise<T>((resolve, reject) => {
      const id = uuidV1();
      event.id = id;
      const handle = window.setTimeout(() => {
        reject(new Error('Websocket Request Timeout'));
        this._requestMap.delete(id);
      }, WebsocketApiService.DEFAULT_TIMEOUT);
      this._requestMap.set(id, { callback: resolve, handle });
      this.emit(event);
    });
  }

  emit(event: WebsocketEvent) {
    if (event && this._socketOpen && this.socket.socket != null && this.socket.socket.readyState === 1) {
      this.socket.send(event);
    }
  }

  onOpened() {
    this._socketOpen = true;
    this._eventEmitter.emit(WebsocketApiService.EVENT_SOCKET_OPENED);
  }

  onError() {
    this._eventEmitter.emit(WebsocketApiService.EVENT_SOCKET_ERROR);
  }

  onClosed() {
    this._socketOpen = false;
    this._eventEmitter.emit(WebsocketApiService.EVENT_SOCKET_CLOSED);
  }

  get socketOpen(): boolean {
    return this._socketOpen;
  }

  onMessageReceived(event: WebsocketEvent) {
    const handler = this._getHandlerForResponse(event);
    if (handler) {
      window.clearTimeout(handler.handle);
      handler.callback(event);
    } else {
      // emit event
      this._eventEmitter.emit(WebsocketApiService.EVENT_SOCKET_MESSAGE_RECEIVED, event);
    }
  }

  _getHandlerForResponse(event: WebsocketEvent) {
    const id = event.replyTo;
    if (!id) {
      return null;
    }
    const handler = this._requestMap.get(id);
    if (!handler) {
      return null;
    }
    this._requestMap.delete(id);
    return handler;
  }

  get socketEventFormatter(): EventMessageFormatter {
    return this.socket.formatter;
  }

  get eventEmitter(): ExtendedEventEmitter {
    return this._eventEmitter;
  }

  hasValidAuth(): boolean {
    return new Date().valueOf() <= this._authenticatedUntil;
  }

  isAuthToken(event: WebsocketEvent): boolean {
    if ([AuthEvent.REQUEST_LOGIN, AuthEvent.REQUEST_REFRESH].includes(event.name as AuthEvent)) {
      const payload = <AuthTokenPayload>event.payload;
      const exp = AuthTokenPayload._parseJwt(payload.token).exp;
      this._authenticatedUntil = exp * 1000;
      return true;
    } else {
      return false;
    }
  }
}
