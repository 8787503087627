import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';

import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ValueExpression, {
  TargetValueType,
} from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

export default new ValueExpression({
  identifier: 'anfahrtsstrecke',
  label: 'Anfahrtsstrecke',
  valueFormat: '-#.###.##0,0 km',
  valueQuotient: 1000,
  chart: ChartType.bar,
  positiveDirection: PositiveDirection.down,
  category: 'tours',
  definition: getDefinitionByName('Anfahrtsstrecke'),
  hidden: true,
  targetValueType: TargetValueType.SUM,
});
