import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import Styles from './KpiOverview.module.scss';
import { FloatingComponent } from '../../common/components/FloatingComponent';
import KpiOverviewBox from './KpiOverviewBox';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import KpiSelectorOverlay from '../kpiCompare/KpiSelectorOverlay';
import { useSelector } from 'react-redux';
import { calculateYesterday, Duration, Durations } from '../models/enums/Duration';
import classNames from 'classnames';
import { DateSelectIncludingTodayConfig } from '../../common/components/DateSelect';
import { Loader } from '../../common/components/Loader';
import { Area, CustomLimit, DefaultLimit, TargetArea } from '../types/AbstractLimit';
import { PositiveDirection } from '../../metrics2/models/entities/PositiveDirection';
import ValueExpression from '../../valueexpressions/models/valueexpressions/ValueExpression';
import { ContractorViewMode } from '../../navigation/constants/ContractorViewMode';
import { injectContractorViewMode } from '../utils/OrgKeyUtils';
import { useWindowSize } from '@legacy-modules/common/hooks/useWindowResize';
import { useValueExpressionContext } from '@contexts/value-expression-context';
import { Tabs } from '@components/tabs';
import { Tab } from '@components/tabs/components/tab';
import { useKpiTabs } from '@hooks/use-kpi-tabs-hook';
import IconButton from '@legacy-modules/common/components/IconButton';
import { KpiOverviewSettings } from '@components/kpi-overview-settings';
import { DataTableHeader as Header } from '@data-table/components/data-table-header';
import { Tooltip } from '@other-components/tooltip';
import TextButton from '@legacy-modules/common/components/TextButton';
import { PopOver } from '@legacy-modules/common/components/PopOver';
import { MdAdd } from 'react-icons/md';
import { EditableText } from '@components/editable-text';
import { useSaveOrgConfig } from '@hooks/use-save-org-config-hook';
import { selectDashboardOrgKey, selectDashboardTrendValueExpression } from '@redux/dashboard.selectors';
import Touren from '@contexts/value-expression-context/value-expressions/touren';
import { DateRangeAggregationInput } from '@graphql-client/graphql';
import { useKpiQuery } from '@hooks/use-kpi-query-hook';
import ChartUtils from '@legacy-modules/dashboard/utils/ChartUtils';

export type KpiThresholdBounds = {
  positive: number;
  negative: number;
};

export type Props = {
  maxBoxCount?: number;
};

type SortableListProps = {
  items: ValueExpression[];
  itemsPerRow: number;
  selectedKpiKey: string;
  removeItem: (index: number) => void;
  highlightLastItem: boolean;
  duration: Duration;
  workdaysInRange: number;
};

type SortableItemProps = {
  className?: string;
  item: ValueExpression;
  variation: 'small' | 'large';
  index: number;
  workingIndex: number;
  isLastInRow: boolean;
  isLast: boolean;
  selectedKpiKey: string;
  removeItem: (index: number) => void;
  highlightLastItem: boolean;
  items: ValueExpression[];
  duration: Duration;
  isInFirstRow: boolean;
  workdaysInRange: number;
};

type KpiOverviewContextType =
  | {
      setDraggingDisabled: (draggingEnabled: boolean) => void;
      onKpiLimitChange: (valueExpression: ValueExpression, bounds: KpiThresholdBounds) => void;
      onKpiCustomLimitChange: (
        valueExpression: ValueExpression,
        bounds: KpiThresholdBounds,
        direction: PositiveDirection.up | PositiveDirection.down
      ) => void;
      onTargetAreaChange: (valueExpression: ValueExpression, area: Area) => void;
      onKpiChange: (oldKpiName: string, newKpiName: string) => void;
      onKpiLimitRemove: (valueExpression: ValueExpression) => void;
    }
  | undefined;
export const KpiOverviewContext = createContext<KpiOverviewContextType>(undefined);

const SortableItem = SortableElement(
  ({
    className,
    workingIndex,
    item,
    variation,
    isLastInRow,
    isLast,
    removeItem,
    highlightLastItem,
    items,
    duration,
    isInFirstRow,
    workdaysInRange,
  }: SortableItemProps) => {
    const { onKpiLimitChange, onKpiChange } = useContext(KpiOverviewContext);
    const onKpiChangeCallback = useCallback(
      (identifier) => {
        onKpiChange(item.identifier, identifier);
      },
      [onKpiChange, item]
    );

    const onLimitChangeCallback = useCallback(
      (positive, negative) => {
        onKpiLimitChange(item, {
          positive,
          negative,
        });
      },
      [onKpiLimitChange, item]
    );

    const onRemoveCallback = useCallback(() => {
      removeItem(workingIndex);
    }, [workingIndex, removeItem]);

    if (!duration) return null;

    return (
      <div
        className={classNames(Styles.Item, Styles[className], {
          [Styles.LastInRow]: isLastInRow,
        })}>
        <KpiOverviewBox
          selectable
          duration={duration}
          className={classNames(Styles.Element, {
            [Styles.LastInRow]: isLastInRow,
            [Styles.Highlight]: isLast && highlightLastItem,
            [Styles.FirstRow]: isInFirstRow,
          })}
          key={item.identifier}
          variation={variation}
          onRemove={onRemoveCallback}
          onKpiChange={onKpiChangeCallback}
          onLimitChange={onLimitChangeCallback}
          valueExpression={item}
          selectedValueExpressions={items}
          workdaysInRange={workdaysInRange}
        />
      </div>
    );
  }
);

const SortableList = SortableContainer(({ removeItem, items, itemsPerRow, duration, ...props }: SortableListProps) => {
  return (
    <div className={Styles.KpiElements}>
      {items.map((value, index) => {
        return (
          <SortableItem
            duration={duration}
            key={`item-${value.identifier}`}
            isLastInRow={(index + 1) % itemsPerRow === 0}
            isLast={index === items.length - 1}
            item={value}
            variation={index >= itemsPerRow ? 'small' : 'large'}
            index={index}
            workingIndex={index}
            items={items}
            isInFirstRow={index < itemsPerRow}
            removeItem={removeItem}
            {...props}
          />
        );
      })}
      {(items?.length % itemsPerRow > 0 || items?.length === 0) && (
        <div
          className={classNames(Styles.FillElement, {
            [Styles.isInFirstRow]: items.length < itemsPerRow,
          })}
        />
      )}
    </div>
  );
});

const KpiOverview: React.FC<Props> = ({ maxBoxCount = 15 }) => {
  const valueExpressions = useValueExpressionContext();
  const selectedValueExpression = useSelector(selectDashboardTrendValueExpression);
  const selectedKpiKey = selectedValueExpression?.identifier;
  const dashboardOrgKey = useSelector(selectDashboardOrgKey);
  // orgKey should be set independently of the contractor view mode
  // because it should be saved and loaded without the contractor view mode suffix
  const orgKey = injectContractorViewMode(dashboardOrgKey, ContractorViewMode.All);

  const {
    config,
    configLoading,
    activeTab,
    canAddMoreTabs,
    onTabChange,
    onTabAdd,
    onKpiAdd,
    onKpiChange,
    onKpiRemove,
    onKpiSort,
    onDurationSelection,
    onTabDragOver,
    onTabDragEnd,
    onTabRemove,
    onTabRename,
  } = useKpiTabs(orgKey, 0);

  const size = useWindowSize();
  const itemsPerRow = (() => {
    const { width } = size;
    if (width < 800) return 2;
    if (width < 1200) return 3;
    return 5;
  })();

  const kpiAddButtonRef = useRef(null);
  const [kpiPopupOpen, setKpiPopupOpen] = useState(false);
  const [highlightLastItem, setHighlightLastItem] = useState(false);

  const duration = useMemo(
    () => calculateYesterday(config?.duration || Durations.yesterday) as Duration,
    [config?.duration]
  );

  const items = useMemo(() => {
    return config?.tabs?.[activeTab]?.tiles?.map((tile) => valueExpressions.get(tile)) || [];
  }, [config, valueExpressions, activeTab]);

  const selectedIdentifiers = useMemo(() => {
    return items?.map((kpi) => kpi.identifier);
  }, [items]);

  const canAddMoreKpis = items?.length < maxBoxCount;

  useEffect(() => {
    if (!highlightLastItem) {
      return;
    }
    setTimeout(() => {
      setHighlightLastItem(false);
    }, 1000);
  }, [highlightLastItem]);

  const addKpi = useCallback(
    (identifier: string) => {
      if (!canAddMoreKpis) {
        return;
      }
      setHighlightLastItem(true);
      onKpiAdd(identifier);
    },
    [canAddMoreKpis, onKpiAdd]
  );

  const onSortStart = useCallback(() => {
    document.body.classList.add('dragging');
  }, []);

  const onSortEnd = useCallback(
    ({ oldIndex, newIndex }) => {
      onKpiSort(oldIndex, newIndex);
      document.body.classList.remove('dragging');
    },
    [onKpiSort]
  );

  const onKpiPopupOpenCallback = useCallback(() => {
    setKpiPopupOpen(true);
  }, []);

  const onKpiPopupCloseCallback = useCallback(() => {
    setKpiPopupOpen(false);
  }, []);

  const onKpiSelectCallback = useCallback(
    (identifier) => {
      setKpiPopupOpen(false);
      addKpi(identifier);
    },
    [addKpi]
  );

  const range = ChartUtils.getDateRange(duration);

  const { data: workdaysInRange } = useKpiQuery(
    {
      orgKeys: [orgKey],
      expressionsFilter: [{ valueExpression: Touren }],
      dateRange: range,
      aggregation: DateRangeAggregationInput.Count,
    },
    {
      enabled: !!orgKey && !!range.from && !!range.to,
    },
    (data) => data.kpis.summary.kpiValues[0].value
  );

  const [draggingDisabled, setDraggingDisabled] = useState(false);

  const { mutate: saveLimit } = useSaveOrgConfig();

  const onKpiLimitRemove = useCallback(
    (valueExpression: ValueExpression) => {
      saveLimit({
        value: null,
        valueExpression,
        orgKey: orgKey,
      });
    },
    [saveLimit, orgKey]
  );

  const onTargetAreaChangeCallback = useCallback(
    (valueExpression: ValueExpression, area: Area) => {
      if (area[0] == null && area[1] == null) {
        onKpiLimitRemove(valueExpression);
      } else {
        saveLimit({
          value: new TargetArea([area[0], area[1]]),
          valueExpression,
          orgKey: orgKey,
        });
      }
    },
    [onKpiLimitRemove, orgKey, saveLimit]
  );

  const onKpiCustomLimitChange = useCallback(
    (
      valueExpression: ValueExpression,
      bounds: KpiThresholdBounds,
      direction: PositiveDirection.up | PositiveDirection.down
    ) => {
      const safeBoundValues = (() => {
        if (!bounds) {
          return {
            positive: null,
            negative: null,
          };
        }
        if (isNaN(bounds.negative) && isNaN(bounds.positive)) {
          return {
            positive: null,
            negative: null,
          };
        }
        return bounds;
      })();

      if (bounds.negative === null && bounds.positive === null) {
        onKpiLimitRemove(valueExpression);
      } else {
        saveLimit({
          value: new CustomLimit(direction, safeBoundValues),
          valueExpression,
          orgKey: orgKey,
        });
      }
    },
    [onKpiLimitRemove, orgKey, saveLimit]
  );

  const onKpiLimitChange = useCallback(
    (valueExpression: ValueExpression, bounds: KpiThresholdBounds) => {
      const safeBoundValues = (() => {
        if (!bounds) {
          return {
            positive: null,
            negative: null,
          };
        }
        if (isNaN(bounds.negative) && isNaN(bounds.positive)) {
          return {
            positive: null,
            negative: null,
          };
        }
        return bounds;
      })();

      if (bounds.negative === null && bounds.positive === null) {
        onKpiLimitRemove(valueExpression);
      } else {
        saveLimit({
          value: new DefaultLimit(safeBoundValues.positive, safeBoundValues.negative),
          valueExpression,
          orgKey: orgKey,
        });
      }
    },
    [onKpiLimitRemove, orgKey, saveLimit]
  );

  const kpiProviderValue: KpiOverviewContextType = useMemo(
    () => ({
      setDraggingDisabled,
      onKpiLimitChange,
      onKpiCustomLimitChange,
      onTargetAreaChange: onTargetAreaChangeCallback,
      onKpiChange,
      onKpiLimitRemove,
    }),
    [
      setDraggingDisabled,
      onKpiLimitChange,
      onKpiCustomLimitChange,
      onTargetAreaChangeCallback,
      onKpiChange,
      onKpiLimitRemove,
    ]
  );

  return (
    <KpiOverviewContext.Provider value={kpiProviderValue}>
      <div className={Styles.KpiOverview}>
        <FloatingComponent className={Styles.FloatingComp}>
          <Header.Header className={Styles.header}>
            <Header.TitleSlot>Übersicht</Header.TitleSlot>
            <TextButton
              ref={kpiAddButtonRef}
              className={Styles.AddKpi}
              onClick={onKpiPopupOpenCallback}
              disabled={!canAddMoreKpis}
              text='Kennzahl hinzufügen'
            />
            <PopOver
              anchorElement={kpiAddButtonRef.current}
              placement={'auto-start'}
              visible={kpiPopupOpen}
              onClose={onKpiPopupCloseCallback}>
              <KpiSelectorOverlay selected={selectedIdentifiers} onSelect={onKpiSelectCallback} />
            </PopOver>
            <Header.ActionsSlot>
              <KpiOverviewSettings
                tabItems={config?.tabs}
                canAddMoreTabs={canAddMoreTabs}
                // FIXME: Resolve event type issue
                // @ts-ignore
                onDragOver={onTabDragOver}
                // FIXME: Resolve event type issue
                // @ts-ignore
                onDragEnd={onTabDragEnd}
                onTabRemove={onTabRemove}
                onTabRename={onTabRename}
                onTabAdd={onTabAdd}
              />
            </Header.ActionsSlot>
            <Header.DateSelector
              container='body'
              selectedDuration={duration}
              onDurationSelected={onDurationSelection}
              config={DateSelectIncludingTodayConfig}
            />
          </Header.Header>
          <Tabs borderBottom>
            {config?.tabs?.map((tab, idx) => (
              <Tab key={tab.name} isActive={activeTab === idx} onTabClick={() => onTabChange(idx)}>
                <EditableText
                  contentEditable='false'
                  initialContent={tab.name}
                  maxLength={15}
                  onEdit={(content) => onTabRename(idx, content)}
                />
              </Tab>
            ))}
            {canAddMoreTabs && (
              <div className={Styles.addTabWrapper}>
                <Tooltip tooltipText='Tab hinzufügen'>
                  <IconButton icon={<MdAdd />} onClick={() => onTabAdd()} />
                </Tooltip>
              </div>
            )}
          </Tabs>
          <div className={Styles.Body}>
            {configLoading ? (
              <div className={Styles.LoaderWrapper}>
                <Loader />
              </div>
            ) : (
              <SortableList
                shouldCancelStart={() => draggingDisabled}
                distance={5}
                axis={'xy'}
                items={items}
                onSortStart={onSortStart}
                onSortEnd={onSortEnd}
                helperClass={Styles.SortableHelper}
                removeItem={onKpiRemove}
                highlightLastItem={highlightLastItem}
                itemsPerRow={itemsPerRow}
                selectedKpiKey={selectedKpiKey}
                duration={duration}
                workdaysInRange={workdaysInRange}
              />
            )}
          </div>
        </FloatingComponent>
      </div>
    </KpiOverviewContext.Provider>
  );
};

export default KpiOverview;
