import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';

import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

export default new ValueExpression({
  identifier: 'tour_loaded.count',
  label: 'Einsätze',
  chart: ChartType.bar,
  positiveDirection: PositiveDirection.custom,
  category: 'tours',
  description: '<p>Anzahl aller geladenen Touren gezählt nach dem ersten abgeschlossenen Zustellvorgang</p>\n',
  definition: getDefinitionByName('Einsätze (Touren)'),
});
