import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';

import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

export default new ValueExpression({
  identifier: 'stock.stored',
  label: 'Lagernde',
  chart: ChartType.bar,
  positiveDirection: 'down',
  category: 'stock',
  description: '<p>Description for stock.stored - tbd</p>\n',
  definition: getDefinitionByName('Lagernde'),
});
