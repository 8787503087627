import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';

import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

export default new ValueExpression({
  identifier: 'zustellungen',
  positiveDirection: PositiveDirection.custom,
  label: 'Zustellungen',
  definition: getDefinitionByName('Zustellungen'),
  description: '<p>Anzahl erfolgreich zugestellter Sendungen (kein Rücklauf)</p>\n',
});
