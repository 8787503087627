import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import ValueExpression, {
  TargetValueType,
} from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

export default new ValueExpression({
  identifier: 'produktivitaet-ht',
  label: 'HT-Produktivität',
  definition: getDefinitionByName('HT-Produktivität'),
  positiveDirection: PositiveDirection.custom,
  // da die Netto-HT-Zeit (Nenner) bei der Berechnung in Sekunden ausgewiesen ist,
  // wir aber die Produktivität in Stunden haben wollen
  valueQuotient: 1 / 3600,
  valueFormat: '-#.###.##0,0',
  targetValueType: TargetValueType.QUOTE,
  decimalPlaces: 2,
});
