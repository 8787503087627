import React, { forwardRef } from 'react';
import NumberFormat from 'react-number-format';

type Props = {
  className?: string;
  value?: number;
  onValueChange: (decimal: number) => void;
  onBlur?: () => void;
  allowMoreThan100Percent?: boolean;
  decimalPlaces?: number;
  name?: string;
  tabIndex?: number;
};

const PercentageInput = forwardRef<NumberFormat<Props>, Props>(
  (
    {
      className = '',
      value,
      onValueChange,
      onBlur,
      allowMoreThan100Percent = true,
      decimalPlaces = 2,
      name,
      tabIndex,
      ...other
    }: Props,
    ref
  ) => {
    const val = typeof value === 'number' && !isNaN(value) ? (value).toFixed(decimalPlaces) : null;

    return (
      <NumberFormat
        {...other}
        ref={ref}
        className={className}
        suffix={'%'}
        name={name}
        value={val}
        decimalSeparator={','}
        isNumericString={true}
        displayType={'input'}
        thousandSeparator={'.'}
        fixedDecimalScale={true}
        decimalScale={decimalPlaces}
        onBlur={onBlur}
        tabIndex={tabIndex}
        onValueChange={(values) => {
          const { value } = values;
          let parsedValue = Number.parseFloat(value);
          if (parsedValue > 100 && !allowMoreThan100Percent) {
            parsedValue = 100;
          }
          onValueChange(parsedValue === 0 ? 0 : parsedValue);
        }}
      />
    );
  }
);

PercentageInput.displayName = 'PercentageInput';
export default PercentageInput;
