import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';

import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

export default new ValueExpression({
  identifier: 'finished_deliveries.items.delivered.inkasso_amount',
  label: 'Kassierter Geldbetrag',
  chart: ChartType.line,
  positiveDirection: PositiveDirection.custom,
  valueFormat: '#.###.##0, Cent',
  category: 'deliveries',
  description: '<p>Euro-Summe aller erfolgreich zugestellten Sendungen mit Nachnahmebetrag</p>\n',
});
