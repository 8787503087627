import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';

import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ValueExpression, {
  TargetValueType,
} from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

export default new ValueExpression({
  identifier: 'stock.remaining_per_inventory',
  positiveDirection: PositiveDirection.down,
  label: 'Liegenbleiber-Quote',
  definition: getDefinitionByName('Liegenbleiber-Quote'),
  chart: ChartType.bar,
  category: 'stock',
  valueFormat: '-#.###.##0,#0%',
  valueQuotient: 0.01,
  percent: true,
  targetValueType: TargetValueType.QUOTE,
  decimalPlaces: 2,
});
